import MultiOption from 'components/Form/MultiOption/MultiOption';
import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import { iconsTemp } from 'iconsTemp';
import IOption from 'interfaces/IOption';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
//import styles from './AppointmentsTags.module.scss';

export type AppointmentsTagsProps = {
  className?: string;
  multi: boolean;
  join: boolean;
  nrRemote?: number;
  nrHome?: number;
  nrOffice?: number;
  onChange: (option: IOption, checked?: boolean) => void;
};

const AppointmentsTags: React.FC<AppointmentsTagsProps> = (props: React.PropsWithChildren<AppointmentsTagsProps>) => {
  const { className, multi, join, nrRemote, nrHome, nrOffice, onChange } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const placesNrs = {
    [AppointmentPlaces.Remote]: nrRemote,
    [AppointmentPlaces.Home]: nrHome,
    [AppointmentPlaces.Office]: nrOffice,
  };
  const options: IOption[] = Object.values(AppointmentPlaces).map((place: AppointmentPlaces) => ({
    id: place,
    name: `${language[place]}${placesNrs[place] !== undefined ? ` (${placesNrs[place]})` : ''}`,
    icon: iconsTemp.appointmentPlaces[place],
  }));

  return (
    <MultiOption
      className={className}
      identifier='appointment-tags'
      multi={multi}
      join={join}
      options={options}
      onChange={onChange}
    />
  );
};

export default AppointmentsTags;
