import { Icon, IProps, rgba, styles, useMediaScreen, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import ILanguage from 'interfaces/ILanguage';
import EventDTO from 'dtos/EventDTO';
import AppointmentWarning from 'components/Appointment/AppointmentWarning';
import { canStartAppointment } from 'utils/appointment';
import { AppointmentCardInfo, AppointmentCardTag, AppointmentCardWrapper } from './AppointmentCard.styles';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import MediaScreenMobile from 'components/Media/MediaScreenMobile';

export interface AppointmentCardProps extends PropsWithChildren<IProps> {
  tagsClassName?: string;
  appointmentEvent: EventDTO;
  placeIcon: string;
  isSelected?: boolean;
  hasPassed: boolean;
  hideInfoOnWarning?: boolean;
  onSelect: (appointmentId: string) => void;
}

const AppointmentCard: FC<AppointmentCardProps> = (props: AppointmentCardProps): JSX.Element => {
  const {
    className,
    style,
    tagsClassName,
    appointmentEvent,
    placeIcon,
    isSelected,
    hasPassed,
    hideInfoOnWarning,
    onSelect,
  } = props;
  const { appointment, nrNotes, nrFiles } = appointmentEvent;
  const { user, place } = appointment!;
  const { formatDate, masks } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const { isMobileScreen } = useMediaScreen();
  const showWarning = canStartAppointment(appointmentEvent);

  return (
    <AppointmentCardWrapper
      data-testid='appointment'
      className={className}
      style={style}
      isSelected={isSelected}
      hasPassed={hasPassed}
      onClick={() => onSelect(appointment!.id)}>
      <UserImage src={user?.image} />
      <AppointmentCardInfo className={styles.info} direction='column' alignItems='flex-start'>
        <h5>{user ? user.name : language.unknown}</h5>
        {showWarning && hideInfoOnWarning && <AppointmentWarning appointmentEvent={appointmentEvent} />}
        {!(showWarning && hideInfoOnWarning) && (
          <div>
            {showWarning && <AppointmentWarning appointmentEvent={appointmentEvent} />}
            {!showWarning && (
              <MediaScreenMobile>
                <AppointmentCardTag
                  className={tagsClassName}
                  icon='timer'
                  label={formatDate(appointmentEvent.start, masks.shortTime)}
                />
              </MediaScreenMobile>
            )}
            <MediaScreenDesktop>
              <AppointmentCardTag
                className={tagsClassName}
                icon='details'
                label={nrNotes !== undefined ? `${nrNotes} ${language.notes}` : '-'}
              />
              <AppointmentCardTag
                className={tagsClassName}
                icon='image'
                label={nrFiles !== undefined ? `${nrFiles} ${language.files}` : '-'}
              />
            </MediaScreenDesktop>
            <AppointmentCardTag
              className={tagsClassName}
              icon={placeIcon as any}
              label={language[place as keyof ILanguage]}
            />
          </div>
        )}
      </AppointmentCardInfo>
      {!(isMobileScreen && isSelected) && (
        <Icon src='arrowRight' fill={isSelected ? styles.colors.brand.dark : rgba(styles.colors.brand.dark, 0.3)} />
      )}
      {isMobileScreen && isSelected && <Icon className='camera' src='videoCall' />}
    </AppointmentCardWrapper>
  );
};

AppointmentCard.defaultProps = {
  isSelected: false,
  hideInfoOnWarning: true,
};

export default AppointmentCard;
