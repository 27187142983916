import Toast, { ToastTypes } from 'components/View/Toast';
import { iconsTemp } from 'iconsTemp';
import styles from './Toasts.module.scss';

export type ToastsProps = {};

const Toasts: React.FC<ToastsProps> = (props: React.PropsWithChildren<ToastsProps>) => {
  const {} = props;

  return (
    <div className={styles.container}>
      <div>
        <div>
          <h4>Info</h4>
        </div>
        <div>
          <h4>Success</h4>
        </div>
        <div>
          <h4>Error</h4>
        </div>
      </div>
      <div>
        <div>
          <Toast
            className={styles.toast}
            content={{ icon: iconsTemp.close, title: 'O cancelamento com João Pires foi efetuado.' }}
            onClose={() => {}}
          />
          <Toast
            className={styles.toast}
            content={{
              icon: iconsTemp.calendar,
              title: 'O pedido de reagendamento com João Pires foi efetuado.',
            }}
            onClose={() => {}}
          />
          <Toast
            className={styles.toast}
            content={{
              icon: iconsTemp.calendar,
              title: 'Agendamento efetuado.',
              subtitle: 'Serás rederecionado para a página de pagamento.',
            }}
            onClose={() => {}}
          />
        </div>
        <div>
          <Toast
            className={styles.toast}
            content={{
              type: ToastTypes.SUCCESS,
              icon: iconsTemp.check,
              title: 'A disponibilidade foi adicionada com sucesso!',
            }}
            onClose={() => {}}
          />
          <Toast
            className={styles.toast}
            content={{
              type: ToastTypes.SUCCESS,
              icon: iconsTemp.delete,
              title: 'A disponibilidade foi eliminada com sucesso.',
            }}
            onClose={() => {}}
          />
          <Toast
            className={styles.toast}
            content={{
              type: ToastTypes.SUCCESS,
              icon: iconsTemp.price,
              title: 'O pagamento foi efetuado com sucesso!',
            }}
            onClose={() => {}}
          />
        </div>
        <div>
          <Toast
            className={styles.toast}
            content={{
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: 'Ocorreu um erro ao eliminar a disponibilidade.',
              subtitle: 'Vamos verificar o problema. Tente novamente mais tarde.',
            }}
            onClose={() => {}}
          />
          <Toast
            className={styles.toast}
            content={{
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: 'Ocorreu um erro ao efetuar agendamento de consulta.',
              subtitle: 'Vamos verificar o problema. Por favor, tente mais tarde.',
            }}
            onClose={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default Toasts;
