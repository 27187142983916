import { styles, rgba } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface ICancelAppointmentSuggestionsProps {}

export const CancelAppointmentSuggestions = styled.div<ICancelAppointmentSuggestionsProps>`
  margin-top: 2rem;

  > p {
    margin: 2rem 1rem 0 1rem;
    color: ${rgba(styles.colors.brand.dark, 0.6)};
  }
`;
