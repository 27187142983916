import styles from './BaseInput.module.scss';
import { InputProps, InputsInnerProps } from './Input';

export type BaseInputProps = InputProps & InputsInnerProps & {};

const BaseInput: React.FC<BaseInputProps> = (props: React.PropsWithChildren<BaseInputProps>) => {
  const {
    dataTestId,
    className,
    type,
    label,
    placeholder,
    value,
    canEdit,
    disabled,
    success,
    error,
    help,
    changeHandler,
    focusHandler,
    blurHandler,
    children,
  } = props;

  return (
    <div className={`${styles.container} ${className}`}>
      {success && (
        <span data-testid={`${dataTestId}_success`} className={styles['success-message']}>
          {success}
        </span>
      )}
      {error && (
        <span data-testid={`${dataTestId}_error`} className={styles['error-message']}>
          {error}
        </span>
      )}
      <label
        data-testid={!canEdit ? dataTestId : undefined}
        className={`${styles.input} ${disabled && styles.disabled} ${success && styles.success} ${
          error && styles.error
        } ${label && styles.label} ${help && styles.help}`}>
        {label && <span>{label}</span>}
        <input
          data-testid={canEdit ? dataTestId : undefined}
          type={type}
          placeholder={placeholder}
          value={value as string | number}
          disabled={disabled}
          readOnly={canEdit !== undefined ? !canEdit : false}
          onChange={changeHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
        {children}
      </label>
      {help && (
        <span data-testid={`${dataTestId}_help`} className={styles['help-message']}>
          {help}
        </span>
      )}
    </div>
  );
};

export default BaseInput;
