import { FC, PropsWithChildren } from 'react';
import VideoStream from 'components/Call/VideoStream/VideoStream';
import { useCallStreams } from './CallStreams.logic';
import styles from './CallStreams.module.scss';

export type CallStreamsProps = {
  onMainVideoClick: () => void;
};

const CallStreams: FC<CallStreamsProps> = (props: PropsWithChildren<CallStreamsProps>) => {
  const { onMainVideoClick } = props;
  const {
    remoteStreamExists,
    screenShareIsInMainVideo,
    cameraIsOn,
    remoteCameraIsOn,
    remoteShareIsOn,
    mainStreamVideoRef,
    sideStream1VideoRef,
    sideStream2VideoRef,
    toggleMainVideo,
  } = useCallStreams();

  return (
    <>
      <VideoStream
        className={styles['main-stream']}
        streamIsOn={remoteStreamExists ? (screenShareIsInMainVideo ? remoteShareIsOn : remoteCameraIsOn) : cameraIsOn}
        mute={!remoteStreamExists}
        onClick={onMainVideoClick}
        ref={mainStreamVideoRef}
      />
      {remoteStreamExists && (
        <div className={styles['side-streams']}>
          {remoteShareIsOn && (
            <VideoStream
              streamIsOn={screenShareIsInMainVideo ? remoteCameraIsOn : remoteShareIsOn}
              mute={false}
              onClick={toggleMainVideo}
              ref={sideStream1VideoRef}
            />
          )}
          <VideoStream streamIsOn={cameraIsOn} mute={false} ref={sideStream2VideoRef} />
        </div>
      )}
    </>
  );
};

export default CallStreams;
