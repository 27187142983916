import NotificationDTO from 'dtos/NotificationDTO';
import { getTimeAgo } from 'utils/language';
import useNotification from './Notification.logic';
import styles from './Notification.module.scss';
import NotificationUser from './NotificationUser';

export type NotificationProps = {
  dataTestId?: string;
  notification: NotificationDTO;
};

const Notification: React.FC<NotificationProps> = (props: React.PropsWithChildren<NotificationProps>) => {
  const { dataTestId, notification } = props;
  const { type, image, icon, userName, message, mainLine, lineThrough, read, date } = notification;
  const { language, notificationRef, formatDate } = useNotification(props);

  return (
    <li data-testid={dataTestId} className={`${styles.notification} ${!read && styles.new}`} ref={notificationRef}>
      <NotificationUser type={type} image={image!} icon={icon} />
      <ul className={styles.info}>
        <li>
          {userName && (
            <>
              <b data-testid={`${dataTestId}-userName`}>{userName}</b>&nbsp;
            </>
          )}
          {message}
        </li>
        {mainLine && <li data-testid={`${dataTestId}-mainLine`}>{mainLine}</li>}
        {lineThrough && (
          <li data-testid={`${dataTestId}-strikeThroughLine`} className={styles['line-through']}>
            {lineThrough}
          </li>
        )}
        <li data-testid={`${dataTestId}-date`}>{getTimeAgo(language, formatDate, date)}</li>
      </ul>
      {!read && (
        <div data-testid={`${dataTestId}-alert`} className={styles.alert}>
          <svg viewBox='0 0 100 100' width={6} height={6} xmlns='http://www.w3.org/2000/svg'>
            <circle cx={50} cy={50} r={50} />
          </svg>
        </div>
      )}
    </li>
  );
};

export default Notification;
