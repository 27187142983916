import useOnScreen from 'hooks/use-onScreen';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { RefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition, Transition, TransitionStatus } from 'react-transition-group';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import Icon from './Icon';
import styles from './LoadMore.module.scss';
import sass from 'styles/exports.module.scss';

export type LoadMoreProps = {
  canLoadMore: boolean;
  allLoadedText?: string;
  onLoadMore: () => void;
};

const LoadMore: React.FC<LoadMoreProps> = (props: React.PropsWithChildren<LoadMoreProps>) => {
  const { canLoadMore, allLoadedText, onLoadMore } = props;
  const logger = useWinstonLogger();
  const language = useSelector((state: IReduxState) => state.language.values);
  const { elementRef, isIntersecting } = useOnScreen(false);

  useEffect(() => {
    if (isIntersecting && canLoadMore) {
      logger.log(LOG_COMPONENT, 'requesting to load more items');
      onLoadMore();
    }
  }, [isIntersecting, canLoadMore]);

  return (
    <div ref={elementRef as RefObject<HTMLDivElement>}>
      <SwitchTransition>
        <Transition key={canLoadMore ? 'load-more' : 'all-loaded'} timeout={+sass.transition}>
          {(state: TransitionStatus) => (
            <div className={`${styles['load-more']} ${styles[state]}`}>
              {canLoadMore && (
                <>
                  <Icon className={styles['loading_icon']} src={iconsTemp.loading} />
                  <span>{language.seeMore}</span>
                </>
              )}
              {!canLoadMore && allLoadedText && <span>{allLoadedText}</span>}
            </div>
          )}
        </Transition>
      </SwitchTransition>
    </div>
  );
};

export default LoadMore;
