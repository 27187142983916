import { RefObject, useContext, useEffect, useRef } from 'react';
import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import AgendaContext from 'store/agenda-context';
import FullCalendar from '@fullcalendar/react';
import { useLocation } from 'react-router-dom';
import { dateIsValid } from 'utils/date';
import ICalendarEvent from 'interfaces/ICalendarEvent';

export interface IUseWeekView {
  days: string[];
  weekEvents: ICalendarEvent[];
  calendarRef: RefObject<FullCalendar>;
  weekCalendarFrameRef: RefObject<HTMLDivElement>;
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string;
  selectDayNumberHandler: (selectedDate: Date) => void;
}

const useWeekView = (): IUseWeekView => {
  const location = useLocation();
  const { weekEvents, calendarRef, selectDayNumberHandler } = useContext(AgendaContext);
  const { days, formatDate } = useDate();
  const weekCalendarFrameRef = useRef<HTMLDivElement>(null);
  const searchParameters = new URLSearchParams(location.search);
  const urlStartDate = searchParameters.get('start');

  useEffect(() => {
    if (dateIsValid(urlStartDate)) calendarRef.current?.getApi().gotoDate(new Date(urlStartDate!));
  }, []);

  useEffect(() => {
    if (!weekCalendarFrameRef.current) return;

    resizeHandler();
    window.onresize = resizeHandler;
  }, [weekCalendarFrameRef.current]);

  // resizes the frame on the week view
  const resizeHandler = (): void => {
    if (!weekCalendarFrameRef.current) return;

    const laneElement = document.querySelector(
      '.fc-timeGridWeek-view .fc-timegrid-slot-lane'
    ) as HTMLTableElement | null;
    const scrollerContainer = document.querySelector(
      '.fc-timeGridWeek-view > table > tbody > tr:last-child .fc-scroller'
    );

    if (laneElement) {
      weekCalendarFrameRef.current.style.left = `${laneElement.offsetLeft}px`;
      weekCalendarFrameRef.current.style.width = `${laneElement.getBoundingClientRect().width}px`;
    }

    if (scrollerContainer)
      weekCalendarFrameRef.current.style.height = `${scrollerContainer.getBoundingClientRect().height}px`;

    const axisColWidth = document.querySelector('.fc-timegrid-slot.fc-timegrid-slot-label')!.clientWidth;
    document.querySelectorAll('.fc-timegrid-axis').forEach((tableElement: Element) => {
      (tableElement as HTMLTableElement).style.width = `${axisColWidth}px`;
    });
  };

  return {
    days,
    weekEvents,
    calendarRef,
    weekCalendarFrameRef,
    formatDate,
    selectDayNumberHandler,
  };
};

export default useWeekView;
