import { FC, PropsWithChildren } from 'react';
import FullCalendar, {
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  SlotLabelContentArg,
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import WeekEvent from '../Events/WeekEvent';
import { areEventsEqual } from 'utils/appointment';
import useWeekView from './WeekView.logic';
import './WeekView.scss';

export interface WeekViewProps {
  selectedEvent?: EventClickArg;
  onSelectEvent: (arg: EventClickArg) => void;
  onCloseEventPopup: () => void;
}

const WeekView: FC<WeekViewProps> = (props: PropsWithChildren<WeekViewProps>): JSX.Element => {
  const { selectedEvent, onSelectEvent, onCloseEventPopup } = props;
  const { days, weekEvents, calendarRef, weekCalendarFrameRef, formatDate, selectDayNumberHandler } = useWeekView();

  return (
    <>
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView='timeGridWeek'
        height='100%'
        headerToolbar={false}
        firstDay={1}
        allDayContent={null}
        unselectAuto={false}
        scrollTime='08:00:00'
        // days head
        navLinks={true}
        navLinkDayClick={selectDayNumberHandler}
        dayHeaderContent={(dayHeader: DayHeaderContentArg) => (
          <>
            {days[dayHeader.date.getDay()]}
            <br />
            <span data-testid={`agenda-calendarDayNumber${formatDate(dayHeader.date, GlobalDateMasks.urlDate)}`}>
              {dayHeader.date.getDate()}
            </span>
          </>
        )}
        // slots
        slotDuration={{ minutes: 30 }}
        slotLabelInterval={{ hours: 1 }}
        slotLabelContent={(slotLabel: SlotLabelContentArg) => formatDate(slotLabel.date, DateMasks.shortTime)}
        // events
        events={weekEvents}
        eventContent={(eventContentArg: EventContentArg) => (
          <WeekEvent
            event={eventContentArg}
            selected={areEventsEqual(selectedEvent?.event, eventContentArg.event)}
            onClose={onCloseEventPopup}
          />
        )}
        eventClick={onSelectEvent}
        slotEventOverlap={false}
        ref={calendarRef}
      />
      <div className='frame' ref={weekCalendarFrameRef} />
    </>
  );
};

export default WeekView;
