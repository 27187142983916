import useDate, { DateMasks } from 'hooks/use-date';
import { FC, PropsWithChildren, useContext } from 'react';
import AgendaContext from 'store/agenda-context';
import { isToday } from 'utils/date';
import styles from './DayCalendarHeader.module.scss';

export interface DayCalendarHeaderProps {}

const DayCalendarHeader: FC<DayCalendarHeaderProps> = (
  props: PropsWithChildren<DayCalendarHeaderProps>
): JSX.Element => {
  const {} = props;
  const { selectedDate } = useContext(AgendaContext);
  const { formatDate } = useDate();

  if (!selectedDate) return <></>;

  return (
    <div className={styles['day-calendar-header']}>
      <h4>
        <span className={`${isToday(selectedDate) && styles.today}`}>{selectedDate.getUTCDate()}</span>
        {formatDate(selectedDate, DateMasks.monthYear)}
      </h4>
    </div>
  );
};

export default DayCalendarHeader;
