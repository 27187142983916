import Icon from 'components/View/Icon';
import styles from './NotificationUser.module.scss';
import { NotificationType } from 'dtos/NotificationDTO';
import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export type NotificationUserProps = {
  type: NotificationType;
  image: string;
  icon: string;
};

const NotificationUser: React.FC<NotificationUserProps> = (props: React.PropsWithChildren<NotificationUserProps>) => {
  const { type, image, icon } = props;

  return (
    <div className={styles['notification-user']}>
      <UserImage dataTestId='notification-userImage' src={image} />
      <Icon dataTestId='notification-typeIcon' className={`${styles.icon} ${styles[type]}`} src={icon} />
    </div>
  );
};

export default NotificationUser;
