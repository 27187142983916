import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { getDoctorAbreviation } from 'utils/language';
import { Flex, IProps, rgba, styles, Tag, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import { AccountInfoUserWrapper } from './AccountInfoUser.styles';
import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { userIsMedic } from 'utils/user';

export interface IAccountInfoUserProps extends PropsWithChildren<IProps> {}

const AccountInfoUser: FC<IAccountInfoUserProps> = (props: IAccountInfoUserProps): JSX.Element => {
  const { className, style } = props;
  const { formatDate, masks } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const user = useSelector((state: IReduxState) => state.entity)!;
  const { name, gender, image, licence } = user;

  return (
    <AccountInfoUserWrapper className={className} style={style} justifyContent='flex-start' alignItems='flex-end'>
      <UserImage style={{ marginRight: '1rem' }} src={image} size='5.75rem' square />
      <Flex direction='column' alignItems='flex-start'>
        <h5>{userIsMedic(userType) ? getDoctorAbreviation(language, gender, name) : name}</h5>
        {licence && (
          <p>
            {language.medicalCertificate}: {licence}
          </p>
        )}
        <Tag
          style={{ marginTop: '0.5rem' }}
          icon='time'
          label={`${language.inSmartALSince} ${formatDate(new Date(), masks.mediumDate)}`}
          color={rgba(styles.colors.brand.dark, 0.75)}
        />
      </Flex>
    </AccountInfoUserWrapper>
  );
};

export default AccountInfoUser;
