import { DraftInlineStyle } from 'draft-js';
import useMediaScreen from 'hooks/use-mediaScreen';
import { useEffect, useState, MouseEvent } from 'react';
import { STYLES, STYLE_TYPE } from 'services/notes.service';
import { WriteNoteFormattingProps } from './WriteNoteFormatting';

export interface IUseWriteNoteFormatting {
  isOpen: boolean;
  currentStyle: DraftInlineStyle;
  blockType: string;
  toggleShowStylesHandler: () => void;
  selectFormatHandler: (event: MouseEvent, style: string, type: STYLE_TYPE) => void;
}

const useWriteNoteFormatting = (props: WriteNoteFormattingProps): IUseWriteNoteFormatting => {
  const { editorState, onToggle, onClear } = props;
  const { isSmartphoneScreen } = useMediaScreen();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const currentStyle = editorState.getCurrentInlineStyle() as DraftInlineStyle;
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    .getType() as string;

  useEffect(() => {
    if (isSmartphoneScreen) setIsOpen(false);
    else setIsOpen(true);
  }, [isSmartphoneScreen]);

  const toggleShowStylesHandler = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  const selectFormatHandler = (event: MouseEvent, style: string, type: STYLE_TYPE): void => {
    event.preventDefault();

    if (style === STYLES.EMPTY) onClear();
    else onToggle(style, type);
  };

  return { isOpen, currentStyle, blockType, toggleShowStylesHandler, selectFormatHandler };
};

export default useWriteNoteFormatting;
