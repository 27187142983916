import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import { FC, PropsWithChildren } from 'react';
import useWriteNoteAutoSave from './WriteNoteAutoSave.logic';
import styles from './WriteNoteAutoSave.module.scss';

export interface WriteNoteAutoSaveProps {
  save: boolean;
}

const WriteNoteAutoSave: FC<WriteNoteAutoSaveProps> = (
  props: PropsWithChildren<WriteNoteAutoSaveProps>
): JSX.Element => {
  const {} = props;
  const { language, isLoading, success, failed } = useWriteNoteAutoSave(props);

  return (
    <div data-testid='autoSave-container' className={styles['auto-save']}>
      {isLoading && (
        <Icon
          dataTestId='autoSave-loadingIcon'
          className={`${styles.icon} ${styles.loading}`}
          src={iconsTemp.loading}
        />
      )}
      {!isLoading && success && (
        <>
          <Icon dataTestId='autoSave-successfull' className={styles.icon} src={iconsTemp.loadingSuccessfull} />
          <p data-testid='autoSave-successfull'>{language.savedAutomatically}</p>
        </>
      )}
      {!isLoading && failed && (
        <>
          <Icon dataTestId='autoSave-failed' className={styles.icon} src={iconsTemp.loadingFailed} />
          <p data-testid='autoSave-failed'>{language.savedAutomaticallyFailed}</p>
        </>
      )}
    </div>
  );
};

export default WriteNoteAutoSave;
