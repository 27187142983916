import { ValueSliderProps, ValueSliderRef } from 'components/View/ValueSlider';
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { map } from 'utils/general';

type WindowSize = {
  width: number;
  height: number;
};

export type UseValueSlider = {
  position: string;
  containerRef: React.RefObject<HTMLDivElement>;
  grabHandler: (event: React.MouseEvent) => void;
};

const useValueSlider = (props: ValueSliderProps, ref: ForwardedRef<ValueSliderRef>): UseValueSlider => {
  const { initialValue, minValue, maxValue, onChange } = props;
  const [windowSize, setWindowSize] = useState<WindowSize>({ width: 0, height: 0 });
  const [position, setPosition] = useState<number>(0);
  const [margin, setMargin] = useState<number>(0);
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({ value: calculateMappedValue() }));

  useEffect(() => {
    const updateWindowSize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', updateWindowSize);
    setPosition(map(initialValue, minValue, maxValue, margin, 100 - margin));

    return () => window.removeEventListener('resize', updateWindowSize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      const newMargin = containerRef.current ? (10 * 100) / containerRef.current!.clientWidth : 0;
      setMargin(newMargin);
      setPosition((prevState) => {
        if (prevState === 100 - margin) prevState = 100;
        else if (prevState === margin) prevState = 0;

        return Math.min(Math.max(newMargin, prevState), 100 - newMargin);
      });
    }, 500);
  }, [windowSize.width]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const move = (event: MouseEvent) => moveHandler(event, isMoving, margin);

    window.addEventListener('mousemove', move);
    window.addEventListener('mouseup', dropHandler);

    return () => {
      window.removeEventListener('mousemove', move);
      window.removeEventListener('mouseup', dropHandler);
    };
  }, [isMoving, margin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onChange) onChange(calculateMappedValue());
  }, [position]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateMappedValue = (): number => {
    return map(position, margin, 100 - margin, minValue, maxValue);
  };

  const grabHandler = (event: React.MouseEvent): void => {
    event.preventDefault();
    setIsMoving(true);
  };

  const moveHandler = (event: MouseEvent, isMoving: boolean, margin: number): void => {
    if (!isMoving) return;

    const deltaX = (event.movementX * 100) / containerRef.current!.clientWidth;
    setPosition((prevState) => Math.min(Math.max(margin, prevState + deltaX), 100 - margin));
  };

  const dropHandler = (event: MouseEvent): void => {
    event.preventDefault();
    setIsMoving(false);
  };

  return { position: `${position}%`, containerRef, grabHandler };
};

export default useValueSlider;
