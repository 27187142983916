import { Popup } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IHeaderProfilePopupProps {}

export const HeaderProfilePopup = styled(Popup)<IHeaderProfilePopupProps>`
  align-items: flex-start;
  padding-right: 1rem;
  padding-left: 1rem;
`;
