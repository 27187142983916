import useUserGuard from 'guards/UserGuard';
import { iconsTemp } from 'iconsTemp';
import IRoute, { IAppointmentCallSubRoutes } from 'interfaces/IRoute';
import { useCallback, useContext } from 'react';
import { generatePath, matchPath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import ChatContext from 'store/chat-context';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import AppointmentContext from 'store/appointment-context';

export interface IUseAppointmentSideButtons {
  actionButtons: ActionButton[];
  hasUnreadMessages: boolean;
  userCanSee: (meta: any) => boolean;
  clickHandler: (index: number) => void;
}

export interface ActionButton {
  route: IRoute;
  active: boolean;
  icon: string;
  showMessagesNotification: boolean;
  dataTestId?: string;
}

const buttonsIcons = {
  notes: iconsTemp.details,
  chat: iconsTemp.chat,
  settings: iconsTemp.options,
};

export const useAppointmentSideButtons = (): IUseAppointmentSideButtons => {
  const logger = useWinstonLogger();
  const { userCanSee } = useUserGuard();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { appointmentEvent } = useContext(AppointmentContext);
  const { hasUnreadMessages } = useContext(ChatContext);
  const actionButtons = useCallback((): ActionButton[] => {
    const appointmentCallSubRoutes = appointmentSubRoutes.call.subRoutes as IAppointmentCallSubRoutes;
    const buttons: ActionButton[] = [];

    Object.entries(appointmentCallSubRoutes).forEach(([key, route]: [key: string, route: IRoute]) => {
      buttons.push({
        route,
        active: !!matchPath(location.pathname, route.path),
        icon: buttonsIcons[key as keyof IAppointmentCallSubRoutes],
        showMessagesNotification: key === 'chat',
        dataTestId: `call-${key}Button`,
      });
    });

    return buttons;
  }, [location.pathname])();

  const clickHandler = (index: number): void => {
    if (actionButtons[index].active) {
      logger.log(LOG_COMPONENT, `redirecting user to ${match.path}`);
      history.replace(generatePath(match.path, { appointmentId: appointmentEvent!.appointment!.id }));
    } else {
      logger.log(LOG_COMPONENT, `redirecting user to ${actionButtons[index].route.path}`);
      history.replace(
        generatePath(actionButtons[index].route.path, {
          appointmentId: appointmentEvent!.appointment!.id,
          page: match.path.includes('call') ? 'call' : match.path.includes('end') ? 'end' : '',
        })
      );
    }
  };

  return { actionButtons, hasUnreadMessages, userCanSee, clickHandler };
};
