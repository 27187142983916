import { IOption, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import PatientDTO from 'dtos/PatientDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCatalogService from 'services/catalog.service';
import useMedicsService from 'services/medics.service';
import usePatientsService from 'services/patients.service';
import { IPatientDataProps } from './PatientData';

export interface IPatientData {
  icon: string;
  iconColor: any;
  name: string;
  list?: IOption[];
  tags?: IOption[];
}

export interface IUsePatientData {
  patientData: IPatientData[];
}

const usePatientData = (props: IPatientDataProps): IUsePatientData => {
  const { patientId } = props;
  const { getMedicPatient } = useMedicsService();
  const { getCurrentPatient } = usePatientsService();
  const { getDailyHabitsOptions } = useCatalogService();
  const language = useSelector((state: IReduxState) => state.language.values);
  // const [diseases, setDiseases] = useState<IOption[]>([]);
  const [medication, setMedication] = useState<IOption[]>([]);
  const [allergies, setAllergies] = useState<IOption[]>([]);
  const [dailyHabits, setDailyHabits] = useState<IOption[]>([]);
  const [patientInformations, setPatientInformations] = useState<IOption[]>([]);

  useEffect(() => {
    const infoToOption = (tags: string[]): IOption[] =>
      tags.map((tag: string, index: number) => ({ id: `${index}`, name: tag }));

    (patientId ? getMedicPatient(patientId) : getCurrentPatient()).then(async (patient: PatientDTO) => {
      // if (patient.diseases) setDiseases([]);
      if (patient.allergies) setAllergies(infoToOption(patient.allergies));
      if (patient.medication) setMedication(infoToOption(patient.medication));

      setPatientInformations([
        { id: 'gender', icon: 'gender', name: language[patient.gender.toLowerCase() as keyof ILanguage] },
        { id: 'height', icon: 'height', name: `${patient.height ? `${patient.height} ${language.meters}` : '-'}` },
        { id: 'weight', icon: 'weight', name: `${patient.weight ? `${patient.weight} kg` : '-'}` },
        {
          id: 'address',
          icon: 'address',
          name: patient.city || '-',
        },
      ]);

      if (patient.dailyHabits) setDailyHabits(await getDailyHabitsOptions(...patient.dailyHabits));
    });
  }, [patientId]);

  return {
    patientData: [
      // {
      //   icon: 'chronicDisease',
      //   iconColor: styles.colors.feedback.alert,
      //   name: language.knownChronicDiseases,
      //   list: diseases,
      // },
      { icon: 'allergy', iconColor: styles.colors.feedback.warning1, name: language.knownAllergies, list: allergies },
      { icon: 'pills', iconColor: styles.colors.auxiliary.auxiliary5, name: language.medication, list: medication },
      {
        icon: 'steps',
        iconColor: styles.colors.auxiliary.auxiliary3,
        name: language.dailyHabits,
        tags: dailyHabits,
      },
      {
        icon: 'userData',
        iconColor: styles.colors.brand.dark,
        name: language.patientInformations,
        tags: patientInformations,
      },
    ],
  };
};

export default usePatientData;
