import NotificationDTO from 'dtos/NotificationDTO';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';
import NotificationMapper from 'mappers/NotificationMapper';
import NotificationSchema from 'schemas/NotificationSchema';
import logger, { LOG_ERROR, LOG_HTTP } from './logger';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyDCAT0KlI1l__fT7IdFDBr0NliD3_jptpw',
  authDomain: 'smartal-b2c-d943a.firebaseapp.com',
  projectId: 'smartal-b2c-d943a',
  storageBucket: 'smartal-b2c-d943a.appspot.com',
  messagingSenderId: '468267919103',
  appId: '1:468267919103:web:753b236e7c39da4a6d8eca',
  measurementId: 'G-X671LJ75F4',
});
const messaging = getMessaging(firebaseApp);

export const requestFCMToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY })
      .then((token: string) => {
        if (token) {
          logger.log(LOG_HTTP, `current token for firebase client: ${token}`);
          resolve(token);
        } else {
          logger.log(LOG_ERROR, 'no registration token available. request permission to generate one.');
          reject();
        }
      })
      .catch((err: any) => {
        logger.log(LOG_ERROR, `an error occurred while retrieving token. ${err}`);
        reject();
      });
  });
};

const notificationMapper = NotificationMapper();

export const onMessageListener = (): Promise<NotificationDTO> => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload: MessagePayload) => {
      logger.log(LOG_HTTP, 'received new notification');

      if (payload.data) {
        const notification = notificationMapper.toInterface(payload.data as any as NotificationSchema);
        resolve(notification);
      }
    });
  });
};
