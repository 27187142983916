import Button, { ButtonThemes } from 'components/Form/Button';
import { FC, PropsWithChildren } from 'react';
import styles from './CallButton.module.scss';

export interface CallButtonProps {
  dataTestId?: string;
  active: boolean;
  activeIcon: string;
  inactiveIcon?: string;
  label: string;
  isEnd?: boolean;
  onClick: () => void;
}

const CallButton: FC<CallButtonProps> = (props: PropsWithChildren<CallButtonProps>): JSX.Element => {
  const { dataTestId, active, activeIcon, inactiveIcon, label, isEnd, onClick } = props;

  return (
    <div>
      <Button
        dataTestId={dataTestId}
        className={`${!isEnd && styles.button} ${isEnd && styles['end_button']}`}
        activeClassName={`${!active && styles.active}`}
        theme={isEnd ? ButtonThemes.RED : ButtonThemes.MAIN}
        icon={active ? activeIcon : inactiveIcon ? inactiveIcon : activeIcon}
        active={!active}
        onClick={onClick}
      />
      <span>{label}</span>
    </div>
  );
};

export default CallButton;
