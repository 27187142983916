import React from 'react';
import useValueSlider from './ValueSlider.logic';
import styles from './ValueSlider.module.scss';

export type ValueSliderProps = {
  initialValue: number;
  minValue: number;
  maxValue: number;
  onChange?: (value: number) => void;
};

export type ValueSliderRef = { value: number };

const ValueSlider: React.FC<ValueSliderProps> = React.forwardRef(
  (props: React.PropsWithChildren<ValueSliderProps>, ref: React.ForwardedRef<ValueSliderRef>) => {
    const { position, containerRef, grabHandler } = useValueSlider(props, ref);

    return (
      <div className={styles.slider} ref={containerRef}>
        <svg height='22'>
          <line x1='3' y1='10' x2={position} y2='10' strokeWidth='6' strokeLinecap='round' />
          <line x1={position} y1='10' x2={`calc(100% - 3px)`} y2='10' strokeWidth='6' strokeLinecap='round' />
          {new Array(2).fill(0).map((_, i) => (
            <circle key={i} cx={position} cy='10' r='10' onMouseDown={grabHandler} />
          ))}
        </svg>
      </div>
    );
  }
);

export default ValueSlider;
