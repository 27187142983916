import { Flex, getGutter, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IRelatedUsersFiltersWrapperProps {}

export const RelatedUsersFiltersWrapper = styled(Flex)<IRelatedUsersFiltersWrapperProps>`
  padding-right: ${getGutter()};
  box-sizing: border-box;

  .search_input {
    width: 50%;
  }

  div {
    width: auto;
  }
`;

export interface IRelatedUsersFiltersLabelProps {}

export const RelatedUsersFiltersLabel = styled.span<IRelatedUsersFiltersLabelProps>`
  margin-right: 0.5rem;
  color: ${rgba(styles.colors.brand.dark, 0.75)};
`;
