import { IMultiOptionProps, MultiOption, rgba, styles, transition } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import Accordion from 'components/View/Accordion/Accordion';
import styled, { css } from 'styled-components';

export interface IAvailableSlotsSliderEmptyMessageProps {}

export const AvailableSlotsSliderEmptyMessage = styled.p<IAvailableSlotsSliderEmptyMessageProps>`
  overflow-y: hidden;
  margin: 0.5rem 0;
  color: ${rgba(styles.colors.brand.dark, 0.75)};
  font-size: ${styles.textSize.small};
  ${transition('max-height', 'margin')}

  &.empty-message-enter-done {
    max-height: 10rem;
  }

  &.empty-message-enter,
  &.empty-message-exit,
  &.empty-message-exit-done {
    margin: 0;
    max-height: 0;
  }
`;

export interface IAvailableSlotsSliderAccordionProps {}

export const AvailableSlotsSliderAccordion = styled(Accordion)<IAvailableSlotsSliderAccordionProps>`
  padding: 0 ${styles.iconSize.normal};
`;

export interface IAvailableSlotsSliderMultiOptionProps {
  hasOptions: boolean;
}

export const AvailableSlotsSliderMultiOption = styled(MultiOption)<IAvailableSlotsSliderMultiOptionProps>`
  align-items: flex-start;
  flex: 1;
  height: inherit;

  ${({ hasOptions }: IAvailableSlotsSliderMultiOptionProps) =>
    hasOptions
      ? css``
      : css`
          height: 100%;
        `}

  &:not(:last-child) {
    padding-right: 0.5rem;
    border-right: 1px solid ${rgba(styles.colors.brand.dark, 0.1)};
    margin-right: 0.5rem;
  }

  > * {
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;

    > * {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 0.5rem;
      }

      div {
        min-width: 100%;
        box-sizing: border-box;
      }
    }
  }
`;

export interface IAvailableSlotsSliderNoAvailabilitiesMessageProps {}

export const AvailableSlotsSliderNoAvailabilitiesMessage = styled.p<IAvailableSlotsSliderNoAvailabilitiesMessageProps>`
  width: 100%;
  color: ${rgba(styles.colors.brand.dark, 0.75)};
  font-size: ${styles.textSize.small};
  text-align: center;
`;
