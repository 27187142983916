import {
  Button,
  FormContextProvider,
  getColumnsWidth,
  PasswordInput,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import { initialChangePasswordFormInputs } from 'services/authentication.service';
import useSecurity from './Security.logic';

export interface ISecurityProps extends PropsWithChildren<{}> {}

const Security: FC<ISecurityProps> = (props: ISecurityProps): JSX.Element => {
  const {} = props;
  const { language, changePasswordHandler } = useSecurity();

  return (
    <FormContextProvider
      style={{ width: getColumnsWidth({ nrColumns: 3 }), margin: '0 auto' }}
      initialValues={initialChangePasswordFormInputs}
      onSubmit={changePasswordHandler}>
      <h5>{language.changePassword}</h5>
      <PasswordInput
        dataTestId='accountSecurity-currentPasswordInput'
        name='currentPassword'
        placeholder={language.currentPassword}
      />
      <PasswordInput dataTestId='accountSecurity-newPasswordInput' name='newPassword' />
      <PasswordInput
        dataTestId='accountSecurity-confirmPasswordInput'
        name='confirmNewPassword'
        placeholder={language.confirmPassword}
        includeHelp
      />
      <Button
        dataTestId='accountSecurity-submitButton'
        style={{ margin: 'auto' }}
        label={language.changePassword}
        buttonType='submit'
      />
    </FormContextProvider>
  );
};

export default Security;
