import AvailabilityDTO from 'dtos/AvailabilityDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import AgendaContext from 'store/agenda-context';
import { FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import useAvailabilitiesService from 'services/availabilities.service';

export interface IUseAddAvailability {
  language: ILanguage;
  isLoading: boolean;
  createAvailabilityHandler: (submit: FormSubmit) => void;
  closeAvailabilityPanelHandler: () => void;
}

const useAddAvailability = (): IUseAddAvailability => {
  const { isLoading, createAvailability } = useAvailabilitiesService();
  const { addAvailableEventsHandler, closeAvailabilityPanelHandler } = useContext(AgendaContext);
  const language = useSelector((state: IReduxState) => state.language.values);

  const createAvailabilityHandler = (submit: FormSubmit) => {
    createAvailability(submit).then((availability: AvailabilityDTO) => {
      addAvailableEventsHandler(availability.events!);
    });
  };

  return {
    language,
    isLoading,
    createAvailabilityHandler,
    closeAvailabilityPanelHandler,
  };
};

export default useAddAvailability;
