import { InputsReducerType } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { getUniqueValues } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { appointmentsAPI, entitiesAPI } from 'apis';
import { ToastTypes } from 'components/View/Toast';
import { AssociatedPatientDTO } from 'dtos/AssociatedUserDTO';
import MedicDTO from 'dtos/MedicDTO';
import { PaginationDTO } from 'dtos/PaginationDTO';
import PatientDTO from 'dtos/PatientDTO';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import AssociatedPatientMapper from 'mappers/AssociatedPatientMapper';
import MedicMapper from 'mappers/MedicMapper';
import PatientMapper from 'mappers/PatientMapper';
import SpecialtyMapper from 'mappers/SpecialtyMapper';
import { useDispatch, useSelector } from 'react-redux';
import AssociatedPatientSchema from 'schemas/AssociatedPatientSchema';
import MedicSchema from 'schemas/MedicSchema';
import PaginationSchema from 'schemas/PaginationSchema';
import PatientSchema from 'schemas/PatientSchema';
import SpecialtySchema from 'schemas/SpecialtySchema';
import { feedbackActions } from 'store/redux/feedback-slice';
import useEntitiesService from './entities.service';
import useFilesService from './files.service';
import useHttp from './http.service';

const medicMapper = MedicMapper();
const patientMapper = PatientMapper();
const specialtyMapper = SpecialtyMapper();
const associatedPatientMapper = AssociatedPatientMapper();

export interface IUseMedicsService {
  isLoading: boolean;
  createMedic: (inputs: InputsReducerType) => Promise<MedicDTO>;
  updateCurrentMedic: (inputs: InputsReducerType) => Promise<MedicDTO>;
  getCurrentMedic: (includeImage?: boolean) => Promise<MedicDTO>;
  getMedic: (medicId: string, includeImage?: boolean) => Promise<MedicDTO>;
  createOrUpdateCurrentMedicImage: (image: File) => Promise<void>;
  getCurrentMedicImage: () => Promise<string>;
  getMedicImage: (medicId: string) => Promise<string>;
  getCurrentMedicSpecialties: () => Promise<SpecialtyDTO[]>;
  getMedicSpecialties: (medicId: string) => Promise<SpecialtyDTO[]>;
  getMedicPatient: (patientId: string) => Promise<PatientDTO>;
  getMedicPatientImage: (patientId: string) => Promise<string>;
  getMedicRelatedPatients: () => Promise<PaginationDTO<AssociatedPatientDTO>>;
}

const useMedicsService = (): IUseMedicsService => {
  const dispatch = useDispatch();
  const { isLoading, get, post, put, patch } = useHttp();
  const { removeRegisterValuesFromStorage } = useEntitiesService();
  const { getNrFilesBetweenUsers } = useFilesService();
  const languageCode = useSelector((state: IReduxState) => state.language.code);
  const userId = useSelector((state: IReduxState) => state.auth.id);
  const language = useSelector((state: IReduxState) => state.language.values);

  // POST /medics
  const createMedic = (inputs: InputsReducerType): Promise<MedicDTO> => {
    return new Promise(async (resolve, reject) => {
      const body = await medicMapper.toBody(inputs);

      post(`${entitiesAPI}/medics`, undefined, body)
        .then((medic: MedicSchema) => {
          removeRegisterValuesFromStorage();
          resolve(medicMapper.toInterface(medic, languageCode!));
        })
        .catch(() => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastError-createMedic',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorCreatingUser,
              subtitle: language.weWillCheckTheProblem,
            })
          );

          reject();
        });
    });
  };

  // PATCH /medics
  const updateCurrentMedic = (inputs: InputsReducerType): Promise<MedicDTO> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (inputs.photo?.value) await createOrUpdateCurrentMedicImage(inputs.photo.value);

        const body = await medicMapper.toBody(inputs);
        const medic: MedicSchema = await patch(`${entitiesAPI}/medics`, undefined, body);
        resolve(medicMapper.toInterface(medic, languageCode!));
      } catch (_: unknown) {
        dispatch(
          feedbackActions.addMessage({
            dataTestId: 'toastError-updateMedic',
            type: ToastTypes.ERROR,
            icon: iconsTemp.close,
            title: language.errorCreatingUser,
            subtitle: language.weWillCheckTheProblem,
          })
        );

        reject();
      }
    });
  };

  // GET /medics/{medicId}
  const getCurrentMedic = (includeImage: boolean = true): Promise<MedicDTO> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/${userId}`, { with_image: includeImage })
        .then((medic: MedicSchema) => {
          const mappedMedic = medicMapper.toInterface(medic, languageCode!);
          resolve(mappedMedic);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /medics/{medicId}
  const getMedic = (medicId: string, includeImage: boolean = true): Promise<MedicDTO> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/${medicId}`, { with_image: includeImage })
        .then((medic: MedicSchema) => {
          const mappedMedic = medicMapper.toInterface(medic, languageCode!);
          resolve(mappedMedic);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // PUT /medics/image
  const createOrUpdateCurrentMedicImage = (image: File): Promise<void> => {
    const body = new FormData();
    body.append('image', image);

    return new Promise(async (resolve, reject) => {
      put(`${entitiesAPI}/medics/image`, undefined, body)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /medics/image
  const getCurrentMedicImage = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/image`)
        .then((blobUrl: string) => {
          resolve(blobUrl);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /medics/{medicId}/image
  const getMedicImage = (medicId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/${medicId}/image`)
        .then((blobUrl: string) => {
          resolve(blobUrl);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /medics/{medicId}/specialties
  const getCurrentMedicSpecialties = (): Promise<SpecialtyDTO[]> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/${userId}/specialties`)
        .then((specialties: SpecialtySchema[]) => {
          resolve(specialties.map((specialty: SpecialtySchema) => specialtyMapper.toInterface(specialty)));
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /medics/{medicId}/specialties
  const getMedicSpecialties = (medicId: string): Promise<SpecialtyDTO[]> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/${medicId}/specialties`)
        .then((specialties: SpecialtySchema[]) => {
          resolve(specialties.map((specialty: SpecialtySchema) => specialtyMapper.toInterface(specialty)));
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /medics/patients/{patientId}
  const getMedicPatient = (patientId: string): Promise<PatientDTO> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/patients/${patientId}`)
        .then((patient: PatientSchema) => {
          const mappedPatient = patientMapper.toInterface(patient);
          resolve(mappedPatient);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /medics/patients/{patientId}/image
  const getMedicPatientImage = (patientId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/medics/patients/${patientId}/image`)
        .then((blobUrl: string) => {
          resolve(blobUrl);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /appointments/patients
  const getMedicRelatedPatients = (): Promise<PaginationDTO<AssociatedPatientDTO>> => {
    return new Promise((resolve, reject) => {
      get(`${appointmentsAPI}/patients`)
        .then(async (pagination: PaginationSchema<AssociatedPatientSchema>) => {
          const patients = pagination.content;
          const usersIds = getUniqueValues(patients, 'id');

          const nrFilesBetweenUsers = (
            await Promise.allSettled([...usersIds.map((userId: string) => getNrFilesBetweenUsers(userId))])
          ).map((result) =>
            result.status === 'fulfilled' ? (result as PromiseFulfilledResult<number>).value : undefined
          );

          resolve({
            list: patients.map((patient: AssociatedPatientSchema) => {
              const patientIdIndex = usersIds.findIndex((patientId: string) => patientId === patient.id);

              return associatedPatientMapper.toInterface({
                ...patient,
                numberOfFiles: nrFilesBetweenUsers[patientIdIndex],
              });
            }),
            last: pagination.last,
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

  return {
    isLoading,
    createMedic,
    updateCurrentMedic,
    getCurrentMedic,
    getMedic,
    createOrUpdateCurrentMedicImage,
    getCurrentMedicImage,
    getMedicImage,
    getCurrentMedicSpecialties,
    getMedicSpecialties,
    getMedicPatient,
    getMedicPatientImage,
    getMedicRelatedPatients,
  };
};

export default useMedicsService;
