import { Action, ActionTypes, FormSubmit, getInputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { IScheduleAppointmentRouteState } from 'interfaces/IRouteStates';
import { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import routes from 'routes/routes';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export interface IUseAvailableSchedules {
  language: ILanguage;
  medicId: string;
  scheduleAppointmentHandler: (submit: FormSubmit) => void;
}

const useAvailableSchedules = (): IUseAvailableSchedules => {
  const logger = useWinstonLogger();
  const history = useHistory();
  const { id: medicId } = useParams<{ id: string }>();
  const language = useSelector((state: IReduxState) => state.language.values);

  const scheduleAppointmentHandler = ({ inputs }: FormSubmit): void => {
    logger.log(LOG_COMPONENT, "going to 'schedule appointment' page");
    history.push(generatePath(routes.schedule.path, { medicId }), {
      specialty: getInputValue(inputs.specialty),
      type: getInputValue(inputs.type),
      event: getInputValue(inputs.event),
    } as IScheduleAppointmentRouteState);
  };

  return { language, medicId, scheduleAppointmentHandler };
};

export default useAvailableSchedules;
