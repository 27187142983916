import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import DailyHabitDTO from 'dtos/DailyHabitDTO';
import DailyHabitSchema, { DailyHabitOptionSchema, DailyHabitsTypes } from 'schemas/DailyHabitSchema';

const DailyHabitMapper = () => {
  const typeToIcon = (type: DailyHabitsTypes): string => {
    switch (type) {
      case DailyHabitsTypes.FOOD:
        return 'calories';
      case DailyHabitsTypes.ACTIVITY:
        return 'muscleRatio';
      case DailyHabitsTypes.WATER:
        return 'waterRatio';
      case DailyHabitsTypes.SLEEP:
        return 'sleep';
      case DailyHabitsTypes.SMOKE:
        return 'spo2';
      default:
        return '';
    }
  };

  const toInterface = (dailyHabit: DailyHabitSchema, languageCode: LanguageCode): DailyHabitDTO => {
    return {
      id: dailyHabit.id,
      icon: typeToIcon(dailyHabit.type),
      name: dailyHabit.name[languageCode],
      options: dailyHabit.options.map((dailyHabitOption: DailyHabitOptionSchema) => ({
        id: dailyHabitOption.id,
        name: dailyHabitOption.name[languageCode],
      })),
    };
  };

  return { toInterface };
};

export default DailyHabitMapper;
