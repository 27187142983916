import Message from './Message';
import styles from './MessagesGroup.module.scss';
import useDate, { DateMasks } from 'hooks/use-date';
import { IMessageGroup } from 'interfaces/IGroup';
import MessageDTO from 'dtos/MessageDTO';

export type MessagesGroupProps = {
  dataTestId?: string;
  group: IMessageGroup;
};

const MessagesGroup: React.FC<MessagesGroupProps> = (props: React.PropsWithChildren<MessagesGroupProps>) => {
  const { dataTestId, group } = props;
  const { date, messages } = group;
  const { formatDate } = useDate();

  return (
    <div data-testid={dataTestId} className={styles.group}>
      <h6 data-testid='messages-day'>{formatDate(date, DateMasks.shortDateTime)}</h6>
      {messages.map((message: MessageDTO, i: number) => (
        <Message key={message.id} dataTestId={`${dataTestId}-message${i}`} message={message} />
      ))}
    </div>
  );
};

export default MessagesGroup;
