import { getColumnsWidth } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface ITodaysEventsSideContainerProps {}

export const TodaysEventsSideContainer = styled.section<ITodaysEventsSideContainerProps>`
  width: ${getColumnsWidth({ nrColumns: 8 })};
  padding-bottom: 0 !important;
`;

/*
@media screen and (max-width: ($smallDesktop + 0px)) {
  .appointments {
    width: getColWidth($nrColumns: 5);
  }

  .appointment-detail,
  .no-appointments {
    width: getColWidth($nrColumns: 7);
  }
}

@media screen and (max-width: ($tablet + 0px)) {
  .appointments,
  .appointment-detail {
    width: 100vw;
    height: 100%;
    margin: 0;
    padding-top: 0 !important;
    background-color: transparent !important;
  }

  .appointments {
    padding-bottom: 4.3rem !important;
  }

  .appointment-detail {
    position: absolute;
    overflow-y: auto;
    padding: 0 !important;
    z-index: 30;
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .appointments {
    padding-bottom: 3.4rem !important;
  }
}
*/
