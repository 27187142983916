import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes/routes';

export type UsePasswordResetSent = {
  language: ILanguage;
};

const usePasswordResetSent = (): UsePasswordResetSent => {
  const history = useHistory();
  const language = useSelector((state: IReduxState) => state.language.values) as ILanguage;

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push(routes.login.path);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { language };
};

export default usePasswordResetSent;
