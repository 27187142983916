import WriteNoteFormatting from './WriteNoteFormatting';
import { FC, PropsWithChildren } from 'react';
import { getBlockClass } from 'services/notes.service';
import { Editor } from 'draft-js';
import 'draft-js/dist/Draft.css';
import styles from './WriteNote.module.scss';
import WriteNoteAutoSave from './WriteNoteAutoSave';
import useNote from './WriteNote.logic';
import MediaScreenSmartphone from 'components/Media/MediaScreenSmartphone';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';

export interface WriteNoteProps {}

const WriteNote: FC<WriteNoteProps> = (props: PropsWithChildren<WriteNoteProps>): JSX.Element => {
  const {} = props;
  const {
    language,
    editorState,
    saveNote,
    formatterToggleHandler,
    clearFormattingHandler,
    handleKeyCommand,
    changeHandler,
  } = useNote();

  return (
    <>
      <MediaScreenDesktop>
        <WriteNoteFormatting
          editorState={editorState}
          onToggle={formatterToggleHandler}
          onClear={clearFormattingHandler}
        />
      </MediaScreenDesktop>
      <div className={styles.note}>
        <Editor
          blockStyleFn={getBlockClass}
          placeholder={language.writeNotes}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={changeHandler}
        />
        <WriteNoteAutoSave save={saveNote} />
      </div>
      <MediaScreenSmartphone>
        <WriteNoteFormatting
          editorState={editorState}
          onToggle={formatterToggleHandler}
          onClear={clearFormattingHandler}
        />
      </MediaScreenSmartphone>
    </>
  );
};

export default WriteNote;
