import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import ILanguage from 'interfaces/ILanguage';
import { Gender } from 'dtos/EntityDTO';
import { getHoursUntilNow, getMinutesUntilNow } from './date';
import { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import AlertDTO, { AlertTypes } from 'dtos/AlertDTO';

// language related attributes
const languages = [
  { code: LanguageCode.pt_PT, name: 'Português' },
  { code: LanguageCode.en_US, name: 'English (US)' },
  { code: LanguageCode.es_ES, name: 'Español' },
];

export const getLanguages = (): LanguageCode[] => {
  return Object.values(LanguageCode);
};

export const getLanguageName = (languageCode: LanguageCode): string => {
  return languages.find((language) => language.code === languageCode)!.name;
};

export const getLanguageFlag = (languageCode: LanguageCode): string => {
  return `${process.env.PUBLIC_URL}/flags/${languageCode}.jpg`;
};

export const toCodeAlpha2 = (languageCode: LanguageCode): string => {
  return languageCode.split('_')[0];
};

// place values on strings
export const placeValue = (text: string, value: string | number): string => {
  if (!text) return '';
  return text.replace('${}', value.toString());
};

export const placeValues = (text: string, values: (string | number)[]): string => {
  if (!text) return '';

  values.forEach((value: string | number) => {
    text = text.replace('${}', value.toString());
  });
  return text;
};

// date/time
export const getTimeAgo = (
  language: ILanguage,
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string,
  date: Date
): string => {
  const minutesDifference = getMinutesUntilNow(date);
  if (minutesDifference === 1) return language['1MinuteAgo'];
  if (minutesDifference < 60) return placeValue(language.xMinutesAgo, minutesDifference);

  const hoursDifference = getHoursUntilNow(date);
  if (hoursDifference === 1) return language['1HourAgo'];
  if (hoursDifference < 24) return placeValue(language.xHoursAgo, hoursDifference);

  // TODO: add custom labels 'yesterday at' and current week days (eg.: 'Tuesday at')

  return formatDate(date, DateMasks.shortDateTime);
};

// user
export const getDoctorAbreviation = (language: ILanguage, gender: Gender, name: string): string => {
  return `${gender === Gender.FEMALE ? language.doctorF : language.doctorM} ${name}`;
};

// appointment
export const getAppointmentIsAboutToStartInMinutes = (language: ILanguage, minutesToStart: number): string => {
  if (minutesToStart === 1) return language.appointmentIsAboutToStartSingular;
  else return placeValue(language.appointmentIsAboutToStartPlural, minutesToStart);
};

export const getAppointmentAlertMessage = (
  language: ILanguage,
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string,
  alert: AlertDTO
): string => {
  const values = [
    alert.appointmentEvent.appointment?.user?.name || language.unknown,
    formatDate(alert.appointmentEvent.start, DateMasks.shortTime),
  ];

  switch (alert.type) {
    case AlertTypes.USER_ENTERED_APPOINTMENT:
      return placeValues(language.userEnteredAppointment, values);
    case AlertTypes.USER_LEFT_APPOINTMENT:
      return placeValues(language.userLeftAppointment, values);
    default:
      return '';
  }
};

// results
export const getFoundXResults = (language: ILanguage, nrMedics: number): string => {
  if (nrMedics === 1) return language.found1Result;
  else return placeValue(language.foundXResults, nrMedics);
};

export const getXMedics = (language: ILanguage, nrMedics: number): string => {
  if (nrMedics === 1) return `1 ${language.medic}`;
  else return `${nrMedics} ${language.medics}`;
};

export const getXPatients = (language: ILanguage, nrPatients: number): string => {
  if (nrPatients === 1) return `1 ${language.patient}`;
  else return `${nrPatients} ${language.patients}`;
};
