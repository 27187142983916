import AvailabilityDTO from 'dtos/AvailabilityDTO';
import AvailabilityBody, { EditAvailabilityBody } from 'RequestsBody/AvailabilityBody';
import AvailabilitySchema from 'schemas/AvailabilitySchema';
import EventSchema from 'schemas/EventSchema';
import EventMapper from './EventMapper';
import { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import { getInputValue, InputsReducerType, InputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

const eventMapper = EventMapper();

const AvailabilityMapper = () => {
  const toInterface = (availability: AvailabilitySchema): AvailabilityDTO => {
    return {
      id: availability.id,
      medicId: availability.medicId,
      specialtyId: availability.specialtyId,
      events: availability.events?.map((event: EventSchema) =>
        eventMapper.toInterface(
          {
            ...event,
            availability: { id: availability.id, price: availability.price, currency: availability.currency },
          },
          undefined
        )
      ),
    };
  };

  const toCreateBody = (
    inputs: InputsReducerType,
    formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string
  ): AvailabilityBody => {
    const convertDate = (value: InputValue): string => formatDate(value as Date, GlobalDateMasks.urlDate);
    const convertTime = (value: InputValue): string => formatDate(value as Date, GlobalDateMasks.urlTime);

    return {
      appointmentType: getInputValue<string>(inputs.place)!.toUpperCase(),
      specialtyId: getInputValue(inputs.specialty)!,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate: getInputValue(inputs.startDate, convertDate)!,
      endDate: getInputValue(inputs.endDate, convertDate)!,
      startTime: getInputValue(inputs.startTime, convertTime)!,
      endTime: getInputValue(inputs.endTime, convertTime)!,
      duration: getInputValue(inputs.duration)!,
      price: getInputValue(inputs.cost)!,
      currency: 'EUR',
    };
  };

  const toEditBody = (inputs: InputsReducerType): EditAvailabilityBody => {
    const startDate = getInputValue<Date>(inputs.startDate)!;
    const startTime = getInputValue<Date>(inputs.startTime)!;

    const startAt = new Date(startDate.toISOString());
    startAt.setHours(startTime.getHours());
    startAt.setMinutes(startTime.getMinutes());

    return {
      availabilityId: getInputValue(inputs.availability)!,
      startAt: startAt.toISOString(),
    };
  };

  return { toInterface, toCreateBody, toEditBody };
};

export default AvailabilityMapper;
