import { Flex } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import Input, { INPUT_TYPES } from 'components/Form/Input/Input';
import Select from 'components/Form/Select';
import { FC, PropsWithChildren } from 'react';
import useRelatedUsersFilters from './RelatedUsersFilters.logic';
import { RelatedUsersFiltersLabel, RelatedUsersFiltersWrapper } from './RelatedUsersFilters.styles';

export interface IRelatedUsersFiltersProps extends PropsWithChildren<{}> {}

const RelatedUsersFilters: FC<IRelatedUsersFiltersProps> = (props: IRelatedUsersFiltersProps) => {
  const {} = props;
  const { language } = useRelatedUsersFilters();

  return (
    <RelatedUsersFiltersWrapper justifyContent='space-between'>
      <Input dataTestId='myRelatedUsers-searchInput' className='search_input' type={INPUT_TYPES.SEARCH} />
      <Flex as='label'>
        <RelatedUsersFiltersLabel>{language.orderBy}</RelatedUsersFiltersLabel>
        <Select
          options={[{ id: 1, name: 'Favoritos' }]}
          defaultOption={{ id: 1, name: language.favorites }}
          small={true}
        />
      </Flex>
    </RelatedUsersFiltersWrapper>
  );
};

export default RelatedUsersFilters;
