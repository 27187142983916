import { RefObject } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import CallContext from 'store/call-context';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export interface IUseCallStreams {
  remoteStreamExists: boolean;
  screenShareIsInMainVideo: boolean;
  cameraIsOn: boolean;
  remoteCameraIsOn: boolean;
  remoteShareIsOn: boolean;
  mainStreamVideoRef: RefObject<HTMLVideoElement>;
  sideStream1VideoRef: RefObject<HTMLVideoElement>;
  sideStream2VideoRef: RefObject<HTMLVideoElement>;
  toggleMainVideo: () => void;
}

export const useCallStreams = (): IUseCallStreams => {
  const logger = useWinstonLogger();
  const { localStream, connection, cameraIsOn, volume } = useContext(CallContext);
  const [screenShareIsInMainVideo, setScreenShareIsInMainVideo] = useState<boolean>(false);
  const mainStreamVideoRef = useRef<HTMLVideoElement>(null);
  const sideStream1VideoRef = useRef<HTMLVideoElement>(null);
  const sideStream2VideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    logger.log(LOG_COMPONENT, 'updating main stream');

    if (!connection) {
      logger.log(LOG_COMPONENT, 'setting local stream on main video');
      updateVideoStream(mainStreamVideoRef, localStream);
      return;
    }

    if (screenShareIsInMainVideo) updateVideoStream(mainStreamVideoRef, connection.remoteScreenShareStream);
    else updateVideoStream(mainStreamVideoRef, connection.remoteStream);
  }, [connection?.remoteStream, mainStreamVideoRef.current, sideStream1VideoRef.current, screenShareIsInMainVideo]);

  useEffect(() => {
    if (!connection) return;

    logger.log(LOG_COMPONENT, 'updating side stream 2');

    if (screenShareIsInMainVideo) updateVideoStream(sideStream1VideoRef, connection.remoteStream);
    else updateVideoStream(sideStream1VideoRef, connection.remoteScreenShareStream);
  }, [sideStream1VideoRef.current, screenShareIsInMainVideo]);

  useEffect(() => {
    logger.log(LOG_COMPONENT, 'updating side stream 2');

    updateVideoStream(sideStream2VideoRef, localStream);
  }, [sideStream2VideoRef?.current]);

  useEffect(() => {
    if (!mainStreamVideoRef.current || !sideStream1VideoRef.current) return;

    if (screenShareIsInMainVideo) {
      logger.log(LOG_COMPONENT, 'updating side stream 1 volume');

      mainStreamVideoRef.current.volume = 1;
      sideStream1VideoRef.current.volume = volume;
    } else {
      logger.log(LOG_COMPONENT, 'updating main stream volume');

      mainStreamVideoRef.current.volume = volume;
      sideStream1VideoRef.current.volume = 1;
    }
  }, [volume, screenShareIsInMainVideo]);

  useEffect(() => {
    setScreenShareIsInMainVideo(!!connection?.remoteShareIsOn);
  }, [connection?.remoteShareIsOn]);

  const updateVideoStream = (videoRef: RefObject<HTMLVideoElement>, stream: MediaStream | null): void => {
    if (videoRef.current && stream) videoRef.current.srcObject = stream;
  };

  const toggleMainVideo = (): void => {
    setScreenShareIsInMainVideo((prevState: boolean) => {
      const newState = !prevState;

      if (newState) logger.log(LOG_COMPONENT, `screen share is going to main video`);
      else logger.log(LOG_COMPONENT, `screen share is going to side video`);

      return newState;
    });
  };

  return {
    remoteStreamExists: !!connection?.remoteStream,
    screenShareIsInMainVideo,
    cameraIsOn,
    remoteCameraIsOn: !!connection?.remoteCameraIsOn,
    remoteShareIsOn: !!connection?.remoteShareIsOn,
    mainStreamVideoRef,
    sideStream1VideoRef,
    sideStream2VideoRef,
    toggleMainVideo,
  };
};
