import { Container, getColumnsWidth } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import PatientData from 'components/PatientData/PatientData';
import PatientInfoHeader from 'components/PatientInfo/PatientInfoHeader';
import MedicProfile from 'components/Profile/MedicProfile/MedicProfile';
import { PropsWithChildren, FC } from 'react';
import useMyProfile from './MyProfile.logic';

export interface IMyProfileProps extends PropsWithChildren<{}> {}

const MyProfile: FC<IMyProfileProps> = (props: IMyProfileProps): JSX.Element => {
  const {} = props;
  const { medic, patient, isMedic, isPatient } = useMyProfile();

  return (
    <>
      {isMedic && medic && <MedicProfile medic={medic} />}
      {isPatient && patient && (
        <Container style={{ width: getColumnsWidth({ nrColumns: 6 }) }}>
          <PatientInfoHeader patient={patient} />
          <PatientData />
        </Container>
      )}
    </>
  );
};

export default MyProfile;
