import { useMediaScreen } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import EventDTO from 'dtos/EventDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { ITodaySubRoutes } from 'interfaces/IRoute';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import homeSubRoutes from 'routes/homeSubRoutes';
import { ITodaysEventsProps } from './TodaysEvents';

export interface IUseTodaysEvents {
  language: ILanguage;
  selectedAppointmentId?: string;
  now: number;
  isMobileScreen: boolean;
  scrollViewRef: RefObject<HTMLDivElement>;
  nextEventContainerRef: RefObject<HTMLLIElement>;
  nextEventId?: string;
  selectHandler: (appointmentId?: string) => void;
}

const useTodaysEvents = (props: ITodaysEventsProps): IUseTodaysEvents => {
  const { events } = props;
  const { id: appointmentId } = useParams<{ id: string }>();
  const history = useHistory();
  const { isMobileScreen } = useMediaScreen();
  const language = useSelector((state: IReduxState) => state.language.values);
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const nextEventContainerRef = useRef<HTMLLIElement>(null);
  const now = new Date().getTime();

  useEffect(() => {
    if (!events || appointmentId) return;

    if (isMobileScreen) selectHandler(undefined);
    else {
      const nextAppointment = events.find((event: EventDTO) => event.appointment && event.end.getTime() > now);
      const firstAppointment = events?.find((event: EventDTO) => event.appointment);
      const appointmentToSelect = nextAppointment || firstAppointment;

      if (appointmentToSelect) selectHandler(appointmentToSelect.appointment!.id);
    }
  }, [events.length, isMobileScreen]);

  useEffect(() => {
    nextEventContainerRef.current?.scrollIntoView();
  }, [nextEventContainerRef.current]);

  const getNextEventId = useCallback((): string | undefined => {
    const nextEvent = events.find((event: EventDTO) => event.end.getTime() > now);
    return nextEvent?.id;
  }, []);

  const selectHandler = (id: string | undefined) => {
    if (appointmentId === id) return;

    if (id === undefined) {
      history.replace(homeSubRoutes.today.linkPath!);
      return;
    }

    const appointmentDetailRoutePath = (homeSubRoutes.today.subRoutes as ITodaySubRoutes).appointmentDetail.path;
    const path = generatePath(appointmentDetailRoutePath, { id });
    if (isMobileScreen) history.push(path);
    else history.replace(path);
  };

  return {
    language,
    selectedAppointmentId: appointmentId,
    now,
    isMobileScreen,
    scrollViewRef,
    nextEventContainerRef,
    nextEventId: getNextEventId(),
    selectHandler,
  };
};

export default useTodaysEvents;
