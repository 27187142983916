import { iconsTemp } from 'iconsTemp';
import Icon from 'components/View/Icon';
import styles from './CheckItem.module.scss';

export type CheckItemProps = {
  dataTestId?: string;
  identifier: string;
  type: 'radio' | 'checkbox';
  checked: boolean;
  onCheck: () => void;
  onUncheck: () => void;
};

const CheckItem: React.FC<CheckItemProps> = (props: React.PropsWithChildren<CheckItemProps>) => {
  const { dataTestId, identifier, type, checked, onCheck, onUncheck, children } = props;

  const changeHandler = (event: React.FormEvent<HTMLInputElement>): void => {
    if (event.currentTarget.checked) onCheck();
    else onUncheck();
  };

  return (
    <label className={styles['check-item']}>
      <input data-testid={dataTestId} type={type} name={identifier} checked={checked} onChange={changeHandler} />
      <Icon
        className={`${styles.icon} ${type == 'radio' && styles['radio-icon']} ${
          type == 'checkbox' && styles['check-icon']
        }`}
        src={type === 'radio' ? iconsTemp.radioButton : type == 'checkbox' ? iconsTemp.check : ''}
      />
      <span>{children}</span>
    </label>
  );
};

export default CheckItem;
