import useDate, { DateMasks } from 'hooks/use-date';
import ILanguage from 'interfaces/ILanguage';
import IOption from 'interfaces/IOption';
import IReduxState from 'interfaces/IReduxState';
import { CalendarViews } from 'mappers/EventMapper';
import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AgendaContext from 'store/agenda-context';
import { monthsAreEqual, yearsAreEqual } from 'utils/date';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export interface IUseCalendarHeader {
  language: ILanguage;
  showDatePicker: boolean;
  title: string;
  options: IOption[];
  selectedView: CalendarViews;
  selectedDate: Date;
  setShowDatePicker: Dispatch<SetStateAction<boolean>>;
  toggleDatePickerHandler: () => void;
  selectDatePickerDateHandler: (date: Date) => void;
  goToTodayHandler: () => void;
  previousHandler: () => void;
  nextHandler: () => void;
  changeViewHandler: (view: CalendarViews) => void;
}

const useCalendarHeader = (): IUseCalendarHeader => {
  const logger = useWinstonLogger();
  const location = useLocation();
  const searchParameters = new URLSearchParams(location.search);
  const urlStartDate = searchParameters.get('start');
  const { selectedView, selectedDate, calendarRef, changeViewHandler, changeActiveViewHandler, selectDayHandler } =
    useContext(AgendaContext);
  const { formatDate } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const getOptions = useCallback(
    (): IOption[] => [
      { id: CalendarViews.MONTH, name: language.month },
      { id: CalendarViews.WEEK, name: language.week },
    ],
    [language]
  );

  const toggleDatePickerHandler = (): void => {
    setShowDatePicker((prevState: boolean) => !prevState);
  };

  const selectDatePickerDateHandler = (date: Date): void => {
    selectDayHandler(date);
    setShowDatePicker((prevState: boolean) => !prevState);
  };

  const goToTodayHandler = (): void => {
    logger.log(LOG_COMPONENT, 'returning to today on calendar view');
    selectDayHandler(new Date());
  };

  const previousHandler = (): void => {
    if (!calendarRef.current) return;

    logger.log(LOG_COMPONENT, 'going to the previous calendar view');
    changeActiveViewHandler('prev');
  };

  const nextHandler = (): void => {
    if (!calendarRef.current) return;

    logger.log(LOG_COMPONENT, 'going to the next calendar view');
    changeActiveViewHandler('next');
  };

  const getWeekTittle = useCallback(
    (startDate: Date, endDate: Date): string => {
      if (monthsAreEqual(startDate, endDate))
        return `${startDate.getDate()} - ${endDate.getDate()} ${formatDate(endDate, DateMasks.monthYear)}`;
      else if (!yearsAreEqual(startDate, endDate))
        return `${formatDate(startDate, DateMasks.shortDayMonthYear)} - ${formatDate(
          endDate,
          DateMasks.shortDayMonthYear
        )}`;
      else return `${formatDate(startDate, DateMasks.shortDayMonth)} - ${formatDate(endDate, DateMasks.shortDayMonth)}`;
    },
    [formatDate]
  );

  const getTitle = useCallback((): string => {
    if (!calendarRef.current) return '-';

    const startDate = new Date(calendarRef.current.getApi().view.currentStart);
    const endDate = new Date(calendarRef.current.getApi().view.currentEnd);
    endDate.setDate(endDate.getDate() - 1);

    switch (selectedView) {
      case CalendarViews.MONTH:
        return formatDate(startDate, DateMasks.monthYear);
      case CalendarViews.WEEK:
        return getWeekTittle(startDate, endDate);
      default:
        return '-';
    }
  }, [selectedView, urlStartDate, calendarRef.current]);

  return {
    language,
    showDatePicker,
    title: getTitle(),
    options: getOptions(),
    selectedView: selectedView as CalendarViews,
    selectedDate: selectedDate!,
    setShowDatePicker,
    toggleDatePickerHandler,
    selectDatePickerDateHandler,
    goToTodayHandler,
    previousHandler,
    nextHandler,
    changeViewHandler,
  };
};

export default useCalendarHeader;
