import { communicationAPI } from 'apis';
import MessageDTO from 'dtos/MessageDTO';
import IReduxState from 'interfaces/IReduxState';
import MessageMapper from 'mappers/MessageMapper';
import { useSelector } from 'react-redux';
import MessageSchema from 'schemas/MessageSchema';
import { LOG_HTTP } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import useHttp from './http.service';

const messageMapper = MessageMapper();

export interface IUseMessagesService {
  getMessagesBetweenUsers: (userId: string, lastDate?: Date | undefined) => Promise<MessageDTO[]>;
}

const useMessagesService = (): IUseMessagesService => {
  const logger = useWinstonLogger();
  const { get } = useHttp();
  const userId = useSelector((state: IReduxState) => state.auth.id)!;

  const getMessagesBetweenUsers = (calleeId: string, lastDate?: Date): Promise<MessageDTO[]> => {
    logger.log(
      LOG_HTTP,
      `getting messages between users where the callee is ${calleeId} and the last message date is ${lastDate}`
    );

    const parameters = { lastDate: lastDate ? lastDate.getTime() : undefined };

    return new Promise((resolve, reject) => {
      get(`${communicationAPI}/messages/${calleeId}`, parameters)
        .then((messages: MessageSchema[]) => {
          resolve(messages.map((message: MessageSchema) => messageMapper.toInterface(userId, message)));
        })
        .catch(() => {
          reject();
        });
    });
  };

  return { getMessagesBetweenUsers };
};

export default useMessagesService;
