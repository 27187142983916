import IReduxState from 'interfaces/IReduxState';
import { IBuyAppointmentRouteParams } from 'interfaces/IRouteParams';
import { IBuyAppointmentRouteState } from 'interfaces/IRouteStates';
import { PropsWithChildren, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useAppointmentsService from 'services/appointments.service';
import { goBack } from 'utils/general';
import { BuyAppointmentWrapper, WalletIFrame } from './BuyAppointment.styles';

export interface IBuyAppointmentProps extends PropsWithChildren<{}> {}

const BuyAppointment: FC<IBuyAppointmentProps> = (props: IBuyAppointmentProps): JSX.Element => {
  const {} = props;
  const history = useHistory();
  const { appointmentId } = useParams<IBuyAppointmentRouteParams>();
  const location = useLocation<IBuyAppointmentRouteState>();
  const { startPayingAppointment } = useAppointmentsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [paymentLink, setPaymentLink] = useState<string | undefined>(location.state?.paymentLink);

  useEffect(() => {
    startPayingAppointment(appointmentId)
      .then((paymentLink) => setPaymentLink(paymentLink))
      .catch(() => goBack(history));
  }, [paymentLink]);

  if (!paymentLink) return <></>;

  return (
    <BuyAppointmentWrapper title={language.buyAppointment} onBack={() => goBack(history)}>
      <WalletIFrame
        src={`https://icheckout.sandbox.meowallet.pt//checkout/frame/${paymentLink?.split('/')[4]}`}
        title='Wallet'
      />
    </BuyAppointmentWrapper>
  );
};

export default BuyAppointment;
