import ILanguage from 'interfaces/ILanguage';
import IMediaDevice from 'interfaces/IMediaDevice';
import MediaDevicesMapper from 'mappers/MediaDevicesMapper';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { useWinstonLogger } from 'winston-react';
import { LOG_COMPONENT, LOG_RTC } from 'utils/logger';
import CallContext from 'store/call-context';

export interface IUseCallSettings {
  language: ILanguage;
  audioInputs: IMediaDevice[];
  videoInputs: IMediaDevice[];
  audioDevice: IMediaDevice | undefined;
  videoDevice: IMediaDevice | undefined;
  volume: number;
  microphoneVolume: number;
  noiseSuppression: boolean;
  changeVolumeHandler: (volume: number) => void;
  changeAudioDeviceHandler: (deviceId: string) => void;
  changeVideoDeviceHandler: (deviceId: string) => void;
  toggleNoiseSupressionHandler: (checked: boolean) => void;
}

const mediaDevicesMapper = MediaDevicesMapper();
let timeInterval: NodeJS.Timer;

export const useCallSettings = (): IUseCallSettings => {
  const logger = useWinstonLogger();
  const {
    localStream,
    volume,
    audioDeviceId,
    videoDeviceId,
    noiseSuppression,
    changeVolumeHandler,
    changeAudioDeviceHandler,
    changeVideoDeviceHandler,
    toggleNoiseSupressionHandler,
  } = useContext(CallContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [audioInputs, setAudioInputs] = useState<IMediaDevice[]>([]);
  const [videoInputs, setVideoInputs] = useState<IMediaDevice[]>([]);
  const [microphoneVolume, setMicrophoneVolume] = useState<number>(0);
  const [volumeListener, setVolumeListener] = useState<{ analyser: AnalyserNode; volumes: Uint8Array } | undefined>();

  useEffect(() => {
    setVolumeListener(() => {
      if (!localStream || localStream.getAudioTracks().length === 0) return undefined;

      logger.log(LOG_RTC, 'accessing and calculating microphone volume listener');

      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(localStream);

      analyser.fftSize = 1024;
      analyser.minDecibels = -127;
      analyser.maxDecibels = 0;
      analyser.smoothingTimeConstant = 0.8;

      microphone.connect(analyser);

      const volumes = new Uint8Array(analyser.frequencyBinCount);

      return { analyser, volumes };
    });
  }, []);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      logger.log(LOG_COMPONENT, 'getting media devices');
      const mappedDevices = mediaDevicesMapper.toInterface(devices);

      setAudioInputs(mappedDevices.audioinput);
      setVideoInputs(mappedDevices.videoinput);
    });
  }, [logger]);

  useEffect(() => {
    if (volumeListener) {
      const { analyser, volumes } = volumeListener;
      timeInterval = setInterval(() => {
        analyser.getByteFrequencyData(volumes);
        let volumeSum = 0;
        volumes.forEach((volume) => (volumeSum += volume));
        const averageVolume = volumeSum / volumes.length;
        setMicrophoneVolume((averageVolume * 100) / Math.abs(analyser.maxDecibels - analyser.minDecibels));
      }, 100);
    }

    return () => clearInterval(timeInterval);
  }, [volumeListener]);

  return {
    language,
    audioInputs,
    videoInputs,
    audioDevice: audioDeviceId ? audioInputs.find((input) => input.id === audioDeviceId) : audioInputs[0],
    videoDevice: videoDeviceId ? videoInputs.find((input) => input.id === videoDeviceId) : videoInputs[0],
    volume,
    microphoneVolume,
    noiseSuppression,
    changeVolumeHandler,
    changeAudioDeviceHandler,
    changeVideoDeviceHandler,
    toggleNoiseSupressionHandler,
  };
};
