import { useEffect, useRef, useState } from 'react';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export interface IUseCounter {
  counter: number;
  containerRef: React.RefObject<HTMLElement>;
  cancelHandler: () => void;
}

export interface UseCounterProps {
  timer?: number;
  onTimerEnd?: () => void;
  onCancel?: () => void;
  onClose: () => void;
}

const useCounter = (props: UseCounterProps): IUseCounter => {
  const { timer, onTimerEnd, onCancel, onClose } = props;
  const logger = useWinstonLogger();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();
  const [counter, setCounter] = useState<number>(timer ? timer : 3000);
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    logger.log(LOG_COMPONENT, 'starting timer');
    startInterval();
  }, []);

  useEffect(() => {
    if (containerRef.current && intervalId !== undefined) {
      containerRef.current.onmouseenter = () => {
        logger.log(LOG_COMPONENT, 'stoping timer on mouse enter');
        clearInterval(intervalId);
      };

      containerRef.current.onmouseleave = () => {
        logger.log(LOG_COMPONENT, 'restarting timer after mouse leave');
        startInterval();
      };
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    if (counter > 0) return;

    logger.log(LOG_COMPONENT, 'timer has finished');
    if (intervalId) clearInterval(intervalId);
    onClose();
    if (onTimerEnd) onTimerEnd();
  }, [counter, intervalId]);

  const startInterval = (): void => {
    setCounter(timer ? timer : 5000);
    setIntervalId(setInterval(() => setCounter((prevState: number) => prevState - 1000), 1000));
  };

  const cancelHandler = (): void => {
    logger.log(LOG_COMPONENT, 'cancelling timer');
    clearInterval(intervalId!);
    if (onCancel) onCancel();
    onClose();
  };

  return { counter: counter / 1000, containerRef, cancelHandler };
};

export default useCounter;
