import useReadMore from './ReadMore.logic';
import styles from './ReadMore.module.scss';

export type ReadMoreProps = {
  className?: string;
};

const ReadMore: React.FC<ReadMoreProps> = (props: React.PropsWithChildren<ReadMoreProps>) => {
  const { className, children } = props;
  const { language, isOpen, textRef, showReadMore, readMoreHandler } = useReadMore();

  return (
    <div className={`${styles['read-more']} ${className}`}>
      <p style={{ maxHeight: isOpen ? textRef.current?.scrollHeight : undefined }} ref={textRef}>
        {children}
      </p>
      {showReadMore && !isOpen && (
        <a href='#' onClick={readMoreHandler}>
          ...{language.seeMore?.toLowerCase()}
        </a>
      )}
    </div>
  );
};

export default ReadMore;
