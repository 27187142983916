import { FC, PropsWithChildren } from 'react';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import onboardingSubRoutes from 'routes/onboardingSubRoutes';
import { StartOnboardingWrapper } from './StartOnboarding.styles';
import { Button } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface IStartOnboardingProps extends PropsWithChildren<{}> {}

const StartOnboarding: FC<IStartOnboardingProps> = (props: IStartOnboardingProps): JSX.Element => {
  const {} = props;
  const history = useHistory();
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <StartOnboardingWrapper>
      <h1>{language.hello}</h1>
      <h5>{language.thanksForBecomingAMember}</h5>
      <p>{language.weAreGoingToCostumizeSomeFields}</p>
      <Button label={language.letsGo} onClick={() => history.push(onboardingSubRoutes.mandatory.path)} />
    </StartOnboardingWrapper>
  );
};

export default StartOnboarding;
