import MedicDTO from 'dtos/MedicDTO';
import PatientDTO from 'dtos/PatientDTO';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useMedicsService from 'services/medics.service';
import usePatientsService from 'services/patients.service';
import { userIsMedic, userIsPatient } from 'utils/user';

export interface IUseMedicProfile {
  medic?: MedicDTO;
  patient?: PatientDTO;
  isMedic: boolean;
  isPatient: boolean;
}

const useMyProfile = (): IUseMedicProfile => {
  const { getCurrentMedic } = useMedicsService();
  const { getCurrentPatient } = usePatientsService();
  const userId = useSelector((state: IReduxState) => state.auth.id)!;
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const [medic, setMedic] = useState<MedicDTO>();
  const [patient, setPatient] = useState<PatientDTO>();
  const isMedic = userIsMedic(userType);
  const isPatient = userIsPatient(userType);

  useEffect(() => {
    if (isMedic) getCurrentMedic().then((medic: MedicDTO) => setMedic(medic));
    if (isPatient) getCurrentPatient().then((patient: PatientDTO) => setPatient(patient));
  }, [userId]);

  return { medic, patient, isMedic, isPatient };
};

export default useMyProfile;
