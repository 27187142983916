import IOption from 'interfaces/IOption';
import { compareToSearch } from 'utils/filter';
import Popup from '../../View/Popup';
import BaseInput from './BaseInput';
import { InputProps, InputsInnerProps } from './Input';
import useOptioncInput from './OptionsInput.logic';
import styles from './OptionsInput.module.scss';

export type OptionsInputProps = InputProps & InputsInnerProps & {};

const OptionsInput: React.FC<OptionsInputProps> = (props: React.PropsWithChildren<OptionsInputProps>) => {
  const { inputValue: value, options } = props;
  const { selected, showPopup, containerRef, setShowPopup, changeTextHandler, openPopupHandler, selectOptionHandler } =
    useOptioncInput(props);

  if (!options) return <></>;

  return (
    <div className={styles['options_input']} ref={containerRef}>
      <BaseInput
        {...{
          ...props,
          value,
          changeHandler: changeTextHandler,
          focusHandler: openPopupHandler,
        }}
      />
      <Popup className={styles.popup} show={showPopup} close={setShowPopup}>
        <ul>
          {options
            .filter((option: IOption) => compareToSearch(value as string, option.name))
            .map((option: IOption) => (
              <li
                key={option.id}
                className={`${option.id === selected && styles.selected}`}
                onClick={() => selectOptionHandler(option)}>
                {option.name}
              </li>
            ))}
        </ul>
      </Popup>
    </div>
  );
};

export default OptionsInput;
