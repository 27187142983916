import NoteDTO from 'dtos/NoteDTO';
import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useNotesService from 'services/notes.service';
import AppointmentContext from 'store/appointment-context';
import { PreviousNotesProps } from './PreviousNotes';

export interface IUsePreviousNotes {
  language: ILanguage;
  notes: NoteDTO[];
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string;
}

const usePreviousNotes = (props: PreviousNotesProps): IUsePreviousNotes => {
  const { calleeId } = props;
  const { formatDate } = useDate();
  const { appointmentEvent } = useContext(AppointmentContext);
  const { getNotesBetweenUsers } = useNotesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [notes, setNotes] = useState<NoteDTO[]>([]);

  useEffect(() => {
    getNotesBetweenUsers(calleeId).then((notes: NoteDTO[]) => {
      setNotes(notes.filter((note: NoteDTO) => note.id !== appointmentEvent?.appointment?.id));
    });
  }, []);

  return { language, notes, formatDate };
};

export default usePreviousNotes;
