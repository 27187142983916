import { PhotoInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IOptionalOnboardingPhotoInputProps {}

export const OptionalOnboardingPhotoInput = styled(PhotoInput)`
  width: auto;
  margin: auto;

  + button {
    margin-top: 0;
  }
`;
