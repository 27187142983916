import { iconsTemp } from 'iconsTemp';
import Button, { ButtonTypes } from '../Button';
import BaseInput from './BaseInput';
import { InputProps, InputsInnerProps, INPUT_TYPES } from './Input';
import usePasswordInput from './PasswordInput.logic';
import styles from './PasswordInput.module.scss';

export type PasswordInputProps = InputProps & InputsInnerProps & {};

const PasswordInput: React.FC<PasswordInputProps> = (props: React.PropsWithChildren<PasswordInputProps>) => {
  const { className, type, disabled } = props;
  const { showPassword, toggleShowPasswordHandler } = usePasswordInput();

  return (
    <BaseInput
      {...{
        ...props,
        className: `${styles['password_input']} ${className}`,
        type: showPassword ? INPUT_TYPES.TEXT : type,
      }}>
      {!disabled && (
        <Button
          className={styles['show-password_button']}
          icon={showPassword ? iconsTemp.visibilityOff : iconsTemp.visibility}
          type={ButtonTypes.TEXT}
          onClick={toggleShowPasswordHandler}
        />
      )}
    </BaseInput>
  );
};

export default PasswordInput;
