import Button from 'components/Form/Button';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import homeSubRoutes from 'routes/homeSubRoutes';
import styles from './NoAppointments.module.scss';

export type NoAppointmentsProps = {};

const NoAppointments: React.FC<NoAppointmentsProps> = (props: React.PropsWithChildren<NoAppointmentsProps>) => {
  const {} = props;
  const history = useHistory();
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <div className={styles['no-apointments']}>
      <h2>{language.noAppointmentsScheduled}</h2>
      <p>{language.helpFindTheMedicYouNeed}</p>
      <img />
      <Button
        icon={iconsTemp.arrows.left}
        label={language.navigateToSearch}
        onClick={() => history.push(homeSubRoutes.searchMedics.path)}
      />
    </div>
  );
};

export default NoAppointments;
