import 'react-datepicker/dist/react-datepicker.css';
import BaseInput from '../BaseInput';
import styles from './DateInput.module.scss';
import { InputProps, InputsInnerProps, INPUT_TYPES } from '../Input';
import useDateInput from './DateInput.logic';
import { DateMasks } from 'hooks/use-date';
import SmallCalendar from 'components/SmallCalendar/SmallCalendar';
import Popup from 'components/View/Popup';

export type DateInputProps = InputProps & InputsInnerProps & {};

const DateInput: React.FC<DateInputProps> = (props: React.PropsWithChildren<DateInputProps>) => {
  const { className, inputValue: value, min, max } = props;
  const { showDatePicker, containerRef, formatDate, setShowDatePicker, openDialogHandler, changeHandler } =
    useDateInput(props);

  return (
    <div className={styles['date_input_container']} ref={containerRef}>
      <BaseInput
        {...{
          ...props,
          className: `${styles['date_input']} ${className}`,
          value: formatDate(value as Date, DateMasks.inputDate),
          canEdit: false,
          type: INPUT_TYPES.TEXT,
          focusHandler: openDialogHandler,
        }}
      />
      <Popup className={styles['date-picker']} show={showDatePicker} close={setShowDatePicker}>
        <SmallCalendar
          value={value as Date}
          min={min ? (min as Date) : new Date()}
          max={max ? (max as Date) : undefined}
          onChange={changeHandler}
        />
      </Popup>
    </div>
  );
};

export default DateInput;
