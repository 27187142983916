import { Button, Flex, Icon, rgba, SmallText, styles, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { LoginAccountWrapper } from './LoginAccount.styles';

export interface ILoginAccountProps extends PropsWithChildren<{}> {
  image?: string;
  name?: string;
  email?: string;
  onSelect: (email: string | undefined) => void;
}

const LoginAccount: FC<ILoginAccountProps> = (props: ILoginAccountProps): JSX.Element => {
  const { image, name, email, onSelect } = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <LoginAccountWrapper justifyContent='space-between' onClick={() => onSelect(email)}>
      <Flex>
        {image && <UserImage src={image} size='3rem' />}
        {!image && (
          <Icon
            style={{ marginRight: '0.75rem' }}
            src='patients'
            size='3rem'
            fill={rgba(styles.colors.brand.main, 0.15)}
            backgroundColor={rgba(styles.colors.brand.main, 0.6)}
          />
        )}
        <div>
          {name && <h6>{name}</h6>}
          {email && <SmallText style={{ marginTop: '0.1rem' }}>{email}</SmallText>}
          {!name && !email && <h6>{language.loginWithNewAccount}</h6>}
        </div>
      </Flex>
      {name && email && <Button type='text' icon='options' />}
    </LoginAccountWrapper>
  );
};

export default LoginAccount;
