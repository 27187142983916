import NoteDTO from 'dtos/NoteDTO';
import NoteSchema from 'schemas/NoteSchema';
import SpecialtyMapper from './SpecialtyMapper';

const specialtyMapper = SpecialtyMapper();

const NoteMapper = () => {
  const toInterface = (schema: NoteSchema): NoteDTO => {
    return {
      id: schema.id,
      text: schema.text,
      createdAt: new Date(schema.createdAt),
      updatedAt: new Date(schema.updatedAt),
      specialty: schema.appointment ? specialtyMapper.toInterface(schema.appointment.specialty) : undefined,
    };
  };

  return { toInterface };
};

export default NoteMapper;
