import Input, { INPUT_TYPES } from 'components/Form/Input/Input';
//import styles from './Inputs.module.scss';

export type InputsProps = {};

const Inputs: React.FC<InputsProps> = (props: React.PropsWithChildren<InputsProps>) => {
  const {} = props;

  return (
    <div>
      <div>
        <div>
          <h4>Text input</h4>
          <span>Single line field</span>
        </div>
        <div>
          <h4>Text input</h4>
          <span>Multi-line field</span>
        </div>
        <div>
          <h4>Password</h4>
          <span>Single line field</span>
        </div>
        <div>
          <h4>Search</h4>
          <span>Single line field</span>
        </div>
      </div>
      <div>
        <div>
          <Input type={INPUT_TYPES.TEXT} placeholder='Name' />
        </div>
        <div>
          <Input type={INPUT_TYPES.TEXT} placeholder='Description' />
        </div>
        <div>
          <Input type={INPUT_TYPES.PASSWORD} placeholder='Password' />
        </div>
        <div>
          <Input type={INPUT_TYPES.SEARCH} />
        </div>
      </div>
      <div>
        <div>
          <h4>Date</h4>
          <span>Single line field, allow just numbers</span>
        </div>
        <div>
          <h4>Time</h4>
          <span>Single line field, allow just numbers</span>
        </div>
        <div>
          <h4>Number</h4>
          <span>Single line field, allow just numbers</span>
        </div>
        <div>
          <h4>Prefix and suffix text</h4>
          <span>Single line field, allow just numbers</span>
        </div>
      </div>
      <div>
        <div>
          <Input type={INPUT_TYPES.DATE} />
        </div>
        <div>
          <Input type={INPUT_TYPES.TIME} placeholder='Description' />
        </div>
        <div>
          <Input type={INPUT_TYPES.NUMBER} placeholder='Number' />
        </div>
        <div>
          <Input type={INPUT_TYPES.NUMBER} label='Cost' step={5} />
        </div>
      </div>
      <h3>Disabled</h3>
      <div>
        <div>
          <Input type={INPUT_TYPES.TEXT} placeholder='Name' disabled={true} />
        </div>
        <div>
          <Input type={INPUT_TYPES.PASSWORD} placeholder='Password' disabled={true} />
        </div>
      </div>
      <h3>States</h3>
      <div>
        <div>
          <Input type={INPUT_TYPES.TEXT} placeholder='Name' error='Name already exists' />
          <Input type={INPUT_TYPES.PASSWORD} placeholder='Password' error='Mandatory field' />
        </div>
        <div>
          <Input
            type={INPUT_TYPES.PASSWORD}
            placeholder='Password'
            help='Minimum 8 characters'
            success='Strong password'
          />
        </div>
      </div>
    </div>
  );
};

export default Inputs;
