import ITab from 'interfaces/ITab';
import React from 'react';
import { generatePath, Link, Switch, useRouteMatch } from 'react-router-dom';
import useTabs from './Tabs.logic';
import { getFilledIcon } from 'utils/general';
import styles from './Tabs.module.scss';
import Icon from 'components/View/Icon';

export type TabsProps = {
  className?: string;
  tabs: ITab[];
  borderWidth?: string;
  hideLabels?: boolean;
  hideTabs?: boolean;
};

const Tabs: React.FC<TabsProps> = (props: React.PropsWithChildren<TabsProps>) => {
  const { className, tabs, borderWidth, hideLabels, hideTabs, children } = props;
  const { tabsRefs, bar, width, left, tabIsSelected } = useTabs(props);
  const match = useRouteMatch();

  return (
    <div className={`${styles.container} ${className}`}>
      {!hideTabs && (
        <ul className={styles['tabs-list']}>
          {tabs.map((tab, i) => {
            const isSelected = tabIsSelected(tab);
            return (
              <li key={i} data-testid={tab.id} className={`${isSelected && styles.selected}`} ref={tabsRefs[i]}>
                <Link
                  to={
                    // to be refractored
                    'tab' in match.params
                      ? generatePath(match.path, { ...match.params, tab: tab.route.path })
                      : tab.route.linkPath
                      ? tab.route.linkPath
                      : tab.route.path
                  }>
                  {tab.icon && <Icon className={styles.icon} src={isSelected ? getFilledIcon(tab.icon) : tab.icon} />}
                  {!hideLabels && tab.name}
                </Link>
              </li>
            );
          })}
          <div
            className={styles['selected-border']}
            ref={bar}
            style={{ width: borderWidth !== undefined ? borderWidth : width, left }}
          />
        </ul>
      )}
      <div>
        <Switch>{children}</Switch>
      </div>
    </div>
  );
};

Tabs.defaultProps = { hideLabels: false, hideTabs: false };

export default Tabs;
