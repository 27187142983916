import React, { useRef } from 'react';
import { InputStepProps } from './InputStep';

export type UseInputStep = {
  inputRef: React.RefObject<HTMLInputElement>;
  increaseHandler: () => void;
  decreaseHandler: () => void;
};

const useInputStep = (props: InputStepProps): UseInputStep => {
  const { min, max, onChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const increaseHandler = (): void => {
    // Circles to minimum value when it reaches the maximum one
    if (Number(inputRef.current?.value) === max) inputRef.current!.value = min + '';
    else inputRef.current?.stepUp();

    inputRef.current!.value = Number(inputRef.current!.value).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    });

    onChange(Number(inputRef.current?.value));
  };

  const decreaseHandler = (): void => {
    // Circles to maximum value when it reaches the minimum one
    if (Number(inputRef.current?.value) === min) inputRef.current!.value = max + '';
    else inputRef.current?.stepDown();

    inputRef.current!.value = Number(inputRef.current!.value).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    });

    onChange(Number(inputRef.current?.value));
  };

  return { inputRef, increaseHandler, decreaseHandler };
};

export default useInputStep;
