import FullCalendar, { EventApi, EventClickArg, EventContentArg, SlotLabelContentArg } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import useDate, { DateMasks } from 'hooks/use-date';
import { FC, PropsWithChildren, useContext } from 'react';
import AgendaContext from 'store/agenda-context';
import { areEventsEqual } from 'utils/appointment';
import DayEvent from '../Events/DayEvent';
import './DayView.scss';

export interface DayViewProps {
  selectedEvent?: EventClickArg;
  onSelectEvent?: (arg: EventClickArg) => void;
  onEventsChange: (events: EventApi[]) => void;
  onClose: () => void;
}

const DayView: FC<DayViewProps> = (props: PropsWithChildren<DayViewProps>): JSX.Element => {
  const { selectedEvent, onSelectEvent, onEventsChange, onClose } = props;
  const { formatDate } = useDate();
  const { dayEvents, dayCalendarRef } = useContext(AgendaContext);

  return (
    <FullCalendar
      plugins={[timeGridPlugin]}
      initialView='timeGridDay'
      headerToolbar={false}
      dayHeaders={false}
      allDaySlot={false}
      slotDuration={{ minutes: 30 }}
      slotLabelInterval={{ minutes: 30 }}
      slotLabelContent={(slotLabel: SlotLabelContentArg) => formatDate(slotLabel.date, DateMasks.shortTime)}
      events={dayEvents}
      eventContent={(eventContent: EventContentArg) => (
        <DayEvent
          event={eventContent}
          selected={areEventsEqual(selectedEvent?.event, eventContent.event)}
          onClose={onClose}
        />
      )}
      eventClick={onSelectEvent}
      eventsSet={onEventsChange}
      ref={dayCalendarRef}
    />
  );
};

export default DayView;
