import WelcomePatientMessage from '../WelcomePatientMessage';
import SearchBar from './SearchBar';
import useSearchMedics from './SearchMedics.logic';
import styles from './SearchMedics.module.scss';
import sass from 'styles/exports.module.scss';
import SearchResults from './SearchResults/SearchResults';
import { Transition, TransitionStatus } from 'react-transition-group';
import { Button } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export type SearchMedicsProps = {};

const SearchMedics: React.FC<SearchMedicsProps> = (props: React.PropsWithChildren<SearchMedicsProps>) => {
  const {} = props;
  const { language, medics, showList, reportSymptomsHandler, submitHandler } = useSearchMedics();

  return (
    <div className={styles['search-medics']}>
      <WelcomePatientMessage show={!showList} />
      <SearchBar onSubmit={submitHandler} />
      <Transition in={!showList} timeout={+sass.transition} mountOnEnter unmountOnExit>
        {(state: TransitionStatus) => (
          <div className={`${styles['report-symptoms']} ${styles[state]}`}>
            <p>{language.notSureWhatLookingFor}</p>
            <Button
              className={styles['report-symptoms_button']}
              type='secondary'
              label={language.reportSymptoms}
              disabled
              onClick={reportSymptomsHandler}
            />
          </div>
        )}
      </Transition>
      {medics && <SearchResults show={showList} medics={medics} />}
    </div>
  );
};

export default SearchMedics;
