import { iconsTemp } from 'iconsTemp';
import IOption from 'interfaces/IOption';
import { Transition } from 'react-transition-group';
import useSelect from 'components/Form/Select.logic';
import Icon from 'components/View/Icon';
import styles from './Select.module.scss';

export type SelectProps = {
  dataTestId?: string;
  className?: string;
  optionsClassName?: string;
  label?: string;
  options: IOption[];
  defaultOption?: IOption;
  option?: IOption;
  small?: boolean;
  disabled?: boolean;
  onChange?: (option: IOption) => void;
};

const Select: React.FC<SelectProps> = (props: React.PropsWithChildren<SelectProps>) => {
  const { dataTestId, className, optionsClassName, label, options, small, disabled } = props;
  const { isOpen, selected, containerRef, selectRef, toggleOpenOptionsHandler, selectOptionHandler } = useSelect(props);

  return (
    <div
      data-testid={dataTestId}
      className={`${styles.select} ${isOpen && styles.open} ${small && styles.small} ${
        disabled && styles.disabled
      } ${className}`}
      ref={containerRef}>
      <select ref={selectRef}>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <div className={`${styles.label} ${selected && styles.active}`} onClick={toggleOpenOptionsHandler}>
        <span>{selected ? selected.name : label}</span>
        <Icon className={styles.icon} src={iconsTemp.arrows.down} />
      </div>
      <Transition in={isOpen} timeout={300} mountOnEnter unmountOnExit>
        {(state) => (
          <div className={`${styles.options} ${styles[state]} ${optionsClassName}`}>
            {options.map((option) => (
              <div
                data-testid={`${dataTestId}-${option.id}`}
                key={option.id}
                className={`${option.id === selected?.id && styles.selected}`}
                onClick={() => selectOptionHandler(option)}>
                {option.name}
              </div>
            ))}
          </div>
        )}
      </Transition>
    </div>
  );
};

Select.defaultProps = {
  className: '',
  label: '',
};

export default Select;
