import { iconsTemp } from 'iconsTemp';
import Button, { ButtonThemes, ButtonTypes } from '../../Button';
import useInputStep from './InputStep.logic';
import styles from './InputStep.module.scss';

export type InputStepProps = {
  valueDataTestId?: string;
  upDataTestId?: string;
  downDataTestId?: string;
  defaultValue: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
};

const InputStep: React.FC<InputStepProps> = (props: React.PropsWithChildren<InputStepProps>) => {
  const { valueDataTestId, upDataTestId, downDataTestId, defaultValue, min, max, step } = props;
  const { inputRef, increaseHandler, decreaseHandler } = useInputStep(props);

  return (
    <div className={styles.step}>
      <Button
        dataTestId={upDataTestId}
        className={styles['arrow_button']}
        type={ButtonTypes.TEXT}
        theme={ButtonThemes.DARK}
        icon={iconsTemp.arrows.up}
        onClick={increaseHandler}
      />
      <input
        data-testid={valueDataTestId}
        type='number'
        defaultValue={defaultValue.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
        min={min}
        max={max}
        step={step}
        ref={inputRef}
        readOnly={true}
      />
      <Button
        dataTestId={downDataTestId}
        className={styles['arrow_button']}
        type={ButtonTypes.TEXT}
        theme={ButtonThemes.DARK}
        icon={iconsTemp.arrows.down}
        onClick={decreaseHandler}
      />
    </div>
  );
};

export default InputStep;
