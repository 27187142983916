import { EventApi, EventClickArg } from '@fullcalendar/react';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { areEventsEqual } from 'utils/appointment';
import sass from 'styles/exports.module.scss';
import EventDTO, { AppointmentEventTypes } from 'dtos/EventDTO';
import { useWinstonLogger } from 'winston-react';
import { LOG_COMPONENT } from 'utils/logger';
import AgendaContext from 'store/agenda-context';
import { useParams } from 'react-router-dom';
import { timeIsPast } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

export interface IUseDayEvents {
  selectedEvent: EventClickArg | undefined;
  scrollViewRef: RefObject<HTMLDivElement>;
  eventsChangeHandler: (events: EventApi[]) => void;
  selectEventHandler: (arg: EventClickArg) => void;
  unselectEventHandler: () => void;
}

const useDayEvents = (): IUseDayEvents => {
  const logger = useWinstonLogger();
  const { date: urlDate } = useParams<{ date: string }>();
  const { dayEvents } = useContext(AgendaContext);
  const [dayChanged, setDayChanged] = useState<boolean>();
  const [selectedEvent, setSelectedEvent] = useState<EventClickArg>();
  const scrollViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => setDayChanged(true), [urlDate]);

  useEffect(() => {
    // unselects an event if it was removed on events change and if one is selected
    const selectedCalendarEvent: EventDTO | undefined = dayEvents.find(
      (event: EventDTO) => event.id === selectedEvent?.event._def.publicId
    );

    if (!selectedCalendarEvent && selectedEvent) {
      logger.log(LOG_COMPONENT, 'unselecting removed day event');
      setSelectedEvent(undefined);
    }
  }, [dayEvents]);

  useEffect(() => {
    eventsChangeHandler([]);
  }, [scrollViewRef.current]);

  const eventsChangeHandler = (_: EventApi[]): void => {
    if (!scrollViewRef.current) return;

    const eventsContainer = scrollViewRef.current?.querySelector('.fc-timegrid-col-events');
    const currentEventsElements = eventsContainer?.childNodes;

    // reverses the render order so that the event popup can be shown above the following events
    currentEventsElements?.forEach((child: ChildNode) => {
      eventsContainer?.insertBefore(child, eventsContainer.firstChild);
    });

    // do not scroll if day didn't change or if there no events
    if (!dayChanged || currentEventsElements?.length === 0) return;

    logger.log(LOG_COMPONENT, 'day events changed - scrolling to first event found');

    // scrolls the container to the first (last because order changed) event element found
    const firstEventTop = (currentEventsElements?.item(currentEventsElements.length - 1) as HTMLDivElement)?.offsetTop;
    const top = firstEventTop - parseInt(sass.scrollGradientheight);
    scrollViewRef.current?.scrollTo({ top, behavior: 'smooth' });

    setDayChanged(false);
  };

  const selectEventHandler = (arg: EventClickArg): void => {
    if (
      arg.event._def.extendedProps.place === AppointmentEventTypes.Available &&
      timeIsPast(arg.event._instance!.range.start)
    )
      return;

    if (areEventsEqual(selectedEvent?.event, arg.event)) {
      logger.log(LOG_COMPONENT, 'unselecting day event because the selected on was clicked again');
      setSelectedEvent(undefined);
    } else {
      logger.log(LOG_COMPONENT, `selecting day event with id ${arg.event._def.defId}`);
      setSelectedEvent(arg);
    }
  };

  const unselectEventHandler = (): void => {
    logger.log(LOG_COMPONENT, 'unselecting day event');
    setSelectedEvent(undefined);
  };

  return {
    selectedEvent,
    scrollViewRef,
    eventsChangeHandler,
    selectEventHandler,
    unselectEventHandler,
  };
};

export default useDayEvents;
