import { Redirect, Route } from 'react-router-dom';
import accountSubRoutes from 'routes/accountSubRoutes';
import routes from 'routes/routes';
import useAccount from './Account.logic';
import AccountInfo from './AccountInfo';
import Security from './Security';
import { PropsWithChildren, FC } from 'react';
import { AccountWrapper, AccountBackButton, AccountTabs, AccountHeaderProfile } from './Account.styles';

export interface IAccountProps extends PropsWithChildren<{}> {}

const Account: FC<IAccountProps> = (props: IAccountProps): JSX.Element => {
  const {} = props;
  const { tabs, backHandler } = useAccount();

  return (
    <>
      <Route path={routes.account.path} exact={true}>
        <Redirect to={accountSubRoutes.info.path} />
      </Route>
      <AccountWrapper>
        <AccountBackButton icon='arrowLeft' type='secondary' onClick={backHandler} />
        <AccountTabs tabs={tabs} borderWidth='1rem'>
          <Route path={accountSubRoutes.info.path} exact={accountSubRoutes.info.exact}>
            <AccountInfo />
          </Route>
          <Route path={accountSubRoutes.security.path} exact={accountSubRoutes.security.exact}>
            <Security />
          </Route>
          <Route path={accountSubRoutes.payments.path} exact={accountSubRoutes.payments.exact}>
            <p>payments</p>
          </Route>
          <Route path={accountSubRoutes.preferences.path} exact={accountSubRoutes.preferences.exact}>
            <p>preferences</p>
          </Route>
        </AccountTabs>
        <AccountHeaderProfile />
      </AccountWrapper>
    </>
  );
};

export default Account;
