import AlertDTO, { AlertTypes } from 'dtos/AlertDTO';
import EventDTO from 'dtos/EventDTO';
import useSocket from 'hooks/use-socket';
import { UserLeftWaitingRoomSocketMessage, UserEnteredWaitingRoomSocketMessage } from 'interfaces/ISocketMessages';
import { useEffect, useState } from 'react';
import useAppointmentsService from 'services/appointments.service';

export interface UseAlerts {
  alerts: any;
  closeAlertHandler: (alertId: string) => void;
}

const useAlerts = (): UseAlerts => {
  const { getAppointment } = useAppointmentsService();
  const { listenSocketMessage } = useSocket();
  const [alerts, setAlerts] = useState<AlertDTO[]>([]);

  useEffect(() => {
    listenSocketMessage('user-entered-waiting', (data: UserEnteredWaitingRoomSocketMessage) => {
      getAlertInfo(data.appointmentId, AlertTypes.USER_ENTERED_APPOINTMENT);
    });

    listenSocketMessage('user-gone', (data: UserLeftWaitingRoomSocketMessage) => {
      getAlertInfo(data.appointmentId, AlertTypes.USER_LEFT_APPOINTMENT);
    });
  }, []);

  const getAlertInfo = (appointmentId: string, alertType: AlertTypes): void => {
    getAppointment(appointmentId).then((appointmentEvent: EventDTO) => {
      setAlerts((prevState: AlertDTO[]) => [
        {
          id: `${appointmentEvent.id}-${new Date().getTime()}`,
          appointmentEvent,
          type: alertType,
        },
        ...prevState,
      ]);
    });
  };

  const closeAlertHandler = (alertId: string): void => {
    setAlerts((prevState: AlertDTO[]) => prevState.filter((alert: AlertDTO) => alert.id !== alertId));
  };

  return { alerts, closeAlertHandler };
};

export default useAlerts;
