import {
  Button,
  FormContextProvider,
  getColumnsWidth,
  getGutter,
  ISmallTextProps,
  rgba,
  SmallText,
  styles,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';

export interface IOnboardingFormProps {}

export const OnboardingForm = styled(FormContextProvider)<IOnboardingFormProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: overlay;
  width: ${getColumnsWidth({ nrColumns: 3 })};
  height: 75vh;
  padding: 1rem ${getGutter()};
  border-radius: 30px;
  box-sizing: border-box;
  margin: 10vh auto 0 auto;
  background-color: ${styles.colors.brand.lightest};

  > * {
    width: 100%;

    &:not(div) {
      text-align: center;
    }
  }

  > h1 {
    margin-top: 1rem;
  }
`;

export interface IOnboardingSubtitleProps {}

export const OnboardingSubtitle = styled.h5`
  margin-top: 2rem;
  text-align: left !important;
`;

export interface IOnboardingParagraphProps {
  textAlign?: 'center' | 'left';
}

export const OnboardingParagraph = styled(SmallText).attrs((_: ISmallTextProps) => ({
  as: 'p',
}))<IOnboardingParagraphProps>`
  width: auto;
  color: ${rgba(styles.colors.brand.dark, 0.75)};

  ${({ textAlign }: IOnboardingParagraphProps) =>
    textAlign === undefined || textAlign === 'center'
      ? css`
          width: 90%;
          margin: 0.75rem auto 0 auto;
        `
      : css`
          margin: 1.5rem 0 2rem 2.5rem;
          text-align: left !important;
        `}
`;

export interface IOnboardingPreviousButtonProps {}

export const OnboardingPreviousButton = styled(Button)<IOnboardingPreviousButtonProps>`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: auto;
`;

export interface IOnboardingSubmitButtonProps {}

export const OnboardingSubmitButton = styled(Button)<IOnboardingSubmitButtonProps>`
  margin: auto auto 0 auto !important;
`;

export interface IOnboardingSkipButtonProps {}

export const OnboardingSkipButton = styled(Button)<IOnboardingSkipButtonProps>`
  margin: 1rem auto 0 auto !important;
`;
