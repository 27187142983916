import Calendar from 'pages/Index/Agenda/Calendar/Calendar';
import { generatePath, Redirect, Route } from 'react-router-dom';
import AddAvailability from './AddAvailability/AddAvailability';
import styles from './Agenda.module.scss';
import DayEvents from './DayCalendar/DayCalendar';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import homeSubRoutes from 'routes/homeSubRoutes';
import useDate, { GlobalDateMasks } from 'hooks/use-date';
import { AgendaContextProvider } from 'store/agenda-context';
import { IAgendaSubRoutes } from 'interfaces/IRoute';
import { CalendarViews } from 'mappers/EventMapper';
import { FC, PropsWithChildren } from 'react';
import EditAvailability from './EditAvailability/EditAvailability';

export interface AgendaProps {}

const Agenda: FC<AgendaProps> = (props: PropsWithChildren<AgendaProps>): JSX.Element => {
  const {} = props;
  const { formatDate } = useDate();
  const agendaSubRoutes = homeSubRoutes.agenda.subRoutes! as IAgendaSubRoutes;
  const { appointmentsSlots, addAvailability, editAvailability } = agendaSubRoutes;

  return (
    <AgendaContextProvider>
      <MediaScreenDesktop>
        <Route path={homeSubRoutes.agenda.linkPath} exact={true}>
          <Redirect
            to={{
              pathname: generatePath(homeSubRoutes.agenda.path, {
                date: formatDate(new Date(), GlobalDateMasks.urlDate),
              }),
              search: `?view=${CalendarViews.MONTH}`,
            }}
          />
        </Route>
      </MediaScreenDesktop>
      <section className={styles.calendar}>
        <Calendar />
      </section>
      <Route path={appointmentsSlots.path} exact={appointmentsSlots.exact}>
        <section data-testid='agenda-dayEvents' className={styles['day-events']}>
          <DayEvents />
        </section>
      </Route>
      <Route path={addAvailability.path} exact={addAvailability.exact}>
        <section className={styles['add-availability']}>
          <AddAvailability />
        </section>
      </Route>
      <Route path={editAvailability.path} exact={editAvailability.exact}>
        <section className={styles['add-availability']}>
          <EditAvailability />
        </section>
      </Route>
    </AgendaContextProvider>
  );
};

export default Agenda;
