import FileDTO from './FileDTO';

export enum MessageState {
  SENDING,
  NOT_SENT,
  SENT,
  READ,
}

export default interface MessageDTO {
  id: string;
  mine: boolean;
  text: string | FileDTO | null;
  date: Date;
  state: MessageState;
}
