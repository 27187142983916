import useScheduleAppointment from './ScheduleAppointment.logic';
import { Button, Flex, FormContextProvider } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import UserInfo from 'components/User/UserInfo';
import { ProfileType } from 'dtos/EntityDTO';
import { getMedicSpecialtiesList } from 'utils/user';
import {
  ScheduleAppointmentButtons,
  ScheduleAppointmentForm,
  ScheduleAppointmentNextButton,
  ScheduleAppointmentStepProgress,
  ScheduleAppointmentWrapper,
} from './ScheduleAppointment.styles';
import BuyAppointmentStep from './Steps/BuyAppointmentStep';
import { scheduleAppointmentFormInputs } from 'services/appointments.service';

export interface IScheduleAppointmentProps extends PropsWithChildren<{}> {}

const ScheduleAppointment: FC<IScheduleAppointmentProps> = (props: IScheduleAppointmentProps): JSX.Element => {
  const {} = props;
  const {
    language,
    medic,
    paymentLink,
    isLoading,
    stepsElements,
    stepsNames,
    step,
    isFistStep,
    nextStepHandler,
    previousStepHandler,
    goBackHandler,
    loadFormHandler,
    submitScheduleHandler,
  } = useScheduleAppointment();

  if (!medic) return <></>;

  const title = (
    <UserInfo user={{ ...medic, type: ProfileType.MEDIC }}>{getMedicSpecialtiesList(medic.specialties)}</UserInfo>
  );

  const Step = stepsElements[step];

  return (
    <ScheduleAppointmentWrapper title={title} isFullPageStep={step > 0} onBack={goBackHandler}>
      <Flex style={{ height: '100%' }} direction='column'>
        <h2>{language.scheduleAppointment}</h2>
        <ScheduleAppointmentStepProgress stepsNames={stepsNames} step={step} />
        {step < 2 && (
          <ScheduleAppointmentForm
            as={FormContextProvider}
            initialValues={scheduleAppointmentFormInputs}
            onLoad={loadFormHandler}
            onSubmit={submitScheduleHandler}>
            <Step specialties={medic.specialties} />
            <ScheduleAppointmentButtons justifyContent='space-between'>
              {!isFistStep && (
                <Button
                  dataTestId='scheduleAppointment-previousButton'
                  type='secondary'
                  state='dark'
                  icon='arrowLeft'
                  label={language.back}
                  onClick={previousStepHandler}
                />
              )}
              <ScheduleAppointmentNextButton
                dataTestId='scheduleAppointment-nextButton'
                buttonType={step === 1 ? 'submit' : 'button'}
                icon='arrowRight'
                label={language.next}
                loading={isLoading}
                reverse
                onClick={step === 0 ? nextStepHandler : undefined}
              />
            </ScheduleAppointmentButtons>
          </ScheduleAppointmentForm>
        )}
        {step === 2 && <BuyAppointmentStep paymentLink={paymentLink!} />}
      </Flex>
    </ScheduleAppointmentWrapper>
  );
};

export default ScheduleAppointment;
