import SpecialtyDTO from 'dtos/SpecialtyDTO';
import SpecialtySchema from 'schemas/SpecialtySchema';

const SpecialtyMapper = () => {
  const toInterface = (specialty: SpecialtySchema): SpecialtyDTO => {
    return {
      id: specialty.id,
      name: specialty.name,
    };
  };

  return { toInterface };
};

export default SpecialtyMapper;
