import { FC, PropsWithChildren } from 'react';
import Button from 'components/Form/Button';
import { SwitchTransition, Transition } from 'react-transition-group';
import styles from './AppointmentAlert.module.scss';
import sass from 'styles/exports.module.scss';

export interface AppointmentAlertMessage {
  message: string;
  buttonLabel?: string;
  buttonDataTest?: string;
  onClick?: () => void;
}

export interface AppointmentAlertProps {
  alert?: AppointmentAlertMessage;
}

const AppointmentAlert: FC<AppointmentAlertProps> = (props: PropsWithChildren<AppointmentAlertProps>): JSX.Element => {
  const { alert } = props;

  return (
    <SwitchTransition>
      <Transition key={alert?.message} timeout={+sass.transition} mountOnEnter unmountOnExit appear>
        {(state) => (
          <>
            {alert?.message && (
              <div className={`${styles['appointment-alert']} ${styles[state]}`}>
                <h4>{alert.message}</h4>
                {alert.buttonLabel && (
                  <Button
                    dataTestId={alert.buttonDataTest}
                    className={styles.button}
                    label={alert.buttonLabel}
                    onClick={alert.onClick ? alert.onClick : () => {}}
                  />
                )}
              </div>
            )}
          </>
        )}
      </Transition>
    </SwitchTransition>
  );
};

export default AppointmentAlert;
