import { CallButtonsProps, CallButtonsRef, CallButtonsTypes } from 'components/Call/CallButtons/CallButtons';
import { ForwardedRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import IReduxState from 'interfaces/IReduxState';
import { useWinstonLogger } from 'winston-react';
import { LOG_COMPONENT } from 'utils/logger';
import useMediaScreen from 'hooks/use-mediaScreen';
import ILanguage from 'interfaces/ILanguage';
import CallContext from 'store/call-context';

export interface IUseCallButtons {
  language: ILanguage;
  showCallButtons: boolean;
  microphoneIsOn: boolean;
  cameraIsOn: boolean;
  screenShareIsOn: boolean;
  cameraIsSwitched: boolean;
  toggleHandler: (buttonType: CallButtonsTypes) => void;
}

export const useCallButtons = (props: CallButtonsProps, ref: ForwardedRef<CallButtonsRef>): IUseCallButtons => {
  const { hideOnMobile } = props;
  const logger = useWinstonLogger();
  const { isMobileScreen } = useMediaScreen();
  const language = useSelector((state: IReduxState) => state.language.values);
  const {
    microphoneIsOn,
    cameraIsOn,
    screenShareIsOn,
    toggleMicrophoneHandler,
    toggleCameraHandler,
    toggleScreenShareHandler,
    switchCameraHandler,
  } = useContext(CallContext);
  const [showCallButtons, setShowCallButtons] = useState<boolean>(true);
  const [showButtonsTimeout, setShowButtonsTimeout] = useState<NodeJS.Timeout>();

  useImperativeHandle(ref, () => ({ toggle: toggleCallButtonsHandler }));

  useEffect(() => {
    if ((isMobile || isMobileScreen) && hideOnMobile) {
      logger.log(LOG_COMPONENT, 'seting timer to hide call buttons');
      createButtonsTimeout();
    } else {
      logger.log(LOG_COMPONENT, 'clearing timeout and showing call buttons');
      clearButtonsTimeout();
      setShowCallButtons(true);
    }

    return () => clearButtonsTimeout();
  }, [logger, isMobileScreen, hideOnMobile]);

  const toggleCallButtonsHandler = (): void => {
    if (isMobile || isMobileScreen)
      setShowCallButtons((prevState) => {
        const newState = !prevState;

        if (newState) createButtonsTimeout();
        else clearButtonsTimeout();

        return newState;
      });
  };

  const createButtonsTimeout = (): void => {
    setShowButtonsTimeout(
      setTimeout(() => {
        setShowCallButtons(false);
      }, 5000)
    );
  };

  const clearButtonsTimeout = (): void => {
    if (showButtonsTimeout) clearTimeout(showButtonsTimeout);
  };

  const resetTimeout = (): void => {
    clearButtonsTimeout();

    if (isMobile || isMobileScreen) {
      logger.log(LOG_COMPONENT, 'reseting hide call buttons timeout');
      createButtonsTimeout();
    }
  };

  const toggleHandler = (buttonType: CallButtonsTypes): void => {
    switch (buttonType) {
      case 'microphone':
        toggleMicrophoneHandler();
        break;
      case 'camera':
        toggleCameraHandler();
        break;
      case 'screen-share':
        toggleScreenShareHandler();
        break;
      case 'switch':
        switchCameraHandler();
        break;
    }

    resetTimeout();
  };

  return {
    language,
    showCallButtons,
    microphoneIsOn,
    cameraIsOn,
    screenShareIsOn,
    cameraIsSwitched: false,
    toggleHandler,
  };
};
