import { getInputValue, InputsReducerType, InputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { DateMasks } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { Gender } from 'dtos/EntityDTO';
import PatientDTO from 'dtos/PatientDTO';
import { CreateOrUpdatePatientBody } from 'RequestsBody/PatientBody';
import PatientSchema from 'schemas/PatientSchema';
import { getAge } from 'utils/date';
import EntityImageMapper from './EntityImageMapper';
import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

const entityImageMapper = EntityImageMapper();

const convertStringToTags = (value: string) => value.split(',').map((value: string) => value.trim());

const convertTagsToString = (value: InputValue) => (value as string[]).join(', ');

const PatientMapper = () => {
  const toInterface = (patient: PatientSchema): PatientDTO => {
    const birthDate = new Date(patient.birthdate);

    return {
      id: patient.id,
      email: patient.email,
      name: patient.name,
      gender: patient.sex as Gender,
      birthDate,
      age: getAge(birthDate),
      vatNumber: +patient.vatNumber,
      timezone: patient.timeZone,
      image: patient.image ? entityImageMapper.toInterface(patient.image) : undefined,
      city: patient.city,
      // country: countries.getName(patient.country.toLowerCase(), toCodeAlpha2(languageCode)),
      country: null,
      language: patient.language as LanguageCode,
      address: patient.address,
      postalCode: patient.postalCode,
      phone: patient.phone,
      height: patient.height ? +patient.height : undefined,
      weight: patient.weight ? +patient.weight : undefined,
      dailyHabits: patient.dailyHabits ? convertStringToTags(patient.dailyHabits) : undefined,
      medication: patient.medication ? convertStringToTags(patient.medication) : undefined,
      allergies: patient.allergies ? convertStringToTags(patient.allergies) : undefined,
    };
  };

  const toBody = async (
    inputs: InputsReducerType,
    formatDate: (date: Date, mask: DateMasks) => string
  ): Promise<CreateOrUpdatePatientBody> => {
    return {
      // mandatory onboarding
      email: getInputValue(inputs.email),
      name: getInputValue(inputs.name),
      sex: getInputValue(inputs.gender),
      timeZone: getInputValue(inputs.timezone),
      birthdate: getInputValue(inputs.birthDate, (value: InputValue) => formatDate(value as Date, DateMasks.urlDate)),
      vatNumber: getInputValue(inputs.tin),

      // optional data
      address: getInputValue(inputs.address),
      city: undefined,
      country: getInputValue(inputs.country),
      phone: getInputValue(inputs.phoneNumber),
      language: getInputValue(inputs.language),
      postalCode: getInputValue(inputs.postalCode),
      // image: inputs.photo ? await entityImageMapper.toBody(inputs.photo.value as File) : undefined,
      height: getInputValue(inputs.height),
      weight: getInputValue(inputs.weight),
      dailyHabits: getInputValue(inputs.dailyHabits, convertTagsToString),
      medication: getInputValue(inputs.medication, convertTagsToString),
      allergies: getInputValue(inputs.allergies, convertTagsToString),
    };
  };

  return { toInterface, toBody };
};

export default PatientMapper;
