import { FC, PropsWithChildren } from 'react';
import CallButtons from 'components/Call/CallButtons/CallButtons';
import AppointmentHeader from 'pages/Appointment/AppointmentHeader';
import AppointmentAlert from 'pages/Appointment/AppointmentAlert';
import { useWaitingRoom } from './WaitingRoom.logic';
import styles from './WaitingRoom.module.scss';
import VideoStream from 'components/Call/VideoStream/VideoStream';

export interface WaitingRoomProps {}

const WaitingRoom: FC<WaitingRoomProps> = (props: PropsWithChildren<WaitingRoomProps>): JSX.Element => {
  const {} = props;
  const { localStreamRef, alert, cameraIsOn, toggleButtonsHandler, exitAppointmentHandler } = useWaitingRoom();

  return (
    <div className={styles['waiting-room']}>
      <AppointmentHeader />
      <div className={styles.main}>
        <VideoStream
          className={styles.stream}
          mute={true}
          streamIsOn={cameraIsOn}
          ref={localStreamRef}
          onClick={toggleButtonsHandler}
        />
        <CallButtons
          className={styles['call-buttons']}
          include={['microphone', 'camera', 'exit']}
          hideOnMobile={false}
          onExit={exitAppointmentHandler}
        />
        <AppointmentAlert alert={alert} />
      </div>
    </div>
  );
};

export default WaitingRoom;
