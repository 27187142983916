import routes from 'routes/routes';

// Routing --------------------------------------------------
export const goBack = (history: any) => {
  if (history.length <= 2) history.push(routes.home.path);
  else history.goBack();
};

export const isInURL = (url: string, valueToSearch: string): boolean => {
  return url.toLowerCase().includes(valueToSearch.toLowerCase());
};

// Icons --------------------------------------------------
export const getFilledIcon = (icon: string) => {
  const filename = icon.substring(0, icon.indexOf('.svg'));
  return `${filename}_filled.svg`;
};

// Other --------------------------------------------------
export const map = (value: number, min1: number, max1: number, min2: number, max2: number): number => {
  return ((value - min1) * (max2 - min2)) / (max1 - min1) + min2;
};

export const updateUndefinedArray = <T>(arrayToAppend: T[], array?: T[], position: 'start' | 'end' = 'end'): T[] => {
  return array ? (position === 'start' ? [...array, ...arrayToAppend] : [...arrayToAppend, ...array]) : arrayToAppend;
};
