import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';

export type UseSearchFilters = {
  language: ILanguage;
};

const useSearchFilters = (): UseSearchFilters => {
  const language = useSelector((state: IReduxState) => state.language.values);

  return { language };
};

export default useSearchFilters;
