import { IOption } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useDate, getDayRange, DateMasks } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import EventAvailabilityDTO from 'dtos/EventAvailabilityDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSchedulesService from 'services/schedules.service';
import { IRescheduleAvailabilitiesProps } from './Availabilities';

export interface IUseRescheduleAvailabilities {
  language: ILanguage;
  date: Date;
  availableEventsByDay: IOption[];
  masks: { [key: string]: string };
  formatDate: (date: Date, mask: string) => string;
  previousDayHandler: () => void;
  nextDayHandler: () => void;
  changeDayHandler: (date: Date) => void;
}

const useAvailabilities = (props: IRescheduleAvailabilitiesProps): IUseRescheduleAvailabilities => {
  const { medicId, specialtyId } = props;
  const { formatDate, masks } = useDate();
  const { getMedicAvailableEvents } = useSchedulesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [date, setDate] = useState<Date>(new Date());
  const [availableEvents, setAvailableEvents] = useState<EventAvailabilityDTO[]>([]);

  useEffect(() => {
    const { startDate, endDate } = getDayRange(date);
    getMedicAvailableEvents(medicId, specialtyId, undefined, startDate, endDate).then(
      (availableEvents: EventAvailabilityDTO[]) => setAvailableEvents(availableEvents)
    );
  }, [date]);

  const incrementDate = (increment: number): void => {
    setDate((prevState: Date) => {
      const newState = new Date(prevState);
      newState.setDate(prevState.getDate() + increment);
      return newState;
    });
  };

  const previousDayHandler = (): void => incrementDate(-1);

  const nextDayHandler = (): void => incrementDate(1);

  const changeDayHandler = (date: Date): void => setDate(date);

  const getAvailableEventsForDay = useCallback((): IOption[] => {
    return availableEvents.map(
      (event: EventAvailabilityDTO) =>
        ({
          id: event.id,
          name: formatDate(event.start, DateMasks.shortTime),
        } as IOption)
    );
  }, [availableEvents, date]);

  return {
    language,
    date,
    availableEventsByDay: getAvailableEventsForDay(),
    masks,
    formatDate,
    previousDayHandler,
    nextDayHandler,
    changeDayHandler,
  };
};

export default useAvailabilities;
