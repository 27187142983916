import { ICallConnection, IDevicesToggles } from 'interfaces/ICall';
import {
  WebRTCAnswerSocketMessage,
  WebRTCIceCandidateSocketMessage,
  WebRTCOfferSocketMessage,
} from 'interfaces/ISocketMessages';
import { CallConnections } from 'store/call-context';
import logger, { LOG_COMPONENT, LOG_ERROR, LOG_RTC } from 'utils/logger';

export const rtcConfiguration = {
  configuration: {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
  },
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: ['turn:aal4all.no-ip.biz:3478'], username: 'turnx', credential: 'DIT.turn' },
  ],
} as RTCConfiguration;

export enum ConnectionActionType {
  ADD,
  UPDATE_CONNECTION_STATE,
  UPDATE_STREAM,
  UPDATE_REMOTE_CAMERA_STATE,
  UPDATE_REMOTE_SHARE_STATE,
  REMOVE,
}

export const connectionsReducer = (
  state: CallConnections,
  action: {
    type: ConnectionActionType;
    sessionId: string;
    connection?: ICallConnection;
    connectionState?: RTCPeerConnectionState;
    streamId?: string;
    streamStatus?: boolean;
    stream?: MediaStream;
  }
): CallConnections => {
  const { type, sessionId, connection, connectionState, streamId, streamStatus, stream } = action;

  switch (type) {
    case ConnectionActionType.ADD:
      if (!connection) throw new Error("ConnectionActionType.ADD action requires 'connection' be defined");

      return { ...state, [sessionId]: connection };
    case ConnectionActionType.UPDATE_CONNECTION_STATE:
      if (state) {
        if (!connectionState)
          throw new Error(
            "ConnectionActionType.UPDATE_CONNECTION_STATE action requires 'connectionState' to be defined"
          );

        logger.log(LOG_RTC, `setting connection state to ${connectionState}`);
        state[sessionId].state = connectionState;
      }
      break;
    case ConnectionActionType.UPDATE_STREAM:
      const newStream = new MediaStream(stream!);
      if (stream?.id === state[sessionId].remoteStreamId) {
        logger.log(LOG_RTC, 'setting new remote stream');
        state[sessionId].remoteStream = newStream;
      }
      if (stream?.id === state[sessionId].remoteScreenShareId) {
        logger.log(LOG_RTC, 'setting new remote screen share stream');
        state[sessionId].remoteScreenShareStream = newStream;
      }
      break;
    case ConnectionActionType.UPDATE_REMOTE_CAMERA_STATE:
      if (state) {
        state[sessionId].remoteStreamId = streamId;
        state[sessionId].remoteCameraIsOn = !!streamStatus;
      }
      break;
    case ConnectionActionType.UPDATE_REMOTE_SHARE_STATE:
      if (state) {
        state[sessionId].remoteScreenShareId = streamId;
        state[sessionId].remoteShareIsOn = !!streamStatus;
      }
      break;
    case ConnectionActionType.REMOVE:
      if (!state || !state[sessionId]) {
        logger.log(LOG_ERROR, `there is no connection with id ${sessionId}`);
        return { ...state };
      }

      logger.log(LOG_COMPONENT, `deleting connection with id ${sessionId}`);

      const { peerConnection } = state[sessionId];
      peerConnection.close();
      delete state[sessionId];
      break;
  }

  return { ...state };
};

export const devicesTogglesReducer = (
  state: IDevicesToggles,
  action: { device: keyof IDevicesToggles; toggle?: boolean; status?: boolean }
): IDevicesToggles => {
  const { device, toggle, status } = action;
  return { ...state, [device]: toggle ? !state[device] : !!status };
};

const createDummyVideoTrack = (): MediaStreamTrack & { enabled: boolean } => {
  logger.log(LOG_RTC, 'creating dummy video track');

  // creates a canvas to simulate a video stream
  const canvas = Object.assign(document.createElement('canvas'), { width: 0, height: 0 });
  canvas.getContext('2d')!.fillRect(0, 0, 0, 0);
  const stream = canvas.captureStream();

  const dummyVideoTrack = stream.getVideoTracks()[0];
  dummyVideoTrack.stop();
  return Object.assign(dummyVideoTrack, { enabled: false });
};

export const createEmptyLocalStream = (): MediaStream => {
  logger.log(LOG_RTC, 'creating local stream with dummy track(s)');
  return new MediaStream([createDummyVideoTrack()]);
};

export const getDeviceConstraint = (
  includeDevice: boolean | undefined,
  devices: MediaDeviceInfo[],
  deviceId: string | undefined,
  kind: 'audioinput' | 'videoinput'
): boolean | MediaTrackConstraints => {
  return includeDevice ? (deviceId ? { deviceId: deviceId } : devices.some((device) => device.kind === kind)) : false;
};

export const createOffer = async (
  peerConnection: RTCPeerConnection | undefined
): Promise<RTCSessionDescriptionInit | undefined> => {
  if (!peerConnection) return;

  logger.log(LOG_RTC, 'creating offer');
  const offer = await peerConnection.createOffer({
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
    iceRestart: true,
  });

  await peerConnection.setLocalDescription(offer);
  logger.log(LOG_RTC, 'created offer and set local description');

  return offer;
};

export const handleOffer = async (
  peerConnection: RTCPeerConnection | undefined,
  data: WebRTCOfferSocketMessage
): Promise<void> => {
  if (!peerConnection) return;

  logger.log(LOG_RTC, 'setting remote description with received offer');
  await peerConnection.setRemoteDescription(data.offer);
};

export const createAnswer = async (
  peerConnection: RTCPeerConnection | undefined
): Promise<RTCSessionDescriptionInit | undefined> => {
  if (!peerConnection || peerConnection.signalingState !== 'have-remote-offer') return;

  logger.log(LOG_RTC, 'creating answer');
  const answer = await peerConnection.createAnswer();
  await peerConnection.setLocalDescription(answer);
  logger.log(LOG_RTC, 'created answer and set local description');

  return answer;
};

export const handleAnswer = async (
  peerConnection: RTCPeerConnection | undefined,
  data: WebRTCAnswerSocketMessage
): Promise<void> => {
  if (!peerConnection) return;

  logger.log(LOG_RTC, 'setting remote description with received answer');
  await peerConnection.setRemoteDescription(data.answer);
};

export const addICECandidate = async (
  peerConnection: RTCPeerConnection | undefined,
  data: WebRTCIceCandidateSocketMessage
): Promise<void> => {
  if (!peerConnection) return;

  try {
    logger.log(LOG_RTC, 'adding ice candidate');
    await peerConnection.addIceCandidate(data.candidate);
  } catch (error) {
    logger.log(LOG_ERROR, `error while trying to add ice candidate: ${error}`);
  }
};
