import { useHeader } from './Header.logic';
import { Route } from 'react-router-dom';
import homeSubRoutes from 'routes/homeSubRoutes';
import routes from 'routes/routes';
import HeaderProfile from './HeaderProfile';
import HeaderNotifications from './HeaderNotifications';
import { NotificationsContextProvider } from 'store/notifications-context';
import MediaScreenMobile from 'components/Media/MediaScreenMobile';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import { HeaderActionsContainer, HeaderAddAvailabilityButton, HeaderLogo, HeaderWrapper } from './Header.styles';

export type HeaderProps = {};

const Header: React.FC<HeaderProps> = (props: React.PropsWithChildren<HeaderProps>) => {
  const {} = props;
  const { showHeader, language, isDesktopScreen, userIsMedic, nrEvents, routeIsHome, addAvailabilityHandler } =
    useHeader();

  if (!showHeader) return null;

  return (
    <HeaderWrapper justifyContent='space-between'>
      <MediaScreenDesktop>
        <HeaderLogo disabled={routeIsHome} to={routes.home.path}>
          <img src={`${process.env.PUBLIC_URL}/logos/simple.svg`} alt='' />
        </HeaderLogo>
      </MediaScreenDesktop>
      <MediaScreenMobile>
        <div>
          <Route path={homeSubRoutes.today.path} exact={homeSubRoutes.today.exact}>
            <h1>{language.todaysAgenda}</h1>
            <h6>{`${nrEvents} ${language.appointments}`}</h6>
          </Route>
          <Route path={homeSubRoutes.agenda.path} exact={homeSubRoutes.agenda.exact}>
            <h1>{language.agenda}</h1>
          </Route>
        </div>
      </MediaScreenMobile>
      <HeaderActionsContainer>
        {userIsMedic && (
          <HeaderAddAvailabilityButton
            dataTestId='header-addAvailabilityButton'
            icon='plus'
            type='secondary'
            label={isDesktopScreen ? language.addAvailability : undefined}
            showLabelOnMouseOuver={isDesktopScreen}
            animate={isDesktopScreen}
            onClick={addAvailabilityHandler}
          />
        )}
        <MediaScreenDesktop>
          <NotificationsContextProvider>
            <HeaderNotifications />
          </NotificationsContextProvider>
          <HeaderProfile />
        </MediaScreenDesktop>
      </HeaderActionsContainer>
    </HeaderWrapper>
  );
};

export default Header;
