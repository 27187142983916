import { getAge } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { AssociatedPatientDTO } from 'dtos/AssociatedUserDTO';
import AssociatedPatientSchema from 'schemas/AssociatedPatientSchema';
import EntityImageMapper from './EntityImageMapper';

const entityImageMapper = EntityImageMapper();

const AssociatedPatientMapper = () => {
  const toInterface = (schema: AssociatedPatientSchema): AssociatedPatientDTO => {
    return {
      id: schema.id,
      name: schema.name,
      age: schema.birthdate ? getAge(new Date(schema.birthdate)) : undefined,
      image: schema.image ? entityImageMapper.toInterface(schema.image) : undefined,
      nrNotes: schema.numberOfNotes,
      nrFiles: schema.numberOfFiles,
      lastAppointmentDate: schema.lastAppointmentEvent ? new Date(schema.lastAppointmentEvent?.startAt) : undefined,
    };
  };

  return { toInterface };
};

export default AssociatedPatientMapper;
