import { iconsTemp } from 'iconsTemp';
import styles from './AppointmentCallSideSection.module.scss';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import PrivateNotes from './AppointmentCallPrivateNotes/AppointmentCallPrivateNotes';
import CallSettings from '../../../../components/Call/CallSettings/CallSettings';
import AppointmentChat from './AppointmentChat';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import Button, { ButtonTypes } from 'components/Form/Button';
import MediaScreenSmartphone from 'components/Media/MediaScreenSmartphone';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import { IAppointmentCallSubRoutes } from 'interfaces/IRoute';
import { goBack } from 'utils/general';
import { FC, PropsWithChildren } from 'react';

export interface AppointmentCallSideSectionProps {}

const AppointmentCallSideSection: FC<AppointmentCallSideSectionProps> = (
  props: PropsWithChildren<AppointmentCallSideSectionProps>
): JSX.Element => {
  const {} = props;
  const history = useHistory();
  const { side } = useParams<{ side?: string }>();
  const language = useSelector((state: IReduxState) => state.language.values);
  const appointmentCallSubRoutes = appointmentSubRoutes.call.subRoutes as IAppointmentCallSubRoutes;

  return (
    <div className={`${styles['side-content']} ${!!side && styles.open}`}>
      <MediaScreenSmartphone>
        <Button
          className={styles['back_button']}
          type={ButtonTypes.SECONDARY}
          icon={iconsTemp.arrows.left}
          onClick={() => goBack(history)}
        />
      </MediaScreenSmartphone>
      <Switch>
        <Route path={appointmentCallSubRoutes.notes.path} exact={appointmentCallSubRoutes.notes.exact}>
          <h3 className={styles.title}>{language.privateNotes}</h3>
          <PrivateNotes />
        </Route>
        <Route path={appointmentCallSubRoutes.chat.path} exact={appointmentCallSubRoutes.chat.exact}>
          <h3 className={styles.title}>{language.chat}</h3>
          <AppointmentChat />
        </Route>
        <Route path={appointmentCallSubRoutes.settings.path} exact={appointmentCallSubRoutes.settings.exact}>
          <h3 className={styles.title}>{language.settings}</h3>
          <CallSettings />
        </Route>
      </Switch>
    </div>
  );
};

export default AppointmentCallSideSection;
