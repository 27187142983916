import { GalleryAndFilesProps } from 'components/Chat/GalleryAndFiles/GalleryAndFiles';
import { IDayGroup } from 'interfaces/IGroup';
import FileDTO, { FILE_TYPES } from 'dtos/FileDTO';
import ILanguage from 'interfaces/ILanguage';
import IOption from 'interfaces/IOption';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupByDay } from 'utils/group';
import useMediaScreen from 'hooks/use-mediaScreen';
import useFilesService from 'services/files.service';

export type UseGalleryAndFiles = {
  language: ILanguage;
  files: FileDTO[];
  groups: IDayGroup<FileDTO>[];
  showFiltersPopup: boolean;
  isMobileScreen: boolean;
  filters: IOption[];
  setShowFiltersPopup: React.Dispatch<React.SetStateAction<boolean>>;
  toggleShowFiltersPopup: () => void;
};

const useGalleryAndFiles = (props: GalleryAndFilesProps): UseGalleryAndFiles => {
  const { getFilesBetweenUsers } = useFilesService();
  let { calleeId, group } = props;
  const language = useSelector((state: IReduxState) => state.language.values) as ILanguage;
  const [files, setFiles] = useState<FileDTO[]>([]);
  const [showFiltersPopup, setShowFiltersPopup] = useState<boolean>(false);
  const { isMobileScreen } = useMediaScreen();
  const filters = [
    { id: 'images', name: language.images, value: FILE_TYPES.IMAGES },
    { id: 'pdf', name: language.pdf, value: FILE_TYPES.PDF },
  ];

  useEffect(() => {
    getFilesBetweenUsers(calleeId).then((files: FileDTO[]) => setFiles(files));
  }, []);

  const toggleShowFiltersPopup = (): void => {
    setShowFiltersPopup((prevState: boolean) => !prevState);
  };

  return {
    language,
    files,
    groups: group ? groupByDay<FileDTO>(files, false, false) : [],
    showFiltersPopup,
    isMobileScreen,
    filters,
    setShowFiltersPopup,
    toggleShowFiltersPopup,
  };
};

export default useGalleryAndFiles;
