import IMediaDevice from 'interfaces/IMediaDevice';
import IMediaDevices from 'interfaces/IMediaDevices';

const MediaDevicesMapper = () => {
  const containsDeviceId = (devicesList: IMediaDevice[], groupId: string): boolean => {
    for (let i = 0; i < devicesList.length; i++) {
      const device = devicesList[i];
      if (device.groupId === groupId) return true;
    }

    return false;
  };

  const toInterface = (devices: MediaDeviceInfo[]): IMediaDevices => {
    const mappedDevices = {
      audioinput: [] as IMediaDevice[],
      audiooutput: [] as IMediaDevice[],
      videoinput: [] as IMediaDevice[],
    };

    devices.forEach((device) => {
      const { deviceId, groupId, label, kind } = device;

      if (!containsDeviceId(mappedDevices[kind], groupId)) {
        mappedDevices[kind].push({
          id: deviceId,
          groupId,
          name: label,
        } as IMediaDevice);
      }
    });

    return mappedDevices;
  };

  return { toInterface };
};

export default MediaDevicesMapper;
