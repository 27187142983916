import { Flex, IFlexProps, Popup, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IHeaderNotificationsPopupWrapperProps {}

export const HeaderNotificationsPopupWrapper = styled(Popup)<IHeaderNotificationsPopupWrapperProps>`
  right: -${styles.iconSize.normal};
  width: max-content;
  padding: 0.75rem 0;

  &.popup-enter-done {
    height: 85vh !important;
    max-height: 85vh !important;
    padding: 0.75rem;
  }
`;

export interface IHeaderNotificationsPopupListContainerProps {}

export const HeaderNotificationsPopupListContainer = styled.ul<IHeaderNotificationsPopupListContainerProps>`
  height: 100%;
  overflow-y: auto;
  padding: 0 !important;
`;

export interface IHeaderNotificationsPopupMessageProps {}

export const HeaderNotificationsPopupMessage = styled(Flex).attrs((_: IFlexProps) => ({
  as: 'p',
}))<IHeaderNotificationsPopupMessageProps>`
  width: 20rem;
  height: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  box-sizing: border-box;
  color: ${rgba(styles.colors.brand.dark, 0.75)};
  text-align: center;
`;
