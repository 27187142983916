import { Popup } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface ILanguagePopupWrapperProps {}

export const LanguagePopupWrapper = styled(Popup)<ILanguagePopupWrapperProps>`
  align-items: flex-start;
  bottom: 2em;
  padding-right: 1rem;
  padding-left: 1rem;
  transform-origin: 50% 100%;
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .language_popup {
    min-width: 20vw;
    right: 0;
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .language_modal {
    position: static;
    border-radius: 0;
    box-shadow: none;

    > div {
      padding: 1.5rem;
      padding-top: 0;
    }
  }
}
*/
