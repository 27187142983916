import { iconsTemp } from 'iconsTemp';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import Icon from '../../View/Icon';
import BaseInput from './BaseInput';
import { InputProps, InputsInnerProps } from './Input';
import styles from './SearchInput.module.scss';

export type SearchInputProps = InputProps & InputsInnerProps & {};

const SearchInput: React.FC<SearchInputProps> = (props: React.PropsWithChildren<SearchInputProps>) => {
  const { className, placeholder } = props;
  const language = useSelector((state: IReduxState) => state.language.values) as ILanguage;

  return (
    <BaseInput
      {...{
        ...props,
        className: `${styles['search_input']} ${className}`,
        placeholder: placeholder ? placeholder : language.search,
      }}>
      <Icon className={styles['search_icon']} src={iconsTemp.search} />
    </BaseInput>
  );
};

export default SearchInput;
