import { FormContextProvider } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IAddAvailabilityFormProps {}

export const AddAvailabilityForm = styled(FormContextProvider)<IAddAvailabilityFormProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;
