import { Container, getColumnsWidth } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IStartOnboardingWrapperProps {}

export const StartOnboardingWrapper = styled(Container)`
  width: ${getColumnsWidth({ nrColumns: 3 })};
  height: auto;
  padding: 2rem 2.5rem;
  margin: 22.5vh auto 0 auto !important;

  > * {
    text-align: center;
  }

  h1 {
    margin-bottom: 0.5rem;
  }

  > p {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;
