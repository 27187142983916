import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import Button, { ButtonThemes } from 'components/Form/Button';
import Evaluate from 'components/Form/Evaluate/Evaluate';
import { Gender } from 'dtos/EntityDTO';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes/routes';
import { placeValue } from 'utils/language';
import usePatientAppointmentEnd from './PatientAppointmentEnd.logic';
import styles from './PatientAppointmentEnd.module.scss';

export interface PatientAppointmentEndProps {}

const PatientAppointmentEnd: FC<PatientAppointmentEndProps> = (
  props: PropsWithChildren<PatientAppointmentEndProps>
): JSX.Element => {
  const {} = props;
  const { language, medic, score, selectScoreHandler, submitEvaluationHandler } = usePatientAppointmentEnd();
  const drName = medic!.name;
  const drGender = medic!.gender;

  return (
    <>
      <div className={styles['appointment-end']}>
        <form>
          <UserImage style={{ marginBottom: '2rem' }} src={medic?.image} size='7.5rem' />
          <h4>
            {placeValue(language[`howWasYourAppointmentWithDrX${drGender === Gender.FEMALE ? 'F' : 'M'}`], drName)}
          </h4>
          <Evaluate
            dataTestId='evaluateMedic'
            className={styles.evaluate}
            score={score}
            onSelectScore={selectScoreHandler}
          />
          <Button
            valueType='submit'
            theme={ButtonThemes.LIGHT}
            label={language.evaluate}
            disabled={score === 0}
            onClick={submitEvaluationHandler}
          />
          <Link className={styles['no-evaluate_link']} to={routes.home.path}>
            <b>{language.iPreferNotToSay}</b>
          </Link>
        </form>
      </div>
    </>
  );
};

export default PatientAppointmentEnd;
