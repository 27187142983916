import { ITagProps, rgba, styles, Tag } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IRelatedUserItemTagProps {}

export const RelatedUserItemTag = styled(Tag).attrs(({ iconColor }: ITagProps) => ({
  textColor: rgba(styles.colors.brand.dark, 0.75),
  iconWithBackground: true,
}))<IRelatedUserItemTagProps>`
  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

export interface IRelatedUserItemDiseasesTagProps {}

export const RelatedUserItemDiseasesTag = styled(RelatedUserItemTag)<IRelatedUserItemDiseasesTagProps>`
  justify-content: flex-start;
  min-width: 100%;
  margin-bottom: 0.5rem;

  + * {
    margin-left: 0 !important;
  }
`;
