import useRescheduleAppointment from './RescheduleAppointment.logic';
import {
  LeftContent,
  RightContent,
  Button,
  FormContextProvider,
  WhitePage,
  TextArea,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import UserInfo from 'components/User/UserInfo';
import EntityDTO from 'dtos/EntityDTO';
import { Separator } from './RescheduleAppointment.styles';
import { placeValue } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { PropsWithChildren, FC } from 'react';
import { rescheduleAppointmentFormInputs } from 'services/appointments.service';
import RescheduleAvailabilities from './Availabilities';

export interface IRescheduleAppointmentProps extends PropsWithChildren<{}> {}

const RescheduleAppointment: FC<IRescheduleAppointmentProps> = (props: IRescheduleAppointmentProps): JSX.Element => {
  const {} = props;
  const { language, appointment, isLoading, backHandler, submitCancellationHandler } = useRescheduleAppointment();

  if (!appointment) return <></>;

  const { place, specialty, user, medicId } = appointment;

  return (
    <FormContextProvider initialValues={rescheduleAppointmentFormInputs} onSubmit={submitCancellationHandler}>
      <WhitePage title={language.rescheduleAppointment} onBack={backHandler}>
        <LeftContent>
          {user && (
            <UserInfo user={user as EntityDTO}>
              {specialty.name ? `${placeValue(language.appointmentSpecialty, specialty.name)} . ` : ''}
              {language[place]}
            </UserInfo>
          )}
          <Separator />
          <TextArea
            dataTestId='rescheduleAppointment-reasonInput'
            name='reason'
            placeholder={language.rescheduleReason}
          />
          <h6 style={{ marginTop: '2rem' }}>{language.timeSuggestions}</h6>
          {medicId && <RescheduleAvailabilities medicId={medicId} specialtyId={specialty.id} />}
        </LeftContent>
        <RightContent>
          <Button
            dataTestId='rescheduleAppointment-submitButton'
            label={language.reschedule}
            buttonType='submit'
            loading={isLoading}
          />
        </RightContent>
      </WhitePage>
    </FormContextProvider>
  );
};

export default RescheduleAppointment;
