import { InputProps, InputRef, InputValue, INPUT_TYPES } from './Input';
import { useEffect, useImperativeHandle, useState } from 'react';
import IOption from 'interfaces/IOption';

export type UseInput = {
  value: InputValue;
  setValue: React.Dispatch<React.SetStateAction<InputValue>>;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  focusHandler: () => void;
  blurHandler: () => void;
  changeNumberHandler: (value: number) => void;
  changeDateOrTimeHandler: (date: Date) => void;
  changeOptionHandler: (option: IOption) => void;
};

const useInput = (props: InputProps, ref: React.ForwardedRef<InputRef>): UseInput => {
  const { type, defaultValue, value: propsValue, options, onChange, onFocus, onBlur } = props;
  const [value, setValue] = useState<InputValue>(getDefaultValue(defaultValue, type!));

  useImperativeHandle(ref, () => ({ value: value }));

  useEffect(() => {
    if (propsValue === undefined) return;

    if (type === INPUT_TYPES.OPTIONS) {
      const optionName = options?.find((option: IOption) => option.id === (propsValue as string | number))?.name;

      if (optionName) setValue(optionName);
    } else setValue(propsValue);
  }, [propsValue, type, options]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange !== undefined) onChange(event.currentTarget.value);
    else setValue(event.currentTarget.value);
  };

  const changeNumberHandler = (value: number) => {
    if (onChange !== undefined) onChange(value);
    else setValue(value);
  };

  const changeDateOrTimeHandler = (date: Date): void => {
    if (onChange !== undefined) onChange(date);
    else setValue(date);
  };

  const changeOptionHandler = (option: IOption): void => {
    if (onChange !== undefined) onChange(option.id);
    else setValue(option.name);
  };

  const focusHandler = (): void => {
    if (onFocus) onFocus();
  };

  const blurHandler = (): void => {
    if (onBlur) onBlur(value);
  };

  return {
    value,
    setValue,
    changeHandler,
    focusHandler,
    blurHandler,
    changeNumberHandler,
    changeDateOrTimeHandler,
    changeOptionHandler,
  };
};

const getDefaultValue = (defaultValue: InputValue | undefined, type: INPUT_TYPES): InputValue => {
  if (defaultValue) return defaultValue;

  switch (type) {
    case INPUT_TYPES.DATE:
    case INPUT_TYPES.TIME:
      return new Date();
    default:
      return '';
  }
};

export default useInput;
