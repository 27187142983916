import { TextArea } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import {
  AppointmentDataStepMultioption,
  AppointmentDataStepSelect,
  AppointmentDataStepWrapper,
} from './AppointmentDataStep.styles';

export interface IAppointmentDataStepProps extends PropsWithChildren<{}> {
  specialties: SpecialtyDTO[];
}

const AppointmentDataStep: FC<IAppointmentDataStepProps> = (props: IAppointmentDataStepProps): JSX.Element => {
  const { specialties } = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <AppointmentDataStepWrapper>
      {/* <AppointmentDataStepSelect name='ensurance' icon='contacts' options={[]} /> */}
      <AppointmentDataStepSelect
        dataTestId='scheduleAppointment-specialtyInput'
        name='specialty'
        icon='appointment'
        placeholder={language.selectSpecialty}
        options={specialties}
      />
      <TextArea
        dataTestId='scheduleAppointment-reasonInput'
        name='reason'
        icon='details'
        placeholder={language.appointmentReason}
      />
      <AppointmentDataStepMultioption dataTestId='scheduleAppointment-appointmentTypeInput' name='type' big />
    </AppointmentDataStepWrapper>
  );
};

export default AppointmentDataStep;
