import EventDTO from 'dtos/EventDTO';
import { IDayGroup } from 'interfaces/IGroup';
import DayGroup from './DayGroup';
import styles from './YearGroup.module.scss';

export type YearGroupProps = {
  yearEvents: IDayGroup<IDayGroup<EventDTO>>;
  onDelete?: (eventId: string) => void;
};

const YearGroup: React.FC<YearGroupProps> = (props: React.PropsWithChildren<YearGroupProps>) => {
  const { yearEvents, onDelete } = props;

  return (
    <div className={styles['year-group']}>
      <span>{yearEvents.date.getFullYear()}</span>
      {yearEvents.list.map((dayEvents: IDayGroup<EventDTO>, i: number) => (
        <DayGroup key={i} dayEvents={dayEvents} onDelete={onDelete} />
      ))}
    </div>
  );
};

export default YearGroup;
