import { iconsTemp } from 'iconsTemp';
import React from 'react';
import styles from './SendMessage.module.scss';
import Icon from 'components/View/Icon';
import useSendMessage from './SendMessage.logic';
import { getFileTypesList } from 'utils/file';
import Button, { ButtonTypes } from 'components/Form/Button';

export type SendMessageProps = {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
};

const SendMessage: React.FC<SendMessageProps> = (props: React.PropsWithChildren<SendMessageProps>) => {
  const { className, disabled, placeholder } = props;
  const { language, nrVisibleLines, inputRef, fileUploadChangeHandler, writeHandler, sendHandler } = useSendMessage();

  return (
    <div className={`${styles.send}  ${className}`}>
      <label className={styles['upload-file']}>
        <input
          data-testid='sendMessage-uploadFileInput'
          type='file'
          disabled={disabled}
          accept={getFileTypesList().join(',')}
          onChange={fileUploadChangeHandler}
        />
        <Icon className={`${styles['add-icon']}  ${disabled && styles.disabled}`} src={iconsTemp.add} />
      </label>
      <textarea
        data-testid='sendMessage-messageInput'
        disabled={disabled}
        placeholder={placeholder ? placeholder : language.sendMessage}
        rows={nrVisibleLines}
        onKeyDown={writeHandler}
        ref={inputRef}
      />
      <Button
        dataTestId='sendMessage-sendButton'
        className={styles['send-message_button']}
        type={ButtonTypes.TEXT}
        icon={iconsTemp.send}
        disabled={disabled}
        onClick={sendHandler}
      />
    </div>
  );
};

export default SendMessage;
