import { Button } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import HeaderNotificationsPopup from 'components/Header/HeaderNotificationsPopup';
import { FC, PropsWithChildren, useContext, useState } from 'react';
import NotificationsContext from 'store/notifications-context';
import { HeaderNotificationsNumber } from './HeaderNotifications.styles';

export interface HeaderNotificationsProps extends PropsWithChildren<{}> {}

const HeaderNotifications: FC<HeaderNotificationsProps> = (props: HeaderNotificationsProps): JSX.Element => {
  const {} = props;
  const { nrUnreadNotifications } = useContext(NotificationsContext);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <div style={{ position: 'relative' }}>
      <Button
        dataTestId='header-notificationsButton'
        icon='notifications'
        type='secondary'
        active={showPopup}
        onClick={() => setShowPopup((prevState: boolean) => !prevState)}
      />
      {nrUnreadNotifications > 0 && (
        <HeaderNotificationsNumber as='span' data-testid='header-notificationsCounter'>
          {nrUnreadNotifications}
        </HeaderNotificationsNumber>
      )}
      <HeaderNotificationsPopup show={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  );
};

export default HeaderNotifications;
