import { IProps, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import PatientDTO from 'dtos/PatientDTO';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { PatientInfoDetails, PatientInfoHeaderWrapper, PatientInfoName } from './PatientInfoHeader.styles';

export interface IPatientInfoHeaderProps extends PropsWithChildren<IProps> {
  patient: PatientDTO;
}

const PatientInfoHeader: FC<IPatientInfoHeaderProps> = (props: IPatientInfoHeaderProps): JSX.Element => {
  const { className, style, patient } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const { image, name, age, city, country } = patient;

  return (
    <PatientInfoHeaderWrapper className={className} style={style} justifyContent='flex-start'>
      <UserImage style={{ marginRight: '2rem' }} src={image} size='7.5rem' />
      <div style={{ marginTop: '2rem' }}>
        <PatientInfoName>
          {name}
          <span>
            , {age} {language.yearsOld}
          </span>
        </PatientInfoName>
        <PatientInfoDetails>
          {city ? city : ''}
          {city && country ? ',' : ''}
          &nbsp;
          {country ? country : ''}
        </PatientInfoDetails>
      </div>
    </PatientInfoHeaderWrapper>
  );
};

export default PatientInfoHeader;
