import EventDTO from 'dtos/EventDTO';
import useDate, { GlobalDateMasks } from 'hooks/use-date';
import { IDayGroup } from 'interfaces/IGroup';
import { isPast } from 'utils/date';
import PatientAppointment from './PatientAppointment';
import styles from './DayGroup.module.scss';

export type DayGroupProps = {
  dayEvents: IDayGroup<EventDTO>;
  onDelete?: (eventId: string) => void;
};

const DayGroup: React.FC<DayGroupProps> = (props: React.PropsWithChildren<DayGroupProps>) => {
  const { dayEvents, onDelete } = props;
  const { formatDate } = useDate();

  return (
    <div className={styles['day-group']}>
      <div className={`${isPast(dayEvents.date) && styles.past}`}>
        <h5>{dayEvents.date.getDate()}</h5>
        <span>{formatDate(dayEvents.date, GlobalDateMasks.shortMonth)}</span>
      </div>
      <div>
        {dayEvents.list.map((event: EventDTO) => (
          <PatientAppointment key={event.id} appointmentEvent={event} onDelete={onDelete} />
        ))}
      </div>
    </div>
  );
};

export default DayGroup;
