import { Button, Flex } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { datesAreEqual, isToday, useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { FC, PropsWithChildren } from 'react';
import { AvailableSlotsSliderHeaderDay, AvailableSlotsSliderHeaderWrapper } from './AvailableSlotsSliderHeader.styles';

export interface IAvailableSlotsSliderHeaderProps extends PropsWithChildren<{}> {
  dates: Date[];
  onGoToPrevious: () => void;
  onGoToNext: () => void;
}

const AvailableSlotsSliderHeader: FC<IAvailableSlotsSliderHeaderProps> = (
  props: IAvailableSlotsSliderHeaderProps
): JSX.Element => {
  const { dates, onGoToPrevious, onGoToNext } = props;
  const { formatDate, masks } = useDate();

  return (
    <AvailableSlotsSliderHeaderWrapper>
      <Button
        type='secondary'
        icon='arrowLeft'
        disabled={datesAreEqual(dates.at(0)!, new Date())}
        onClick={onGoToPrevious}
      />
      <Flex style={{ flex: 1 }} justifyContent='space-between'>
        {dates.map((date: any, index: number) => (
          <AvailableSlotsSliderHeaderDay key={index} isToday={isToday(date)}>
            <div>
              <b>{formatDate(date, masks.shortWeekDay).toLowerCase()}</b>
              <br />
              <span>{formatDate(date, masks.shortDayNumberMonth)}</span>
            </div>
          </AvailableSlotsSliderHeaderDay>
        ))}
      </Flex>
      <Button type='secondary' icon='arrowRight' onClick={onGoToNext} />
    </AvailableSlotsSliderHeaderWrapper>
  );
};

export default AvailableSlotsSliderHeader;
