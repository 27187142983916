import { Flex, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface ILoginAccountWrapperProps {}

export const LoginAccountWrapper = styled(Flex)<ILoginAccountWrapperProps>`
  width: 100%;
  padding: 1.25rem 1rem;
  box-sizing: border-box;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba(styles.colors.brand.main, 0.15)};
  }
`;
