import {
  Button,
  Flex,
  getColumnsWidth,
  getGutter,
  styles,
  rgba,
  transition,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import Messages from 'components/Chat/Messages/Messages';
import SendMessage from 'components/Chat/Messages/SendMessage';
import styled, { css } from 'styled-components';

const sideContainerWidth = getColumnsWidth({ nrColumns: 3 });

export interface IAppointmentDetailsWrapperProps {}

export const AppointmentDetailsWrapper = styled(Flex)<IAppointmentDetailsWrapperProps>`
  flex: 1;
  overflow-y: hidden;
  width: 100%;
`;

export interface IAppointmentDetailsChatProps {
  isOpen: boolean;
}

export const AppointmentDetailsChat = styled.div<IAppointmentDetailsChatProps>`
  position: relative;
  width: 0;
  height: 100%;
  padding-left: 0;
  margin-left: 0;
  ${transition('width', 'padding-left', 'border', 'border-color', 'margin-left')}

  ${({ isOpen }: IAppointmentDetailsChatProps) =>
    isOpen
      ? css`
          width: ${getColumnsWidth({ nrColumns: 3 })};
          padding-left: calc(${getGutter()} / 2);
          margin-left: calc(${getGutter()} / 2);
          border-left: 1px solid ${rgba(styles.colors.brand.dark, 0.1)};
        `
      : css``}
`;

export interface IAppointmentDetailsChatButtonProps {}

export const AppointmentDetailsChatButton = styled(Button)<IAppointmentDetailsChatButtonProps>`
  position: absolute;
  top: 0;
  right: 0;
  min-width: auto;
  z-index: 1;
`;

export interface IAppointmentDetailsChatContainerProps {}

export const AppointmentDetailsChatContainer = styled(Flex)<IAppointmentDetailsChatContainerProps>`
  overflow: hidden;
  height: 100%;
`;

export interface IAppointmentDetailsChatMessagesProps {}

export const AppointmentDetailsChatMessages = styled(Messages)<IAppointmentDetailsChatMessagesProps>`
  width: ${sideContainerWidth};
  box-sizing: border-box;
  margin-top: 3.5rem;
  padding-right: 1.2rem;

  > .messages-user {
    right: 0.15rem;
  }
`;

export interface IAppointmentDetailsChatSendMessageProps {}

export const AppointmentDetailsChatSendMessage = styled(SendMessage)<IAppointmentDetailsChatSendMessageProps>`
  width: ${sideContainerWidth};

  > textarea {
    height: 2.4rem;
  }
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .details-tabs {
    overflow-y: visible;

    > ul {
      justify-content: center;

      > li {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }

    > div {
      padding: 0 getMargin($tablet);
      box-sizing: border-box;
      margin-top: 0;

      > div {
        @include container;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        padding: getGutter($tablet) * 2;
        padding-bottom: 6rem;
      }
    }
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .details-tabs {
    > ul {
      justify-content: flex-start;
      padding: 0 getMargin($tablet);
      box-sizing: border-box;

      &::-webkit-scrollbar {
        height: 0;
        background: transparent;
      }
    }

    > div {
      padding: 0;

      > div {
        padding-bottom: 5rem;
      }
    }
  }
}
*/
