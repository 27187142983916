import { notificationsAPI } from 'apis';
import NotificationDTO from 'dtos/NotificationDTO';
import IReduxState from 'interfaces/IReduxState';
import NotificationMapper from 'mappers/NotificationMapper';
import { useSelector } from 'react-redux';
import FirebaseTokenBody from 'RequestsBody/FirebaseTokenBody';
import NotificationSchema from 'schemas/NotificationSchema';
import { getUniqueValues } from 'utils/filter';
import { onMessageListener } from 'utils/firebase';
import { userIsMedic } from 'utils/user';
import useHttp from './http.service';
import useMedicsService from './medics.service';

const notificationMapper = NotificationMapper();

export const DEFAULT_NR_NOTIFICATIONS = 10;

export type UseNotificationsService = {
  isLoading: boolean;
  createOrUpdateFirebaseToken: (token: string) => Promise<void>;
  onNewNotificationReceived: () => Promise<NotificationDTO>;
  getNotifications: (lastId?: string, unreadOnly?: boolean, pageSize?: number) => Promise<NotificationDTO[]>;
  getNrUnreadNotifications: () => Promise<number>;
  setNotificationRead: (notificationId: string) => Promise<void>;
};

const useNotificationsService = (): UseNotificationsService => {
  const { get, post, put, isLoading } = useHttp();
  const { getMedicImage, getMedicPatientImage } = useMedicsService();
  const userType = useSelector((state: IReduxState) => state.auth.type)!;

  const getNotificationsUsersImages = async (notifications: NotificationDTO[]): Promise<NotificationDTO[]> => {
    const usersIds = getUniqueValues(notifications, 'userId');

    await Promise.allSettled([
      ...usersIds.map(async (userId: string) => {
        await (userIsMedic(userType) ? getMedicPatientImage : getMedicImage)(userId).then((imageBlob: string) => {
          notifications.forEach((notification: NotificationDTO) => {
            if (notification.userId === userId) notification.image = imageBlob;
          });
        });
      }),
    ]);

    return notifications;
  };

  const createOrUpdateFirebaseToken = (token: string): Promise<void> => {
    const body: FirebaseTokenBody = { firebaseToken: token };

    return new Promise((resolve, reject) => {
      post(`${notificationsAPI}/firebaseToken`, undefined, body)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  const onNewNotificationReceived = (): Promise<NotificationDTO> => {
    return new Promise((resolve) => {
      onMessageListener().then(async (notification: NotificationDTO) => {
        notification = (await getNotificationsUsersImages([notification]))[0];
        resolve(notification);
      });
    });
  };

  const getNotifications = (
    lastId?: string,
    unreadOnly: boolean = false,
    pageSize: number = DEFAULT_NR_NOTIFICATIONS
  ): Promise<NotificationDTO[]> => {
    const parameters = { lastId, unreadOnly, pageSize };

    return new Promise((resolve, reject) => {
      get(`${notificationsAPI}/userNotifications/history`, parameters)
        .then(async (notifications: NotificationSchema[]) => {
          const mappedNotifications = notifications.map((notification: NotificationSchema) =>
            notificationMapper.toInterface(notification)
          );

          resolve(await getNotificationsUsersImages(mappedNotifications));
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getNrUnreadNotifications = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      get(`${notificationsAPI}/userNotifications/unread`)
        .then((nrUnreadNotifications: number) => {
          resolve(nrUnreadNotifications);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const setNotificationRead = (notificationId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      put(`${notificationsAPI}/userNotifications/${notificationId}/read`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  return {
    createOrUpdateFirebaseToken,
    onNewNotificationReceived,
    getNotifications,
    getNrUnreadNotifications,
    setNotificationRead,
    isLoading,
  };
};

export default useNotificationsService;
