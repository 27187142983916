import { FC, PropsWithChildren } from 'react';
import Select from 'components/Form/Select';
import Toggle from 'components/Form/Toggle/Toggle';
import { useCallSettings } from './CallSettings.logic';
import styles from './CallSettings.module.scss';
import MicrophoneVolume from './MicrophoneVolume';
import SpeakerVolume from './SpeakerVolume';
import IOption from 'interfaces/IOption';

export type CallSettingsProps = {};

const CallSettings: FC = (props: PropsWithChildren<CallSettingsProps>) => {
  const {} = props;
  const {
    language,
    audioInputs,
    videoInputs,
    audioDevice,
    videoDevice,
    volume,
    microphoneVolume,
    noiseSuppression,
    changeVolumeHandler,
    changeAudioDeviceHandler,
    changeVideoDeviceHandler,
    toggleNoiseSupressionHandler,
  } = useCallSettings();

  return (
    <div className={styles.settings}>
      <section>
        <h4>{language.audio}</h4>
        <label>{language.speaker}</label>
        <SpeakerVolume volume={volume} onVolumeChange={changeVolumeHandler} />
        <label>{language.microphone}</label>
        <Select
          dataTestId='call-changeMicrophoneInput'
          options={audioInputs}
          option={audioDevice}
          onChange={(option: IOption) => changeAudioDeviceHandler(option.id as string)}
        />
        <MicrophoneVolume volume={microphoneVolume} />
        <label>
          <span>{language.noiseCancellation}</span>
          <Toggle
            dataTestId='call-noiseSupressionToggle'
            checked={noiseSuppression}
            onChange={toggleNoiseSupressionHandler}
          />
        </label>
      </section>
      <section>
        <h4>{language.camera}</h4>
        <Select
          dataTestId='call-changeCameraInput'
          options={videoInputs}
          option={videoDevice}
          onChange={(option: IOption) => changeVideoDeviceHandler(option.id as string)}
        />
      </section>
    </div>
  );
};

export default CallSettings;
