import styles from './Color.module.scss';

export type ColorProps = {
  name: string;
  hex: string;
};

const Color: React.FC<ColorProps> = (props: React.PropsWithChildren<ColorProps>) => {
  const { name, hex } = props;

  return (
    <div className={`${styles.color} ${name.includes('lightest') && styles.border}`}>
      <div style={{ backgroundColor: hex }}></div>
      <div>
        <b>{name}</b>
        <span>{hex}</span>
      </div>
    </div>
  );
};

export default Color;
