import { rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';
import { EventCardContainer } from './EventCard.styles';

export interface IEmptyEventCardWrapperProps {}

export const EmptyEventCardWrapper = styled(EventCardContainer)<IEmptyEventCardWrapperProps>`
  justify-content: center;
  width: 100%;
  color: ${rgba(styles.colors.brand.dark, 0.75)};
  background-color: ${rgba(styles.colors.brand.dark, 0.05)};
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .no-appointment {
    background-color: rgba($main, 0.1);
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .no-appointment {
    font-size: $textSize_small;
  }
}
*/
