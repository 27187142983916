import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AppointmentInfo from 'components/Appointment/AppointmentInfo';
import Button, { ButtonThemes, ButtonTypes } from 'components/Form/Button';
import Popup from 'components/View/Popup';
import { AppointmentStatus } from 'dtos/AppointmentDTO';
import EventDTO from 'dtos/EventDTO';
import { iconsTemp } from 'iconsTemp';
import { isAppointmentPayed } from 'utils/appointment';
import { getDoctorAbreviation } from 'utils/language';
import useAppointment from './PatientAppointment.logic';
import styles from './PatientAppointment.module.scss';

export type PatientAppointmentProps = {
  appointmentEvent: EventDTO;
  onDelete?: (eventId: string) => void;
};

const PatientAppointment: React.FC<PatientAppointmentProps> = (
  props: React.PropsWithChildren<PatientAppointmentProps>
) => {
  const { appointmentEvent, onDelete } = props;
  const { appointment, price } = appointmentEvent;
  const {
    language,
    appointmentIsConfirmed,
    showPopup,
    showStartAppointmentButton,
    appointmentHasPassed,
    togglePopupHandler,
    payAppointmentHandler,
    startAppointmentHandler,
    rescheduleAppointmentHandler,
    cancelAppointmentHandler,
  } = useAppointment(props);

  return (
    <div className={styles.appointment}>
      <div>
        <div
          className={`${
            (appointment?.status === AppointmentStatus.Created || appointment?.status === AppointmentStatus.Pending) &&
            styles.disabled
          }`}>
          <UserImage src={appointment?.user?.image} />
          <div>
            <h5 className={`${appointmentHasPassed && styles.past}`}>
              {appointment!.user && getDoctorAbreviation(language, appointment!.user.gender, appointment!.user.name)}
              {!appointment!.user && language.unknown}
            </h5>
            <AppointmentInfo appointmentEvent={appointmentEvent} showOnlySpecialty={appointmentHasPassed} />
          </div>
        </div>
        {appointmentIsConfirmed && !appointmentHasPassed && (
          <div>
            <Button
              className={styles['options_button']}
              type={ButtonTypes.SECONDARY}
              active={showPopup}
              icon={iconsTemp.options}
              onClick={togglePopupHandler}
            />
            <Popup className={styles['options_popup']} show={showPopup} onClose={togglePopupHandler}>
              {!isAppointmentPayed(appointment?.status) && (
                <Button
                  type={ButtonTypes.TEXT}
                  theme={ButtonThemes.DARK}
                  icon={iconsTemp.calendar}
                  label={language.reschedule}
                  onClick={rescheduleAppointmentHandler}
                />
              )}
              {onDelete && (
                <Button
                  type={ButtonTypes.TEXT}
                  theme={ButtonThemes.RED}
                  icon={iconsTemp.close}
                  label={language.cancelAppointment}
                  onClick={cancelAppointmentHandler}
                />
              )}
            </Popup>
          </div>
        )}
      </div>
      <div>
        {showStartAppointmentButton && (
          <Button
            type={ButtonTypes.PRIMARY}
            theme={ButtonThemes.GREEN}
            icon={iconsTemp.call}
            label={language.startAppointment}
            onClick={startAppointmentHandler}
          />
        )}
        {!appointmentIsConfirmed && !appointmentHasPassed && (
          <>
            <Button
              type={ButtonTypes.PRIMARY}
              icon={iconsTemp.price}
              label={`${language.makeThePayment} ${price}€`}
              onClick={payAppointmentHandler}
            />
            {!isAppointmentPayed(appointment?.status) && (
              <Button
                type={ButtonTypes.SECONDARY}
                theme={ButtonThemes.DARK}
                icon={iconsTemp.calendar}
                label={language.reschedule}
                onClick={rescheduleAppointmentHandler}
              />
            )}
            {onDelete && (
              <Button
                type={ButtonTypes.SECONDARY}
                theme={ButtonThemes.DARK}
                icon={iconsTemp.close}
                label={language.cancelAppointment}
                onClick={cancelAppointmentHandler}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PatientAppointment;
