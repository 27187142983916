import { FC, PropsWithChildren } from 'react';
import { ScrollView, Flex, DateInput, TimeInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import useEditAvailabilityFormContent from './EditAvailabilityFormContent.logic';
import EventDTO from 'dtos/EventDTO';

export interface IEditAvailabilityFormContentProps extends PropsWithChildren<{}> {
  event: EventDTO;
}

const EditAvailabilityFormContent: FC<IEditAvailabilityFormContentProps> = (
  props: IEditAvailabilityFormContentProps
): JSX.Element => {
  const {} = props;
  const {} = useEditAvailabilityFormContent(props);

  return (
    <ScrollView>
      <DateInput dataTestId='editAvailabilityForm-startDateInput' name='startDate' icon='calendar' />
      <Flex justifyContent='flex-start'>
        <TimeInput dataTestId='editAvailabilityForm-startTimeInput' name='startTime' icon='time' />
        <span style={{ margin: '1.2rem 1rem 0 1rem' }}>-</span>
        <TimeInput dataTestId='editAvailabilityForm-endTimeInput' name='endTime' disabled />
      </Flex>
    </ScrollView>
  );
};

export default EditAvailabilityFormContent;
