import { PropsWithChildren, FC } from 'react';
import { LoginContainer } from '../Login.styles';
import usePasswordResetSent from './PasswordResetSent.logic';
import { PasswordResetSentIllustration, PasswordResetSentMessage } from './PasswordResetSent.styles';

export interface PasswordResetSentProps extends PropsWithChildren<{}> {}

const PasswordResetSent: FC<PasswordResetSentProps> = (props: PasswordResetSentProps): JSX.Element => {
  const {} = props;
  const { language } = usePasswordResetSent();

  return (
    <LoginContainer>
      <PasswordResetSentIllustration src='' />
      <PasswordResetSentMessage>{language.passwordResetSent}</PasswordResetSentMessage>
    </LoginContainer>
  );
};

export default PasswordResetSent;
