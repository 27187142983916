import { isMobile } from 'react-device-detect';
import { Transition } from 'react-transition-group';
import { iconsTemp } from 'iconsTemp';
import styles from './CallButtons.module.scss';
import sass from 'styles/exports.module.scss';
import { ForwardedRef, forwardRef, ForwardRefExoticComponent, PropsWithChildren, RefAttributes } from 'react';
import CallButton from './CallButton';
import { useCallButtons } from './CallButtons.logic';

export type CallButtonsTypes = 'microphone' | 'camera' | 'switch' | 'screen-share' | 'end' | 'exit';

export interface CallButtonsProps {
  className?: string;
  include: CallButtonsTypes[];
  hideOnMobile?: boolean;
  onEndCall?: () => void;
  onExit?: () => void;
}

export interface CallButtonsRef {
  toggle: () => void;
}

const CallButtons: ForwardRefExoticComponent<CallButtonsProps & RefAttributes<CallButtonsRef>> = forwardRef<
  CallButtonsRef,
  CallButtonsProps
>((props: PropsWithChildren<CallButtonsProps>, ref: ForwardedRef<CallButtonsRef>) => {
  const { className, include, onEndCall, onExit } = props;
  const { language, showCallButtons, microphoneIsOn, cameraIsOn, screenShareIsOn, cameraIsSwitched, toggleHandler } =
    useCallButtons(props, ref);

  return (
    <Transition in={showCallButtons} timeout={+sass.transition} mountOnEnter unmountOnExit>
      {(state) => (
        <div className={`${styles.container} ${styles[state]} ${className}`}>
          {include.includes('microphone') && (
            <CallButton
              dataTestId='call-microphoneButton'
              active={microphoneIsOn}
              activeIcon={iconsTemp.microphoneOn}
              inactiveIcon={iconsTemp.microphoneOff}
              label={language.silence}
              onClick={() => toggleHandler('microphone')}
            />
          )}
          {include.includes('camera') && (
            <CallButton
              dataTestId='call-cameraButton'
              active={!cameraIsOn}
              activeIcon={iconsTemp.videoCallOff}
              inactiveIcon={iconsTemp.videoCallOn}
              label={language.camera}
              onClick={() => toggleHandler('camera')}
            />
          )}
          {include.includes('screen-share') && !isMobile && (
            <CallButton
              dataTestId='call-screenShareButton'
              active={!screenShareIsOn}
              activeIcon={iconsTemp.screenShare}
              label={language.screenShare}
              onClick={() => toggleHandler('screen-share')}
            />
          )}
          {include.includes('switch') && isMobile && (
            <CallButton
              dataTestId='call-switchCameraButton'
              active={!cameraIsSwitched}
              activeIcon={iconsTemp.flipCamera}
              label={language.switch}
              onClick={() => toggleHandler('switch')}
            />
          )}
          {include.includes('end') && (
            <CallButton
              dataTestId='call-endButton'
              active={true}
              activeIcon={iconsTemp.endCall}
              label={language.endCall}
              onClick={onEndCall ? onEndCall : () => {}}
              isEnd
            />
          )}
          {include.includes('exit') && (
            <CallButton
              active={true}
              activeIcon={iconsTemp.exit}
              label={language.exit}
              onClick={onExit ? onExit : () => {}}
              isEnd
            />
          )}
        </div>
      )}
    </Transition>
  );
});

CallButtons.defaultProps = {
  hideOnMobile: true,
};

export default CallButtons;
