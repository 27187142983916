import { ProfileType } from 'dtos/EntityDTO';
import IRoute, { IRegisterSubRoutes } from 'interfaces/IRoute';

const registerSubRoutes: IRegisterSubRoutes = {
  feedback: {
    path: '/register/:type/feedback',
    exact: true,
    meta: { auth: false, roles: [ProfileType.MEDIC] },
  } as IRoute,
};

export default registerSubRoutes;
