import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './Popup.module.scss';
import sass from 'styles/exports.module.scss';
import { useRef, useState } from 'react';
import useMediaScreen from 'hooks/use-mediaScreen';
import useClickOutside from 'hooks/use-clickOutside';

export type PopupProps = {
  dataTestId?: string;
  className?: string;
  show: boolean;
  top?: string;
  close?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
};

const Popup: React.FC<PopupProps> = (props: React.PropsWithChildren<PopupProps>) => {
  const { dataTestId, className, show, top, close, onClose, children } = props;
  const { isMobileScreen } = useMediaScreen();
  const [complete, setComplete] = useState<boolean>(false);
  const style = { top };
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, close, onClose);

  const mouseDownHandler = (event: React.MouseEvent): void => {
    event.preventDefault();
  };

  return (
    <Transition
      in={show}
      timeout={+sass.transition}
      addEndListener={() => setComplete((prevState: boolean) => !prevState)}
      mountOnEnter
      unmountOnExit>
      {(state: TransitionStatus) => (
        <>
          {isMobileScreen && <div className={`${styles.background} ${state && styles[state]}`} />}
          <div
            data-testid={dataTestId}
            className={`${styles.popup} ${state && styles[state]} ${complete && styles.complete} ${className}`}
            style={style}
            onMouseDown={mouseDownHandler}
            ref={containerRef}>
            {children}
          </div>
        </>
      )}
    </Transition>
  );
};

export default Popup;
