import IRoute from 'interfaces/IRoute';
import loginSubRoutes from './loginSubRoutes';
import homeSubRoutes from './homeSubRoutes';
import { ProfileType } from 'dtos/EntityDTO';
import accountSubRoutes from './accountSubRoutes';
import onboardingSubRoutes from './onboardingSubRoutes';
import appointmentSubRoutes from './appointmentSubRoutes';
import registerSubRoutes from './registerSubRoutes';

const routes = {
  home: {
    path: '/home',
    exact: false,
    meta: { auth: true, roles: [] },
    subRoutes: homeSubRoutes,
  } as IRoute,
  addAvailability: {
    path: '/addAvailability',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  schedule: {
    path: '/schedule/:medicId',
    exact: true,
    meta: { auth: true, roles: [ProfileType.PATIENT] },
  } as IRoute,
  buyAppointment: {
    path: '/buy/:appointmentId',
    exact: true,
    meta: { auth: true, roles: [ProfileType.PATIENT] },
  } as IRoute,
  reschedule: {
    path: '/reschedule/:appointmentId',
    exact: true,
    meta: { auth: true, roles: [ProfileType.PATIENT] },
  } as IRoute,
  cancelAppointment: {
    path: '/cancel/:appointmentId',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  appointment: {
    path: '/appointment/:appointmentId',
    exact: false,
    meta: { auth: true, roles: [] },
    subRoutes: appointmentSubRoutes,
  } as IRoute,
  account: {
    path: '/account',
    exact: false,
    meta: { auth: false, roles: [] },
    subRoutes: accountSubRoutes,
  } as IRoute,
  onboarding: {
    path: '/onboarding',
    exact: false,
    meta: { auth: false, roles: [] },
    subRoutes: onboardingSubRoutes,
  } as IRoute,
  register: {
    path: '/register/:type?',
    linkPath: '/register',
    exact: false,
    meta: { auth: false, roles: [] },
    subRoutes: registerSubRoutes,
  } as IRoute,
  login: {
    path: '/',
    exact: false,
    meta: { auth: false, roles: [] },
    subRoutes: loginSubRoutes,
  } as IRoute,
};

export default routes;
