/**
 * Web RTC API negotiation
 * https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation
 */

import useSocket from 'hooks/use-socket';
import { ICallConnection, MediaDevicesConfig } from 'interfaces/ICall';
import IReduxState from 'interfaces/IReduxState';
import {
  WebRTCOfferSocketMessage,
  WebRTCAnswerSocketMessage,
  WebRTCIceCandidateSocketMessage,
  StreamStateSocketMessage,
  UserLeftAppointmentCallSocketMessage,
} from 'interfaces/ISocketMessages';
import { createContext, PropsWithChildren, useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LOG_ERROR, LOG_RTC, LOG_SOCKET, LOG_WARN } from 'utils/logger';
import { userIsPatient } from 'utils/user';
import {
  rtcConfiguration,
  createEmptyLocalStream,
  handleOffer,
  createAnswer,
  handleAnswer,
  addICECandidate,
  createOffer,
  getDeviceConstraint,
  ConnectionActionType,
  connectionsReducer,
  devicesTogglesReducer,
} from 'utils/call';
import { useWinstonLogger } from 'winston-react';

export type CallConnections = { [userSessionId: string]: ICallConnection };

export interface ICallContext {
  localStream: MediaStream | null;
  localScreenShareStream: MediaStream | null;
  connection?: ICallConnection;
  microphoneIsOn: boolean;
  cameraIsOn: boolean;
  screenShareIsOn: boolean;
  audioDeviceId?: string;
  videoDeviceId?: string;
  volume: number;
  noiseSuppression: boolean;
  startCallHandler: (sessionsIds: string[]) => void;
  toggleMicrophoneHandler: () => void;
  toggleCameraHandler: () => void;
  toggleScreenShareHandler: () => void;
  switchCameraHandler: () => void;
  changeVolumeHandler: (volume: number) => void;
  changeAudioDeviceHandler: (deviceId: string) => void;
  changeVideoDeviceHandler: (deviceId: string) => void;
  toggleNoiseSupressionHandler: (checked: boolean) => void;
}

const CallContext = createContext<ICallContext>({
  localStream: null,
  localScreenShareStream: null,
  connection: undefined,
  microphoneIsOn: false,
  cameraIsOn: false,
  screenShareIsOn: false,
  audioDeviceId: undefined,
  videoDeviceId: undefined,
  volume: 1,
  noiseSuppression: true,
  startCallHandler: (_: string[]) => {},
  toggleMicrophoneHandler: () => {},
  toggleCameraHandler: () => {},
  toggleScreenShareHandler: () => {},
  switchCameraHandler: () => {},
  changeVolumeHandler: (_: number) => {},
  changeAudioDeviceHandler: (_: string) => {},
  changeVideoDeviceHandler: (_: string) => {},
  toggleNoiseSupressionHandler: (_: boolean) => {},
});

let mounted = true;
let makingOffer = false;

export const CallContextProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const logger = useWinstonLogger();
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const { listenSocketMessage, emitSocketMessage } = useSocket();
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [localScreenShareStream, setLocalScreenShareStream] = useState<MediaStream | null>(null);
  const [connections, dispatchConnections] = useReducer(connectionsReducer, {});
  const [devicesToggles, dispatchDevicesToggles] = useReducer(devicesTogglesReducer, {
    microphoneIsOn: false,
    cameraIsOn: false,
    screenShareIsOn: false,
    remoteCameraIsOn: false,
    remoteShareIsOn: false,
  });
  const [volume, setVolume] = useState<number>(1);
  const [videoDeviceId, setVideoDeviceId] = useState<string>();
  const [audioDeviceId, setAudioDeviceId] = useState<string>();
  const [noiseSuppression, setNoiseSuppression] = useState<boolean>(false);
  const nrConnections = Object.keys(connections).length;

  useEffect(() => {
    if (!localStream) getLocalStream({ includeVideo: true, includeAudio: true });

    mounted = true;

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) return;

      logger.log(LOG_RTC, 'cleaning streams');

      localStream?.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
        localStream?.removeTrack(track);
      });

      localScreenShareStream?.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
        localScreenShareStream?.removeTrack(track);
      });

      Object.keys(connections).forEach((sessionId: string) =>
        dispatchConnections({ type: ConnectionActionType.REMOVE, sessionId })
      );
    };
  }, [localStream, localScreenShareStream, nrConnections]);

  useEffect(() => {
    if (nrConnections === 0) return;

    listenSocketMessage('user-left', async (data: UserLeftAppointmentCallSocketMessage) => {
      const { from } = data;

      logger.log(LOG_SOCKET, `user left call`);
      logger.log(LOG_RTC, `deleting connection for session ${from}`);

      dispatchConnections({ type: ConnectionActionType.REMOVE, sessionId: from });
    });

    listenSocketMessage('offer-made', async (data: WebRTCOfferSocketMessage) => {
      const { from, to } = data;

      logger.log(LOG_SOCKET, `received offer with data ${JSON.stringify({ from, to })}`);

      const peerConnection = connections[from]?.peerConnection;
      const offerCollision = makingOffer || peerConnection?.signalingState != 'stable';
      const ignoreOffer = !userIsPatient(userType) && offerCollision; // patients are the polite user

      if (ignoreOffer) {
        logger.log(LOG_RTC, `ignoring offer from ${from} because there is a collision`);
        return;
      }

      await handleOffer(peerConnection, data);

      const answer = await createAnswer(peerConnection);

      if (answer) {
        logger.log(LOG_SOCKET, `emiting answer to users from appointment ${to}`);
        emitSocketMessage('make-answer', { answer, to } as WebRTCAnswerSocketMessage);
      }
    });

    listenSocketMessage('answer-made', async (data: WebRTCAnswerSocketMessage) => {
      const { from, to } = data;

      logger.log(LOG_SOCKET, `received answer with data ${JSON.stringify({ from, to })}`);

      const peerConnection = connections[from]?.peerConnection;
      await handleAnswer(peerConnection, data);
    });

    listenSocketMessage('ice-candidate', async (data: WebRTCIceCandidateSocketMessage) => {
      const { from, to } = data;

      logger.log(LOG_SOCKET, `received ice candidate with data ${JSON.stringify({ from, to })}`);

      const peerConnection = connections[from]?.peerConnection;
      await addICECandidate(peerConnection, data);
    });

    listenSocketMessage('video-state', (data: StreamStateSocketMessage) => {
      const { from, streamId, enabled } = data;

      logger.log(LOG_SOCKET, `received new video state from ${from} (enabled: ${enabled})`);

      dispatchConnections({
        type: ConnectionActionType.UPDATE_REMOTE_CAMERA_STATE,
        sessionId: from,
        streamId,
        streamStatus: enabled,
      });
    });

    listenSocketMessage('screen-share-state', (data: StreamStateSocketMessage) => {
      const { from, streamId, enabled } = data;

      logger.log(LOG_SOCKET, `received new screen share state from ${from} (enabled: ${enabled})`);

      dispatchConnections({
        type: ConnectionActionType.UPDATE_REMOTE_SHARE_STATE,
        sessionId: from,
        streamId,
        streamStatus: enabled,
      });
    });
  }, [nrConnections]);

  useEffect(() => {
    if (localStream) {
      logger.log(LOG_RTC, 'updating local stream tracks on connections');
      updateLocalStreamTracksOnPeerConnections();
    }
  }, [localStream, nrConnections]);

  useEffect(() => {
    if (!localScreenShareStream) return;

    logger.log(LOG_RTC, 'updating screen share tracks on connections');

    Object.keys(connections).forEach((sessionId: string) => {
      const { peerConnection } = connections[sessionId];

      const videoSenders = peerConnection!.getSenders().filter((sender) => sender.track?.kind.includes('video'));
      const track = localScreenShareStream.getVideoTracks()[0];

      if (videoSenders.length === 2) {
        logger.log(LOG_RTC, `replacing screen share track for connection ${sessionId}`);
        videoSenders[1].replaceTrack(track);
      } else {
        logger.log(LOG_RTC, `adding screen share track for connection ${sessionId}`);
        peerConnection!.addTrack(track, localScreenShareStream);
      }
    });
  }, [localScreenShareStream, nrConnections]);

  useEffect(() => {
    if (nrConnections == 0) {
      logger.log(LOG_WARN, 'trying to emiting a new video state while there is no other user registred');
    } else {
      logger.log(LOG_SOCKET, 'emiting new video state');

      emitSocketMessage('video-state', {
        streamId: localStream?.id,
        enabled: devicesToggles.cameraIsOn,
        to: appointmentId,
      } as StreamStateSocketMessage);
    }
  }, [devicesToggles.cameraIsOn, nrConnections]);

  useEffect(() => {
    if (nrConnections > 0) {
      logger.log(LOG_SOCKET, 'emiting new screen share state');

      emitSocketMessage('screen-share-state', {
        streamId: localScreenShareStream?.id,
        enabled: devicesToggles.screenShareIsOn,
        to: appointmentId,
      } as StreamStateSocketMessage);
    }
  }, [devicesToggles.screenShareIsOn, nrConnections]);

  useEffect(() => {
    if (audioDeviceId && devicesToggles.microphoneIsOn) {
      logger.log(LOG_RTC, `getting new audio track because device id changed to ${audioDeviceId}`);
      getLocalStream({ includeAudio: true });
    }
  }, [audioDeviceId]);

  useEffect(() => {
    if (videoDeviceId && devicesToggles.cameraIsOn) {
      logger.log(LOG_RTC, `getting new video track because device id changed to ${videoDeviceId}`);
      getLocalStream({ includeVideo: true });
    }
  }, [videoDeviceId]);

  const createPeerConnection = (sessionId: string): void => {
    logger.log(LOG_RTC, `creating peer connection for session ${sessionId}`);
    const peerConnection = new RTCPeerConnection(rtcConfiguration);

    peerConnection.onnegotiationneeded = async () => {
      logger.log(LOG_RTC, 'negotiation is needed');

      makingOffer = true;

      const offer = await createOffer(peerConnection);
      logger.log(LOG_SOCKET, `emiting offer to users from appointment ${appointmentId}`);
      emitSocketMessage('make-offer', { offer, to: appointmentId } as WebRTCOfferSocketMessage);

      makingOffer = false;
    };

    peerConnection.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (event.candidate) {
        logger.log(LOG_SOCKET, 'emiting ice candidate');

        emitSocketMessage('ice-candidate', {
          candidate: event.candidate,
          to: appointmentId,
          from: sessionId,
        } as WebRTCIceCandidateSocketMessage);
      }
    };

    peerConnection.onconnectionstatechange = () => {
      const connectionState = peerConnection!.connectionState;

      logger.log(LOG_RTC, `peer connection was changed to ${connectionState}`);

      dispatchConnections({ type: ConnectionActionType.UPDATE_CONNECTION_STATE, sessionId, connectionState });

      switch (connectionState) {
        case 'connected':
          logger.log(LOG_RTC, `succesfully connected with peer ${sessionId}`);
          break;
        case 'failed':
          logger.log(LOG_ERROR, `failed to connect with peer ${sessionId}`);
          peerConnection?.restartIce();
          break;
      }
    };

    peerConnection.ontrack = (event: RTCTrackEvent) => {
      const { streams } = event;
      logger.log(LOG_RTC, `received new track from stream with id ${streams[0].id}`);
      dispatchConnections({ type: ConnectionActionType.UPDATE_STREAM, sessionId, stream: streams[0] });
    };

    dispatchConnections({
      type: ConnectionActionType.ADD,
      sessionId,
      connection: {
        peerConnection,
        state: 'new',
        remoteStream: null,
        remoteScreenShareStream: null,
        remoteCameraIsOn: false,
        remoteShareIsOn: false,
      },
    });
  };

  const getLocalStream = async (mediaDevicesConfig: MediaDevicesConfig = {}): Promise<void> => {
    const { includeVideo, includeAudio, includeNoiseSuppression } = mediaDevicesConfig;

    logger.log(LOG_RTC, 'getting user media devices');

    const devices = await navigator.mediaDevices.enumerateDevices();

    const constraints = {
      audio: getDeviceConstraint(includeAudio, devices, audioDeviceId, 'audioinput'),
      video: getDeviceConstraint(includeVideo, devices, videoDeviceId, 'videoinput'),
      noiseSuppression: includeNoiseSuppression === undefined ? includeNoiseSuppression : noiseSuppression,
    } as MediaStreamConstraints;

    logger.log(LOG_RTC, `getting media devices with contraints: ${JSON.stringify(constraints)}`);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(async (stream: MediaStream) => {
        logger.log(LOG_RTC, 'successfully got media devices');

        updateLocalStream(stream);

        if (includeAudio) dispatchDevicesToggles({ device: 'microphoneIsOn', status: true });
        if (includeVideo) dispatchDevicesToggles({ device: 'cameraIsOn', status: true });
      })
      .catch((error) => {
        logger.log(LOG_ERROR, `error while trying to get access to a camera/microphone\n error: ${error}`);

        if (!localStream) {
          const stream = createEmptyLocalStream();
          logger.log(LOG_RTC, `creating new local dummy stream with id ${stream.id}`);
          setLocalStream(stream);
        }
      });
  };

  const updateLocalStream = (stream: MediaStream): void => {
    if (!localStream) {
      logger.log(LOG_RTC, `creating new local stream with id ${stream.id}`);
      setLocalStream(stream);
    } else {
      logger.log(LOG_RTC, 'replacing local stream tracks');

      for (const track of stream.getTracks()) {
        const localStreamTrack = localStream.getTracks().find((t) => t.kind === track.kind);
        if (localStreamTrack) localStream.removeTrack(localStreamTrack);

        track.enabled = true;
        localStream.addTrack(track);
      }

      updateLocalStreamTracksOnPeerConnections();
    }
  };

  const updateLocalStreamTracksOnPeerConnections = useCallback(() => {
    if (!localStream) {
      logger.log(LOG_WARN, 'local stream is null');
      return;
    }

    const connectionsSessionsIds = Object.keys(connections);

    if (connectionsSessionsIds.length === 0) {
      logger.log(LOG_WARN, 'cannot add local stream tracks when there are no peer connections');
      return;
    }

    connectionsSessionsIds.forEach((sessionId: string) => {
      const { peerConnection } = connections[sessionId];
      const senders = peerConnection.getSenders();

      for (const track of localStream.getTracks()) {
        const sender = senders.find((sender) => sender.track?.kind === track.kind);

        if (sender) {
          logger.log(LOG_RTC, `replacing local stream track for connection ${sessionId}`);
          sender.replaceTrack(track);
        } else {
          logger.log(LOG_RTC, `adding local stream track for connection ${sessionId}`);
          peerConnection.addTrack(track, localStream);
        }
      }
    });
  }, [localStream, nrConnections]);

  const getScreenShare = (): void => {
    navigator.mediaDevices
      .getDisplayMedia({ video: true })
      .then(async (stream: MediaStream) => {
        setLocalScreenShareStream(stream);
        dispatchDevicesToggles({ device: 'screenShareIsOn', status: true });

        stream.getVideoTracks()[0].onended = () => {
          logger.log(LOG_RTC, 'screen share has ended');
          dispatchDevicesToggles({ device: 'screenShareIsOn', status: false });
        };
      })
      .catch((error) => {
        logger.log(LOG_ERROR, `error while trying to get access to screen share\n error: ${error}`);
      });
  };

  const startCallHandler = (sessionsIds: string[]): void => {
    sessionsIds.forEach((sessionId: string) => {
      createPeerConnection(sessionId);
    });
  };

  const toggleMicrophoneHandler = (): void => {
    if (localStream?.getAudioTracks().length === 0) getLocalStream({ includeAudio: true });
    else {
      dispatchDevicesToggles({ device: 'microphoneIsOn', toggle: true });
      const audioTrack = localStream?.getAudioTracks()[0];
      if (audioTrack) audioTrack.enabled = !devicesToggles.microphoneIsOn;
    }
  };

  const toggleCameraHandler = (): void => {
    if (!devicesToggles.cameraIsOn) getLocalStream({ includeVideo: true });
    else {
      dispatchDevicesToggles({ device: 'cameraIsOn', status: false });
      const videoTrack = localStream?.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = false;
        videoTrack.stop();
      }
    }
  };

  const toggleScreenShareHandler = (): void => {
    if (!devicesToggles.screenShareIsOn) getScreenShare();
    else {
      dispatchDevicesToggles({ device: 'screenShareIsOn', status: false });
      const videoTrack = localScreenShareStream?.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = false;
        videoTrack.stop();
      }
    }
  };

  const switchCameraHandler = (): void => {};

  const changeVolumeHandler = (volume: number): void => {
    setVolume(volume);
  };

  const changeAudioDeviceHandler = (deviceId: string): void => {
    setAudioDeviceId(deviceId);
  };

  const changeVideoDeviceHandler = (deviceId: string): void => {
    setVideoDeviceId(deviceId);
  };

  const toggleNoiseSupressionHandler = (checked: boolean): void => {
    setNoiseSuppression(checked);
    getLocalStream({ includeNoiseSuppression: checked });
  };

  const value: ICallContext = {
    localStream,
    localScreenShareStream,
    connection: Object.keys(connections).at(0) ? connections[Object.keys(connections).at(0)!] : undefined,
    microphoneIsOn: devicesToggles.microphoneIsOn,
    cameraIsOn: devicesToggles.cameraIsOn,
    screenShareIsOn: devicesToggles.screenShareIsOn,
    audioDeviceId,
    videoDeviceId,
    noiseSuppression,
    volume,
    startCallHandler,
    toggleMicrophoneHandler,
    toggleCameraHandler,
    toggleScreenShareHandler,
    switchCameraHandler,
    changeVolumeHandler,
    changeAudioDeviceHandler,
    changeVideoDeviceHandler,
    toggleNoiseSupressionHandler,
  };

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>;
};

export default CallContext;
