import React from 'react';
import MediaQuery from 'react-responsive';
import sass from 'styles/exports.module.scss';

const MediaScreenMobile: React.FC = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;

  return <MediaQuery maxWidth={parseInt(sass.tablet)}>{children}</MediaQuery>;
};

export default MediaScreenMobile;
