import IRoute, { ILoginSubRoutes } from 'interfaces/IRoute';

const loginSubRoutes: ILoginSubRoutes = {
  accounts: {
    path: '/loginAccounts',
    exact: true,
    meta: { auth: false, roles: [] },
  } as IRoute,
  login: {
    path: '/login',
    exact: true,
    meta: { auth: false, roles: [] },
  } as IRoute,
  resetPassword: {
    path: '/resetPassword',
    exact: true,
    meta: { auth: false, roles: [] },
  } as IRoute,
  resetPasswordSent: {
    path: '/resetPasswordSent',
    exact: true,
    meta: { auth: false, roles: [] },
  } as IRoute,
};

export default loginSubRoutes;
