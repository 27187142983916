import MedicDTO from 'dtos/MedicDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import useAppointmentsService from 'services/appointments.service';
import AppointmentContext from 'store/appointment-context';

export interface IUsePatientAppointmentEnd {
  language: ILanguage;
  appointmentId: string;
  medic: MedicDTO | null;
  score: number;
  selectScoreHandler: (score: number) => void;
  submitEvaluationHandler: () => void;
}

const usePatientAppointmentEnd = (): IUsePatientAppointmentEnd => {
  const history = useHistory();
  const { evaluateMedic } = useAppointmentsService();
  const { appointmentEvent } = useContext(AppointmentContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [score, setScore] = useState<number>(0);

  const selectScoreHandler = (score: number): void => {
    setScore(score);
  };

  const submitEvaluationHandler = (): void => {
    evaluateMedic(appointmentEvent!.appointment!.id, score).then(() => {
      history.push(routes.home.path);
    });
  };

  return {
    language,
    appointmentId: appointmentEvent!.appointment!.id,
    medic: appointmentEvent!.appointment!.user! as MedicDTO,
    score,
    selectScoreHandler,
    submitEvaluationHandler,
  };
};

export default usePatientAppointmentEnd;
