import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import { FC, PropsWithChildren } from 'react';
import styles from './Star.module.scss';

export interface StarProps {
  dataTestId?: string;
  active: boolean;
  hover: boolean;
  onHover: () => void;
  onSelect: () => void;
}

const Star: FC<StarProps> = (props: PropsWithChildren<StarProps>) => {
  const { dataTestId, hover, active, onHover, onSelect } = props;

  return (
    <label className={`${styles.star} ${hover && styles.hover} ${active && styles.active}`} onMouseOver={onHover}>
      <input data-testid={dataTestId} name='evaluate' type='radio' onChange={onSelect} />
      <Icon className={styles.star} src={iconsTemp.star} />
      <Icon className={styles['star-outline']} src={iconsTemp.starOutline} />
    </label>
  );
};

export default Star;
