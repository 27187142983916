import routes from 'routes/routes';
import IReduxState from 'interfaces/IReduxState';
import { ProfileType } from 'dtos/EntityDTO';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userIsPatient } from 'utils/user';

export type UsePatientGuard = {
  patientCanSee: (meta: any) => boolean;
  patientCanActivate: (meta: any) => boolean;
};

const usePatientGuard = (): UsePatientGuard => {
  const history = useHistory();
  const type = useSelector((state: IReduxState) => state.auth.type);

  const patientCanSee = (meta: any): boolean => {
    return meta.roles.length === 0 || (userIsPatient(type) && meta.roles.includes(ProfileType.PATIENT));
  };

  const patientCanActivate = (meta: any): boolean => {
    if (meta.roles.length === 0) return true;

    if (userIsPatient(type) && !meta.roles.includes(ProfileType.PATIENT)) {
      history.push(routes.login.path);
      return false;
    }

    return true;
  };

  return { patientCanSee, patientCanActivate };
};

export default usePatientGuard;
