import { ToastTypes } from 'components/View/Toast';
import PatientDTO from 'dtos/PatientDTO';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { IHomeSubRoutes } from 'interfaces/IRoute';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import routes from 'routes/routes';
import useMedicsService from 'services/medics.service';
import { feedbackActions } from 'store/redux/feedback-slice';

export interface IUsePatientDetails {
  patientId: string;
  patient?: PatientDTO;
  backHandler: () => void;
}

const usePatientDetails = (): IUsePatientDetails => {
  const history = useHistory();
  const { id: patientId } = useParams<{ id: string; tab?: string }>();
  const dispatch = useDispatch();
  const { getMedicPatient } = useMedicsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [patient, setPatient] = useState<PatientDTO>();

  useEffect((): void => {
    getMedicPatient(patientId)
      .then((patient: PatientDTO): void => setPatient(patient))
      .catch((): void => {
        dispatch(
          feedbackActions.addMessage({
            dataTestId: 'toastError-patient',
            type: ToastTypes.ERROR,
            icon: iconsTemp.close,
            title: language.errorGettingPatient,
          })
        );

        history.replace((routes.home.subRoutes as IHomeSubRoutes).patients.path);
      });
  }, [patientId]);

  const backHandler = (): void => {
    history.goBack();
  };

  return { patientId, patient, backHandler };
};

export default usePatientDetails;
