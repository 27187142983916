import { datesAreEqual, useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import EventAvailabilityDTO from 'dtos/EventAvailabilityDTO';
import PeriodsDTO from 'dtos/PeriodsDTO';
import { IDayGroup } from 'interfaces/IGroup';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSchedulesService from 'services/schedules.service';
import { separateEventsIntoPeriods } from 'utils/appointment';
import { groupByDay } from 'utils/group';
import { LOG_COMPONENT, LOG_WARN } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import { IAvailableSlotsSliderProps } from './AvailableSlotsSlider';

export interface ISlot {
  title: string;
  events?: IDayGroup<EventAvailabilityDTO>[];
}

export interface IUseAvailableSlotsSlider {
  language: ILanguage;
  dates: Date[];
  slots: ISlot[];
  formatDate: (date: Date, mask: string) => string;
  goToPreviousHandler: () => void;
  goToNextHandler: () => void;
}

const useAvailableSlotsSlider = (props: IAvailableSlotsSliderProps): IUseAvailableSlotsSlider => {
  const { medicId, specialtyId, type, start, nrDays } = props;
  const logger = useWinstonLogger();
  const { formatDate } = useDate();
  const { getMedicAvailableEvents } = useSchedulesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [startDate, setStartDate] = useState<Date>(start);
  const [availableEvents, setAvailableEvents] = useState<PeriodsDTO<IDayGroup<EventAvailabilityDTO>[]>>();

  const dates = useCallback((): Date[] => {
    const dates: Date[] = [];

    new Array(nrDays).fill(0).forEach((_: any, index: number) => {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + index);
      dates.push(date);
    });

    return dates;
  }, [startDate, nrDays])();

  useEffect(() => {
    logger.log(LOG_COMPONENT, 'loading new medic available slots');
    loadMedicsAvailabilities();
  }, [medicId, specialtyId, type]);

  useEffect(() => {
    loadMedicsAvailabilities();
  }, [startDate]);

  const loadMedicsAvailabilities = (): void => {
    const from = dates.at(0)!;
    const to = new Date(dates.at(-1)!);
    to.setHours(0, 0, 0, 0);
    to.setDate(to.getDate() + 1);

    getMedicAvailableEvents(medicId, specialtyId, type, from, to).then((events: EventAvailabilityDTO[]) => {
      const eventsByPeriod = separateEventsIntoPeriods(events);

      setAvailableEvents({
        dawn: groupPeriodEvents(dates, groupByDay(eventsByPeriod.dawn, false, false)),
        morning: groupPeriodEvents(dates, groupByDay(eventsByPeriod.morning, false, false)),
        afternoon: groupPeriodEvents(dates, groupByDay(eventsByPeriod.afternoon, false, false)),
        night: groupPeriodEvents(dates, groupByDay(eventsByPeriod.night, false, false)),
      });
    });
  };

  const incrementStartDate = (direction: -1 | 1): void => {
    setStartDate((prevState: Date) => {
      const now = new Date();
      const newState = new Date(prevState);
      newState.setDate(prevState.getDate() + direction * nrDays);

      if (newState.getTime() < now.getTime()) {
        logger.log(LOG_WARN, "going to a date previous from now. setting new slots' start to now");
        return now;
      } else return newState;
    });
  };

  const goToPreviousHandler = (): void => {
    logger.log(LOG_COMPONENT, 'going to the previous slots slide');
    incrementStartDate(-1);
  };

  const goToNextHandler = (): void => {
    logger.log(LOG_COMPONENT, 'going to the next slots slide');
    incrementStartDate(1);
  };

  return {
    language,
    dates,
    slots: [
      { title: language.dawn, events: availableEvents?.dawn },
      { title: language.morning, events: availableEvents?.morning },
      { title: language.afternoon, events: availableEvents?.afternoon },
      { title: language.night, events: availableEvents?.night },
    ],
    formatDate,
    goToPreviousHandler,
    goToNextHandler,
  };
};

const groupPeriodEvents = (
  dates: Date[],
  groupedEvents: IDayGroup<EventAvailabilityDTO>[]
): IDayGroup<EventAvailabilityDTO>[] => {
  if (groupedEvents.length > 0) {
    dates.forEach((date: Date) => {
      const group = groupedEvents.find((dayEvents: IDayGroup<EventAvailabilityDTO>) =>
        datesAreEqual(date, dayEvents.date)
      );

      if (!group) groupedEvents.push({ date: date, list: [] });
    });

    groupedEvents.sort(
      (group1: IDayGroup<EventAvailabilityDTO>, group2: IDayGroup<EventAvailabilityDTO>) =>
        group1.date.getTime() - group2.date.getTime()
    );
  }

  return groupedEvents;
};

export default useAvailableSlotsSlider;
