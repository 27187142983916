import MedicDTO from 'dtos/MedicDTO';
import { Gender } from 'dtos/EntityDTO';
import MedicSchema from 'schemas/MedicSchema';
import SpecialtySchema from 'schemas/SpecialtySchema';
import SpecialtyMapper from './SpecialtyMapper';
import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import EntityImageMapper from './EntityImageMapper';
import countries from 'i18n-iso-countries';
import { toCodeAlpha2 } from 'utils/language';
import CreateOrUpdateMedicBody from 'RequestsBody/MedicBody';
import { getInputValue, InputsReducerType, InputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

const specialtyMapper = SpecialtyMapper();
const entityImageMapper = EntityImageMapper();

const MedicMapper = () => {
  const toInterface = (medic: MedicSchema, languageCode: LanguageCode): MedicDTO => {
    return {
      id: medic.id,
      email: medic.email,
      name: medic.name,
      licence: +medic.medicLicence,
      vatNumber: +medic.vatNumber,
      specialties: medic.specialties.map((specialty: SpecialtySchema) => specialtyMapper.toInterface(specialty)),
      gender: medic.sex as Gender,
      timezone: medic.timeZone,
      city: medic.city,
      country: medic.country ? countries.getName(medic.country.toLowerCase(), toCodeAlpha2(languageCode)) : null,
      language: medic.language as LanguageCode,
      address: medic.address,
      postalCode: medic.postalCode,
      phone: medic.phone,
      image: medic.image ? entityImageMapper.toInterface(medic.image) : undefined,
    };
  };

  const toBody = async (inputs: InputsReducerType): Promise<CreateOrUpdateMedicBody> => {
    return {
      // mandatory onboarding
      email: getInputValue(inputs.email),
      name: getInputValue(inputs.name),
      medicLicence: getInputValue(inputs.licence),
      sex: getInputValue(inputs.gender),
      timeZone: getInputValue(inputs.timezone),
      vatNumber: getInputValue(inputs.tin),
      specialties: getInputValue(inputs.specialties, (value: InputValue) =>
        (value as string[]).map((specialty: string) => ({ id: specialty }))
      ),

      // optional data
      address: getInputValue(inputs.address),
      city: undefined,
      country: getInputValue(inputs.country),
      phone: getInputValue(inputs.phoneNumber),
      language: getInputValue(inputs.language),
      postalCode: getInputValue(inputs.postalCode),
      // image: inputs.photo ? await entityImageMapper.toBody(inputs.photo.value as File) : undefined,
      about: getInputValue(inputs.about),
      spokenLanguages: undefined, // getInputValue(inputs.languages),
    };
  };

  return { toInterface, toBody };
};

export default MedicMapper;
