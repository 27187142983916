import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export type UseReadMore = {
  language: ILanguage;
  isOpen: boolean;
  showReadMore: boolean;
  textRef: React.RefObject<HTMLParagraphElement>;
  readMoreHandler: () => void;
};

const useReadMore = (): UseReadMore => {
  const language = useSelector((state: IReduxState) => state.language.values);
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showReadMore, setShowReadMore] = useState<boolean>(false);

  useEffect(() => {
    setShowReadMore(!!textRef.current && textRef.current?.scrollHeight > textRef.current?.clientHeight);
  }, [textRef.current, textRef.current?.scrollHeight, textRef.current?.clientHeight]);

  const readMoreHandler = (): void => {
    setIsOpen((prevState: boolean) => !prevState);
  };

  return { language, isOpen, showReadMore, textRef, readMoreHandler };
};

export default useReadMore;
