import EventDTO from 'dtos/EventDTO';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import useMediaScreen from 'hooks/use-mediaScreen';
import useDate, { DateMasks } from 'hooks/use-date';
import { EmptyEventCardWrapper } from './EmptyEventCard.styles';
import { IProps } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';

export interface IEmptyEventCardProps extends PropsWithChildren<IProps> {
  event: EventDTO;
  hasPassed: boolean;
}

const EmptyEventCard: FC<IEmptyEventCardProps> = (props: IEmptyEventCardProps): JSX.Element => {
  const { className, style, event, hasPassed } = props;
  const { formatDate } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const { isMobileScreen } = useMediaScreen();

  return (
    <EmptyEventCardWrapper className={className} style={style} hasPassed={hasPassed}>
      {isMobileScreen && `${language.noAppointmentScheduledAt} ${formatDate(event.start, DateMasks.shortTime)}`}
      {!isMobileScreen && language.noAppointment}
    </EmptyEventCardWrapper>
  );
};

export default EmptyEventCard;
