import { FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AppointmentDTO from 'dtos/AppointmentDTO';
import EventDTO from 'dtos/EventDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import routes from 'routes/routes';
import useAppointmentsService from 'services/appointments.service';
import { goBack } from 'utils/general';

export interface IUseCancelAppointment {
  language: ILanguage;
  appointment?: AppointmentDTO;
  isLoading: boolean;
  backHandler: () => void;
  submitCancellationHandler: (submit: FormSubmit) => void;
}

const useCancelAppointment = (): IUseCancelAppointment => {
  const history = useHistory();
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const language = useSelector((state: IReduxState) => state.language.values);
  const { isLoading, getAppointment, rescheduleAppointment } = useAppointmentsService();
  const [appointment, setAppointment] = useState<AppointmentDTO>();

  useEffect(() => {
    getAppointment(appointmentId)
      .then((appointmentEvent: EventDTO) => {
        setAppointment(appointmentEvent.appointment);
      })
      .catch(() => {
        history.push(routes.home.path);
      });
  }, [appointmentId]);

  const backHandler = (): void => {
    goBack(history);
  };

  const submitCancellationHandler = (submit: FormSubmit): void => {
    rescheduleAppointment(appointment!, submit).then(() => {
      history.push(routes.home.path);
    });
  };

  return { language, appointment, isLoading, backHandler, submitCancellationHandler };
};

export default useCancelAppointment;
