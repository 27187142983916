import { FC, PropsWithChildren } from 'react';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { userIsMedic, userIsPatient } from 'utils/user';
import MedicAppointmentEnd from './MedicAppointmentEnd';
import PatientAppointmentEnd from './PatientAppointmentEnd';
//import styles from "./AppointmentEnd.module.scss";

export interface AppointmentEndProps {}

const AppointmentEnd: FC<AppointmentEndProps> = (props: PropsWithChildren<AppointmentEndProps>): JSX.Element => {
  const {} = props;
  const userType = useSelector((state: IReduxState) => state.auth.type);

  return (
    <>
      {userIsMedic(userType) && <MedicAppointmentEnd />}
      {userIsPatient(userType) && <PatientAppointmentEnd />}
    </>
  );
};

export default AppointmentEnd;
