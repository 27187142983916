import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeedbackSlice } from 'interfaces/ISlices';
import IToastContent from 'interfaces/IToastContent';

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    toastFeedbacks: [],
  } as IFeedbackSlice,
  reducers: {
    addMessage: (state: any, action: PayloadAction<IToastContent>): void => {
      state.toastFeedbacks.push({ ...action.payload, id: `${action.payload.type}-${new Date().getTime()}`.toString() });
    },
    removeMessage: (state: any, action: PayloadAction<string>): void => {
      state.toastFeedbacks = [
        ...state.toastFeedbacks.filter((toastFeedback: IToastContent) => toastFeedback.id !== action.payload),
      ];
    },
  },
});

export const feedbackActions = feedbackSlice.actions;

export default feedbackSlice;
