import MessageSchema from 'schemas/MessageSchema';
import MessageDTO, { MessageState } from 'dtos/MessageDTO';
import FileMapper from './FileMapper';

const fileMapper = FileMapper();

const MessageMapper = () => {
  const toInterface = (userId: string, schema: MessageSchema): MessageDTO => {
    return {
      id: schema.id,
      mine: schema.sender === userId,
      text: schema.isFile ? fileMapper.toInterface(JSON.parse(schema.message)) : schema.message,
      date: new Date(schema.createdAt),
      state: schema.read ? MessageState.READ : MessageState.SENT,
    };
  };

  const createMessage = (id: number, messageText: string): MessageDTO => {
    return {
      id: `${id}`,
      mine: true,
      text: messageText,
      date: new Date(),
      state: MessageState.SENDING,
    };
  };

  return { toInterface, createMessage };
};

export default MessageMapper;
