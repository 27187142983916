import { Flex, IProps, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useDate, placeValue } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { AppointmentStatus } from 'dtos/AppointmentDTO';
import EventDTO from 'dtos/EventDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import { isAppointmentAboutToStart, isAppointmentOngoing } from 'utils/appointment';
import { AppointmentInfoTag } from './AppointmentInfo.styles';

export interface IAppointmentInfoProps extends PropsWithChildren<IProps> {
  appointmentEvent: EventDTO;
  hideSpecialty?: boolean;
  showOnlySpecialty?: boolean;
}

const AppointmentInfo: FC<IAppointmentInfoProps> = (props: IAppointmentInfoProps): JSX.Element => {
  const { className, style, appointmentEvent, hideSpecialty, showOnlySpecialty } = props;
  const { formatDate, masks } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const { start, place, icon, appointment } = appointmentEvent;
  const showAlert =
    (isAppointmentAboutToStart(appointmentEvent) || isAppointmentOngoing(appointmentEvent)) &&
    appointment?.status !== AppointmentStatus.Ended;

  return (
    <Flex className={className} style={style} justifyContent='flex-start'>
      <AppointmentInfoTag
        icon='clock'
        label={formatDate(start, masks.shortTime)}
        color={showAlert ? styles.colors.feedback.alert : undefined}
      />
      <AppointmentInfoTag icon={icon as any} label={language[place as keyof ILanguage]} />
      {!hideSpecialty && (
        <AppointmentInfoTag
          icon='appointment'
          label={
            appointment?.specialty?.name
              ? showOnlySpecialty
                ? appointment.specialty.name
                : placeValue(language.appointmentSpecialty, appointment.specialty.name)
              : '-'
          }
        />
      )}
    </Flex>
  );
};

export default AppointmentInfo;
