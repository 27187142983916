import NotificationDTO from 'dtos/NotificationDTO';
import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import useNotificationsService, { DEFAULT_NR_NOTIFICATIONS } from 'services/notifications.service';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export interface INotificationsContext {
  nrUnreadNotifications: number;
  notifications: NotificationDTO[];
  canLoadMore: boolean;
  showError: boolean;
  loadMoreNotifications: () => void;
  readNotification: (notificationId: string) => void;
}

const NotificationsContext = createContext<INotificationsContext>({
  nrUnreadNotifications: 0,
  notifications: [],
  canLoadMore: true,
  showError: false,
  loadMoreNotifications: () => {},
  readNotification: (_: string) => {},
} as INotificationsContext);

export const NotificationsContextProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const logger = useWinstonLogger();
  const { onNewNotificationReceived, getNrUnreadNotifications, getNotifications, setNotificationRead } =
    useNotificationsService();
  const [nrUnreadNotifications, setNrUnreadNotifications] = useState<number>(0);
  const [notifications, setNotifications] = useState<NotificationDTO[]>([]);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    getNrUnreadNotifications().then((nrUnreadNotifications: number) => {
      if (!isMounted) return;
      setNrUnreadNotifications(nrUnreadNotifications);
    });

    loadNotifications();

    return () => setIsMounted(false);
  }, []);

  const loadNotifications = useCallback(() => {
    const lastId = notifications.length > 0 ? notifications.at(-1)?.id : undefined;

    getNotifications(lastId)
      .then((notifications: NotificationDTO[]) => {
        if (!isMounted) return;

        if (notifications.length < DEFAULT_NR_NOTIFICATIONS) setCanLoadMore(false);
        setNotifications((prevState: NotificationDTO[]) => [...prevState, ...notifications]);
      })
      .catch(() => {
        setShowError(true);
      });
  }, [isMounted, notifications]);

  const incrementCounter = (increment: number): void => {
    if (increment > 0) logger.log(LOG_COMPONENT, `updating counter for new notification received`);
    setNrUnreadNotifications((prevState: number) => prevState + increment);
  };

  onNewNotificationReceived().then((notification: NotificationDTO) => {
    setNotifications((prevState: NotificationDTO[]) => [notification, ...prevState]);
    incrementCounter(1);
  });

  const loadMoreNotifications = (): void => loadNotifications();

  const readNotification = (notificationId: string): void => {
    setNotificationRead(notificationId).then(() => {
      setNotifications((prevState: NotificationDTO[]) =>
        prevState.map((notification: NotificationDTO) => ({
          ...notification,
          read: notification.id === notificationId ? true : notification.read,
        }))
      );
      incrementCounter(-1);
    });
  };

  const value: INotificationsContext = {
    nrUnreadNotifications,
    notifications,
    canLoadMore,
    showError,
    loadMoreNotifications,
    readNotification,
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export default NotificationsContext;
