import useAppointmentDetail from './AppointmentDetail.logic';
import AppointmentDetailHeader from './AppointmentDetailHeader';
import EventDTO from 'dtos/EventDTO';
import { ChatContextProvider } from 'store/chat-context';
import { Transition } from 'react-transition-group';
import PatientInfo from 'components/PatientInfo/PatientInfo';
import {
  AppointmentDetailsChat,
  AppointmentDetailsChatButton,
  AppointmentDetailsChatContainer,
  AppointmentDetailsChatMessages,
  AppointmentDetailsChatSendMessage,
  AppointmentDetailsWrapper,
} from './AppointmentDetail.styles';
import { styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';

export interface IAppointmentDetailProps extends PropsWithChildren<{}> {
  appointmentEvent: EventDTO;
}

const AppointmentDetail: FC<IAppointmentDetailProps> = (props: IAppointmentDetailProps): JSX.Element => {
  const { appointmentEvent } = props;
  const {
    language,
    calleeId,
    shrinkHeader,
    showChat,
    headerRef,
    scrollViewRef,
    startAppointmentHandler,
    rescheduleAppointmentHandler,
    cancelAppointmentHandler,
    toggleShowChatHandler,
  } = useAppointmentDetail(props);

  return (
    <>
      <AppointmentDetailHeader
        shrink={shrinkHeader}
        appointmentEvent={appointmentEvent}
        onStart={startAppointmentHandler}
        onReschedule={rescheduleAppointmentHandler}
        onCancel={cancelAppointmentHandler}
        ref={headerRef}
      />
      <AppointmentDetailsWrapper justifyContent='flex-start' alignItems='flex-start'>
        <PatientInfo patientId={calleeId} scrollViewRef={scrollViewRef} />
        <AppointmentDetailsChat isOpen={showChat}>
          <AppointmentDetailsChatButton
            className={styles['show-chat_button']}
            type='secondary'
            active={showChat}
            icon='chat'
            label={language.chat}
            onClick={toggleShowChatHandler}
          />
          <ChatContextProvider calleeId={calleeId} disableSend={true}>
            <Transition in={showChat} timeout={styles.transition} mountOnEnter unmountOnExit>
              <AppointmentDetailsChatContainer direction='column'>
                <AppointmentDetailsChatMessages userClassName='messages-user' />
                <AppointmentDetailsChatSendMessage
                  disabled={true}
                  placeholder={language.canOnlySendMessagesDuringAppointment}
                />
              </AppointmentDetailsChatContainer>
            </Transition>
          </ChatContextProvider>
        </AppointmentDetailsChat>
      </AppointmentDetailsWrapper>
    </>
  );
};

export default AppointmentDetail;
