import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './WelcomePatientMessage.module.scss';
import sass from 'styles/exports.module.scss';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { placeValue } from 'utils/language';

export type WelcomePatientMessageProps = {
  show: boolean;
};

const WelcomePatientMessage: React.FC<WelcomePatientMessageProps> = (
  props: React.PropsWithChildren<WelcomePatientMessageProps>
) => {
  const { show } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const userName = useSelector((state: IReduxState) => state.entity!.name);

  return (
    <Transition in={show} timeout={+sass.transition} mountOnEnter unmountOnExit>
      {(state: TransitionStatus) => (
        <div className={`${styles.welcome} ${styles[state]}`}>
          <h1>{userName && placeValue(language.helloX, userName)}</h1>
          <h4>{language.helpFindTheMedicYouNeed}</h4>
        </div>
      )}
    </Transition>
  );
};

export default WelcomePatientMessage;
