import { IOption } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useHttp } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { catalogAPI } from 'apis';
import DailyHabitDTO, { DailyHabitOptionDTO } from 'dtos/DailyHabitDTO';
import IReduxState from 'interfaces/IReduxState';
import DailyHabitMapper from 'mappers/DailyHabitMapper';
import { useSelector } from 'react-redux';
import DailyHabitSchema from 'schemas/DailyHabitSchema';

const dailyHabitMapper = DailyHabitMapper();

export interface IUseCatalogService {
  getDailyHabits: () => Promise<DailyHabitDTO[]>;
  getDailyHabitsOptions: (...optionsIds: string[]) => Promise<IOption[]>;
}

const useCatalogService = (): IUseCatalogService => {
  const { get } = useHttp();
  const languageCode = useSelector((state: IReduxState) => state.language.code);

  const getDailyHabitsOptions = async (...optionsIds: string[]): Promise<IOption[]> => {
    const dailyHabits = await getDailyHabits();

    return dailyHabits.map((dailyHabit: DailyHabitDTO) => {
      const dailyHabitOption = dailyHabit.options.find((option: DailyHabitOptionDTO) => optionsIds.includes(option.id));

      return {
        id: dailyHabit.id,
        icon: dailyHabit.icon,
        name: dailyHabitOption ? dailyHabitOption.name : '-',
      } as IOption;
    });
  };

  const getDailyHabits = (): Promise<DailyHabitDTO[]> => {
    return new Promise((resolve, reject) => {
      get(`${catalogAPI}/dailyhabits`, { code: languageCode })
        .then((dailyHabits: DailyHabitSchema[]) => {
          resolve(
            dailyHabits.map((dailyHabit: DailyHabitSchema) => dailyHabitMapper.toInterface(dailyHabit, languageCode!))
          );
        })
        .catch(() => {
          reject();
        });
    });
  };

  return { getDailyHabits, getDailyHabitsOptions };
};

export default useCatalogService;
