import DayView from './Views/DayView';
import DayCalendarHeader from './DayCalendarHeader';
import ScrollView from 'components/View/ScrollView';
import styles from './DayCalendar.module.scss';
import useDayEvents from './DayCalendar.logic';
import { FC, PropsWithChildren } from 'react';

export interface DayEventsProps {}

const DayEvents: FC<DayEventsProps> = (props: PropsWithChildren<DayEventsProps>): JSX.Element => {
  const {} = props;
  const { scrollViewRef, selectedEvent, selectEventHandler, eventsChangeHandler, unselectEventHandler } =
    useDayEvents();

  return (
    <>
      <DayCalendarHeader />
      <ScrollView className={styles['day-events_scroll']} ref={scrollViewRef}>
        <DayView
          selectedEvent={selectedEvent}
          onSelectEvent={selectEventHandler}
          onEventsChange={eventsChangeHandler}
          onClose={unselectEventHandler}
        />
      </ScrollView>
    </>
  );
};

export default DayEvents;
