import useAvailabilities from './Availabilities.logic';
import SmallCalendar from 'components/SmallCalendar/SmallCalendar';
import { Button, Flex, IProps } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { isToday } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { PropsWithChildren, FC } from 'react';
import {
  RescheduleAvailabilitiesEventsMultiOption,
  RescheduleAvailabilitiesLeftContent,
  RescheduleAvailabilitiesNoEventsMessage,
  RescheduleAvailabilitiesWrapper,
} from './Availabilities.styles';

export interface IRescheduleAvailabilitiesProps extends PropsWithChildren<IProps> {
  medicId: string;
  specialtyId: string;
}

const RescheduleAvailabilities: FC<IRescheduleAvailabilitiesProps> = (
  props: IRescheduleAvailabilitiesProps
): JSX.Element => {
  const {} = props;
  const {
    language,
    date,
    availableEventsByDay,
    masks,
    formatDate,
    previousDayHandler,
    nextDayHandler,
    changeDayHandler,
  } = useAvailabilities(props);

  return (
    <RescheduleAvailabilitiesWrapper justifyContent='flex-start' alignItems='flex-start'>
      <RescheduleAvailabilitiesLeftContent>
        <Flex style={{ marginBottom: '1rem' }} justifyContent='space-between'>
          <h3>{formatDate(date, masks.mediumDate)}</h3>
          <Flex>
            <Button
              dataTestId='rescheduleAppointment-previousDayButton'
              style={{ marginRight: '1rem' }}
              type='secondary'
              icon='arrowLeft'
              disabled={isToday(date)}
              onClick={previousDayHandler}
            />
            <Button
              dataTestId='rescheduleAppointment-nextDayButton'
              type='secondary'
              icon='arrowRight'
              onClick={nextDayHandler}
            />
          </Flex>
        </Flex>
        {availableEventsByDay.length > 0 && (
          <RescheduleAvailabilitiesEventsMultiOption
            dataTestId='rescheduleAppointment-availabilitiesMultiOption'
            name='event'
            options={availableEventsByDay}
          />
        )}
        {availableEventsByDay.length === 0 && (
          <RescheduleAvailabilitiesNoEventsMessage>{language.noAvailableEvent}</RescheduleAvailabilitiesNoEventsMessage>
        )}
      </RescheduleAvailabilitiesLeftContent>
      <SmallCalendar value={date} min={new Date()} onChange={changeDayHandler} />
    </RescheduleAvailabilitiesWrapper>
  );
};

export default RescheduleAvailabilities;
