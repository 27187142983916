import useOptionList, { OptionListType } from 'hooks/use-optionList';
import IOption from 'interfaces/IOption';
import React, { useImperativeHandle } from 'react';
import CheckItem from './CheckItem';
import styles from './CheckList.module.scss';

export type CheckListType = string | number | (string | number)[] | undefined;

export interface CheckListProps {
  dataTestId?: string;
  identifier: string;
  type: 'radio' | 'checkbox';
  options: IOption[];
  defaultSelected?: OptionListType;
  selected?: OptionListType;
  onChange?: (option: IOption, checked?: boolean) => void;
  onValueChange?: (option: IOption, checked: boolean) => void;
}

export interface CheckListRef {
  value: OptionListType;
}

const CheckList: React.ForwardRefExoticComponent<CheckListProps & React.RefAttributes<CheckListRef>> = React.forwardRef<
  CheckListRef,
  CheckListProps
>((props: React.PropsWithChildren<CheckListProps>, ref: React.ForwardedRef<CheckListRef>) => {
  const { dataTestId, identifier, type, options } = props;
  const { selectedOptions, refValue, changeHandler } = useOptionList(props);

  useImperativeHandle(ref, () => ({ value: refValue }));

  return (
    <div className={styles['check-list']}>
      {options.map((option: IOption, index: number) => (
        <CheckItem
          key={option.id}
          dataTestId={`${dataTestId}-${index}`}
          identifier={identifier}
          type={type}
          checked={selectedOptions.includes(option.id)}
          onCheck={() => changeHandler(true, option)}
          onUncheck={() => changeHandler(true, option)}>
          {option.name}
        </CheckItem>
      ))}
    </div>
  );
});

export default CheckList;
