import { FC, PropsWithChildren } from 'react';
import Icon from 'components/View/Icon';
import ValueSlider from 'components/View/ValueSlider';
import { iconsTemp } from 'iconsTemp';
import styles from './SpeakerVolume.module.scss';

export type SpeakerVolumeProps = {
  volume: number;
  onVolumeChange: (volume: number) => void;
};

const SpeakerVolume: FC<SpeakerVolumeProps> = (props: PropsWithChildren<SpeakerVolumeProps>) => {
  const { volume, onVolumeChange } = props;

  return (
    <div className={styles.speaker}>
      {volume > 0 && <Icon className={styles.icon} src={iconsTemp.speakerOn} />}
      {volume <= 0 && <Icon className={styles.icon} src={iconsTemp.speakerOff} />}
      <ValueSlider initialValue={volume} minValue={0} maxValue={1} onChange={onVolumeChange} />
    </div>
  );
};

export default SpeakerVolume;
