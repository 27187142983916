import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';
import { canModifyAppointment, canStartAppointment } from 'utils/appointment';
import EventDTO from 'dtos/EventDTO';
import MediaScreenMobile from 'components/Media/MediaScreenMobile';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import { Button, Icon, useMediaScreen } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import {
  AppointmentDetailsHeaderButtonsWrapper,
  AppointmentDetailsHeaderPopup,
  AppointmentDetailsHeaderPopupButton,
} from './AppointmentDetailHeaderButtons.styles';
import { useDate, timeIsPast } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

export interface IAppointmentDetailHeaderButtonsProps extends PropsWithChildren<{}> {
  appointmentEvent: EventDTO;
  onStart: () => void;
  onReschedule: () => void;
  onCancel: () => void;
}

const AppointmentDetailHeaderButtons: FC<IAppointmentDetailHeaderButtonsProps> = (
  props: IAppointmentDetailHeaderButtonsProps
): JSX.Element => {
  const { appointmentEvent, onStart, onReschedule, onCancel } = props;
  const { start, end } = appointmentEvent;
  const { formatDate, masks } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [buttonsPopupIsOpen, setButtonsPopupIsOpen] = useState<boolean>(false);
  const { isMobileScreen } = useMediaScreen();
  const isPast = timeIsPast(end);
  const showStartAppointmentButton = canStartAppointment(appointmentEvent);
  const showModificationButtons = canModifyAppointment(appointmentEvent);

  const toggleButtonsPopupHandler = (): void => {
    setButtonsPopupIsOpen((prevState: boolean) => !prevState);
  };

  if (isPast) return <></>;

  return (
    <AppointmentDetailsHeaderButtonsWrapper justifyContent='flex-end'>
      {!showStartAppointmentButton && showModificationButtons && (
        <>
          <Button
            dataTestId='index-rescheduleButton'
            label={language.reschedule}
            icon='edit'
            type='secondary'
            state='dark'
            onClick={onReschedule}
          />
          <Button
            dataTestId='index-cancelButton'
            label={language.cancel}
            icon='close'
            type='secondary'
            state='dark'
            onClick={onCancel}
          />
        </>
      )}
      {showStartAppointmentButton && (
        <>
          <MediaScreenDesktop>
            <Button
              dataTestId='index-callButton'
              label={language.initAppointment}
              icon='videoCall'
              state='green'
              onClick={onStart}
            />
          </MediaScreenDesktop>
          <MediaScreenMobile>
            <div>
              <div>
                <Icon src='clock' />
                <div>
                  <h4>{formatDate(start, masks.shortTime)}</h4>
                  <h5>{formatDate(end, masks.shortTime)}</h5>
                </div>
              </div>
              <Button
                dataTestId='index-callButton'
                label={language.initAppointment}
                icon='videoCall'
                state='green'
                onClick={onStart}
              />
            </div>
          </MediaScreenMobile>
        </>
      )}
      {((showStartAppointmentButton && showModificationButtons) || isMobileScreen) && (
        <>
          <AppointmentDetailsHeaderPopupButton
            type='secondary'
            icon='options'
            active={buttonsPopupIsOpen}
            onClick={toggleButtonsPopupHandler}
          />
          <AppointmentDetailsHeaderPopup show={buttonsPopupIsOpen} top='3rem' close={setButtonsPopupIsOpen}>
            <Button type='text' state='dark' icon='edit' label={language.rescheduleAppointment} onClick={() => {}} />
            <Button type='text' state='red' icon='close' label={language.cancelAppointment} onClick={onCancel} />
          </AppointmentDetailsHeaderPopup>
        </>
      )}
    </AppointmentDetailsHeaderButtonsWrapper>
  );
};

export default AppointmentDetailHeaderButtons;
