import IOption from 'interfaces/IOption';
import Option from './Option';
import styles from './MultiOption.module.scss';
import { OptionListType } from '../../../hooks/use-optionList';
import React, { useImperativeHandle } from 'react';
import useOptionList from '../../../hooks/use-optionList';

export interface MultiOptionProps {
  dataTestId?: string;
  className?: string;
  identifier: string;
  multi?: boolean;
  join?: boolean;
  options: IOption[];
  defaultSelected?: OptionListType;
  selected?: OptionListType;
  onChange?: (option: IOption, checked?: boolean) => void;
  onValueChange?: (option: IOption, checked: boolean) => void;
}

export interface MultiOptionRef {
  value: OptionListType;
}

const MultiOption: React.ForwardRefExoticComponent<MultiOptionProps & React.RefAttributes<MultiOptionRef>> =
  React.forwardRef<MultiOptionRef, MultiOptionProps>(
    (props: React.PropsWithChildren<MultiOptionProps>, ref: React.ForwardedRef<MultiOptionRef>) => {
      const { dataTestId, className, multi, join, identifier, options } = props;
      const { selectedOptions, refValue, changeHandler } = useOptionList(props);

      useImperativeHandle(ref, () => ({ value: refValue }));

      return (
        <div
          data-testid={dataTestId}
          className={`${styles['multi-option']} ${join ? styles.join : styles.apart} ${className}`}>
          {options.map((option: IOption, index: number) => (
            <Option
              dataTestId={`${dataTestId}-${index}`}
              key={option.id}
              type={multi ? 'checkbox' : 'radio'}
              identifier={identifier}
              option={option}
              checked={selectedOptions.includes(option.id)}
              onCheck={() => changeHandler(true, option)}
              onUncheck={() => changeHandler(false, option)}
            />
          ))}
        </div>
      );
    }
  );

MultiOption.defaultProps = {
  multi: false,
  join: false,
};

export default MultiOption;
