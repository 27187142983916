import { IOption, styles, Tag } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import usePatientData from './PatientData.logic';
import { PatientDataTag } from './PatientData.styles';
import PatientDataItem from './PatientDataItem';

export interface IPatientDataProps extends PropsWithChildren<{}> {
  patientId?: string;
}

const PatientData: FC<IPatientDataProps> = (props: IPatientDataProps): JSX.Element => {
  const {} = props;
  const { patientData } = usePatientData(props);

  return (
    <div>
      {patientData.map(({ icon, iconColor, name, list, tags }, index: number) => (
        <PatientDataItem
          key={index}
          icon={icon! as string}
          iconColor={iconColor}
          title={name}
          text={list?.length ? list.map((item: IOption) => item.name).join(', ') : tags?.length ? undefined : '-'}>
          {tags &&
            tags.map(({ id, icon, name }: IOption) => (
              <PatientDataTag key={id} icon={icon} label={name} color={styles.colors.brand.dark} />
            ))}
        </PatientDataItem>
      ))}
    </div>
  );
};

export default PatientData;
