import { Flex, getColumnsWidth, getGutter } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface ITodaysEventsWrapperProps {}

export const TodaysEventsWrapper = styled(Flex)<ITodaysEventsWrapperProps>`
  width: ${getColumnsWidth({ nrColumns: 4 })};
  padding-top: calc(${getGutter()} / 1.5);
  padding-right: 0 !important;
  padding-left: 0 !important;

  > h1 {
    padding-left: ${getGutter()};
    box-sizing: border-box;
    margin-bottom: 2rem;
    font-size: 1.125rem;
  }
`;

export interface ITodaysEventsListProps {}

export const TodaysEventsList = styled(Flex)<ITodaysEventsListProps>`
  padding: 0 ${getGutter()};
  box-sizing: border-box;

  > li {
    width: 100%;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

/*
@media screen and (max-width: ($smallDesktop + 0px)) {
  .appointments_list {
    padding: 0 getGutter() / 2;
  }
}

@media screen and (max-width: ($tablet + 0px)) {
  .appointments_list {
    width: getColWidth($tablet, 8);
    padding-right: getMargin($tablet);
    padding-left: getMargin($tablet);
    margin-right: 0;
    box-sizing: content-box;
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .appointments_list {
    padding-top: 0.2rem;

    > li {
      margin-bottom: 0.25rem;
    }
  }
}
*/
