import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import { AssociatedMedicDTO } from 'dtos/AssociatedUserDTO';
import { Gender } from 'dtos/EntityDTO';
import AssociatedMedicSchema from 'schemas/AssociatedMedicSchema';
import SpecialtySchema from 'schemas/SpecialtySchema';
import EntityImageMapper from './EntityImageMapper';
import SpecialtyMapper from './SpecialtyMapper';

const entityImageMapper = EntityImageMapper();
const specialtyMapper = SpecialtyMapper();

const AssociatedMedicMapper = () => {
  const toInterface = (schema: AssociatedMedicSchema): AssociatedMedicDTO => {
    return {
      id: schema.id,
      name: schema.name,
      gender: schema.sex as Gender,
      image: schema.image ? entityImageMapper.toInterface(schema.image) : undefined,
      specialties: schema.specialties.map((specialty: SpecialtySchema) => specialtyMapper.toInterface(specialty)),
      appointmentTypes: schema.appointmentTypes.map((type: string) => type.toLowerCase() as AppointmentPlaces),
      lastAppointmentDate: schema.lastAppointmentEvent ? new Date(schema.lastAppointmentEvent?.startAt) : undefined,
    };
  };

  return { toInterface };
};

export default AssociatedMedicMapper;
