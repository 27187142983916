import { Icon, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { EventContentArg } from '@fullcalendar/react';
import EventDTO from 'dtos/EventDTO';
import useDate, { DateMasks } from 'hooks/use-date';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUserInitials } from 'utils/user';
import MonthEventPopup from './CalendarEventPopup';
import styles from './WeekEvent.module.scss';

export interface WeekEventProps {
  event: EventContentArg;
  selected?: boolean;
  onClose: () => void;
}

const WeekEvent: FC<WeekEventProps> = (props: PropsWithChildren<WeekEventProps>): JSX.Element => {
  const { event, selected, onClose } = props;
  const { formatDate } = useDate();
  const eventContainerRef = useRef<HTMLDivElement>(null);
  const { start, end } = event.event._instance!.range;
  const { place, appointment, icon } = event?.event._def.extendedProps as EventDTO;
  const language = useSelector((state: IReduxState) => state.language.values);
  const duration = (end!.getTime() - start!.getTime()) / 1000 / 60;

  return (
    <div
      data-testid={`weekEvent-${place}-${formatDate(event.event._instance!.range.start, DateMasks.shortTime)}`}
      className={`${styles['week-event']} ${styles[place]} ${selected && styles.selected}`}
      ref={eventContainerRef}>
      <Icon className={styles.icon} src={icon as any} />
      {duration >= 30 && <span>{appointment ? getUserInitials(appointment.user!.name) : language.available}</span>}
      {appointment && <UserImage style={{ marginLeft: 'auto' }} src={appointment.user?.image} size='1rem' />}
      <MonthEventPopup show={!!selected} parent={eventContainerRef} event={event} onClose={onClose} />
    </div>
  );
};

export default WeekEvent;
