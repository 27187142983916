import EntityImageDTO from 'dtos/EntityImageDTO';
import { FILE_TYPES } from 'dtos/FileDTO';

export const getFileTypesList = (): string[] => {
  let fileTypes: string[] = [];

  Object.values(FILE_TYPES).forEach((types: string[]) => {
    fileTypes = fileTypes.concat([...types]);
  });

  return fileTypes;
};

export const getImageBase64 = (image: EntityImageDTO | undefined): string => {
  if (!image) return '';
  else return `data:${image.contentType};base64,${image.bytes}`;
};
