import { rgba, SmallText, styles, transition } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';
import { LoginContainer } from '../Login.styles';

export interface ILoginFormWrapperProps {
  showError: boolean;
}

export const LoginFormWrapper = styled(LoginContainer)<ILoginFormWrapperProps>`
  border: 2px solid transparent;
  ${transition('border-color')}

  ${({ showError }: ILoginFormWrapperProps) =>
    showError
      ? css`
          border-color: ${rgba(styles.colors.feedback.alert, 0.8)};
        `
      : css``}
`;

export interface ILoginFormForgotPasswordLinkProps {}

export const LoginFormForgotPasswordLink = styled(SmallText)<ILoginFormForgotPasswordLinkProps>`
  align-self: flex-start;
  margin-top: 0.5rem;
  margin-left: 1.5rem;
`;

export interface ILoginFormErrorMessageProps {}

export const LoginFormErrorMessage = styled(SmallText)<ILoginFormErrorMessageProps>`
  position: absolute;
  bottom: 10px;
  color: ${rgba(styles.colors.feedback.alert, 0.8)};
  text-align: center;

  &.login-error-message-enter-active {
    opacity: 1;
  }

  &.login-error-message-enter,
  &.login-error-message-exit,
  &.login-error-message-exit-active {
    opacity: 0;
  }
`;

/*
@media screen and (max-width: ($smartphone + 0px)) {
  .form {
    > .error {
      bottom: 5px;
    }
  }
}
*/
