import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import Icon from 'components/View/Icon';
import EntityImageDTO from 'dtos/EntityImageDTO';
import { iconsTemp } from 'iconsTemp';
import { FC, PropsWithChildren } from 'react';
import styles from './UserItem.module.scss';

export type UserItemProps = {
  name: string | JSX.Element;
  userImage?: string | EntityImageDTO;
};

const UserItem: FC<UserItemProps> = (props: PropsWithChildren<UserItemProps>) => {
  const { name, userImage, children } = props;

  return (
    <div className={styles['user-item']}>
      <UserImage className={styles['user-image']} src={userImage} />
      <div>
        <h5>{name}</h5>
        <div className={styles['user-details']}>{children}</div>
      </div>
      <Icon className={styles['arrow_icon']} src={iconsTemp.arrows.right} />
    </div>
  );
};

export default UserItem;
