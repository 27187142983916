import { useEffect, useState } from 'react';
import Icon from './Icon';
import styles from './Tag.module.scss';

export type TagProps = {
  className?: string;
  toggle?: boolean;
  defaultActive?: boolean;
  active?: boolean;
  icon?: string;
  onClick?: (active: boolean) => void;
};

const Tag: React.FC<TagProps> = (props: React.PropsWithChildren<TagProps>) => {
  const { className, toggle, defaultActive, active, icon, children, onClick } = props;
  const [isActive, setIsActive] = useState<boolean>(defaultActive || false);

  useEffect(() => {
    setIsActive(active as boolean);
  }, [active]);

  const toggleHandler = () => {
    if (!toggle) return;

    setIsActive((prevState: boolean) => {
      if (onClick) {
        onClick(!prevState);
        return prevState;
      }

      return !prevState;
    });
  };

  return (
    <div
      className={`${styles.tag} ${!toggle && styles['no-toggle']} ${isActive && styles.active} ${className}`}
      onClick={toggleHandler}>
      {icon && <Icon className={styles.icon} src={icon} />}
      <span>{children}</span>
    </div>
  );
};

Tag.defaultProps = {
  className: '',
  toggle: true,
  defaultActive: true,
};

export default Tag;
