import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import React from 'react';
import styles from './LikeButton.module.scss';

export type LikeButtonProps = {
  liked: boolean;
  onClick: () => void;
};

const LikeButton: React.FC<LikeButtonProps> = (props: React.PropsWithChildren<LikeButtonProps>) => {
  const { liked, onClick } = props;

  const clickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  };

  return (
    <button className={styles['like_button']} type='button' onClick={clickHandler}>
      {liked && <Icon className={`${styles.icon} ${styles.liked}`} src={iconsTemp.heartFilled} />}
      {!liked && <Icon className={styles.icon} src={iconsTemp.heart} />}
    </button>
  );
};

export default LikeButton;
