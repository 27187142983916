import { FormContext, getInputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import { PropsWithChildren, FC, useContext } from 'react';
import { AvailabilitiesStepSlotsSlider, AvailabilitiesStepWrapper } from './AvailabilitiesStep.styles';

export interface IAvailabilitiesStepProps extends PropsWithChildren<{}> {}

const AvailabilitiesStep: FC<IAvailabilitiesStepProps> = (props: IAvailabilitiesStepProps): JSX.Element => {
  const {} = props;
  const { inputs } = useContext(FormContext);

  return (
    <AvailabilitiesStepWrapper>
      <AvailabilitiesStepSlotsSlider
        name='event'
        medicId={getInputValue(inputs.medic)!}
        specialtyId={getInputValue(inputs.specialty)!}
        type={getInputValue(inputs.type)! as AppointmentPlaces}
        start={new Date()}
        nrDays={5}
      />
    </AvailabilitiesStepWrapper>
  );
};

export default AvailabilitiesStep;
