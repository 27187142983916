import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { Dispatch, FocusEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LOG_HTTP } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import { ILoginRouteState } from 'interfaces/IRouteStates';
import useAuthenticationService from 'services/authentication.service';
import { Action, ActionTypes, FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface IUseLoginForm {
  language: ILanguage;
  wrongCredentials: boolean;
  isLoading: boolean;
  formLoadHandler: (dispatchInputs: Dispatch<Action>) => void;
  formFocusHandler: (event: FocusEvent<HTMLFormElement>) => void;
  loginHandler: (submit: FormSubmit) => void;
}

const useLoginForm = (): IUseLoginForm => {
  const logger = useWinstonLogger();
  const location = useLocation<ILoginRouteState>();
  const { isLoading, login } = useAuthenticationService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [wrongCredentials, setWrongCredentials] = useState<boolean>(false);

  const formLoadHandler = (dispatchInputs: Dispatch<Action>): void => {
    if (location.state) {
      const { email } = location.state;

      if (email) dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'email', value: email });
    }
  };

  const formFocusHandler = (_: FocusEvent<HTMLFormElement>) => {
    setWrongCredentials(false);
  };

  const loginHandler = (submit: FormSubmit): void => {
    logger.log(LOG_HTTP, 'logging user in');
    login(submit).catch(() => setWrongCredentials(true));
  };

  return { language, wrongCredentials, isLoading, formLoadHandler, formFocusHandler, loginHandler };
};

export default useLoginForm;
