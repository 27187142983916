import React from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './Button.module.scss';
import sass from 'styles/exports.module.scss';
import Icon from 'components/View/Icon';
import useButton from './Button.logic';

export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TEXT = 'text',
}

export enum ButtonThemes {
  MAIN = 'main',
  RED = 'red',
  GREEN = 'green',
  DARK = 'dark',
  LIGHT = 'light',
}

export type ButtonProps = {
  dataTestId?: string;
  className?: string;
  activeClassName?: string;
  label?: string;
  icon?: string;
  theme?: ButtonThemes;
  type?: ButtonTypes;
  active?: boolean;
  disabled?: boolean;
  valueType?: 'button' | 'submit' | 'reset';
  showLabelOnMouseOuver?: boolean;
  onClick: () => void;
  onMouseOver?: (() => JSX.Element) | string;
};

const Button: React.FC<ButtonProps> = (props: React.PropsWithChildren<ButtonProps>) => {
  const {
    dataTestId,
    className,
    activeClassName,
    label,
    icon,
    theme,
    type,
    active,
    disabled,
    valueType,
    showLabelOnMouseOuver,
    onMouseOver,
  } = props;
  const { mouseIsOver, setMouseIsOver, clickHandler, mouseDownHandler } = useButton(props);

  return (
    <>
      <button
        data-testid={dataTestId}
        className={`${styles.button} ${icon && (!label || showLabelOnMouseOuver) && styles['icon-button']} ${
          styles[type!]
        } ${styles[theme!]} ${active && styles.active} ${className} ${active && activeClassName}`}
        type={valueType}
        disabled={disabled}
        onMouseDown={mouseDownHandler}
        onClick={clickHandler}
        onMouseEnter={() => setMouseIsOver(true)}
        onMouseLeave={() => setMouseIsOver(false)}>
        {icon && <Icon className={styles.icon} src={icon} />}
        {(showLabelOnMouseOuver ? mouseIsOver && label : label) && <span className={styles.label}>{label}</span>}
      </button>
      <Transition in={mouseIsOver && !!onMouseOver} timeout={+sass.transition} mountOnEnter unmountOnExit>
        {(state: TransitionStatus) => (
          <div className={`${styles.tooltip} ${styles[state]}`}>
            {typeof onMouseOver === 'function' ? onMouseOver() : onMouseOver}
          </div>
        )}
      </Transition>
    </>
  );
};

Button.defaultProps = {
  theme: ButtonThemes.MAIN,
  type: ButtonTypes.PRIMARY,
  active: false,
  disabled: false,
  valueType: 'button',
  showLabelOnMouseOuver: false,
};

export default Button;
