import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  initialMedicOptionalOnboardingFormInputs,
  initialPatientOptionalOnboardingFormInputs,
} from 'services/entities.service';
import routes from 'routes/routes';
import { userIsMedic, userIsPatient } from 'utils/user';
import MedicAboutStep from './Medic/MedicOptionalOnboarding/MedicAboutStep';
import PatientHealthStep from './Patient/PatientOptionalOnboarding/PatientHealthStep';
import PatientLifestyleStep from './Patient/PatientOptionalOnboarding/PatientLifestyleStep';
import AddressStep from './Steps/AddressStep';
import PhotoStep from './Steps/PhotoStep';
import { IInitFormInput, FormSubmit, IUseStep, useStep } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { entityActions } from 'store/redux/entity-slice';
import MedicDTO from 'dtos/MedicDTO';
import PatientDTO from 'dtos/PatientDTO';
import useMedicsService from 'services/medics.service';
import usePatientsService from 'services/patients.service';

export interface IUseOptionalOnboarding extends Omit<IUseStep, 'goToStep'> {
  language: ILanguage;
  initialValues: IInitFormInput[];
  stepsElements: JSX.Element[];
  isLoading: boolean;
  submitHandler: (submit: FormSubmit) => void;
  skipOptionalOnboardingHandler: () => void;
}

const useOptionalOnboarding = (): IUseOptionalOnboarding => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading: isLoadingMedic, updateCurrentMedic } = useMedicsService();
  const { isLoading: isLoadingPatient, updateCurrentPatient } = usePatientsService();
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const language = useSelector((state: IReduxState) => state.language.values);
  const isMedic = userIsMedic(userType);
  const isPatient = userIsPatient(userType);
  const stepsElements = isMedic
    ? [<PhotoStep />, <AddressStep />, <MedicAboutStep />]
    : isPatient
    ? [<PhotoStep />, <AddressStep />, <PatientHealthStep />, <PatientLifestyleStep />]
    : [];
  const { step, isFistStep, isFinalStep, nextStepHandler, previousStepHandler } = useStep(stepsElements.length, -1);

  const submitHandler = ({ inputs }: FormSubmit): void => {
    (isMedic ? updateCurrentMedic : updateCurrentPatient)(inputs).then((entity: MedicDTO | PatientDTO) => {
      dispatch(entityActions.setEntity(entity));
      history.push(routes.home.path);
    });
  };

  const skipOptionalOnboardingHandler = (): void => {
    history.push(routes.home.path);
  };

  return {
    language,
    initialValues: isMedic
      ? initialMedicOptionalOnboardingFormInputs
      : isPatient
      ? initialPatientOptionalOnboardingFormInputs
      : [],
    stepsElements,
    step,
    isFistStep,
    isFinalStep,
    isLoading: isLoadingMedic || isLoadingPatient,
    nextStepHandler,
    previousStepHandler,
    submitHandler,
    skipOptionalOnboardingHandler,
  };
};

export default useOptionalOnboarding;
