import EventDTO from 'dtos/EventDTO';
import AppointmentDetailHeaderButtons from './AppointmentDetailHeaderButtons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import AppointmentInfo from 'components/Appointment/AppointmentInfo';
import useMediaScreen from 'hooks/use-mediaScreen';
import { goBack } from 'utils/general';
import PatientDTO from 'dtos/PatientDTO';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import {
  AppointmentDetailHeaderInfo,
  AppointmentDetailHeaderPatientInfo,
  AppointmentDetailHeaderWrapper,
} from './AppointmentDetailHeader.styles';
import { Button, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface IAppointmentDetailHeaderProps extends PropsWithChildren<{}> {
  shrink: boolean;
  appointmentEvent: EventDTO;
  onStart: () => void;
  onReschedule: () => void;
  onCancel: () => void;
}

const AppointmentDetailHeader = forwardRef<HTMLDivElement, IAppointmentDetailHeaderProps>(
  (props: IAppointmentDetailHeaderProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const { shrink, appointmentEvent, onStart, onReschedule, onCancel } = props;
    const { appointment } = appointmentEvent;
    const history = useHistory();
    const language = useSelector((state: IReduxState) => state.language.values);
    const { isMobileScreen } = useMediaScreen();

    return (
      <AppointmentDetailHeaderWrapper ref={ref}>
        {isMobileScreen && <Button type='secondary' icon='arrowLeft' onClick={() => goBack(history)} />}
        <UserImage src={appointment?.user?.image} size={!shrink ? '7.5rem' : undefined} square={!shrink} />
        <AppointmentDetailHeaderInfo direction='column' justifyContent='flex-end' isShrinked={shrink}>
          <AppointmentDetailHeaderButtons
            appointmentEvent={appointmentEvent}
            onStart={onStart}
            onReschedule={onReschedule}
            onCancel={onCancel}
          />
          <AppointmentDetailHeaderPatientInfo justifyContent='flex-start' isShrinked={shrink}>
            {appointment?.user ? (
              <>
                <h1>
                  {appointment!.user.name}
                  <MediaScreenDesktop>,&nbsp;</MediaScreenDesktop>
                </h1>
                <h3>
                  {(appointment?.user as PatientDTO)?.age} {language.yearsOld}
                </h3>
              </>
            ) : (
              <h1>{language.unknown}</h1>
            )}
          </AppointmentDetailHeaderPatientInfo>
          <MediaScreenDesktop>
            <AppointmentInfo appointmentEvent={appointmentEvent} />
          </MediaScreenDesktop>
        </AppointmentDetailHeaderInfo>
      </AppointmentDetailHeaderWrapper>
    );
  }
);

export default AppointmentDetailHeader;
