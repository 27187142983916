import { EventClickArg, MoreLinkArg } from '@fullcalendar/react';
import { useContext, useState } from 'react';
import { useWinstonLogger } from 'winston-react';
import { LOG_COMPONENT } from 'utils/logger';
import { areEventsEqual } from 'utils/appointment';
import AgendaContext from 'store/agenda-context';
import { CalendarViews } from 'mappers/EventMapper';

export interface IUseCalendar {
  selectedView: CalendarViews;
  selectedEvent?: EventClickArg;
  selectEventHandler: (event: EventClickArg) => void;
  seeMoreClickHandler: (moreLinkArg: MoreLinkArg) => void;
  unselectEventHandler: () => void;
}

const useCalendar = (): IUseCalendar => {
  const logger = useWinstonLogger();
  const { selectedView } = useContext(AgendaContext);
  const [selectedEvent, setSelectedEvent] = useState<EventClickArg>();

  // checks if the selected event is the current one and unselects it if it is
  const selectEventHandler = (arg: EventClickArg): void => {
    if (areEventsEqual(selectedEvent?.event, arg.event)) {
      logger.log(LOG_COMPONENT, 'unselecting calendar event because the selected on was clicked again');
      setSelectedEvent(undefined);
    } else {
      logger.log(LOG_COMPONENT, `selecting day event with id ${arg.event._def.defId}`);
      setSelectedEvent(arg);
    }
  };

  const seeMoreClickHandler = (moreLink: MoreLinkArg): void => {
    logger.log(LOG_COMPONENT, "showing more events that weren't visible on the calendar month view");

    const cell = (moreLink.jsEvent.target as HTMLElement).closest('td');
    // TODO: show custom popup with all grouped events (replace default one and hide it on scss)
  };

  const unselectEventHandler = (): void => {
    logger.log(LOG_COMPONENT, 'unselecting calendar event');
    setSelectedEvent(undefined);
  };

  return {
    selectedView,
    selectedEvent,
    selectEventHandler,
    seeMoreClickHandler,
    unselectEventHandler,
  };
};

export default useCalendar;
