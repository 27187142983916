import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { userIsMedic } from 'utils/user';
import { OnboardingParagraph } from '../Onboarding.styles';
import { OptionalOnboardingPhotoInput } from './PhotoStep.styles';

export interface IPatientPhotoStepProps extends PropsWithChildren<{}> {}

const PatientPhotoStep: FC<IPatientPhotoStepProps> = (props: IPatientPhotoStepProps) => {
  const {} = props;
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <>
      <h1>{language.addYourPhoto}</h1>
      <OnboardingParagraph>
        {userIsMedic(userType)
          ? language.itWillHelpYourPatientsToRecognizeYou
          : language.itWillHelpYourMedicsToRecognizeYou}
      </OnboardingParagraph>
      <OptionalOnboardingPhotoInput name='photo' uploadedLabel={language.looksVeryGood} />
    </>
  );
};

export default PatientPhotoStep;
