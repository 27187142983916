import { ITagProps, Tag } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IAppointmentInfoTagProps {}

export const AppointmentInfoTag = styled(Tag).attrs((_: ITagProps) => ({
  iconWithBackground: true,
}))<IAppointmentInfoTagProps>`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;
