import EventDTO from 'dtos/EventDTO';
import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSchedulesService from 'services/schedules.service';
import AppointmentContext from 'store/appointment-context';

export interface IUseMedicAppointmentEnd {
  language: ILanguage;
  calleeId: string;
  appointmentId: string;
  appointmentEnd: Date;
  nextAppointmentEvent?: EventDTO | null;
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string;
  endAppointmentHandler: () => void;
}

const useMedicAppointmentEnd = (): IUseMedicAppointmentEnd => {
  const { formatDate } = useDate();
  const { getNextAppointment } = useSchedulesService();
  const { appointmentEvent, endAppointmentHandler } = useContext(AppointmentContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [nextAppointmentEvent, setNextAppointmentEvent] = useState<EventDTO | null>();

  useEffect(() => {
    getNextAppointment(appointmentEvent!.end).then((nextAppointment: EventDTO | null) => {
      if (nextAppointment?.appointment) setNextAppointmentEvent(nextAppointment);
      else setNextAppointmentEvent(null);
    });
  }, []);

  return {
    language,
    calleeId: appointmentEvent!.appointment!.user!.id,
    appointmentId: appointmentEvent!.appointment!.id,
    appointmentEnd: appointmentEvent!.end,
    nextAppointmentEvent,
    formatDate,
    endAppointmentHandler,
  };
};

export default useMedicAppointmentEnd;
