// Gets an object/nested object property by a given path (i.e. appointment.patientId)
const getObjectProperty = (object: {}, path: string[]): string => {
  let property: any = object;

  for (let i = 0; i < path.length; i++) {
    const newProperty = property[path[i] as keyof {}];
    property = newProperty;
    if (!newProperty) break;
  }

  return property as string;
};

export const getUniqueValues = (list: {}[], attributeName: string): string[] => {
  let values = list.map((item: {}) => getObjectProperty(item, attributeName.split('.')));
  values = values.filter((value: string | undefined, index: number, self: (string | undefined)[]) =>
    value ? self.indexOf(value) === index : false
  );

  return values;
};

export const compareToSearch = (search: string, text: string): boolean => {
  const normalizedSearch = search
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();

  if (normalizedSearch === '') return true;

  const normalizedText = text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  const words = normalizedSearch.split(' ');
  let nrMatches = 0;
  words.forEach((word: string) => (normalizedText.indexOf(word) >= 0 ? nrMatches++ : ''));
  return words.length === nrMatches;
};
