import { MessageState } from 'dtos/MessageDTO';
import useOnScreen from 'hooks/use-onScreen';
import { iconsTemp } from 'iconsTemp';
import { RefObject, useContext, useEffect } from 'react';
import ChatContext from 'store/chat-context';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import { MessageProps } from './Message';

export type UseMessage = {
  messageContainer: RefObject<HTMLDivElement>;
  stateIcon?: string;
};

const useMessage = (props: MessageProps): UseMessage => {
  const { message } = props;
  const { mine, state } = message;
  const logger = useWinstonLogger();
  const { readMessage } = useContext(ChatContext);
  const { elementRef, isIntersecting } = useOnScreen();

  useEffect(() => {
    // only updates a message state to read if it was received and not sent
    if (isIntersecting && !mine && state !== MessageState.READ) {
      logger.log(LOG_COMPONENT, `setting received message with id ${message.id} as read`);
      readMessage(message.id);
    }
  }, [isIntersecting]);

  return {
    messageContainer: elementRef as RefObject<HTMLDivElement>,
    stateIcon: mine ? getStateIcon(state) : undefined,
  };
};

const getStateIcon = (state: MessageState): string | undefined => {
  switch (state) {
    case MessageState.NOT_SENT:
      return iconsTemp.close;
    case MessageState.SENDING:
      return '';
    case MessageState.SENT:
      return iconsTemp.check;
    default:
      return;
  }
};

export default useMessage;
