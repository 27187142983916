import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import styles from './Modal.module.scss';

export type ModalProps = {
  title: string;
  cancelButton?: boolean;
  submitButton?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
};

const Modal: React.FC<ModalProps> = (props: React.PropsWithChildren<ModalProps>) => {
  let { title, cancelButton, submitButton, cancelLabel, submitLabel, onCancel, onSubmit, children } = props;
  const language = useSelector((state: IReduxState) => state.language.values) as ILanguage;

  if (cancelLabel === undefined) cancelLabel = language.cancel;
  if (submitLabel === undefined) submitLabel = language.submit;

  const cancelHandler = () => {
    if (onCancel !== undefined) onCancel();
  };

  const submitHandler = () => {
    if (onSubmit !== undefined) onSubmit();
  };

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div />
      <div>
        <h3>{title}</h3>
        {children}
        {(cancelButton || submitButton) && (
          <div className={styles.buttons}>
            {cancelButton && <button onClick={cancelHandler}>{cancelLabel}</button>}
            {submitButton && <button onClick={submitHandler}>{submitLabel}</button>}
          </div>
        )}
      </div>
    </div>,
    document.querySelector('#modal')!
  );
};

Modal.defaultProps = {
  cancelButton: true,
  submitButton: true,
};

export default Modal;
