import { IOption, MultiOption, NumberInput, TagsWithOptionsInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { FC, PropsWithChildren } from 'react';
import { initialMedicMandatoryOnboardingFormInputs } from 'services/entities.service';
import { OnboardingForm, OnboardingParagraph, OnboardingSubmitButton, OnboardingSubtitle } from '../Onboarding.styles';
import useMedicMandatoryOnboarding from './MedicMandatoryOnboarding.logic';

export interface IMedicMandatoryOnboardingProps extends PropsWithChildren<{}> {}

const MedicMandatoryOnboarding: FC<IMedicMandatoryOnboardingProps> = (
  props: IMedicMandatoryOnboardingProps
): JSX.Element => {
  const {} = props;
  const { language, genderOptions, specialties, isLoading, loadHandler, submitHandler } = useMedicMandatoryOnboarding();

  return (
    <OnboardingForm
      initialValues={initialMedicMandatoryOnboardingFormInputs}
      onLoad={loadHandler}
      onSubmit={submitHandler}>
      <h1>{language.profileData}</h1>
      <OnboardingSubtitle>{language.canYouTellUsTheTitleYouPrefer}</OnboardingSubtitle>
      <MultiOption name='gender' options={genderOptions} />
      <OnboardingSubtitle>{language.canYouTellUsYourTin}</OnboardingSubtitle>
      <OnboardingParagraph style={{ marginBottom: '0', marginLeft: '0' }} textAlign='left'>
        {language.yourTinWillHelpTheBillingService}
      </OnboardingParagraph>
      <NumberInput name='tin' placeholder={language.tin} nrDigits={9} />
      <OnboardingSubtitle>{language.whatsYourSpecialty}</OnboardingSubtitle>
      <TagsWithOptionsInput
        name='specialties'
        placeholder={language.specialtiesPlaceholder}
        tags={specialties as IOption[]}
      />
      <OnboardingSubmitButton buttonType='submit' label={language.next} loading={isLoading} />
    </OnboardingForm>
  );
};

export default MedicMandatoryOnboarding;
