import Button, { ButtonThemes, ButtonTypes } from 'components/Form/Button';
import Icon from 'components/View/Icon';
import Popup from 'components/View/Popup';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import styles from './DayEventPopup.module.scss';
import { hasAppointmentEnded } from 'utils/appointment';
import EventDTO from 'dtos/EventDTO';
import { FC, PropsWithChildren, useContext } from 'react';
import AgendaContext from 'store/agenda-context';
import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface DayEventPopupProps {
  show: boolean;
  event: EventDTO;
  onClose: () => void;
}

const DayEventPopup: FC<DayEventPopupProps> = (props: PropsWithChildren<DayEventPopupProps>): JSX.Element => {
  const { show, event, onClose } = props;
  const { seePatientDetailsHandler, goToRescheduleAppointmentHandler, deleteEventHandler } = useContext(AgendaContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const { id, start, end, appointment } = event;
  const appointmentIsPast = event ? hasAppointmentEnded({ start, end, appointment } as EventDTO) : false;

  return (
    <Popup className={styles['day-event_popup']} show={show} onClose={onClose}>
      {appointment && (
        <>
          {/*Link to patient details needs to be a div due to this components being inside and a tag created from fullcalendar */}
          <div onClick={() => appointment.user && seePatientDetailsHandler(appointment.user.id)}>
            <UserImage src={appointment.user?.image} />
            <span>{language.seeDetails}</span>
            <Icon className={styles.icon} src={iconsTemp.arrows.right} />
          </div>
        </>
      )}
      {!appointmentIsPast && (
        <>
          {/*!isAppointmentPayed(appointment?.status) && (
            <Button
              dataTestId='dayEvent-rescheduleButton'
              type={ButtonTypes.TEXT}
              theme={ButtonThemes.DARK}
              icon={iconsTemp.edit}
              label={appointment ? language.rescheduleAppointment : language.rescheduleAvailableEvent}
              onClick={() => goToRescheduleAppointmentHandler(appointment!.id)}
            />
          )*/}
          <Button
            dataTestId='dayEvent-cancelButton'
            type={ButtonTypes.TEXT}
            theme={ButtonThemes.RED}
            icon={iconsTemp.close}
            label={appointment ? language.cancelAppointment : language.deleteAvailableEvent}
            onClick={() => deleteEventHandler(id, appointment?.id)}
          />
        </>
      )}
    </Popup>
  );
};

export default DayEventPopup;
