import useClickOutside from 'hooks/use-clickOutside';
import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import { useRef, useState } from 'react';
import { DateInputProps } from './DateInput';

export type UseDateInput = {
  showDatePicker: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  openDialogHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeHandler: (date: Date) => void;
};

const useDateInput = (props: DateInputProps): UseDateInput => {
  const { focusHandler, changeDateOrTimeHandler } = props;
  const { formatDate } = useDate();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, setShowDatePicker);

  const openDialogHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDatePicker(true);
    focusHandler(event);
  };

  const changeHandler = (date: Date) => {
    setShowDatePicker(false);
    changeDateOrTimeHandler!(date);
  };

  return {
    showDatePicker,
    containerRef,
    formatDate,
    setShowDatePicker,
    openDialogHandler,
    changeHandler,
  };
};

export default useDateInput;
