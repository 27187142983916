import { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import EventDTO, { AppointmentEventTypes } from 'dtos/EventDTO';
import useDate, { GlobalDateMasks } from 'hooks/use-date';
import ICalendarEvent from 'interfaces/ICalendarEvent';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { IAgendaSubRoutes, IPatientsSubRoutes } from 'interfaces/IRoute';
import EventMapper, { CalendarViews } from 'mappers/EventMapper';
import { createContext, createRef, PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, matchPath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import homeSubRoutes from 'routes/homeSubRoutes';
import routes from 'routes/routes';
import useAvailabilitiesService from 'services/availabilities.service';
import useSchedulesService from 'services/schedules.service';
import { getEventTypeImportance } from 'utils/appointment';
import { dateIsInsideRange, dateIsPast, dateIsValid, datesAreEqual, monthsAreEqual, yearsAreEqual } from 'utils/date';
import { LOG_COMPONENT, LOG_ERROR, LOG_WARN } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

const eventMapper = EventMapper();

interface CalendarRange {
  start: Date;
  end: Date;
}

interface ChangeRouteParams {
  pathname?: string;
  date?: Date;
  view?: CalendarViews;
  start?: Date;
}

export interface IAgendaContext {
  selectedDate?: Date;
  selectedView: CalendarViews;
  events: EventDTO[];
  monthEvents: ICalendarEvent[];
  weekEvents: ICalendarEvent[];
  dayEvents: EventDTO[];
  calendarRef: RefObject<FullCalendar>;
  dayCalendarRef: RefObject<FullCalendar>;
  removeEvent: (eventId: string) => void;
  changeViewHandler: (view: CalendarViews) => void;
  changeActiveViewHandler: (increment: 'prev' | 'next') => void;
  selectDayHandler: (selectedDate: Date) => void;
  selectDayNumberHandler: (selectedDate: Date) => void;
  selectDayCellHandler: (selectedDate: DateClickArg) => void;
  addAvailableEventsHandler: (newEvents: EventDTO[]) => void;
  closeAvailabilityPanelHandler: () => void;
  seePatientDetailsHandler: (patientId: string) => void;
  goToRescheduleAppointmentHandler: (appointmentId: string) => void;
  goToEditAvailabilityHandler: (eventId: string) => void;
  deleteEventHandler: (eventId: string, appointmentId?: string) => void;
}

const AgendaContext = createContext<IAgendaContext>({
  selectedDate: new Date(),
  selectedView: CalendarViews.MONTH,
  events: [],
  monthEvents: [],
  weekEvents: [],
  dayEvents: [],
  calendarRef: createRef<FullCalendar>(),
  dayCalendarRef: createRef<FullCalendar>(),
  removeEvent: (_: string) => {},
  changeViewHandler: (_: CalendarViews) => {},
  changeActiveViewHandler: (_: 'prev' | 'next') => {},
  selectDayHandler: (_: Date) => {},
  selectDayNumberHandler: (_: Date) => {},
  selectDayCellHandler: (_: DateClickArg) => {},
  addAvailableEventsHandler: (_: EventDTO[]) => {},
  closeAvailabilityPanelHandler: () => {},
  seePatientDetailsHandler: (_: string) => {},
  goToEditAvailabilityHandler: (_: string) => {},
  goToRescheduleAppointmentHandler: (_: string) => {},
  deleteEventHandler: (_: string, _2?: string) => {},
} as IAgendaContext);

export const AgendaContextProvider = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;
  const logger = useWinstonLogger();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { date: urlDate } = useParams<{ date: string }>();
  const { getEvents } = useSchedulesService();
  const { deleteAvailableEvent } = useAvailabilitiesService();
  const { formatDate } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [range, setRange] = useState<CalendarRange>();
  const [events, setEvents] = useState<EventDTO[]>([]);
  const calendarRef = useRef<FullCalendar>(null);
  const dayCalendarRef = useRef<FullCalendar>(null);
  const agendaSubRoutes = homeSubRoutes.agenda.subRoutes! as IAgendaSubRoutes;
  const appointmentsSlotsRoute = agendaSubRoutes.appointmentsSlots;
  const addAvailabilityRoute = agendaSubRoutes.addAvailability;
  const searchParameters = new URLSearchParams(location.search);
  const selectedView = searchParameters.get('view') as CalendarViews;
  const urlStartDate = searchParameters.get('start');

  useEffect(() => {
    urlDateIsValid(true);
  }, [urlDate]);

  useEffect(() => {
    if (!selectedView || !Object.values<string>(CalendarViews).includes(selectedView)) {
      logger.log(LOG_ERROR, `invalid view set: '${selectedView}'. changing to default view: '${CalendarViews.MONTH}'`);
      changeRoute({ view: CalendarViews.MONTH });
    }
  }, [selectedView]);

  // changes the calendar selected date if the url date paremeter has changed
  useEffect(() => {
    if (!urlDateIsValid()) return;

    const selectedDate = new Date(urlDate);
    selectedDate.setHours(0, 0, 0, 0);
    const calendarDate = calendarRef.current?.getApi().getDate();

    // clears selected event (and closes popup) on calendar month/year change
    // if the view changed after selecting a date
    if (!monthsAreEqual(selectedDate, calendarDate) || !yearsAreEqual(selectedDate, calendarDate)) {
      //setShowEventPopup(false);
    }

    if (!dateIsValid(urlStartDate)) calendarRef.current?.getApi().gotoDate(selectedDate);

    calendarRef.current?.getApi().select(selectedDate);
    dayCalendarRef.current?.getApi().gotoDate(selectedDate);
  }, [urlDate, calendarRef.current, dayCalendarRef.current]);

  useEffect(() => {
    if (!calendarRef.current) return;

    const selectedDate = new Date(urlDate);
    selectedDate.setHours(0, 0, 0, 0);

    if (dateIsValid(urlStartDate)) {
      calendarRef.current.getApi().gotoDate(new Date(urlStartDate!));
      calendarRef.current.getApi().select(selectedDate);
    }

    const startDate = new Date(calendarRef.current.getApi().view.activeStart);
    const endDate = new Date(calendarRef.current.getApi().view.activeEnd);
    endDate.setDate(endDate.getDate() + 1);

    // prevents a new request if the view range is still the same (even though the url date changed)
    if (datesAreEqual(range?.start, startDate) && datesAreEqual(range?.end, endDate)) return;

    setRange({ start: startDate, end: endDate });
    getEvents(startDate, endDate).then((events: EventDTO[]) => {
      setEvents((prevState: EventDTO[]) => {
        const keepSelectedDayEvents = !dateIsInsideRange(selectedDate, startDate, endDate);
        const selectedDayEvents = keepSelectedDayEvents
          ? prevState.filter((event: EventDTO) => datesAreEqual(event.start, selectedDate))
          : [];

        return [...selectedDayEvents, ...events];
      });
    });
  }, [calendarRef.current, selectedView, urlStartDate]);

  const urlDateIsValid = (change: boolean = false): boolean => {
    if (!urlDate) return false;

    const isUrlDateValid = dateIsValid(urlDate);

    if (!isUrlDateValid) {
      if (change) {
        logger.log(LOG_ERROR, `invalid date parameter '${urlDate}'. changing to today`);
        changeRoute({ date: new Date() });
      }

      return false;
    }

    return true;
  };

  const changeRoute = ({ pathname, date, view, start }: ChangeRouteParams): void => {
    const paremeters = new URLSearchParams(location.search);
    if (view) paremeters.set('view', view);
    if (start) paremeters.set('start', formatDate(start, GlobalDateMasks.urlDate));

    const path = pathname
      ? pathname
      : matchPath(location.pathname, { path: appointmentsSlotsRoute.path, exact: true })
      ? appointmentsSlotsRoute.path
      : matchPath(location.pathname, { path: addAvailabilityRoute.path, exact: true })
      ? addAvailabilityRoute.path
      : '';

    history.replace({
      pathname: generatePath(path, {
        date: formatDate(date ? date : new Date(urlDate), GlobalDateMasks.urlDate),
      }),
      search: paremeters.toString(),
    });
  };

  const removeEvent = (eventId: string): void => {
    setEvents((prevState: EventDTO[]) => prevState.filter((event: EventDTO) => event.id !== eventId));
  };

  const changeViewHandler = (view: CalendarViews): void => {
    changeRoute({ view });
  };

  const changeActiveViewHandler = (increment: 'prev' | 'next'): void => {
    if (!calendarRef.current) return;

    const date = new Date(urlStartDate || urlDate);
    const inc: number = increment === 'prev' ? -1 : increment === 'next' ? 1 : 0;

    if (selectedView === CalendarViews.MONTH) date.setMonth(date.getMonth() + inc);
    else if (selectedView === CalendarViews.WEEK) date.setDate(date.getDate() + inc * 7);

    changeRoute({ start: date });
  };

  const selectDayHandler = (selectedDate: Date): void => {
    logger.log(LOG_COMPONENT, `changing calendar selected date to ${selectedDate.toISOString()}`);
    changeRoute({ date: selectedDate, start: selectedDate });
  };

  // selects a day to show its events
  const selectDayNumberHandler = (selectedDate: Date): void => {
    if (!(datesAreEqual(selectedDate, new Date(urlDate)) && match.isExact)) {
      logger.log(LOG_COMPONENT, `selecting day ${selectedDate.toISOString()} and showing day events panel`);
      changeRoute({ pathname: appointmentsSlotsRoute.path, date: selectedDate, start: selectedDate });
    }
  };

  // selects a day to show the Add Availability form starting on that day
  const selectDayCellHandler = (dateClicked: DateClickArg): void => {
    if (dateIsPast(dateClicked.date)) {
      logger.log(LOG_WARN, 'tried to open add availability panel from a past day');
      return;
    }

    if (datesAreEqual(dateClicked.date, new Date(urlDate)) && !match.isExact) {
      logger.log(LOG_COMPONENT, 'hiding add availability panel');
      changeRoute({ pathname: appointmentsSlotsRoute.path, start: dateClicked.date });
    } else {
      logger.log(LOG_COMPONENT, `selecting day ${dateClicked.date.toISOString()} and showing add availability panel`);
      changeRoute({ pathname: addAvailabilityRoute.path, date: dateClicked.date, start: dateClicked.date });
    }
  };

  // adds the new events resulted from the Add Availability form success
  const addAvailableEventsHandler = (newEvents: EventDTO[]): void => {
    logger.log(LOG_COMPONENT, 'adding new events');
    setEvents((prevState: EventDTO[]) => [...prevState, ...newEvents]);
  };

  const closeAvailabilityPanelHandler = (): void => {
    logger.log(LOG_COMPONENT, 'closing add availability panel');
    changeRoute({ pathname: appointmentsSlotsRoute.path });
  };

  const seePatientDetailsHandler = (patientId: string) => {
    history.push(
      generatePath((homeSubRoutes.patients.subRoutes! as IPatientsSubRoutes).patientDetails.path, {
        id: patientId,
      })
    );
  };

  const goToEditAvailabilityHandler = (eventId: string): void => {
    changeRoute({
      pathname: generatePath((homeSubRoutes.agenda.subRoutes! as IAgendaSubRoutes).editAvailability.path, {
        date: urlDate,
        eventId,
      }),
    });
  };

  const goToRescheduleAppointmentHandler = (appointmentId: string): void => {
    history.push(generatePath(routes.reschedule.path, { appointmentId }));
  };

  const deleteEventHandler = (eventId: string, appointmentId?: string): void => {
    if (appointmentId) {
      logger.log(LOG_COMPONENT, 'redirecting user to cancel appointment page');
      history.push(generatePath(routes.cancelAppointment.path, { appointmentId }));
    } else {
      logger.log(LOG_COMPONENT, 'deleting available event');

      deleteAvailableEvent(eventId).then(() => {
        removeEvent(eventId);
        closeAvailabilityPanelHandler();
      });
    }
  };

  const isAddAvailabilityPanelOpen = (): boolean => {
    return !!matchPath(location.pathname, { path: addAvailabilityRoute.path, exact: addAvailabilityRoute.exact });
  };

  const value: IAgendaContext = {
    selectedDate: !isNaN(Date.parse(urlDate)) ? new Date(urlDate) : undefined,
    selectedView,
    events,
    monthEvents: selectedView === CalendarViews.MONTH ? getMonthEvents(language, events) : [],
    weekEvents:
      selectedView === CalendarViews.WEEK
        ? events.map((event: EventDTO) => eventMapper.toCalendarEvent(event, language, CalendarViews.WEEK))
        : [],
    dayEvents: !isAddAvailabilityPanelOpen()
      ? events
          .filter((event: EventDTO) => datesAreEqual(event.start, new Date(urlDate)))
          .map((event: EventDTO) => ({ ...event, durationTime: event.duration }))
      : [],
    calendarRef,
    dayCalendarRef,
    removeEvent,
    changeViewHandler,
    changeActiveViewHandler,
    selectDayHandler,
    selectDayNumberHandler,
    selectDayCellHandler,
    addAvailableEventsHandler,
    closeAvailabilityPanelHandler,
    seePatientDetailsHandler,
    goToEditAvailabilityHandler,
    goToRescheduleAppointmentHandler,
    deleteEventHandler,
  };

  return <AgendaContext.Provider value={value}>{children}</AgendaContext.Provider>;
};

const getMonthEvents = (language: ILanguage, events: EventDTO[]): ICalendarEvent[] => {
  const newCalendarEvents: ICalendarEvent[] = [];

  events.forEach((event: EventDTO) => {
    const day = new Date(event.start);
    day.setHours(0, 0, 0, 0);
    const place = event.appointment ? event.appointment.place : AppointmentEventTypes.Available;

    const calendarEventIndex = newCalendarEvents.findIndex(
      (calendarEvent: ICalendarEvent) =>
        datesAreEqual(calendarEvent.date, day) && calendarEvent.extendedProps.place === place
    );

    if (calendarEventIndex >= 0) {
      newCalendarEvents[calendarEventIndex].extendedProps.events.push(event);
    } else {
      const calendarEvent = eventMapper.toCalendarEvent(event, language, CalendarViews.MONTH);
      calendarEvent.date = day;
      newCalendarEvents.push(calendarEvent);
    }
  });

  // sorts events inside each calendar event
  newCalendarEvents.forEach((calendarEvent: ICalendarEvent) => {
    calendarEvent.extendedProps.events.sort(
      (event1: EventDTO, event2: EventDTO) => event1.start.getTime() - event2.start.getTime()
    );
  });

  // sorts events by type importance
  newCalendarEvents.sort(
    (event1: ICalendarEvent, event2: ICalendarEvent) =>
      getEventTypeImportance(event1.extendedProps.place) - getEventTypeImportance(event2.extendedProps.place)
  );

  return newCalendarEvents;
};

export default AgendaContext;
