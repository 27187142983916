import useTodaysAgenda from './TodaysAgenda.logic';
import { Route } from 'react-router-dom';
import TodaysEvents from './TodaysEvents';
import AppointmentDetail from './AppointmentDetail/AppointmentDetail';
import NoTodayAppointments from './NoTodayAppointments/NoTodayAppointments';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import { TodaysEventsSideContainer } from './TodaysAgenda.styles';
import NoTodayEvents from './NoTodayEvents';
import { PropsWithChildren, FC } from 'react';

export interface ITodaysAgendaProps extends PropsWithChildren<{}> {}

const TodaysAgenda: FC<ITodaysAgendaProps> = (props: ITodaysAgendaProps): JSX.Element => {
  const {} = props;
  const { routes, isLoading, events, selectedEvent, isMobileScreen } = useTodaysAgenda();

  if (!events) return <></>;

  return (
    <>
      {events.length === 0 && !isLoading && <NoTodayEvents />}
      {events.length > 0 && (
        <>
          <Route path={routes.appointments.path} exact={isMobileScreen || routes.appointments.exact}>
            <TodaysEvents events={events} />
          </Route>
          <Route path={routes.appointmentDetail.path} exact={routes.appointmentDetail.exact}>
            {selectedEvent && selectedEvent.appointment && (
              <TodaysEventsSideContainer>
                <AppointmentDetail appointmentEvent={selectedEvent} />
              </TodaysEventsSideContainer>
            )}
          </Route>
          {selectedEvent && !selectedEvent.appointment && (
            <MediaScreenDesktop>
              <TodaysEventsSideContainer as={NoTodayAppointments} />
            </MediaScreenDesktop>
          )}
        </>
      )}
    </>
  );
};

export default TodaysAgenda;
