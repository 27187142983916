import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import useAppointmentsService from 'services/appointments.service';
import { IAppointmentDetailProps } from './AppointmentDetail';

export interface IUseAppointmentDetail {
  language: ILanguage;
  calleeId: string;
  shrinkHeader: boolean;
  showChat: boolean;
  headerRef: React.RefObject<HTMLDivElement>;
  scrollViewRef: React.RefObject<HTMLDivElement>;
  startAppointmentHandler: () => void;
  rescheduleAppointmentHandler: () => void;
  cancelAppointmentHandler: () => void;
  toggleShowChatHandler: () => void;
}

const useAppointmentDetail = (props: IAppointmentDetailProps): IUseAppointmentDetail => {
  const { appointmentEvent } = props;
  const history = useHistory();
  const { startAppointment } = useAppointmentsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [shrinkHeader, setShrinkHeader] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const scrollViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      const scroll = scrollViewRef.current?.scrollTop;

      if (scroll !== undefined) {
        // gets the height of the detail header to shrink only if the scroll is greater then it
        const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;

        if (scroll <= headerHeight) setShrinkHeader(false);
        else if (scroll > headerHeight) setShrinkHeader(true);
      }
    };

    scrollViewRef.current?.addEventListener('scroll', scrollHandler);

    return () => scrollViewRef.current?.removeEventListener('scroll', scrollHandler);
  }, []);

  const startAppointmentHandler = (): void => {
    startAppointment(appointmentEvent);
  };

  const rescheduleAppointmentHandler = (): void => {
    history.push(generatePath(routes.reschedule.path, { appointmentId: appointmentEvent.appointment?.id }));
  };

  const cancelAppointmentHandler = (): void => {
    history.push(generatePath(routes.cancelAppointment.path, { appointmentId: appointmentEvent.appointment?.id }));
  };

  const toggleShowChatHandler = (): void => {
    setShowChat((prevState: boolean) => !prevState);
  };

  return {
    language,
    calleeId: appointmentEvent.appointment!.userId,
    shrinkHeader,
    showChat,
    headerRef,
    scrollViewRef,
    startAppointmentHandler,
    rescheduleAppointmentHandler,
    cancelAppointmentHandler,
    toggleShowChatHandler,
  };
};

export default useAppointmentDetail;
