import LoadMore from 'components/View/LoadMore';
import NotificationDTO from 'dtos/NotificationDTO';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren, useContext } from 'react';
import { useSelector } from 'react-redux';
import NotificationsContext from 'store/notifications-context';
import Notification from '../Notifications/Notification';
import {
  HeaderNotificationsPopupListContainer,
  HeaderNotificationsPopupMessage,
  HeaderNotificationsPopupWrapper,
} from './HeaderNotificationsPopup.styles';

export interface IHeaderNotificationsPopup extends PropsWithChildren<{}> {
  show: boolean;
  onClose: () => void;
}

const HeaderNotificationsPopup: FC<IHeaderNotificationsPopup> = (props: IHeaderNotificationsPopup): JSX.Element => {
  const { show, onClose } = props;
  const { notifications, canLoadMore, showError, loadMoreNotifications } = useContext(NotificationsContext);
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <HeaderNotificationsPopupWrapper dataTestId='notificationsPopup-popup' top='115%' show={show} onClose={onClose}>
      {notifications.length > 0 && (
        <HeaderNotificationsPopupListContainer>
          {notifications.map((notification: NotificationDTO, index: number) => (
            <Notification
              key={notification.id}
              dataTestId={`notificationsPopup-notification${index}`}
              notification={notification}
            />
          ))}
          <LoadMore
            canLoadMore={canLoadMore}
            allLoadedText={language.youHaveNoMoreNotifications}
            onLoadMore={loadMoreNotifications}
          />
        </HeaderNotificationsPopupListContainer>
      )}
      {!showError && notifications.length === 0 && (
        <HeaderNotificationsPopupMessage>{language.noRecentNotifications}</HeaderNotificationsPopupMessage>
      )}
      {showError && notifications.length === 0 && (
        <HeaderNotificationsPopupMessage>{language.errorGettingNotifications}</HeaderNotificationsPopupMessage>
      )}
    </HeaderNotificationsPopupWrapper>
  );
};

export default HeaderNotificationsPopup;
