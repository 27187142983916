import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import sass from 'styles/exports.module.scss';

enum Scroll {
  TOP = 'no-top',
  CENTER = '',
  BOTTOM = 'no-bottom',
  NONE = 'no-scroll',
}

export type UseScrollView = {
  scroll: Scroll;
  showButton: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  scrollToTopHandler: () => void;
};

const useScrollView = (ref: React.ForwardedRef<HTMLDivElement | null>): UseScrollView => {
  const [scroll, setScroll] = useState<Scroll>(Scroll.NONE);
  const [showButton, setShowButton] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => containerRef.current!);

  useEffect(() => {
    if (!containerRef.current) return;

    if (containerRef.current.scrollHeight > containerRef.current.clientHeight)
      setScroll(containerRef.current.scrollTop ? Scroll.CENTER : Scroll.TOP);

    const scrollHandler = () => {
      const scrollTop = containerRef.current!.scrollTop;
      const minScroll = parseInt(sass.scrollGradientheight);
      const maxScroll =
        containerRef.current!.scrollHeight - containerRef.current!.offsetHeight - parseInt(sass.scrollGradientheight);

      if (scrollTop <= minScroll) setScroll(Scroll.TOP);
      else if (scrollTop >= maxScroll) setScroll(Scroll.BOTTOM);
      else setScroll(Scroll.CENTER);

      setShowButton(!!containerRef.current && containerRef.current.scrollTop > 0);
    };

    containerRef.current.addEventListener('scroll', scrollHandler);

    return () => containerRef.current?.removeEventListener('scroll', scrollHandler);
  }, [containerRef.current]);

  const scrollToTopHandler = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return { scroll, showButton, containerRef, scrollToTopHandler };
};

export default useScrollView;
