import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import EntityDTO from 'dtos/EntityDTO';
import { IEntitySlice } from 'interfaces/ISlices';

const entitySlice = createSlice({
  name: 'auth',
  initialState: null as IEntitySlice | null,
  reducers: {
    setEntity: (_: any, action: PayloadAction<Omit<EntityDTO, 'type'>>): IEntitySlice => {
      return { ...action.payload };
    },
    clear: (_: any): null => {
      return null;
    },
  },
});

export const entityActions = entitySlice.actions;

export default entitySlice;
