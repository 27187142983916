import { DateInput, MultiOption, NumberInput, TextInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { FC, PropsWithChildren } from 'react';
import { initialPatientMandatoryOnboardingFormInputs } from 'services/entities.service';
import { getMinAgeDate } from 'utils/date';
import { getGenderOptions } from 'utils/user';
import { OnboardingForm, OnboardingParagraph, OnboardingSubmitButton } from '../Onboarding.styles';
import usePatientMandatoryOnboarding from './PatientMandatoryOnboarding.logic';

const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 120);

export interface IPatientMandatoryOnboardingProps extends PropsWithChildren<{}> {}

const PatientMandatoryOnboarding: FC<IPatientMandatoryOnboardingProps> = (
  props: IPatientMandatoryOnboardingProps
): JSX.Element => {
  const {} = props;
  const { language, isLoading, loadHandler, submitHandler } = usePatientMandatoryOnboarding();

  return (
    <OnboardingForm
      initialValues={initialPatientMandatoryOnboardingFormInputs}
      onLoad={loadHandler}
      onSubmit={submitHandler}>
      <h1>{language.personalData}</h1>
      <OnboardingParagraph>{language.thisDataWillBeVisibleToYourMedic}</OnboardingParagraph>
      <TextInput name='name' icon='profile' placeholder={language.name} />
      <DateInput name='birthDate' icon='birthDate' min={minDate} max={getMinAgeDate()} />
      <MultiOption style={{ marginTop: '1.375rem' }} name='gender' icon='gender' options={getGenderOptions(language)} />
      <NumberInput name='tin' icon='contacts' nrDigits={9} placeholder={language.tin} />
      <OnboardingParagraph textAlign='left'>{language.tinWillHelpnBillingService}</OnboardingParagraph>
      <OnboardingSubmitButton buttonType='submit' label={language.next} loading={isLoading} />
    </OnboardingForm>
  );
};

export default PatientMandatoryOnboarding;
