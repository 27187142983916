import { ILoginSubRoutes } from 'interfaces/IRoute';
import { ILoginRouteState } from 'interfaces/IRouteStates';
import { useHistory } from 'react-router-dom';
import routes from 'routes/routes';

export type UseLoginAccounts = {
  accounts: any[];
  selectAccountHandler: (email: string | undefined) => void;
};

const useLoginAccounts = (): UseLoginAccounts => {
  const history = useHistory();
  const accounts = [
    { name: 'Dra. Rita Oliveira', email: 'r******@gmail.com' },
    { name: 'Rita Oliveira', email: 'r******@gmail.com' },
    { name: undefined, email: undefined },
  ];

  const selectAccountHandler = (email: string | undefined): void => {
    history.push((routes.login.subRoutes! as ILoginSubRoutes).login.path, { email } as ILoginRouteState);
  };

  return { accounts, selectAccountHandler };
};

export default useLoginAccounts;
