import IRoute from 'interfaces/IRoute';
import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export type UseRouterShow = boolean;

const useRouterShow = (routes: IRoute[]): UseRouterShow => {
  const location = useLocation();

  const show = useCallback((): boolean => {
    for (let i = 0; i < routes.length; i++) {
      const route = matchPath(location.pathname, { path: routes[i].path, exact: routes[i].exact });
      if (route) return false;
    }

    return true;
  }, [location, routes]);

  return show();
};

export default useRouterShow;
