import { rgba, SmallText, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IPasswordResetSentIllustrationProps {}

export const PasswordResetSentIllustration = styled.img<IPasswordResetSentIllustrationProps>`
  display: block;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  margin: 2rem auto 0 auto;
  background-color: ${rgba(styles.colors.brand.main, 0.05)};
`;

export interface IPasswordResetSentMessageProps {}

export const PasswordResetSentMessage = styled(SmallText)<IPasswordResetSentMessageProps>`
  width: 80% !important;
  margin: 1.25rem auto 0 auto;
  text-align: center;
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .container {
    > p {
      width: 60% !important;
    }
  }
}
*/
