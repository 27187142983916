import { rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IStartOptionalOnboardingSubtitleProps {}

export const StartOptionalOnboardingSubtitle = styled.h5<IStartOptionalOnboardingSubtitleProps>`
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
`;

export interface IStartOptionalOnboardingIllustrationProps {}

export const StartOptionalOnboardingIllustration = styled.img<IStartOptionalOnboardingIllustrationProps>`
  width: 12.5rem !important;
  height: 12.5rem;
  border-radius: 999px;
  margin: 1.5rem auto 0 auto;
  background-color: ${rgba(styles.colors.brand.main, 0.1)};
`;
