import { useEffect, useContext, useState } from 'react';
import useStateRef from 'react-usestateref';
import SocketContext, { SocketEmitterEvents, SocketListenerEvents } from 'store/socket-context';
import { LOG_SOCKET, LOG_WARN } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export interface UseSocket {
  listenSocketMessage: (event: SocketListenerEvents, listener: (...args: any[]) => void) => void;
  emitSocketMessage: (
    event: SocketEmitterEvents,
    body?: any,
    callback?: ((...args: any[]) => void) | undefined
  ) => void;
}

type EventsToStartListen = {
  event: SocketListenerEvents;
  listener: (...args: any[]) => void;
};

const useSocket = (): UseSocket => {
  const logger = useWinstonLogger();
  const { socket, connected } = useContext(SocketContext);
  const [eventsToStartListen, setEventsToStartListen] = useState<EventsToStartListen[]>([]);
  const [_, setEventsToListen, eventsToListenRef] = useStateRef<SocketListenerEvents[]>([]);

  useEffect(() => {
    return () => {
      eventsToListenRef.current.forEach((event: SocketListenerEvents) => {
        clearSocketListener(event);
      });
    };
  }, []);

  useEffect(() => {
    if (!connected) return;

    eventsToStartListen.forEach((eventToStart: EventsToStartListen) => {
      const { event, listener } = eventToStart;
      logger.log(LOG_SOCKET, `adding socket listener '${event}' defined before socket connected`);
      socket?.on(event, listener);
    });

    setEventsToStartListen([]);
  }, [connected]);

  const listenSocketMessage = (event: SocketListenerEvents, listener: (...args: any[]) => void): void => {
    if (!socket) {
      logger.log(LOG_WARN, `trying to listen to event '${event}' while socket does not exist`);
      setEventsToStartListen((prevState: EventsToStartListen[]) => [...prevState, { event, listener }]);
      return;
    }

    if (eventsToListenRef.current.includes(event)) clearSocketListener(event);
    else setEventsToListen((prevState: SocketListenerEvents[]) => [...prevState, event]);

    logger.log(LOG_SOCKET, `listening for '${event}'`);
    socket.on(event, listener);
  };

  const emitSocketMessage = (event: SocketEmitterEvents, body?: any, callback?: (...args: any[]) => void): void => {
    if (!socket) {
      logger.log(LOG_WARN, `trying to emit event '${event}' while socket does not exist`);
      return;
    }

    if (body && callback) socket.emit(event, body, callback);
    else if (body) socket.emit(event, body);
    else if (callback) socket.emit(event, callback);
    else socket.emit(event);
  };

  const clearSocketListener = (event: SocketListenerEvents): void => {
    logger.log(LOG_SOCKET, `clearing socket listener '${event}'`);
    socket?.off(event);
  };

  return { listenSocketMessage, emitSocketMessage };
};

export default useSocket;
