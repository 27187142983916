import { SelectProps } from 'components/Form/Select';
import useClickOutside from 'hooks/use-clickOutside';
import IOption from 'interfaces/IOption';
import { useEffect, useRef, useState } from 'react';

export type UseSelect = {
  isOpen: boolean;
  selected: IOption | undefined;
  containerRef: React.RefObject<HTMLDivElement>;
  selectRef: React.RefObject<HTMLSelectElement>;
  toggleOpenOptionsHandler: () => void;
  selectOptionHandler: (option: IOption) => void;
};

const useSelect = (props: SelectProps): UseSelect => {
  const { defaultOption, option, disabled, onChange } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<IOption | undefined>(defaultOption);
  const containerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (option !== undefined) {
      setSelected(option);
      selectRef.current!.value = option.id + '';
    }
  }, [option]);

  useClickOutside(containerRef, setIsOpen);

  const toggleOpenOptionsHandler = (): void => {
    if (!disabled) setIsOpen((prevState) => !prevState);
  };

  const selectOptionHandler = (option: IOption): void => {
    selectRef.current!.value = option.id + '';

    if (onChange !== undefined) onChange(option);
    else setSelected(option);

    setIsOpen((prevState) => !prevState);
  };

  return { isOpen, selected, containerRef, selectRef, toggleOpenOptionsHandler, selectOptionHandler };
};

export default useSelect;
