import { ScrollView, IProps } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import GalleryAndFiles from 'components/Chat/GalleryAndFiles/GalleryAndFiles';
import PatientData from 'components/PatientData/PatientData';
import PreviousNotes from 'components/PreviousNotes/PreviousNotes';
import IReduxState from 'interfaces/IReduxState';
import { IHomeSubRoutes, ITodaySubRoutes, IAppointmentDetailSubRoutes } from 'interfaces/IRoute';
import ITab from 'interfaces/ITab';
import { FC, PropsWithChildren, RefObject, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect, generatePath } from 'react-router-dom';
import routes from 'routes/routes';
import { PatientInfoTabs } from './PatientInfo.styles';

export interface IPatientInfoProps extends PropsWithChildren<IProps> {
  patientId: string;
  scrollViewRef?: RefObject<HTMLDivElement>;
}

const PatientInfo: FC<IPatientInfoProps> = (props: IPatientInfoProps): JSX.Element => {
  const { className, patientId, scrollViewRef } = props;
  const match = useRouteMatch<{ id: string; tab?: string }>();
  const language = useSelector((state: IReduxState) => state.language.values);
  const userDetailsSubRoutes = ((routes.home.subRoutes! as IHomeSubRoutes).today.subRoutes as ITodaySubRoutes)
    .appointmentDetail.subRoutes as IAppointmentDetailSubRoutes;

  const tabs = useCallback((): ITab[] => {
    return [
      { id: 'todaysAgenda-patientDataTab', route: userDetailsSubRoutes.patientData, name: language.patientData },
      { id: 'todaysAgenda-previousNotesTab', route: userDetailsSubRoutes.notes, name: language.notes },
      {
        id: 'todaysAgenda-galleryAndFilesTab',
        route: userDetailsSubRoutes.galleryAndFiles,
        name: language.galleryAndFiles,
      },
    ];
  }, []);

  const getRoutePath = useCallback(
    (subRouteKey: keyof typeof userDetailsSubRoutes): string => {
      return generatePath(match.path, { id: match.params.id, tab: userDetailsSubRoutes[subRouteKey].path });
    },
    [match.params.id]
  );

  return (
    <>
      {!match.params.tab && <Redirect to={getRoutePath('patientData')} />}
      <PatientInfoTabs className={className} tabs={tabs()}>
        <ScrollView showButtonScrollTop={true} ref={scrollViewRef}>
          <Switch>
            <Route path={getRoutePath('patientData')} exact={userDetailsSubRoutes.patientData.exact}>
              <PatientData patientId={patientId} />
            </Route>
            <Route path={getRoutePath('notes')} exact={userDetailsSubRoutes.notes.exact}>
              <PreviousNotes calleeId={patientId} />
            </Route>
            <Route path={getRoutePath('galleryAndFiles')} exact={userDetailsSubRoutes.galleryAndFiles.exact}>
              <GalleryAndFiles calleeId={patientId} />
            </Route>
          </Switch>
        </ScrollView>
      </PatientInfoTabs>
    </>
  );
};

export default PatientInfo;
