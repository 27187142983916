import { Tag, SmallText, Flex, Popup, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IAppointmentWrapperProps {}

export const AppointmentWrapper = styled(Flex)<IAppointmentWrapperProps>`
  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${rgba(styles.colors.brand.dark, 0.15)};
    margin-bottom: 1rem;
  }
`;

export interface IAppointmentTitleProps {}

export const AppointmentTitle = styled(SmallText)<IAppointmentTitleProps>`
  display: block;
  margin-bottom: 0.5rem;
`;

export interface IAppointmentMotiveTagProps {}

export const AppointmentMotiveTag = styled(Tag)<IAppointmentMotiveTagProps>`
  > span {
    &:last-child {
      font-weight: 600;
    }
  }
`;

export interface IAppointmentButtonsPopupProps {}

export const AppointmentButtonsPopup = styled(Popup)<IAppointmentButtonsPopupProps>`
  align-items: flex-start;
  right: 0;
  margin-left: 1.25rem;
`;
