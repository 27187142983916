import EventDTO from 'dtos/EventDTO';
import { IDayGroup } from 'interfaces/IGroup';
import { FC, Fragment, PropsWithChildren } from 'react';
import useAppointments from './PatientDetailsAppointments.logic';
import {
  AppointmentsListContainer,
  AppointmentsYear,
  DayAppointmentsWrapper,
  PatientDetailsAppointmentsWrapper,
} from './PatientDetailsAppointments.styles';
import LoadMore from 'components/View/LoadMore';
import Appointment from './Appointment';
import { SmallText } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface IPatientDetailsAppointmentsProps extends PropsWithChildren<{}> {}

const PatientDetailsAppointments: FC<IPatientDetailsAppointmentsProps> = (
  props: IPatientDetailsAppointmentsProps
): JSX.Element => {
  const {} = props;
  const {
    language,
    nextAppointments,
    pastAppointments,
    hasMorePastAppointments,
    formatDate,
    loadMorePastAppointmentsHandler,
  } = useAppointments();
  const lists = [
    { id: '1', title: language.nextAppointments, appointments: nextAppointments, arePast: false, showLoadMore: false },
    { id: '2', title: language.pastAppointments, appointments: pastAppointments, arePast: true, showLoadMore: true },
  ];

  return (
    <PatientDetailsAppointmentsWrapper direction='column'>
      {lists.map(({ id, title, appointments, arePast, showLoadMore }: any) => (
        <Fragment key={id}>
          {appointments && !!appointments.length && (
            <AppointmentsListContainer arePast={arePast}>
              <h5>{title}</h5>
              {appointments.map(
                ({ date, list: groupedAppointmentEvents }: IDayGroup<IDayGroup<EventDTO>>, index: number) => (
                  <div key={index}>
                    <AppointmentsYear>{date.getFullYear()}</AppointmentsYear>
                    {groupedAppointmentEvents.map(
                      ({ date: day, list: appointmentEvents }: IDayGroup<EventDTO>, index: number) => (
                        <DayAppointmentsWrapper key={index} hasPassed={arePast}>
                          <div>
                            <h2 style={{ textAlign: 'center' }}>{formatDate(day, 'dd')}</h2>
                            <SmallText as='b' style={{ textAlign: 'center' }}>
                              {formatDate(day, 'mmm')}
                            </SmallText>
                          </div>
                          <div>
                            {appointmentEvents.map((appointmentEvent: EventDTO) => (
                              <Appointment key={appointmentEvent.id} appointmentEvent={appointmentEvent} />
                            ))}
                          </div>
                        </DayAppointmentsWrapper>
                      )
                    )}
                  </div>
                )
              )}
              {showLoadMore && (
                <LoadMore canLoadMore={hasMorePastAppointments} onLoadMore={loadMorePastAppointmentsHandler} />
              )}
            </AppointmentsListContainer>
          )}
        </Fragment>
      ))}
    </PatientDetailsAppointmentsWrapper>
  );
};

export default PatientDetailsAppointments;
