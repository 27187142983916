import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import ITab from 'interfaces/ITab';
import ILanguage from 'interfaces/ILanguage';
import { iconsTemp } from 'iconsTemp';
import { useCallback, useEffect, useState } from 'react';
import routes from 'routes/routes';
import IRoute, { IHomeSubRoutes, IMedicsSubRoutes, IPatientsSubRoutes, ITodaySubRoutes } from 'interfaces/IRoute';
import useRouterShow from 'hooks/use-routerShow';
import homeSubRoutes from 'routes/homeSubRoutes';
import { useLocation } from 'react-router-dom';
import useMediaScreen from 'hooks/use-mediaScreen';
import { Gender } from 'dtos/EntityDTO';
import { userIsMedic, userIsPatient } from 'utils/user';

export type UseDoctorIndex = {
  language: ILanguage;
  showGoodWorkMessage: boolean;
  homeRoutes: IHomeSubRoutes;
  patientsSubRoutes: IPatientsSubRoutes;
  medicsSubRoutes: IMedicsSubRoutes;
  tabs: ITab[];
  isSmartphoneScreen: boolean;
  hideMenu: boolean;
  isMedic: boolean;
  isPatient: boolean;
  userName: string | null;
  userGender: Gender | null;
};

const useIndex = (): UseDoctorIndex => {
  const location = useLocation();
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const userName = useSelector((state: IReduxState) => state.entity!.name);
  const userGender = useSelector((state: IReduxState) => state.entity!.gender);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [showGoodWorkMessage, setShowGoodWorkMessage] = useState<boolean>(location.pathname === routes.home.path);
  const { isMobileScreen, isSmartphoneScreen } = useMediaScreen();
  const homeRoutes = routes.home.subRoutes as IHomeSubRoutes;
  const isMedic = userIsMedic(userType);
  const isPatient = userIsPatient(userType);

  const tabs = useCallback((): ITab[] => {
    const tabs: ITab[] = [];

    if (isMedic) {
      tabs.push(
        {
          id: 'index-todaysAgendaTab',
          route: homeRoutes.today,
          name: isMobileScreen ? language.today : language.todaysAgenda,
          icon: iconsTemp.home,
        },
        {
          id: 'index-agendaTab',
          route: homeRoutes.agenda,
          name: isMobileScreen ? language.agenda : language.myAppointments,
          icon: iconsTemp.clinicalFile,
        },
        {
          id: 'index-patientsTab',
          route: homeRoutes.patients,
          name: isMobileScreen ? language.patients : language.myPatients,
          icon: iconsTemp.user,
        }
      );
    }
    if (isPatient) {
      tabs.push(
        {
          id: 'index-searchMedicsTab',
          route: homeSubRoutes.searchMedics,
          name: language.search,
          icon: iconsTemp.search,
        },
        {
          id: 'index-appointmentsTab',
          route: homeSubRoutes.patientAppointments,
          name: isMobileScreen ? language.agenda : language.myAppointments,
          icon: iconsTemp.clinicalFile,
        },
        {
          id: 'index-medicsTab',
          route: homeRoutes.medics,
          name: isMobileScreen ? language.medics : language.myMedics,
          icon: iconsTemp.medic,
        }
      );
    }

    if (isMobileScreen) {
      tabs.push(
        {
          id: 'index-notificationsTab',
          route: homeRoutes.profile,
          name: language.notifications,
          icon: iconsTemp.notifications,
        },
        {
          id: 'index-menuTab',
          route: homeRoutes.profile,
          name: language.menu,
          icon: iconsTemp.menu,
        }
      );
    } else {
      tabs.push({
        id: 'index-profileTab',
        route: homeRoutes.profile,
        name: language.myProfile,
        icon: iconsTemp.profile,
      });
    }
    return tabs;
  }, [language, isMobileScreen]);

  const routesToNotShow = useCallback((): IRoute[] => {
    const routes: IRoute[] = [];
    if (isMobileScreen) routes.push((homeSubRoutes.today.subRoutes as ITodaySubRoutes).appointmentDetail);
    return routes;
  }, [isMobileScreen]);

  useEffect(() => {
    const timeout = setTimeout(() => setShowGoodWorkMessage(false), 3000);
    return () => clearTimeout(timeout);
  }, []);

  return {
    language,
    showGoodWorkMessage,
    homeRoutes,
    patientsSubRoutes: homeRoutes.patients.subRoutes! as IPatientsSubRoutes,
    medicsSubRoutes: homeRoutes.medics.subRoutes! as IMedicsSubRoutes,
    tabs: tabs(),
    isSmartphoneScreen,
    hideMenu: !useRouterShow(routesToNotShow()),
    isMedic,
    isPatient,
    userName,
    userGender,
  };
};

export default useIndex;
