import {
  Action,
  ActionTypes,
  FormSubmit,
  InputValue,
  IUseStep,
  useStep,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AppointmentDTO from 'dtos/AppointmentDTO';
import MedicDTO from 'dtos/MedicDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { IScheduleAppointmentRouteState } from 'interfaces/IRouteStates';
import { Dispatch, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useAppointmentsService from 'services/appointments.service';
import useMedicsService from 'services/medics.service';
import { goBack } from 'utils/general';
import AppointmentDataStep, { IAppointmentDataStepProps } from './Steps/AppointmentDataStep';
import AvailabilitiesStep, { IAvailabilitiesStepProps } from './Steps/AvailabilitiesStep';

export interface IUseScheduleAppointment extends Omit<IUseStep, 'isFistStep' | 'isFinalStep' | 'goToStep'> {
  language: ILanguage;
  medic?: MedicDTO;
  paymentLink?: string;
  isLoading: boolean;
  stepsElements: (FC<IAppointmentDataStepProps> | FC<IAvailabilitiesStepProps>)[];
  stepsNames: string[];
  isFistStep: boolean;
  goBackHandler: () => void;
  loadFormHandler: (dispatchInputs: Dispatch<Action>) => void;
  submitScheduleHandler: (submit: FormSubmit) => void;
}

const useScheduleAppointment = (): IUseScheduleAppointment => {
  const history = useHistory();
  const location = useLocation<IScheduleAppointmentRouteState>();
  const { medicId } = useParams<{ medicId: string }>();
  const { getMedic } = useMedicsService();
  const { isLoading, createAppointment, startPayingAppointment } = useAppointmentsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [medic, setMedic] = useState<MedicDTO>();
  const [paymentLink, setPaymentLink] = useState<string>();
  const stepsElements = [AppointmentDataStep, AvailabilitiesStep];
  const stepsNames = [language.appointmentData, language.scheduling, language.payment];
  const { step, isFistStep, nextStepHandler, previousStepHandler } = useStep(stepsElements.length + 1, 0);

  useEffect(() => {
    if (medicId) getMedic(medicId).then((medic: MedicDTO) => setMedic(medic));
  }, [medicId]);

  const goBackHandler = (): void => {
    goBack(history);
  };

  const loadFormHandler = (dispatchInputs: Dispatch<Action>): void => {
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'medic', value: medicId });

    if (!location.state) return;

    const { specialty, type, event } = location.state;

    interface IStateValue {
      attribute: string;
      value: InputValue;
    }

    const stateValues: IStateValue[] = [
      { attribute: 'specialty', value: specialty },
      { attribute: 'type', value: type },
      { attribute: 'event', value: event },
    ];

    stateValues.forEach(({ attribute, value }: IStateValue) => {
      if (value) dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute, value });
    });
  };

  const submitScheduleHandler = (submit: FormSubmit): void => {
    createAppointment(submit).then((appointment: AppointmentDTO) => {
      startPayingAppointment(appointment.id).then((paymentLink: string) => {
        setPaymentLink(paymentLink);
        nextStepHandler();
      });
    });
  };

  return {
    language,
    medic,
    paymentLink,
    isLoading,
    stepsElements,
    stepsNames,
    step,
    isFistStep,
    nextStepHandler,
    previousStepHandler,
    goBackHandler,
    loadFormHandler,
    submitScheduleHandler,
  };
};

export default useScheduleAppointment;
