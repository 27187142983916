import { map } from 'utils/general';
import styles from './Volume.module.scss';

export type VolumeProps = {
  value: number;
  min: number;
  max: number;
  length?: number;
};

const Volume: React.FC<VolumeProps> = (props: React.PropsWithChildren<VolumeProps>) => {
  const { value, min, max, length } = props;
  const mappedValue = Math.floor(map(value, min, max, 0, length ? length : 0));

  return (
    <div className={styles.volume}>
      {new Array(length).fill(0).map((_, i) => (
        <div key={i} className={`${i < mappedValue && styles.active}`}></div>
      ))}
    </div>
  );
};

Volume.defaultProps = {
  length: 13,
};

export default Volume;
