export const FILE_TYPES = {
  IMAGES: ['image/png', 'image/jpeg'],
  PDF: ['application/pdf'],
};

export default interface FileDTO {
  id: string;
  filename: string;
  date: Date;
  mimeType: string;
}
