import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth-slice';
import entitySlice from './entity-slice';
import feedbackSlice from './feedback-slice';
import languageSlice from './language-slice';

const store = configureStore({
  reducer: {
    language: languageSlice.reducer,
    feedback: feedbackSlice.reducer,
    auth: authSlice.reducer,
    entity: entitySlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
