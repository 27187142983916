import IRoute, { IOnboardingSubRoutes } from 'interfaces/IRoute';

const onboardingSubRoutes: IOnboardingSubRoutes = {
  mandatory: {
    path: '/onboarding/mandatory',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
  optional: {
    path: '/onboarding/optional',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
};

export default onboardingSubRoutes;
