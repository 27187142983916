import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useDispatch, useSelector } from 'react-redux';
import useSchedulesService from 'services/schedules.service';
import { useContext, useEffect, useState } from 'react';
import AgendaContext from 'store/agenda-context';
import { FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import EventDTO from 'dtos/EventDTO';
import { useParams } from 'react-router-dom';
import { IEditAvailabilityRouteParams } from 'interfaces/IRouteParams';
import { ToastTypes } from 'components/View/Toast';
import { iconsTemp } from 'iconsTemp';
import { feedbackActions } from 'store/redux/feedback-slice';
import useAvailabilitiesService from 'services/availabilities.service';

export interface IUseEditAvailability {
  language: ILanguage;
  selectedEvent?: EventDTO;
  isLoading: boolean;
  closeAvailabilityPanelHandler: () => void;
  submitEditAvailabilityHandler: (submit: FormSubmit) => void;
  deleteAvailableEventHandler: () => void;
}

const useEditAvailability = (): IUseEditAvailability => {
  const dispatch = useDispatch();
  const { eventId } = useParams<IEditAvailabilityRouteParams>();
  const { isLoading, editAvailableEvent } = useAvailabilitiesService();
  const { events, removeEvent, addAvailableEventsHandler, closeAvailabilityPanelHandler, deleteEventHandler } =
    useContext(AgendaContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [selectedEvent, setSelectedEvent] = useState<EventDTO>();

  useEffect(() => {
    const selectedEvent = events.find((event: EventDTO) => event.id === eventId);

    if (!selectedEvent) {
      dispatch(
        feedbackActions.addMessage({
          dataTestId: 'toastError-noEventToEdit',
          type: ToastTypes.ERROR,
          icon: iconsTemp.delete,
          title: language.errorGettingAvailableEvent,
        })
      );

      closeAvailabilityPanelHandler();
    }

    setSelectedEvent(selectedEvent);
  }, [eventId]);

  const submitEditAvailabilityHandler = (submit: FormSubmit): void => {
    editAvailableEvent(submit).then((event: EventDTO) => {
      addAvailableEventsHandler([event]);
      removeEvent(eventId);
      closeAvailabilityPanelHandler();
    });
  };

  const deleteAvailableEventHandler = () => {
    deleteEventHandler(eventId);
  };

  return {
    language,
    selectedEvent,
    isLoading,
    closeAvailabilityPanelHandler,
    submitEditAvailabilityHandler,
    deleteAvailableEventHandler,
  };
};

export default useEditAvailability;
