import routes from 'routes/routes';
import IReduxState from 'interfaces/IReduxState';
import { ProfileType } from 'dtos/EntityDTO';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userIsMedic } from 'utils/user';

export type UseMedicGuard = {
  medicCanSee: (meta: any) => boolean;
  medicCanActivate: (meta: any) => boolean;
};

const useMedicGuard = (): UseMedicGuard => {
  const history = useHistory();
  const type = useSelector((state: IReduxState) => state.auth.type);

  const medicCanSee = (meta: any): boolean => {
    return meta.roles.length === 0 || (userIsMedic(type) && meta.roles.includes(ProfileType.MEDIC));
  };

  const medicCanActivate = (meta: any): boolean => {
    if (meta.roles.length === 0) return true;

    if (userIsMedic(type) && !meta.roles.includes(ProfileType.MEDIC)) {
      history.push(routes.login.path);
      return false;
    }

    return true;
  };

  return { medicCanSee, medicCanActivate };
};

export default useMedicGuard;
