import { MultiOption, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IMedicAppointmentOptionsProps {}

export const MedicAppointmentOptions = styled(MultiOption)<IMedicAppointmentOptionsProps>`
  margin-top: ${styles.inputMargin};

  > div {
    flex: 1;
    justify-content: space-between;

    > * {
      margin-right: 0;
    }
  }
`;
