import { FC, PropsWithChildren } from 'react';
import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import { userIsMedic } from 'utils/user';
import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './VideoPlaceholder.module.scss';
import sass from 'styles/exports.module.scss';

export interface VideoPlaceholderProps {
  show: boolean;
}

const VideoPlaceholder: FC<VideoPlaceholderProps> = (props: PropsWithChildren<VideoPlaceholderProps>) => {
  const { show } = props;
  const location = useLocation();
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const icon = location.pathname.includes(appointmentSubRoutes.waitingRoom.path)
    ? userIsMedic(userType)
      ? iconsTemp.doctor
      : iconsTemp.patient
    : userIsMedic(userType)
    ? iconsTemp.patient
    : iconsTemp.doctor;

  return (
    <Transition in={show} timeout={+sass.timeout} mountOnEnter unmountOnExit>
      {(state: TransitionStatus) => (
        <div className={`${styles['video-placeholder']} ${styles[state]}`}>
          <Icon className={styles.icon} src={icon} />
        </div>
      )}
    </Transition>
  );
};

export default VideoPlaceholder;
