import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import styles from './MedicHighLight.module.scss';

export enum MedicHighLightsType {
  MOST_CONSULTED = 'most-consulted',
  SMARTAL_RECOMENDATION = 'smartal-recomendation',
  HAD_APPOINTMENT = 'had-appointment',
}

export type MedicHighLightProps = {
  type: MedicHighLightsType;
};

const MedicHighLight: React.FC<MedicHighLightProps> = (props: React.PropsWithChildren<MedicHighLightProps>) => {
  const { type } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  let text = '';

  switch (type) {
    case MedicHighLightsType.MOST_CONSULTED:
      text = language.mostConsulted;
      break;
    case MedicHighLightsType.SMARTAL_RECOMENDATION:
      text = `${language.smartalRecomends}!`;
      break;
    case MedicHighLightsType.HAD_APPOINTMENT:
      text = language.alreadyHadAppointment;
      break;
  }

  return (
    <div className={styles['medic-highlight']}>
      <div className={styles[type]}>
        <b>{text}</b>
      </div>
    </div>
  );
};

export default MedicHighLight;
