import winston from 'winston';

export const LOG_COMPONENT = 'component';
export const LOG_SOCKET = 'socket';
export const LOG_RTC = 'rtc';
export const LOG_HTTP = 'http';
export const LOG_WARN = 'warn';
export const LOG_ERROR = 'error';

const levels = {
  component: 0,
  socket: 1,
  rtc: 2,
  http: 3,
  warn: 4,
  error: 5,
};

export const consoleTransport = new winston.transports.Console({
  level: 'error',
  format: winston.format.combine(
    winston.format.colorize(),
    winston.format.cli({
      colors: {
        component: 'cyan',
        socket: 'green',
        rtc: 'blue',
        http: 'blue',
        warn: 'yellow',
        error: 'red',
      },
    }),
    winston.format.timestamp({
      format: 'YYYY-MM-DD HH:mm:ss.SSS',
    }),
    winston.format.printf((info) => {
      let message;
      const index = info.message.indexOf('undefined');
      if (index === 0) message = info.message.replace('undefined', '');
      else message = info.message;
      return `${info.timestamp} ${info.level}: ${message}`;
    })
  ),
  handleExceptions: true,
});

const logger = winston.createLogger({
  levels: levels,
  transports: [consoleTransport],
});

export default logger;
