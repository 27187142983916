import { AppointmentStatus } from 'dtos/AppointmentDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { IBuyAppointmentRouteState } from 'interfaces/IRouteStates';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import useAppointmentsService from 'services/appointments.service';
import { hasAppointmentEnded, canStartAppointment } from 'utils/appointment';
import { PatientAppointmentProps } from './PatientAppointment';

export type UsePatientAppointment = {
  language: ILanguage;
  appointmentIsConfirmed: boolean;
  showPopup: boolean;
  showStartAppointmentButton: boolean;
  appointmentHasPassed: boolean;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
  togglePopupHandler: () => void;
  payAppointmentHandler: () => void;
  startAppointmentHandler: () => void;
  rescheduleAppointmentHandler: () => void;
  cancelAppointmentHandler: () => void;
};

const usePatientAppointment = (props: PatientAppointmentProps): UsePatientAppointment => {
  const { appointmentEvent, onDelete } = props;
  const history = useHistory();
  const { startPayingAppointment, startAppointment, cancelAppointment } = useAppointmentsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const appointmentIsConfirmed =
    appointmentEvent.appointment?.status === AppointmentStatus.Confirmed ||
    appointmentEvent.appointment?.status === AppointmentStatus.Started ||
    appointmentEvent.appointment?.status === AppointmentStatus.Ended;

  const togglePopupHandler = () => {
    setShowPopup((prevState: boolean) => !prevState);
  };

  const payAppointmentHandler = () => {
    startPayingAppointment(appointmentEvent.appointment!.id).then((paymentLink: string) => {
      history.push(generatePath(routes.buyAppointment.path, { appointmentId: appointmentEvent.appointment!.id }), {
        paymentLink,
      } as IBuyAppointmentRouteState);
    });
  };

  const startAppointmentHandler = (): void => {
    startAppointment(appointmentEvent);
  };

  const rescheduleAppointmentHandler = (): void => {
    history.push(generatePath(routes.reschedule.path, { appointmentId: appointmentEvent.appointment!.id }));
  };

  const cancelAppointmentHandler = (): void => {
    cancelAppointment(appointmentEvent.appointment!).then(() => {
      if (onDelete) onDelete(appointmentEvent.id);
    });
  };

  return {
    language,
    appointmentIsConfirmed,
    showPopup,
    showStartAppointmentButton: canStartAppointment(appointmentEvent),
    appointmentHasPassed: hasAppointmentEnded(appointmentEvent),
    setShowPopup,
    togglePopupHandler,
    payAppointmentHandler,
    startAppointmentHandler,
    rescheduleAppointmentHandler,
    cancelAppointmentHandler,
  };
};

export default usePatientAppointment;
