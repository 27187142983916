import IRoute, { IAccountSubRoutes } from 'interfaces/IRoute';

const accountSubRoutes: IAccountSubRoutes = {
  info: {
    path: '/account/info',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
  security: {
    path: '/account/security',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
  payments: {
    path: '/account/payments',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
  preferences: {
    path: '/account/preferences',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
};

export default accountSubRoutes;
