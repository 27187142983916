import { ForwardedRef, forwardRef, PropsWithChildren, MouseEvent, TouchEvent } from 'react';
import VideoPlaceholder from './VideoPlaceholder';
import styles from './VideoStream.module.scss';

export interface VideoStreamProps {
  className?: string;
  streamIsOn: boolean;
  mute: boolean;
  onClick?: () => void;
}

const VideoStream = forwardRef<HTMLVideoElement, VideoStreamProps>(
  (props: PropsWithChildren<VideoStreamProps>, ref: ForwardedRef<HTMLVideoElement>) => {
    const { className, streamIsOn, mute, onClick } = props;

    const clickHandler = (event: MouseEvent | TouchEvent) => {
      event.stopPropagation();
      if (onClick) onClick();
    };

    return (
      <div
        className={`${styles.video}  ${onClick !== undefined && styles.clickable} ${
          !streamIsOn && styles['stream-on']
        } ${className}`}
        onClick={clickHandler}
        onTouchStart={clickHandler}>
        <video ref={ref} autoPlay muted={mute} />
        <VideoPlaceholder show={!streamIsOn} />
      </div>
    );
  }
);

export default VideoStream;
