import { getMargin, WhitePage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IBuyAppointmentWrapperProps {}

export const BuyAppointmentWrapper = styled(WhitePage)<IBuyAppointmentWrapperProps>`
  padding-right: 0;
  padding-left: 0;

  > div {
    &:nth-child(1) {
      margin-left: ${getMargin()};
    }

    &:nth-child(2) {
      overflow-y: hidden;
    }
  }
`;

export interface IWalletIFrameProps {}

export const WalletIFrame = styled.iframe<IWalletIFrameProps>`
  width: 100vw !important;
  height: 100%;
  overflow-y: auto;
`;
