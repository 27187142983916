import Button, { ButtonTypes } from 'components/Form/Button';
import MedicDTO from 'dtos/MedicDTO';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import { getDoctorAbreviation } from 'utils/language';
import { getMedicSpecialtiesList } from 'utils/user';
import LikeButton from './LikeButton';
import styles from './MedicCard.module.scss';
import MedicHighLight, { MedicHighLightsType } from './MedicHighLight';

export type MedicCardProps = {
  medic: MedicDTO;
};

const MedicCard: React.FC<MedicCardProps> = (props: React.PropsWithChildren<MedicCardProps>) => {
  const { medic } = props;
  const { id, name, specialties, gender } = medic;
  const history = useHistory();
  const language = useSelector((state: IReduxState) => state.language.values);
  const highlights = [
    MedicHighLightsType.MOST_CONSULTED,
    MedicHighLightsType.HAD_APPOINTMENT,
    MedicHighLightsType.SMARTAL_RECOMENDATION,
  ];

  return (
    <div className={styles['medic-card']}>
      <div className={styles.info}>
        <div>
          <img src='/medicPicture.jpg' />
          <LikeButton liked={Math.random() < 0.5} onClick={() => {}} />
        </div>
        <div>
          <div>
            <h4>{getDoctorAbreviation(language, gender, name)}</h4>
            <span>{getMedicSpecialtiesList(specialties)}</span>
          </div>
          <div>
            <div>
              <img src='/star.svg' />
              <span>
                <b>4.7</b>
                &nbsp;(16 {language.appointments && language.appointments.toLowerCase()})
              </span>
            </div>
            <b>{Math.round(Math.random() * 50)}€</b>
          </div>
          <Button
            dataTestId='searchMedics-scheduleButton'
            className={styles['schedule_button']}
            type={ButtonTypes.SECONDARY}
            label={language.schedule}
            onClick={() => history.push(generatePath(routes.schedule.path, { medicId: id }))}
          />
        </div>
      </div>
      {Math.random() < 0.5 && <MedicHighLight type={highlights[Math.round(Math.random() * 2)]} />}
    </div>
  );
};

export default MedicCard;
