import MedicDTO from 'dtos/MedicDTO';
import MedicCard from '../MedicCard/MedicCard';
import SearchFilters from './SearchFilters';
import { getFoundXResults } from 'utils/language';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './SearchResults.module.scss';
import sass from 'styles/exports.module.scss';

export type SearchResultsProps = {
  medics: MedicDTO[];
  show: boolean;
};

const SearchResults: React.FC<SearchResultsProps> = (props: React.PropsWithChildren<SearchResultsProps>) => {
  const { show, medics } = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <Transition in={show} timeout={+sass.transition} mountOnEnter unmountOnExit>
      {(state: TransitionStatus) => (
        <section className={`${styles['search-results']} ${styles[state]}`}>
          <h1>{getFoundXResults(language, medics.length)}</h1>
          <SearchFilters />
          {medics.length > 0 && (
            <div className={styles['results-list']}>
              {medics.map((medic: MedicDTO) => (
                <MedicCard key={medic.id} medic={medic} />
              ))}
            </div>
          )}
          {medics.length === 0 && <p className={styles['no-medics']}>{language.noResultsFound}</p>}
        </section>
      )}
    </Transition>
  );
};

export default SearchResults;
