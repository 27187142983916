import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import IReduxState from 'interfaces/IReduxState';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const token = /d{1,4}|m{1,4}|yy(?:yy)?|(?!\\h)([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g;

export enum DateMasks {
  shortWeekDay = 'shortWeekDay',
  shortWeekDayNumber = 'shortWeekDayNumber',
  shortDayMonth = 'shortDayMonth',
  shortDayMonthYear = 'shortDayMonthYear',
  mediumDate = 'mediumDate',
  monthYear = 'monthYear',
  shortTime = 'shortTime',
  shortDayNumberMonth = 'shortMonthDayNumber',
  shortMonthDayNumber = 'shortMonthDayNumber',
  dayNumber = 'dayNumber',
  shortMonth = 'shortMonth',
  dateTime = 'dateTime',
  shortDateTime = 'shortDateTime',
  fullDateTime = 'fullDateTime',
  shortFullDate = 'shortFullDate',
  fullDate = 'fullDate',
  inputDate = 'inputDate',
}

export enum GlobalDateMasks {
  urlDateTime = 'urlDateTime',
  urlDate = 'urlDate',
  urlTime = 'urlTime',
  shortMonth = 'shortMonth',
}

export type UseDate = {
  days: string[];
  months: string[];
  formatDate: (date: Date, mask: string) => string;
};

const useDate = () => {
  const languageCode = useSelector((state: IReduxState) => state.language.code);
  const language = useSelector((state: IReduxState) => state.language.values);

  const days = useCallback(() => {
    return [
      language.sunday,
      language.monday,
      language.tuesday,
      language.wednesday,
      language.thursday,
      language.friday,
      language.saturday,
    ];
  }, [language])();

  const months = useCallback(() => {
    return [
      language.january,
      language.february,
      language.march,
      language.april,
      language.may,
      language.june,
      language.july,
      language.august,
      language.september,
      language.october,
      language.november,
      language.december,
    ];
  }, [language])();

  const convert = (mask: DateMasks | GlobalDateMasks, flags: any): string => {
    const maskToApply = Object.keys(GlobalDateMasks).includes(mask.toString())
      ? globalDateMasks[mask as GlobalDateMasks]
      : dateMasks[languageCode!][mask as DateMasks];

    return maskToApply.replace(token, ($0) => {
      return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
    });
  };

  const formatDate = (date: Date, mask: DateMasks | GlobalDateMasks): string => {
    const weekDay = date.getDay();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const twelveHourFormat = hours % 12;
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const flags = {
      d: day,
      dd: day?.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      ddd: days[weekDay]?.substring(0, 3),
      dddd: days[weekDay],
      mm: (month + 1)?.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      mmm: months[month]?.substring(0, 3),
      mmmm: months[month],
      yy: year.toString().substring(2),
      yyyy: year,
      hh: (twelveHourFormat || 12).toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      HH: hours.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      MM: minutes.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      ss: seconds.toLocaleString('en-US', { minimumIntegerDigits: 2 }),
      TT: hours < 12 ? 'AM' : 'PM',
    };

    return convert(mask, flags);
  };

  return { days, months, formatDate, dateMasks: Object.keys(dateMasks[languageCode!]) };
};

const dateMasks = {
  [LanguageCode.en_US]: {
    [DateMasks.shortWeekDay]: 'ddd',
    [DateMasks.shortWeekDayNumber]: 'd ddd',
    [DateMasks.shortDayMonth]: 'd mmm',
    [DateMasks.shortDayMonthYear]: 'd mmm yy',
    [DateMasks.mediumDate]: 'mmmm, dd yyyy',
    [DateMasks.monthYear]: 'mmmm yyyy',
    [DateMasks.shortTime]: 'hh:MM TT',
    [DateMasks.shortDayNumberMonth]: 'd mmm',
    [DateMasks.shortMonthDayNumber]: 'mmm d',
    [DateMasks.dayNumber]: 'dd',
    [DateMasks.shortMonth]: 'mmm',
    [DateMasks.dateTime]: 'mmmm, dd yyyy, hh:MM TT',
    [DateMasks.shortDateTime]: "dd/mm 'at' HH'h'MM",
    [DateMasks.fullDateTime]: 'mmm, dd yyyy, hh:MM TT',
    [DateMasks.shortFullDate]: 'd ddd\nmmm yyyy',
    [DateMasks.fullDate]: 'dddd, dd mmmm yyyy',
    [DateMasks.inputDate]: 'mm/dd/yyyy',
  },
  [LanguageCode.pt_PT]: {
    [DateMasks.shortWeekDay]: 'ddd',
    [DateMasks.shortWeekDayNumber]: 'd ddd',
    [DateMasks.shortDayMonth]: 'd mmm',
    [DateMasks.shortDayMonthYear]: 'd mmm yy',
    [DateMasks.mediumDate]: "dd 'de' mmmm yyyy",
    [DateMasks.monthYear]: 'mmmm yyyy',
    [DateMasks.shortTime]: "HH'h'MM",
    [DateMasks.shortDayNumberMonth]: 'd mmm',
    [DateMasks.shortMonthDayNumber]: 'mmm d',
    [DateMasks.dayNumber]: 'dd',
    [DateMasks.shortMonth]: 'mmm',
    [DateMasks.dateTime]: "dd mmmm yyyy, HH'h'MM",
    [DateMasks.shortDateTime]: "dd/mm 'às' HH'h'MM",
    [DateMasks.fullDateTime]: "dd 'de' mmm yyyy, HH'h'MM",
    [DateMasks.shortFullDate]: 'd ddd\nmmm yyyy',
    [DateMasks.fullDate]: "dddd, dd 'de' mmmm yyyy",
    [DateMasks.inputDate]: 'dd/mm/yyyy',
  },
  [LanguageCode.es_ES]: {
    [DateMasks.shortWeekDay]: 'ddd',
    [DateMasks.shortWeekDayNumber]: 'd ddd',
    [DateMasks.shortDayMonth]: 'd mmm',
    [DateMasks.shortDayMonthYear]: 'd mmm yy',
    [DateMasks.mediumDate]: 'dd mmmm yyyy',
    [DateMasks.monthYear]: 'mmmm yyyy',
    [DateMasks.shortTime]: "HH'h'MM",
    [DateMasks.shortDayNumberMonth]: 'd mmm',
    [DateMasks.shortMonthDayNumber]: 'mmm d',
    [DateMasks.dayNumber]: 'dd',
    [DateMasks.shortMonth]: 'mmm',
    [DateMasks.dateTime]: "dd mmmm yyyy, HH'h'MM",
    [DateMasks.shortDateTime]: "dd/mm 'a las' HH'h'MM",
    [DateMasks.fullDateTime]: "dd mmm yyyy, HH'h'MM",
    [DateMasks.shortFullDate]: 'd ddd\nmmm yyyy',
    [DateMasks.fullDate]: 'dddd, dd mmmm yyyy',
    [DateMasks.inputDate]: 'dd/mm/yyyy',
  },
};

const globalDateMasks = {
  [GlobalDateMasks.urlDateTime]: 'yyyy-mm-dd HH:MM:ss',
  [GlobalDateMasks.urlDate]: 'yyyy-mm-dd',
  [GlobalDateMasks.urlTime]: 'HH:MM',
  [GlobalDateMasks.shortMonth]: 'mmm',
};

export default useDate;
