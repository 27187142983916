import styles from './Icon.module.scss';

import SVG from 'react-inlinesvg';

export type IconProps = {
  dataTestId?: string;
  className?: string;
  src: string;
};

const Icon: React.FC<IconProps> = (props: React.PropsWithChildren<IconProps>) => {
  const { dataTestId, className, src } = props;

  return (
    <span data-testid={dataTestId} className={`${styles.icon} ${className}`}>
      <SVG src={`${process.env.PUBLIC_URL}/icons/${src}`} />
    </span>
  );
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
