import { Flex, Icon, transition } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';

export interface IAuthenticationLinksWrapperProps {}

export const AuthenticationLinksWrapper = styled(Flex)<IAuthenticationLinksWrapperProps>`
  position: absolute;
  bottom: 1.24rem;

  > * {
    position: relative;
    margin-right: 2rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export interface IAuthenticationLinksLanguageIconProps {
  isOpen: boolean;
}

export const AuthenticationLinksLanguageIcon = styled(Icon)<IAuthenticationLinksLanguageIconProps>`
  display: inline-flex;
  position: relative;
  top: 0.25rem;
  left: 0.3125rem;
  transform-origin: 50% 50%;
  ${transition('transform')}

  ${({ isOpen }: IAuthenticationLinksLanguageIconProps) =>
    isOpen
      ? css`
          transform: rotate(-180deg);
        `
      : css``}
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .container {
    bottom: 40px;
    flex-wrap: wrap;

    > * {
      margin-top: 30px;

      b {
        font-size: $textSize_tiny;
      }

      &.open {
        position: relative;
        z-index: 50;
      }
    }
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .popup_button {
    position: absolute;
    top: getMargin($smartphone);
    right: getMargin($smartphone);
    transform: rotate(90deg);
  }

  .container {
    @include flex($direction: column, $horizontal: flex-start, $vertical: flex-start);
    top: calc(#{getMargin($smartphone)} + 50px);
    bottom: auto;
    max-width: getColWidth($smartphone, 3);
    right: getMargin($smartphone);
    border-radius: 10px;
    background-color: $lightest;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);

    > * {
      @include flex($horizontal: space-between);
      width: 100%;
      padding: 12px 20px;
      box-sizing: border-box;
      margin-top: 0;
      font-size: $textSize;

      &:first-child {
        padding-top: 24px;
      }

      &:last-child {
        padding-bottom: 24px;
      }

      > * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .open {
        .language-icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
*/
