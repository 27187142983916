import IOption from 'interfaces/IOption';
import React from 'react';
import Icon from 'components/View/Icon';
import styles from './Option.module.scss';

export enum OptionType {
  TAG = 'tag',
  RADIO_BUTTON = 'radio-button',
}

export type OptionProps = {
  dataTestId?: string;
  className?: string;
  type: 'radio' | 'checkbox';
  identifier?: string;
  option: IOption;
  checked: boolean;
  onCheck: () => void;
  onUncheck: () => void;
};

const Option: React.FC<OptionProps> = (props: React.PropsWithChildren<OptionProps>) => {
  const { dataTestId, className, type, identifier, option, checked, onCheck, onUncheck } = props;
  const { icon, name } = option;

  const changeHandler = (event: React.FormEvent<HTMLInputElement>): void => {
    if (event.currentTarget.checked) onCheck();
    else onUncheck();
  };

  return (
    <label className={`${styles.option} ${className}`}>
      <input data-testid={dataTestId} type={type} name={identifier} checked={checked} onChange={changeHandler} />
      <div>
        {icon && <Icon className={styles.icon} src={icon} />}
        {name && <span className={styles.label}>{name}</span>}
      </div>
    </label>
  );
};

export default Option;
