import { useEffect, useState } from 'react';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import styles from './Grid.module.scss';

export type GridProps = {};

const Grid: React.FC<GridProps> = (props: React.PropsWithChildren<GridProps>) => {
  const {} = props;
  const logger = useWinstonLogger();
  const [showGrid, setShowGrid] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('keypress', (event) => {
      if (event.ctrlKey && event.shiftKey && event.code === 'KeyQ') {
        setShowGrid((prevState) => {
          if (prevState) logger.log(LOG_COMPONENT, 'hiding grid');
          else logger.log(LOG_COMPONENT, 'showing grid');
          return !prevState;
        });
      }
    });
  }, [logger]);

  if (!showGrid) return null;

  return (
    <div className={styles.grid}>
      {new Array(12).fill(0).map((_, i) => (
        <div key={i} className='col-1'></div>
      ))}
    </div>
  );
};

export default Grid;
