import Tag from 'components/View/Tag';
import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import styles from './MedicProfileInfo.module.scss';
import MedicDTO from 'dtos/MedicDTO';
import { getDoctorAbreviation } from 'utils/language';
import Icon from 'components/View/Icon';
import { getMedicSpecialtiesList } from 'utils/user';
import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export type MedicProfileInfoProps = {
  medic: MedicDTO;
};

const MedicProfileInfo: React.FC<MedicProfileInfoProps> = (props: React.PropsWithChildren<MedicProfileInfoProps>) => {
  const { medic } = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <section className={styles['profile-info']}>
      <div className={styles.info}>
        <UserImage style={{ marginRight: '1rem' }} src={medic.image} size='7.5rem' big />
        <div>
          <h1>{getDoctorAbreviation(language, medic.gender, medic.name)}</h1>
          <h5>{getMedicSpecialtiesList(medic.specialties)}</h5>
          <span>
            {medic.city}, {medic.country}
          </span>
        </div>
      </div>
      <ul className={styles['appointments-types']}>
        {Object.values(AppointmentPlaces).map((place: AppointmentPlaces) => (
          <Tag key={place} className={`${styles[place]} ${styles.tag}`} icon={iconsTemp.appointmentPlaces[place]}>
            {language[place]}
          </Tag>
        ))}
      </ul>
      <div className={styles.statistics}>
        <div>
          <h1>16</h1>
          <span>{language.appointments}</span>
        </div>
        <div>
          <div>
            <Icon className={styles.star} src={iconsTemp.star} />
            <h1>4.7</h1>
          </div>
          <span>{language.evaluation}</span>
        </div>
        <div>
          <h1>22</h1>
          <span>{language.comments}</span>
        </div>
      </div>
    </section>
  );
};

export default MedicProfileInfo;
