import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import {
  ImproveProfileSuggestionsWrapper,
  SuggestionItem,
  SuggestionsContainer,
} from './ImproveProfileSuggestions.styles';

export interface IImproveProfileSuggestionsProps extends PropsWithChildren<{}> {}

const ImproveProfileSuggestions: FC<IImproveProfileSuggestionsProps> = (props: IImproveProfileSuggestionsProps) => {
  const {} = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <ImproveProfileSuggestionsWrapper>
      <p>{language.noAppointmentForToday}</p>
      <p>{language.shareYourProfile}</p>
      <SuggestionsContainer>
        <img />
        <div>
          <h5>{language.someSuggestions}:</h5>
          <ul>
            <SuggestionItem>
              <span>&bull;</span>
              {language.shareProfileSuggestion1};
            </SuggestionItem>
            <SuggestionItem>
              <span>&bull;</span>
              {language.shareProfileSuggestion2};
            </SuggestionItem>
            <SuggestionItem>
              <span>&bull;</span>
              {language.shareProfileSuggestion3}.
            </SuggestionItem>
          </ul>
        </div>
      </SuggestionsContainer>
    </ImproveProfileSuggestionsWrapper>
  );
};

export default ImproveProfileSuggestions;
