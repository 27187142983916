import AccordionItem from 'components/View/Accordion/AccordionItem';
import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import EventAvailabilityDTO from 'dtos/EventAvailabilityDTO';
import { DateMasks } from 'hooks/use-date';
import { IDayGroup } from 'interfaces/IGroup';
import { FC, PropsWithChildren } from 'react';
import useAvailableSlotsSlider, { ISlot } from './AvailableSlotsSlider.logic';
import AvailableSlotsSliderHeader from './AvailableSlotsSliderHeader';
import { CSSTransition } from 'react-transition-group';
import { Flex, IProps, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import {
  AvailableSlotsSliderAccordion,
  AvailableSlotsSliderEmptyMessage,
  AvailableSlotsSliderMultiOption,
  AvailableSlotsSliderNoAvailabilitiesMessage,
} from './AvailableSlotsSlider.styles';

export interface IAvailableSlotsSliderProps extends PropsWithChildren<IProps> {
  name: string;
  medicId: string;
  specialtyId: string;
  type: AppointmentPlaces;
  start: Date;
  nrDays: number;
}

const AvailableSlotsSlider: FC<IAvailableSlotsSliderProps> = (props: IAvailableSlotsSliderProps): JSX.Element => {
  const { className, style, name, specialtyId, type } = props;
  const { language, dates, slots, formatDate, goToPreviousHandler, goToNextHandler } = useAvailableSlotsSlider(props);

  return (
    <div className={className} style={style}>
      <AvailableSlotsSliderHeader dates={dates} onGoToPrevious={goToPreviousHandler} onGoToNext={goToNextHandler} />
      <CSSTransition
        classNames='empty-message'
        in={!specialtyId || !type}
        timeout={styles.timeout}
        mountOnEnter
        unmountOnExit>
        <AvailableSlotsSliderEmptyMessage>
          {language.selectSpecialtyAndAppointmentPlaceToSeeAvailabilities}
        </AvailableSlotsSliderEmptyMessage>
      </CSSTransition>
      <AvailableSlotsSliderAccordion>
        {slots.map((slot: ISlot, index: number) => (
          <AccordionItem key={index} title={slot.title} disabled={!specialtyId || !type}>
            <Flex style={{ height: '100%' }} justifyContent='space-between' alignItems='flex-start'>
              {slot.events?.length ? (
                slot.events.map((dayEvents: IDayGroup<EventAvailabilityDTO>, index: number) => (
                  <AvailableSlotsSliderMultiOption
                    key={index}
                    name={name}
                    options={dayEvents.list.map((event: EventAvailabilityDTO) => ({
                      id: event.id,
                      name: formatDate(event.start, DateMasks.shortTime),
                    }))}
                    hasOptions={dayEvents.list.length > 0}
                  />
                ))
              ) : (
                <AvailableSlotsSliderNoAvailabilitiesMessage>
                  {language.noAvailabilities}
                </AvailableSlotsSliderNoAvailabilitiesMessage>
              )}
            </Flex>
          </AccordionItem>
        ))}
      </AvailableSlotsSliderAccordion>
    </div>
  );
};

export default AvailableSlotsSlider;
