import LoginSchema from 'schemas/LoginSchema';
import LoginDTO from 'dtos/LoginDTO';
import LoginBody from 'RequestsBody/LoginBody';
import { getInputValue, InputsReducerType } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

const LoginMapper = () => {
  const toInterface = (schema: LoginSchema): LoginDTO => {
    return {
      expiresIn: schema.expires_in,
      token: schema.token,
    };
  };

  const toBody = (inputs: InputsReducerType): LoginBody => {
    return {
      username: getInputValue(inputs.email)!,
      password: getInputValue(inputs.password)!,
    };
  };

  return { toInterface, toBody };
};

export default LoginMapper;
