import useClickOutside from 'hooks/use-clickOutside';
import { useRef, useState } from 'react';
import { NumberInputProps } from './NumberInput';

export type UseNumberInput = {
  showStep: boolean;
  containerRef: React.RefObject<HTMLLabelElement>;
  setShowStep: React.Dispatch<React.SetStateAction<boolean>>;
  openDialogHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const useNumberInput = (props: NumberInputProps): UseNumberInput => {
  const { step, focusHandler } = props;
  const [showStep, setShowStep] = useState<boolean>(false);
  const containerRef = useRef<HTMLLabelElement>(null);
  useClickOutside(containerRef, setShowStep);

  const openDialogHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!step) return;
    setShowStep(true);
    focusHandler(event);
  };

  return { showStep, containerRef, setShowStep, openDialogHandler };
};

export default useNumberInput;
