import { FormContextProvider, MultiOption, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IAccountInfoFormProps {}

export const AccountInfoForm = styled(FormContextProvider)<IAccountInfoFormProps>`
  justify-content: center;

  button {
    margin-right: auto;
    margin-left: auto;
  }
`;

export interface IAccountGenderMultiOptionProps {}

export const AccountGenderMultiOption = styled(MultiOption)<IAccountGenderMultiOptionProps>`
  margin-top: ${styles.inputMargin};

  label {
    width: auto;
  }
`;

export interface IAccountInfoSeparatorProps {}

export const AccountInfoSeparator = styled.hr<IAccountInfoSeparatorProps>`
  border-top: 1px solid ${rgba(styles.colors.brand.dark, 0.1)};
  margin: 1.5rem 0;
`;
