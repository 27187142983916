import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { LOG_ERROR, LOG_SOCKET, LOG_WARN } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import useHttp from 'services/http.service';
import { IAppointmentRouteState } from 'interfaces/IRouteStates';
import { AppointmentStartSocketMessage } from 'interfaces/ISocketMessages';
import { generatePath, useHistory } from 'react-router-dom';
import routes from 'routes/routes';

export interface ISocketContext {
  socket?: Socket;
  connected: boolean;
  connectToSocket: (token: string) => void;
  refreshSocketToken: (token: string) => void;
  disconnectSocket: () => void;
}

const SocketContext = createContext<ISocketContext>({
  socket: undefined,
  connected: false,
  connectToSocket: (_: string) => {},
  refreshSocketToken: (_: string) => {},
  disconnectSocket: () => {},
} as ISocketContext);

export type SocketContextProviderProps = {};

export const SocketContextProvider = (props: PropsWithChildren<SocketContextProviderProps>) => {
  const { children } = props;
  const logger = useWinstonLogger();
  const history = useHistory();
  const { get } = useHttp();
  const [socket, setSocket] = useState<Socket>();
  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    if (!socket) return;

    socket.removeAllListeners();

    socket.on('connect', () => {
      logger.log(LOG_SOCKET, `connected successfully with id ${socket.id}`);
      setConnected(true);
    });

    socket.on('disconnect', (reason: Socket.DisconnectReason) => {
      logger.log(LOG_SOCKET, 'socket has disconnected');
      logger.log(LOG_SOCKET, `reason: ${JSON.stringify(reason)}`);
      setConnected(false);
    });

    socket.io.on('ping', () => {
      logger.log(LOG_SOCKET, 'ping');
    });

    socket.io.on('reconnect', (attempt: number) => {
      logger.log(LOG_SOCKET, `reconnect: ${JSON.stringify(attempt)}`);
    });

    socket.io.on('reconnect_error', (error: Error) => {
      logger.log(LOG_SOCKET, `reconnect error: ${JSON.stringify(error)}`);
    });

    socket.io.on('reconnect_failed', () => {
      logger.log(LOG_SOCKET, 'reconnect failed');
    });

    socket.io.on('error', (error: Error) => {
      logger.log(LOG_SOCKET, `error: ${JSON.stringify(error)}`);
    });

    socket.on('go-to-appointment', (data: AppointmentStartSocketMessage) => {
      logger.log(LOG_SOCKET, `received info to go to appointment ${data.appointmentId}`);
      history.push(generatePath(routes.appointment.path, { appointmentId: data.appointmentId }), {
        verified: true,
        appointmentHasStarted: true,
      } as IAppointmentRouteState);
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [socket]);

  const root = `${process.env.REACT_APP_COMMUNICATION_API}/hello`;

  const createSocket = (token: string): void => {
    get(root).then(() => setSocket(io('/', { extraHeaders: { Authorization: `Bearer ${token}` } })));
  };

  const connectToSocket = (token: string): void => {
    if (!socket) {
      createSocket(token);
      return;
    }

    logger.log(LOG_SOCKET, 'connecting to already existing socket');
    socket.io.opts.extraHeaders = { Authorization: `Bearer ${token}` };
    socket.connect();
  };

  const refreshSocketToken = (token: string): void => {
    if (!socket) {
      connectToSocket(token);
      return;
    }

    logger.log(LOG_SOCKET, 'refreshing socket token');
    socket.io.opts.extraHeaders = { Authorization: `Bearer ${token}` };

    logger.log(LOG_SOCKET, 'emiting refreshing-token');
    socket.emit('refreshing-token', (success: boolean) => {
      if (success) {
        socket.disconnect();
        setTimeout(() => socket.connect(), 0); // Workaround to not call 2 disconnects
      } else logger.log(LOG_ERROR, "it's Jean's fault");
    });
  };

  const disconnectSocket = (): void => {
    if (!socket) {
      logger.log(LOG_WARN, 'trying to disconnect socket while it does not exist');
      return;
    }

    logger.log(LOG_SOCKET, 'disconnecting socket');
    socket.disconnect();
  };

  const value = {
    socket,
    connected,
    connectToSocket,
    refreshSocketToken,
    disconnectSocket,
  };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export type SocketListenerEvents =
  // waiting room
  | 'user-entered-waiting'
  | 'user-waiting'
  | 'user-gone'
  // appointment
  | 'go-to-appointment'
  | 'starting-appointment'
  | 'user-left'
  // call
  | 'offer-made'
  | 'answer-made'
  | 'ice-candidate'
  | 'video-state'
  | 'screen-share-state'
  | 'leave-call'
  // chat
  | 'received-message'
  | 'file-shared'
  | 'message-read';

export type SocketEmitterEvents =
  // waiting room
  | 'enter-waiting-room'
  | 'entered-waiting-room'
  | 'left-waiting-room'
  // appointment
  | 'start-appointment'
  | 'left-appointment'
  // call
  | 'make-offer'
  | 'make-answer'
  | 'ice-candidate'
  | 'video-state'
  | 'screen-share-state'
  | 'left-call'
  // chat
  | 'send-message-appointment'
  | 'set-message-read';

export default SocketContext;
