import { InputsReducerType } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { appointmentsAPI, entitiesAPI } from 'apis';
import { ToastTypes } from 'components/View/Toast';
import { AssociatedMedicDTO } from 'dtos/AssociatedUserDTO';
import { PaginationDTO } from 'dtos/PaginationDTO';
import PatientDTO from 'dtos/PatientDTO';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import AssociatedMedicMapper from 'mappers/AssociatedMedicMapper';
import PatientMapper from 'mappers/PatientMapper';
import { useDispatch, useSelector } from 'react-redux';
import AssociatedMedicSchema from 'schemas/AssociatedMedicSchema';
import PaginationSchema from 'schemas/PaginationSchema';
import PatientSchema from 'schemas/PatientSchema';
import { feedbackActions } from 'store/redux/feedback-slice';
import useEntitiesService from './entities.service';
import useHttp from './http.service';

const patientMapper = PatientMapper();
const associatedMedicMapper = AssociatedMedicMapper();

export interface IUsePatientsService {
  isLoading: boolean;
  createPatient: (inputs: InputsReducerType) => Promise<PatientDTO>;
  createOrUpdateCurrentPatientImage: (image: File) => Promise<void>;
  updateCurrentPatient: (inputs: InputsReducerType) => Promise<PatientDTO>;
  getCurrentPatient: () => Promise<PatientDTO>;
  getCurrentPatientImage: () => Promise<string>;
  getPatientRelatedMedics: () => Promise<PaginationDTO<AssociatedMedicDTO>>;
}

const usePatientsService = (): IUsePatientsService => {
  const dispatch = useDispatch();
  const { isLoading, get, post, put, patch } = useHttp();
  const { formatDate } = useDate();
  const { removeRegisterValuesFromStorage } = useEntitiesService();
  const language = useSelector((state: IReduxState) => state.language.values);

  // POST /patients
  const createPatient = (inputs: InputsReducerType): Promise<PatientDTO> => {
    return new Promise(async (resolve, reject) => {
      const body = await patientMapper.toBody(inputs, formatDate);

      post(`${entitiesAPI}/patients`, undefined, body)
        .then((patient: PatientSchema) => {
          removeRegisterValuesFromStorage();
          resolve(patientMapper.toInterface(patient));
        })
        .catch(() => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastError-createPatient',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorCreatingUser,
              subtitle: language.weWillCheckTheProblem,
            })
          );

          reject();
        });
    });
  };

  // PATCH /patients
  const updateCurrentPatient = (inputs: InputsReducerType): Promise<PatientDTO> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (inputs.photo?.value) await createOrUpdateCurrentPatientImage(inputs.photo.value);

        const body = await patientMapper.toBody(inputs, formatDate);
        const patient: PatientSchema = await patch(`${entitiesAPI}/patients`, undefined, body);
        resolve(patientMapper.toInterface(patient));
      } catch (_: unknown) {
        dispatch(
          feedbackActions.addMessage({
            dataTestId: 'toastError-updatePatient',
            type: ToastTypes.ERROR,
            icon: iconsTemp.close,
            title: language.errorCreatingUser,
            subtitle: language.weWillCheckTheProblem,
          })
        );

        reject();
      }
    });
  };

  // GET /patients
  const getCurrentPatient = (): Promise<PatientDTO> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/patients`)
        .then((patient: PatientSchema) => {
          const mappedPatient = patientMapper.toInterface(patient);
          resolve(mappedPatient);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // PUT /patients/image
  const createOrUpdateCurrentPatientImage = (image: File): Promise<void> => {
    const body = new FormData();
    body.append('image', image);

    return new Promise(async (resolve, reject) => {
      put(`${entitiesAPI}/patients/image`, undefined, body)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /patients/image
  const getCurrentPatientImage = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/patients/image`)
        .then((blobUrl: string) => {
          resolve(blobUrl);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /appointments/medics
  const getPatientRelatedMedics = (): Promise<PaginationDTO<AssociatedMedicDTO>> => {
    return new Promise((resolve, reject) => {
      get(`${appointmentsAPI}/medics`)
        .then((pagination: PaginationSchema<AssociatedMedicSchema>) => {
          resolve({
            list: pagination.content.map((medic: AssociatedMedicSchema) => associatedMedicMapper.toInterface(medic)),
            last: pagination.last,
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

  return {
    isLoading,
    createPatient,
    updateCurrentPatient,
    getCurrentPatient,
    createOrUpdateCurrentPatientImage,
    getCurrentPatientImage,
    getPatientRelatedMedics,
  };
};

export default usePatientsService;
