// import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, FC } from 'react';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import routes from 'routes/routes';

export interface INoAccountProps extends PropsWithChildren<{}> {}

const NoAccount: FC<INoAccountProps> = (props: INoAccountProps): JSX.Element => {
  const {} = props;
  // const language = useSelector((state: IReduxState) => state.language.values);

  return <></>;

  // return (
  //   <p data-testid='login-createAccountLink'>
  //     {language.noAccount}&nbsp;
  //     <Link to={routes.register.linkPath!}>
  //       <b>{language.createAccount}</b>
  //     </Link>
  //   </p>
  // );
};

export default NoAccount;
