import MedicDTO from './MedicDTO';
import NoteDTO from './NoteDTO';
import PatientDTO from './PatientDTO';
import SpecialtyDTO from './SpecialtyDTO';

export enum AppointmentPlaces {
  Remote = 'remote',
  Office = 'office',
  Home = 'home',
}

export enum AppointmentStatus {
  Created = 'CREATED',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Canceled = 'CANCELED',
  Started = 'STARTED',
  Ended = 'ENDED',
  Refunded = 'REFUNDED',
}

export interface AppointmentSpecialtyDTO extends Omit<SpecialtyDTO, 'name'> {
  name?: string;
}

export default interface AppointmentDTO {
  id: string;
  medicId: string;
  patientId: string;
  userId: string;
  user?: MedicDTO | PatientDTO;
  specialty: AppointmentSpecialtyDTO;
  status: AppointmentStatus;
  place: AppointmentPlaces;
  startedAt?: Date;
  endedAt?: Date;
  note?: NoteDTO;
}
