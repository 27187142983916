import { Flex, rgba, SmallText, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IPatientInfoHeaderWrapperProps {}

export const PatientInfoHeaderWrapper = styled(Flex)<IPatientInfoHeaderWrapperProps>`
  padding-bottom: 2rem;
`;

export interface IPatientInfoNameProps {}

export const PatientInfoName = styled.h2<IPatientInfoNameProps>`
  margin-bottom: 0.5rem;
  color: ${rgba(styles.colors.brand.dark, 0.75)};
  font-size: 1.5rem;
`;

export interface IPatientInfoDetailsProps {}

export const PatientInfoDetails = styled(SmallText)<IPatientInfoDetailsProps>`
  color: ${rgba(styles.colors.brand.dark, 0.75)};
`;
