import { Icon, IOption, IProps, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import IReduxState from 'interfaces/IReduxState';
import { FC, Fragment, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { getAppointmentPlacesOptions } from 'utils/appointment';
import scssStyles from './AppointmentTypesIcons.module.scss';
import { AppointmentTypesIconsWrapper } from './AppointmentTypesIcons.styles';

export interface IAppointmentTypesIconsProps extends PropsWithChildren<IProps> {
  include: AppointmentPlaces[];
}

const AppointmentTypesIcons: FC<IAppointmentTypesIconsProps> = (props: IAppointmentTypesIconsProps): JSX.Element => {
  const { className, style, include } = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <AppointmentTypesIconsWrapper className={`${scssStyles['appointment-places']} ${className}`} style={style}>
      {getAppointmentPlacesOptions(language).map(({ id, icon }: IOption) => (
        <Fragment key={id}>
          {include.includes(id as AppointmentPlaces) && (
            <span className={scssStyles[id]}>
              <Icon className={scssStyles['circle-icon']} size={styles.iconSize.text} src={icon as any} />
            </span>
          )}
        </Fragment>
      ))}
    </AppointmentTypesIconsWrapper>
  );
};

export default AppointmentTypesIcons;
