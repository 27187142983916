import { useHttp } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { entitiesAPI } from 'apis';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import SpecialtyMapper from 'mappers/SpecialtyMapper';
import SpecialtySchema from 'schemas/SpecialtySchema';

const specialtyMapper = SpecialtyMapper();

export interface IUseSpecialtiesService {
  isLoading: boolean;
  getSpecialties: () => Promise<SpecialtyDTO[]>;
  getSpecialty: (specialtyId: string) => Promise<SpecialtyDTO>;
}

const useSpecialtiesService = (): IUseSpecialtiesService => {
  const { isLoading, get } = useHttp();

  // GET /specialties
  const getSpecialties = (): Promise<SpecialtyDTO[]> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/specialties`)
        .then((specialties: SpecialtySchema[]) => {
          resolve(specialties.map((specialty: SpecialtySchema) => specialtyMapper.toInterface(specialty)));
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /specialties/{specialtyId}
  const getSpecialty = (specialtyId: string): Promise<SpecialtyDTO> => {
    return new Promise((resolve, reject) => {
      get(`${entitiesAPI}/specialties/${specialtyId}`)
        .then((specialty: SpecialtySchema) => {
          resolve(specialtyMapper.toInterface(specialty));
        })
        .catch(() => {
          reject();
        });
    });
  };

  return {
    isLoading,
    getSpecialties,
    getSpecialty,
  };
};

export default useSpecialtiesService;
