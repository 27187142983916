import EventAvailabilityDTO from 'dtos/EventAvailabilityDTO';
import EventAvailabilitySchema from 'schemas/EventAvailabilitySchema';

const EventAvailabilityMapper = () => {
  const toInterface = (schema: EventAvailabilitySchema): EventAvailabilityDTO => {
    return {
      id: schema.id,
      start: new Date(schema.startAt),
      end: new Date(schema.endAt),
      price: schema.availability.price,
    };
  };

  return { toInterface };
};

export default EventAvailabilityMapper;
