import React, { useEffect, useImperativeHandle, useState } from 'react';
import styles from './Toggle.module.scss';

export type ToggleProps = {
  dataTestId?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  onValueChange?: (checked: boolean) => void;
};

export type ToggleRef = {
  isChecked: boolean;
};

const Toggle: React.FC<ToggleProps> = React.forwardRef(
  (props: React.PropsWithChildren<ToggleProps>, ref: React.ForwardedRef<ToggleRef>) => {
    const { dataTestId, defaultChecked, checked, onChange, onValueChange } = props;
    const [isChecked, setIsChecked] = useState<boolean>(defaultChecked !== undefined ? defaultChecked : false);

    useEffect(() => {
      if (checked !== undefined) setIsChecked(checked);
    }, [checked]);

    useImperativeHandle(ref, () => {
      return { isChecked: isChecked };
    });

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      const checked = event.target.checked;
      if (onChange) onChange(checked);

      if (onValueChange) onValueChange(checked);
      else setIsChecked(checked);
    };

    return (
      <span className={styles.toggle}>
        <input data-testid={dataTestId} type='checkbox' checked={isChecked} onChange={changeHandler} />
        <svg viewBox='0 0 32 22'>
          <line x1='6' y1='10' x2='26' y2='10' strokeWidth='12' strokeLinecap='round' />
          {new Array(2).fill(0).map((_, i) => (
            <circle key={i} cy='10' r='9' />
          ))}
        </svg>
      </span>
    );
  }
);

export default Toggle;
