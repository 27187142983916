import { IMessageGroup } from 'interfaces/IGroup';
import React from 'react';
import useMessages from './Messages.logic';
import styles from './Messages.module.scss';
import MessagesGroup from './MessagesGroup';
import LoadMore from 'components/View/LoadMore';
import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export type MessagesProps = {
  className?: string;
  userClassName?: string;
};

const Messages: React.FC<MessagesProps> = (props: React.PropsWithChildren<MessagesProps>) => {
  const { className, userClassName } = props;
  const {
    language,
    canLoadMore,
    groupedMessages,
    messagesContainerRef,
    lastReadMessagePosition,
    calleeImage,
    loadMoreMessagesHandler,
  } = useMessages();

  return (
    <div data-testid='chat-messages' className={`${styles.messages} ${className}`} ref={messagesContainerRef}>
      <LoadMore
        canLoadMore={canLoadMore}
        allLoadedText={language.youHaveNoMoreMessages}
        onLoadMore={loadMoreMessagesHandler}
      />
      {groupedMessages.map((messagesGroup: IMessageGroup, i: number) => (
        <MessagesGroup key={i} dataTestId={`messages-group${i}`} group={messagesGroup} />
      ))}
      {lastReadMessagePosition && (
        <div className={`${styles.user} ${userClassName}`} style={{ top: lastReadMessagePosition }}>
          <UserImage src={calleeImage} size='1rem' />
        </div>
      )}
    </div>
  );
};

export default Messages;
