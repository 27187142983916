import { Button, FormContextProvider } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';
import EventInfo from '../EventInfo';

export interface IEditAvailabilityEventInfoProps {}

export const EditAvailabilityEventInfo = styled(EventInfo)<IEditAvailabilityEventInfoProps>`
  height: auto;
  padding: 0;
  margin-bottom: 1rem;
  background-color: transparent !important;
  pointer-events: none;
`;

export interface IEditAvailabilityFormProps {}

export const EditAvailabilityForm = styled(FormContextProvider)<IEditAvailabilityFormProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export interface IEditAvailabilitySubmitButtonProps {}

export const EditAvailabilitySubmitButton = styled(Button)<IEditAvailabilitySubmitButtonProps>`
  min-width: 6.25rem;
  margin-top: 0 !important;
  margin-left: 1rem;
`;
