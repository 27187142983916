import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { DateInput, Flex, FormContext, getInputValue, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { FC, PropsWithChildren, useContext } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {
  AddAvailabilityEndRepeatButton,
  AddAvailabilityEndDateCloseRepeatButton,
  AddAvailabilityEndRepeatContainer,
} from './AddAvailabilityFormContent.styles';

export interface IAddAvailabilityEndDateProps extends PropsWithChildren<{}> {
  showEndDate: boolean;
  onToggle: () => void;
}

const AddAvailabilityEndDate: FC<IAddAvailabilityEndDateProps> = (props: IAddAvailabilityEndDateProps): JSX.Element => {
  const { showEndDate, onToggle } = props;
  const { inputs } = useContext(FormContext);
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <SwitchTransition>
      <CSSTransition classNames='repeat' key={`${showEndDate}`} timeout={styles.transition}>
        <AddAvailabilityEndRepeatContainer>
          {!showEndDate && (
            <AddAvailabilityEndRepeatButton
              dataTestId='addAvailabilityForm-repeatEndButton'
              type='secondary'
              icon='plus'
              label={language.defineRepeatEndDate}
              onClick={onToggle}
            />
          )}
          {showEndDate && (
            <Flex justifyContent='flex-start'>
              <DateInput
                dataTestId='addAvailabilityForm-endDateInput'
                name='endDate'
                icon='calendar'
                min={getInputValue(inputs.startDate)!}
              />
              <AddAvailabilityEndDateCloseRepeatButton
                dataTestId='addAvailabilityForm-closeEndDateButton'
                type='text'
                icon='close'
                onClick={onToggle}
              />
            </Flex>
          )}
        </AddAvailabilityEndRepeatContainer>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default AddAvailabilityEndDate;
