import { getGutter } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import Tabs from 'components/View/Tabs';
import styled from 'styled-components';

export interface IPatientInfoTabsProps {}

export const PatientInfoTabs = styled(Tabs)<IPatientInfoTabsProps>`
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  padding-bottom: ${getGutter()};
  box-sizing: border-box;

  > ul {
    justify-content: flex-start;

    > li {
      margin-right: 2rem;
      margin-left: 0 !important;
    }
  }

  > div {
    margin-top: 15px;
  }
`;
