import AuthenticationLinks from 'pages/Authentication/AuthenticationLinks/AuthenticationLinks';
import LoginAccounts from 'pages/Authentication/Login/LoginAccounts/LoginAccounts';
import LoginForm from './LoginForm/LoginForm';
import PasswordReset from './PasswordReset/PasswordReset';
import PasswordResetSent from './PasswordReset/PasswordResetSent';
import { Route, Switch } from 'react-router-dom';
import loginSubRoutes from 'routes/loginSubRoutes';
import useLogin from './Login.logic';
import { PropsWithChildren, FC } from 'react';
import { LoginWrapper } from './Login.styles';

export interface ILoginProps extends PropsWithChildren<{}> {}

const Login: FC<ILoginProps> = (props: ILoginProps): JSX.Element => {
  const {} = props;
  const { title, phrase } = useLogin();

  return (
    <LoginWrapper direction='column' justifyContent='flex-start'>
      <img src={`${process.env.PUBLIC_URL}/logos/simple.svg`} alt='Simple Logo' />
      <h1>{title}</h1>
      <p>{phrase}</p>
      <Switch>
        <Route path={loginSubRoutes.accounts.path} exact={loginSubRoutes.accounts.exact}>
          <LoginAccounts />
        </Route>
        <Route path={loginSubRoutes.login.path} exact={loginSubRoutes.login.exact}>
          <LoginForm />
        </Route>
        <Route path={loginSubRoutes.resetPassword.path} exact={loginSubRoutes.resetPassword.exact}>
          <PasswordReset />
        </Route>
        <Route path={loginSubRoutes.resetPasswordSent.path} exact={loginSubRoutes.resetPasswordSent.exact}>
          <PasswordResetSent />
        </Route>
      </Switch>
      <AuthenticationLinks />
    </LoginWrapper>
  );
};

export default Login;
