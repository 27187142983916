import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import useOnScreen from 'hooks/use-onScreen';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { RefObject, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NotificationsContext from 'store/notifications-context';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import { NotificationProps } from './Notification';

export type UseNotification = {
  language: ILanguage;
  notificationRef: RefObject<HTMLLIElement>;
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string;
};

const useNotification = (props: NotificationProps): UseNotification => {
  const { notification } = props;
  const logger = useWinstonLogger();
  const { readNotification } = useContext(NotificationsContext);
  const { formatDate } = useDate();
  const { elementRef, isIntersecting } = useOnScreen();
  const language = useSelector((state: IReduxState) => state.language.values);

  useEffect(() => {
    if (isIntersecting && !notification.read) {
      logger.log(LOG_COMPONENT, `read notification with id ${notification.id}`);
      readNotification(notification.id);
    }
  }, [isIntersecting]);

  return { language, notificationRef: elementRef as RefObject<HTMLLIElement>, formatDate };
};

export default useNotification;
