import { Flex, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';

export interface IAvailableSlotsSliderHeaderWrapperProps {}

export const AvailableSlotsSliderHeaderWrapper = styled(Flex)<IAvailableSlotsSliderHeaderWrapperProps>`
  margin-bottom: 0.75rem;
`;

export interface IAvailableSlotsSliderHeaderDayProps {
  isToday: boolean;
}

export const AvailableSlotsSliderHeaderDay = styled.div<IAvailableSlotsSliderHeaderDayProps>`
  flex: 1;
  text-align: center;

  &:not(:last-child) {
    padding-right: 0.5rem;
    border-right: 1px solid ${rgba(styles.colors.brand.dark, 0.1)};
    margin-right: 0.5rem;
  }

  > div {
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    border-radius: 999px;
    margin: 0 auto;

    ${({ isToday }: IAvailableSlotsSliderHeaderDayProps) =>
      isToday
        ? css`
            background-color: ${rgba(styles.colors.brand.main, 0.6)};

            * {
              color: ${styles.colors.brand.lightest};
            }
          `
        : css``}

    > span {
      margin-top: 0.1rem;
    }
  }
`;
