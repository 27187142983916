import usePatientAppointments from './PatientAppointments.logic';
import styles from './PatientAppointments.module.scss';
import AppointmentsList from './AppointmentsList';
import NoAppointments from './NoAppointments';

export type PatientAppointmentsProps = {};

const PatientAppointments: React.FC<PatientAppointmentsProps> = (
  props: React.PropsWithChildren<PatientAppointmentsProps>
) => {
  const {} = props;
  const {
    language,
    upcomingAppointments,
    hasMoreUpcomingAppointments,
    pastAppointments,
    hasMorePastAppointments,
    loadMoreUpcomingAppointmentsHandler,
    loadMorePastAppointmentsHandler,
    deleteAppointmentHandler,
  } = usePatientAppointments();

  if (upcomingAppointments === undefined || pastAppointments === undefined) return <></>;

  if (upcomingAppointments.length === 0 && pastAppointments.length === 0) return <NoAppointments />;

  return (
    <>
      {upcomingAppointments.length > 0 ? (
        <section className={styles.upcoming}>
          <AppointmentsList
            title={language.upcomingF}
            appointments={upcomingAppointments}
            hasMoreAppointments={hasMoreUpcomingAppointments}
            allLoadedText={language.youHaveNoMoreAppointments}
            onDeleteAppointment={deleteAppointmentHandler}
            onLoadMore={loadMoreUpcomingAppointmentsHandler}
          />
        </section>
      ) : (
        <p className={styles['no-upcoming-appointments']}>{language.noAppointmentsScheduled}</p>
      )}
      {pastAppointments.length > 0 ? (
        <section className={styles.past}>
          <div>
            <AppointmentsList
              title={language.pastMultipleF}
              appointments={pastAppointments}
              hasMoreAppointments={hasMorePastAppointments}
              allLoadedText={language.youHaveNoMoreAppointments}
              onLoadMore={loadMorePastAppointmentsHandler}
            />
          </div>
        </section>
      ) : (
        <p className={styles['no-past-appointments']}>{language.appointmentsHistoryWillBeDisplayedAfterFirst}</p>
      )}
    </>
  );
};

export default PatientAppointments;
