import FileSchema from 'schemas/FileSchema';
import useHttp from './http.service';
import FilesMapper from 'mappers/FileMapper';
import FileDTO from 'dtos/FileDTO';
import MessageDTO, { MessageState } from 'dtos/MessageDTO';
import MessageSchema from 'schemas/MessageSchema';
import MessageMapper from 'mappers/MessageMapper';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { communicationAPI } from 'apis';

const fileMapper = FilesMapper();
const messageMapper = MessageMapper();

export interface IUseFilesService {
  getFilesBetweenUsers: (userId: string) => Promise<FileDTO[]>;
  getFilePreview: (fileId: string) => Promise<string>;
  getFile: (fileId: string) => Promise<void>;
  getNrFilesBetweenUsers: (userId: string) => Promise<number>;
  uploadFile: (calleeId: string, appointmentId: string, file: File) => Promise<MessageDTO>;
}

const useFilesService = (): IUseFilesService => {
  const { get, post } = useHttp();
  const userId = useSelector((state: IReduxState) => state.auth.id)!;

  const getFilesBetweenUsers = (userId: string): Promise<FileDTO[]> => {
    return new Promise((resolve, reject) => {
      get(`${communicationAPI}/fileShares/history/${userId}`)
        .then((files: FileSchema[]) => {
          resolve(files.map((file: FileSchema) => fileMapper.toInterface(file)));
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getFilePreview = (fileId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      get(`${communicationAPI}/fileShares/${fileId}/preview`)
        .then((blobUrl: string) => {
          resolve(blobUrl);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getFile = (fileId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      get(`${communicationAPI}/fileShares/${fileId}`)
        .then((fileUrl: string) => {
          window.open(fileUrl, '_blank')!.focus();
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getNrFilesBetweenUsers = (userId: string): Promise<number> => {
    return new Promise((resolve, reject) => {
      get(`${communicationAPI}/fileShares/count/${userId}`)
        .then((nrFiles: number) => {
          resolve(nrFiles);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const uploadFile = (calleeId: string, appointmentId: string, file: File): Promise<MessageDTO> => {
    const body = new FormData();
    body.append('appointmentId', appointmentId);
    body.append('file', file, file.name);

    return new Promise((resolve, reject) => {
      post(`${communicationAPI}/fileShares/${calleeId}`, undefined, body)
        .then((newMessage: MessageSchema) => {
          const message = messageMapper.toInterface(userId, newMessage);
          message.state = MessageState.SENT;
          resolve(message);
        })
        .catch(() => {
          reject();
        });
    });
  };

  return { getFilesBetweenUsers, getFilePreview, getFile, getNrFilesBetweenUsers, uploadFile };
};

export default useFilesService;
