import SpecialtyDTO from 'dtos/SpecialtyDTO';
import ILanguage from 'interfaces/ILanguage';
import IOption from 'interfaces/IOption';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useMedicsService from 'services/medics.service';

export interface IUseAddAvailabilitySpecialty {
  language: ILanguage;
  specialties: IOption[];
}

const useAddAvailabilitySpecialty = (): IUseAddAvailabilitySpecialty => {
  const { getCurrentMedicSpecialties } = useMedicsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [specialties, setSpecialties] = useState<IOption[]>([]);

  useEffect(() => {
    getCurrentMedicSpecialties().then((specialties: SpecialtyDTO[]) => {
      setSpecialties(
        specialties.map((specialty: SpecialtyDTO) => ({ id: specialty.id, name: specialty.name } as IOption))
      );
    });
  }, []);

  return { language, specialties };
};

export default useAddAvailabilitySpecialty;
