import { TagsInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import IReduxState from 'interfaces/IReduxState';
import { OnboardingParagraph } from 'pages/Onboarding/Onboarding.styles';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

export interface IPatientHealthStepProps extends PropsWithChildren<{}> {}

const PatientHealthStep: FC<IPatientHealthStepProps> = (props: IPatientHealthStepProps) => {
  const {} = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <>
      <h1>{language.aboutYourHealth}</h1>
      <OnboardingParagraph>{language.tellUsAboutYourHealth}</OnboardingParagraph>
      <TagsInput name='diseases' icon='chronicDisease' placeholder={language.knownChronicDiseases} />
      <TagsInput name='medication' icon='pills' placeholder={language.medication} />
      <TagsInput name='allergies' icon='allergy' placeholder={language.knownAllergies} />
    </>
  );
};

export default PatientHealthStep;
