import useClickOutside from 'hooks/use-clickOutside';
import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import { useRef, useState } from 'react';
import { TimeInputProps } from './TimeInput';

export type UseTimeInput = {
  showTimePicker: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string;
  setShowTimePicker: React.Dispatch<React.SetStateAction<boolean>>;
  openDialogHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeHandler: (hour?: number, minutes?: number) => void;
};

const useTimeInput = (props: TimeInputProps): UseTimeInput => {
  const { inputValue: value, changeDateOrTimeHandler, focusHandler } = props;
  const { formatDate } = useDate();
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, setShowTimePicker);

  const openDialogHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowTimePicker(true);
    focusHandler(event);
  };

  const changeHandler = (hour?: number, minutes?: number): void => {
    const newValue = value as Date;
    if (hour !== undefined) newValue.setHours(hour);
    if (minutes !== undefined) newValue.setMinutes(minutes);
    changeDateOrTimeHandler!(newValue);
  };

  return { showTimePicker, containerRef, formatDate, setShowTimePicker, openDialogHandler, changeHandler };
};

export default useTimeInput;
