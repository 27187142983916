import { ToastTypes } from 'components/View/Toast';
import EventDTO from 'dtos/EventDTO';
import { iconsTemp } from 'iconsTemp';
import { IDayGroup } from 'interfaces/IGroup';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useSchedulesService from 'services/schedules.service';
import { feedbackActions } from 'store/redux/feedback-slice';
import { updateUndefinedArray } from 'utils/general';
import { groupByDay, groupByYear } from 'utils/group';
import { LOG_COMPONENT, LOG_HTTP } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export type UsePatientAppointments = {
  language: ILanguage;
  upcomingAppointments?: IDayGroup<IDayGroup<EventDTO>>[];
  hasMoreUpcomingAppointments: boolean;
  pastAppointments?: IDayGroup<IDayGroup<EventDTO>>[];
  hasMorePastAppointments: boolean;
  loadMoreUpcomingAppointmentsHandler: () => void;
  loadMorePastAppointmentsHandler: () => void;
  deleteAppointmentHandler: (eventId: string) => void;
};

const usePatientAppointments = (): UsePatientAppointments => {
  const logger = useWinstonLogger();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { getPatientNextAppointments, getPatientPastAppointments } = useSchedulesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [upcomingAppointments, setUpcomingAppointments] = useState<EventDTO[] | undefined>();
  const [upcomingAppointmentsPage, setUpcomingAppointmentsPage] = useState<number>(0);
  const [hasMoreUpcomingAppointments, setHasMoreUpcomingAppointments] = useState<boolean>(false);
  const [pastAppointments, setPastAppointments] = useState<EventDTO[] | undefined>();
  const [pastAppointmentsPage, setPastAppointmentsPage] = useState<number>(0);
  const [hasMorePastAppointments, setHasMorePastAppointments] = useState<boolean>(false);

  useEffect(() => {
    getUpcomingAppointmentsEvents();
    getPastAppointmentsEvents();
  }, []);

  useEffect(() => {
    if (!upcomingAppointments && upcomingAppointmentsPage > 0 && hasMoreUpcomingAppointments)
      getUpcomingAppointmentsEvents();
  }, [upcomingAppointments, upcomingAppointmentsPage, hasMoreUpcomingAppointments]);

  useEffect(() => {
    if (!language.scheduleDone) return;

    const successParameter = new URLSearchParams(search).get('success');

    // query parameter resulted in the callback from the wallet payment success
    if (successParameter) {
      const paymentWasSuccessfull = successParameter === 'true';

      logger.log(LOG_COMPONENT, `showing payment ${paymentWasSuccessfull ? 'success' : 'failed'} toast`);

      if (paymentWasSuccessfull) {
        dispatch(
          feedbackActions.addMessage({
            type: ToastTypes.SUCCESS,
            icon: iconsTemp.calendar,
            title: language.paymentDone,
          })
        );
      } else {
        dispatch(
          feedbackActions.addMessage({
            type: ToastTypes.ERROR,
            icon: iconsTemp.close,
            title: language.paymentFailed,
          })
        );
      }

      history.replace({ search: '' });
    }
  }, []);

  const getUpcomingAppointmentsEvents = useCallback((): void => {
    logger.log(LOG_HTTP, `getting appointments' events at page ${upcomingAppointmentsPage}`);

    getPatientNextAppointments(upcomingAppointmentsPage).then(
      (appointmentsPagination: { nextEvents: EventDTO[]; finishedEvents: EventDTO[]; last: boolean }) => {
        const { nextEvents, finishedEvents, last } = appointmentsPagination;

        if (finishedEvents.length > 0)
          setPastAppointments((prevState?: EventDTO[]) => updateUndefinedArray(finishedEvents, prevState));

        if (nextEvents.length > 0 || last)
          setUpcomingAppointments((prevState?: EventDTO[]) => updateUndefinedArray(nextEvents, prevState));

        if (!last) setUpcomingAppointmentsPage((prevState: number) => ++prevState);
        else logger.log(LOG_HTTP, `next appointments' pagination reached the end at page ${upcomingAppointmentsPage}`);

        setHasMoreUpcomingAppointments(!last);
      }
    );
  }, [upcomingAppointmentsPage]);

  const getPastAppointmentsEvents = useCallback((): void => {
    logger.log(LOG_HTTP, `getting past appointments' events at page ${pastAppointmentsPage}`);

    getPatientPastAppointments(pastAppointmentsPage).then(
      (appointmentsPagination: { events: EventDTO[]; last: boolean }) => {
        const { events, last } = appointmentsPagination;

        setPastAppointments((prevState?: EventDTO[]) => updateUndefinedArray(events, prevState));

        if (!last) setPastAppointmentsPage((prevState: number) => ++prevState);
        else logger.log(LOG_HTTP, `past appointments' pagination reached the end at page ${pastAppointmentsPage}`);

        setHasMorePastAppointments(!last);
      }
    );
  }, [pastAppointmentsPage]);

  const loadMoreUpcomingAppointmentsHandler = (): void => getUpcomingAppointmentsEvents();

  const loadMorePastAppointmentsHandler = (): void => getPastAppointmentsEvents();

  const deleteAppointmentHandler = (eventId: string): void => {
    logger.log(LOG_COMPONENT, `deleting appointment event with id ${eventId}`);

    setUpcomingAppointments((prevState: EventDTO[] | undefined) =>
      prevState!.filter((appointmentEvent: EventDTO) => appointmentEvent.id !== eventId)
    );
  };

  return {
    language,
    upcomingAppointments: upcomingAppointments
      ? groupByYear(groupByDay(upcomingAppointments, true, false), true, false)
      : undefined,
    hasMoreUpcomingAppointments,
    pastAppointments: pastAppointments ? groupByYear(groupByDay(pastAppointments)) : undefined,
    hasMorePastAppointments,
    loadMoreUpcomingAppointmentsHandler,
    loadMorePastAppointmentsHandler,
    deleteAppointmentHandler,
  };
};

export default usePatientAppointments;
