import { useState } from 'react';
import { ButtonProps } from './Button';

export type UseButton = {
  mouseIsOver: boolean;
  setMouseIsOver: React.Dispatch<React.SetStateAction<boolean>>;
  clickHandler: (event: React.MouseEvent) => void;
  mouseDownHandler: (event: React.MouseEvent) => void;
};

const useButton = (props: ButtonProps): UseButton => {
  const { disabled, onClick } = props;
  const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);

  const clickHandler = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (!disabled) onClick();
  };

  const mouseDownHandler = (event: React.MouseEvent): void => {
    event.preventDefault();
  };

  return { mouseIsOver, setMouseIsOver, clickHandler, mouseDownHandler };
};

export default useButton;
