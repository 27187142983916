import { Button } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { Gender } from 'dtos/EntityDTO';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import { getDoctorAbreviation } from 'utils/language';
import { NoTodayEventsWrapper } from './NoTodayEvents.styles';

export interface INoTodayEventsProps extends PropsWithChildren<{}> {}

const NoTodayEvents: FC<INoTodayEventsProps> = (props: INoTodayEventsProps): JSX.Element => {
  const {} = props;
  const history = useHistory();
  const language = useSelector((state: IReduxState) => state.language.values);
  const userName = useSelector((state: IReduxState) => state.entity!.name);
  const userGender = useSelector((state: IReduxState) => state.entity!.gender);

  return (
    <NoTodayEventsWrapper direction='column' justifyContent='flex-start'>
      <h1>
        <span>{userGender === Gender.FEMALE ? language.welcomeF : language.welcomeM}</span>,&nbsp;
        {userGender && userName && getDoctorAbreviation(language, userGender, userName)}
      </h1>
      <h4>{language.startAddingAvailability}</h4>
      <img />
      <Button icon='plus' label={language.addAvailability} onClick={() => history.push(routes.addAvailability.path)} />
    </NoTodayEventsWrapper>
  );
};

export default NoTodayEvents;
