import { FC, PropsWithChildren } from 'react';
import useCalendar from './Calendar.logic';
import { SwitchTransition, Transition, TransitionStatus } from 'react-transition-group';
import styles from './Calendar.module.scss';
import sass from 'styles/exports.module.scss';
import MonthView from './Views/MonthView';
import WeekView from './Views/WeekView';
import CalendarHeader from './CalendarHeader';
import { CalendarViews } from 'mappers/EventMapper';

export interface CalendarProps {}

const Calendar: FC<CalendarProps> = (props: PropsWithChildren<CalendarProps>): JSX.Element => {
  const {} = props;
  const { selectedView, selectedEvent, selectEventHandler, seeMoreClickHandler, unselectEventHandler } = useCalendar();

  return (
    <>
      <CalendarHeader />
      {
        /*<SwitchTransition>
        <Transition key={selectedView} timeout={+sass.transition}>
          {(state: TransitionStatus) => (*/
        <div data-testid='agenda-calendar' className={`${styles.calendar} ${/*styles[state]*/ ''}`}>
          {selectedView === CalendarViews.MONTH && (
            <MonthView
              selectedEvent={selectedEvent}
              onSelectEvent={selectEventHandler}
              onSeeMore={seeMoreClickHandler}
              onCloseEventPopup={unselectEventHandler}
            />
          )}
          {selectedView === CalendarViews.WEEK && (
            <WeekView
              selectedEvent={selectedEvent}
              onSelectEvent={selectEventHandler}
              onCloseEventPopup={unselectEventHandler}
            />
          )}
        </div>
        /* )}
              </Transition> 
      </SwitchTransition>*/
      }
    </>
  );
};

export default Calendar;
