import Popup from 'components/View/Popup';
import InputStep from '../NumberInput/InputStep';
import styles from './TimePicker.module.scss';

export type TimePickerProps = {
  dataTestId?: string;
  show: boolean;
  hour: number;
  minutes: number;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (hour?: number, minutes?: number) => void;
};

const TimePicker: React.FC<TimePickerProps> = (props: React.PropsWithChildren<TimePickerProps>) => {
  const { dataTestId, show, hour, minutes, close, onChange } = props;

  return (
    <Popup className={styles['time-picker']} show={show} close={close}>
      <div>
        <InputStep
          valueDataTestId={`${dataTestId}-stepUpHoursValue`}
          upDataTestId={`${dataTestId}-stepUpHoursButton`}
          downDataTestId={`${dataTestId}-stepDownHoursButton`}
          defaultValue={hour}
          min={0}
          max={23}
          step={1}
          onChange={(value: number) => onChange(value)}
        />
        <span>:</span>
        <InputStep
          valueDataTestId={`${dataTestId}-stepUpMinutesValue`}
          upDataTestId={`${dataTestId}-stepUpMinutesButton`}
          downDataTestId={`${dataTestId}-stepDownMinutesButton`}
          defaultValue={minutes}
          min={0}
          max={55}
          step={5}
          onChange={(value: number) => onChange(undefined, value)}
        />
      </div>
    </Popup>
  );
};

export default TimePicker;
