import {
  Flex,
  getColumnsWidth,
  getGutter,
  getMargin,
  MultiOption,
  rgba,
  styles,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IRescheduleAvailabilitiesWrapperProps {}

export const RescheduleAvailabilitiesWrapper = styled(Flex)<IRescheduleAvailabilitiesWrapperProps>`
  position: relative;
  width: calc(100vw - (2 * ${getMargin()}));
  margin-top: 1rem;
`;

export interface IRescheduleAvailabilitiesLeftContentProps {}

export const RescheduleAvailabilitiesLeftContent = styled.div<IRescheduleAvailabilitiesLeftContentProps>`
  width: ${getColumnsWidth({ nrColumns: 4 })};
  padding-right: ${getGutter()};
  margin-right: ${getGutter()};
`;

export interface IAvailabilitiesEventsMultiOptionProps {}

export const RescheduleAvailabilitiesEventsMultiOption = styled(MultiOption)<IAvailabilitiesEventsMultiOptionProps>`
  margin-top: 1.5rem;

  > div {
    flex-wrap: wrap;

    > * {
      width: auto;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export interface IAvailabilitiesNoEventsMessageProps {}

export const RescheduleAvailabilitiesNoEventsMessage = styled.p<IAvailabilitiesNoEventsMessageProps>`
  margin-top: 1rem;
  color: ${rgba(styles.colors.brand.dark, 0.75)};
`;
