import IRoute, {
  IAgendaSubRoutes,
  IAppointmentDetailSubRoutes,
  IHomeSubRoutes,
  IMedicsSubRoutes,
  IPatientsSubRoutes,
  ITodaySubRoutes,
} from 'interfaces/IRoute';
import { ProfileType } from 'dtos/EntityDTO';

const userDetailsSubRoutes: IAppointmentDetailSubRoutes = {
  patientData: {
    path: 'patientData',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  notes: {
    path: 'notes',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  galleryAndFiles: {
    path: 'files',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
};

const todaySubRoutes: ITodaySubRoutes = {
  appointments: {
    path: '/home/todayAppointments/:id?',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  appointmentDetail: {
    path: '/home/todayAppointments/:id/:tab?',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
    subRoutes: userDetailsSubRoutes,
  } as IRoute,
};

const agendaSubRoutes: IAgendaSubRoutes = {
  appointmentsSlots: {
    path: '/home/agenda/:date',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  addAvailability: {
    path: '/home/agenda/:date/addAvailability',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  editAvailability: {
    path: '/home/agenda/:date/editAvailability/:eventId',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
};

const patientsSubRoutes: IPatientsSubRoutes = {
  patientDetails: {
    path: '/home/patients/:id/:tab?',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  },
};

const medicsSubRoutes: IMedicsSubRoutes = {
  medicDetails: {
    path: '/home/medics/:id',
    exact: true,
    meta: { auth: true, roles: [ProfileType.PATIENT] },
  },
};

const homeSubRoutes: IHomeSubRoutes = {
  today: {
    path: '/home/todayAppointments/:id?',
    linkPath: '/home/todayAppointments',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
    subRoutes: todaySubRoutes,
  } as IRoute,
  agenda: {
    path: '/home/agenda/:date?',
    linkPath: '/home/agenda',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
    subRoutes: agendaSubRoutes,
  } as IRoute,
  searchMedics: {
    path: '/home/search',
    exact: true,
    meta: { auth: true, roles: [ProfileType.PATIENT] },
  } as IRoute,
  patientAppointments: {
    path: '/home/myappointments',
    exact: true,
    meta: { auth: true, roles: [ProfileType.PATIENT] },
  } as IRoute,
  patients: {
    path: '/home/patients',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
    subRoutes: patientsSubRoutes,
  } as IRoute,
  medics: {
    path: '/home/medics',
    exact: true,
    meta: { auth: true, roles: [ProfileType.PATIENT] },
    subRoutes: medicsSubRoutes,
  } as IRoute,
  profile: {
    path: '/home/profile',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
};

export default homeSubRoutes;
