import {
  Container,
  Flex,
  getColumnsWidth,
  ITinyTextProps,
  rgba,
  styles,
  TinyText,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';

export interface IPatientDetailsAppointmentsWrapperProps {}

export const PatientDetailsAppointmentsWrapper = styled(Flex)<IPatientDetailsAppointmentsWrapperProps>`
  width: ${getColumnsWidth({ nrColumns: 4 })};

  > * {
    > h5 {
      margin-bottom: 0.75rem;
      margin-left: 1.5rem;
    }
  }
`;

export interface IAppointmentsListContainerProps {
  arePast: boolean;
}

export const AppointmentsListContainer = styled.div<IAppointmentsListContainerProps>`
  width: 100%;

  &:not(:first-child) {
    margin-top: 2.5rem;
  }

  ${({ arePast }: IAppointmentsListContainerProps) =>
    arePast
      ? css`
          > h5,
          > div > span {
            color: ${rgba(styles.colors.brand.dark, 0.7)};
          }
        `
      : css``}
`;

export interface IAppointmentsYearProps {}

export const AppointmentsYear = styled(TinyText).attrs((_: ITinyTextProps) => ({
  as: 'b',
}))<IAppointmentsYearProps>`
  display: block;
  margin-bottom: 1rem;
  color: ${rgba(styles.colors.brand.dark, 0.7)};
  text-align: center;
`;

export interface IDayAppointmentsWrapperProps {
  hasPassed: boolean;
}

export const DayAppointmentsWrapper = styled(Container)<IDayAppointmentsWrapperProps>`
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 1.25rem 1.5rem;
  ${({ hasPassed }: IDayAppointmentsWrapperProps) =>
    hasPassed
      ? css`
          background-color: ${rgba(styles.colors.brand.main, 0.05)};
        `
      : css``};

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  > * {
    &:first-child {
      width: auto;
      margin-right: 1.25rem;
    }
  }
`;
