import Tabs from 'components/View/Tabs';
import useIndex from 'pages/Index/Index.logic';
import styles from './Index.module.scss';
import TodaysAgenda from 'pages/Index/TodaysAgenda/TodaysAgenda';
import { illustrations } from 'illustrations';
import { Transition, TransitionStatus } from 'react-transition-group';
import { Route, Redirect } from 'react-router-dom';
import Agenda from 'pages/Index/Agenda/Agenda';
import routes from 'routes/routes';
import SearchMedics from './SearchMedics/SearchMedics';
import PatientAppointments from './PatientAppointments/PatientAppointments';
import { getDoctorAbreviation } from 'utils/language';
import MyProfile from './MyProfile/MyProfile';
import RelatedUsers from './RelatedUsers/RelatedUsers';
import MedicDetails from './RelatedUsers/MedicDetails/MedicDetails';
import Alerts from 'components/Alerts/Alerts';
import PatientDetails from './RelatedUsers/PatientDetails/PatientDetails';

export type IndexProps = {};

const Index: React.FC<IndexProps> = (props: React.PropsWithChildren<IndexProps>) => {
  const {} = props;
  const {
    language,
    showGoodWorkMessage,
    homeRoutes,
    patientsSubRoutes,
    medicsSubRoutes,
    tabs,
    isSmartphoneScreen,
    hideMenu,
    isMedic,
    isPatient,
    userName,
    userGender,
  } = useIndex();

  return (
    <>
      <Route path={routes.home.path} exact={true}>
        {isMedic && <Redirect to={homeRoutes.today.linkPath!} />}
        {isPatient && <Redirect to={homeRoutes.searchMedics.path} />}
      </Route>
      {isMedic && (
        <Transition in={showGoodWorkMessage} timeout={parseInt(styles.goodWorkTransition)} mountOnEnter unmountOnExit>
          {(state: TransitionStatus) => (
            <div className={`${styles['good-work']} ${styles[state]}`}>
              <img src={illustrations.goodWork} alt='' />
              <p>
                {language.goodWork},&nbsp;
                <b>{userName && userGender && getDoctorAbreviation(language, userGender, userName)}!</b>
              </p>
            </div>
          )}
        </Transition>
      )}
      <Tabs className={styles.tabs} tabs={tabs} borderWidth='1rem' hideLabels={isSmartphoneScreen} hideTabs={hideMenu}>
        <Route path={homeRoutes.today.path} exact={homeRoutes.today.exact}>
          <TodaysAgenda />
        </Route>
        <Route path={homeRoutes.agenda.path} exact={homeRoutes.agenda.exact}>
          <Agenda />
        </Route>
        <Route path={homeRoutes.searchMedics.path} exact={homeRoutes.searchMedics.exact}>
          <SearchMedics />
        </Route>
        <Route path={homeRoutes.patientAppointments.path} exact={homeRoutes.patientAppointments.exact}>
          <PatientAppointments />
        </Route>
        <Route path={[homeRoutes.medics.path, homeRoutes.patients.path]} exact={homeRoutes.medics.exact}>
          <RelatedUsers />
        </Route>
        <Route path={patientsSubRoutes.patientDetails.path} exact={patientsSubRoutes.patientDetails.exact}>
          <PatientDetails />
        </Route>
        <Route path={medicsSubRoutes.medicDetails.path} exact={medicsSubRoutes.medicDetails.exact}>
          <MedicDetails />
        </Route>
        <Route path={homeRoutes.profile.path} exact={homeRoutes.profile.exact}>
          <MyProfile />
        </Route>
      </Tabs>
      {isPatient && <div className={styles['background-circle']} />}
      <Alerts />
    </>
  );
};

export default Index;
