import routes from 'routes/routes';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export type UseAuthGuard = {
  canActivate: (meta: any) => boolean;
};

const useAuthGuard = (): UseAuthGuard => {
  const history = useHistory();
  const userId = useSelector((state: IReduxState) => state.auth.id) as string;

  const isLoggedIn = (): boolean => {
    return userId !== null && userId !== undefined;
  };

  const canActivate = (meta: any): boolean => {
    if (!isLoggedIn() && meta.auth) {
      history.push(routes.login.path);
      return false;
    }

    return true;
  };

  return { canActivate };
};

export default useAuthGuard;
