import Button, { ButtonTypes } from 'components/Form/Button';
import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './AccordionItem.module.scss';
import sass from 'styles/exports.module.scss';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';

export interface AccordionItemProps {
  title: string;
  disabled?: boolean;
  opened?: boolean;
  onClick?: () => void;
}

const AccordionItem: FC<AccordionItemProps> = (props: PropsWithChildren<AccordionItemProps>) => {
  const { title, opened, disabled, children, onClick } = props;
  const contentContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disabled && opened && onClick) onClick();
  }, [disabled]);

  return (
    <div className={styles['accordion-item']}>
      <div className={styles.header}>
        <Button
          className={styles.button}
          type={ButtonTypes.SECONDARY}
          label={title}
          active={opened}
          disabled={disabled}
          onClick={onClick ? onClick : () => {}}
        />
      </div>
      <Transition in={opened} timeout={+sass.transition} mountOnEnter={true} unmountOnExit={true}>
        {(state: TransitionStatus) => (
          <div
            className={`${styles.content} ${styles[state]}`}
            style={{ height: state === 'entered' ? contentContainer.current?.scrollHeight : 0 }}
            ref={contentContainer}>
            {children}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default AccordionItem;
