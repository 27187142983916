import { useState } from 'react';

export type UsePasswordInput = {
  showPassword: boolean;
  toggleShowPasswordHandler: () => void;
};

const usePasswordInput = (): UsePasswordInput => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPasswordHandler = (): void => {
    setShowPassword((prevState: boolean) => !prevState);
  };

  return { showPassword, toggleShowPasswordHandler };
};

export default usePasswordInput;
