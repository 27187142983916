import { useContext, useEffect } from 'react';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import { useWinstonLogger } from 'winston-react';
import { LOG_COMPONENT, LOG_SOCKET } from 'utils/logger';
import AppointmentContext from 'store/appointment-context';
import useSocket from 'hooks/use-socket';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import { userIsPatient } from 'utils/user';
import { IAppointmentCallRouteParams } from 'interfaces/IRouteParams';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { AppointmentStartSocketMessage } from 'interfaces/ISocketMessages';
import CallContext from 'store/call-context';

export type IUseAppointmentCall = {
  calleeId: string;
  appointmentId: string;
  endAppointmentCallHandler: () => void;
};

const useAppointmentCall = (): IUseAppointmentCall => {
  const logger = useWinstonLogger();
  const location = useLocation();
  const history = useHistory();
  const { listenSocketMessage } = useSocket();
  const { appointmentEvent, endAppointmentCallHandler } = useContext(AppointmentContext);
  const { startCallHandler } = useContext(CallContext);
  const userType = useSelector((state: IReduxState) => state.auth.type);

  useEffect(() => {
    listenSocketMessage('starting-appointment', (data: AppointmentStartSocketMessage) => {
      const { to } = data;
      startCallHandler([to]);
    });

    listenSocketMessage('leave-call', () => {
      logger.log(LOG_SOCKET, 'received info to leave appointment call');

      const pathInfo = matchPath<IAppointmentCallRouteParams>(location.pathname, {
        path: appointmentSubRoutes.call.path,
      })!;

      if (userIsPatient(userType)) {
        pathInfo.params.side = undefined;
        pathInfo.params.tab = undefined;
      }

      history.push(generatePath(appointmentSubRoutes.end.path, { ...pathInfo.params }));
    });

    const unlisten = history.listen(() => {
      if (history.action === 'POP') {
        logger.log(LOG_COMPONENT, 'user clicked on the back button while in a call');
        endAppointmentCallHandler();
      }
    });

    return () => {
      history.listen(unlisten);
    };
  }, []);

  return {
    calleeId: appointmentEvent!.appointment!.user!.id,
    appointmentId: appointmentEvent!.appointment!.id,
    endAppointmentCallHandler,
  };
};

export default useAppointmentCall;
