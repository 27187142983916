import { FC, PropsWithChildren } from 'react';
import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import styles from './CallLoader.module.scss';

export interface CallLoaderProps {
  state: string;
}

const CallLoader: FC<CallLoaderProps> = (props: PropsWithChildren<CallLoaderProps>) => {
  const { state } = props;
  const language = useSelector((state: IReduxState) => state.language.values) as ILanguage;

  return (
    <div className={`${styles.loader} ${styles[state]}`}>
      <div>
        <Icon className={styles.icon} src={iconsTemp.loading} />
        <p>{language.waitFewSeconds}</p>
      </div>
    </div>
  );
};

export default CallLoader;
