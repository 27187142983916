/**
 * TODO: will check if medic has missing fields on profile
 * to decide which component it will show
 * and can decide if it will help spread smartal's info on other platforms
 * such as LinkedIn
 */

export interface IUseNoTodayAppointments {}

const useNoTodayAppointments = (): IUseNoTodayAppointments => {
  return {};
};

export default useNoTodayAppointments;
