import { FC, PropsWithChildren, useContext } from 'react';
import { Switch, Route, Redirect, useLocation, generatePath, useParams } from 'react-router-dom';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import AppointmentContext from 'store/appointment-context';
import { CallContextProvider } from 'store/call-context';
import WaitingRoom from './WaitingRoom/WaitingRoom';
import AppointmentEnd from './AppointmentEnd/AppointmentEnd';
import routes from 'routes/routes';
import AppointmentCall from './AppointmentCall/AppointmentCall';
import { IAppointmentCallRouteParams } from 'interfaces/IRouteParams';
import { IAppointmentRouteState } from 'interfaces/IRouteStates';
//import styles from './Appointment.module.scss';

export interface AppointmentProps {}

const Appointment: FC<AppointmentProps> = (props: PropsWithChildren<AppointmentProps>): JSX.Element => {
  const {} = props;
  const location = useLocation<IAppointmentRouteState>();
  const { appointmentId } = useParams<IAppointmentCallRouteParams>();
  const { appointmentIsVerified } = useContext(AppointmentContext);

  return (
    <Switch>
      <Route path={routes.appointment.path} exact={true}>
        <Redirect
          to={{
            pathname: generatePath(appointmentSubRoutes.waitingRoom.path, { appointmentId }),
            state: location.state,
          }}
        />
      </Route>
      {appointmentIsVerified && (
        <>
          <Route path={[appointmentSubRoutes.waitingRoom.path, appointmentSubRoutes.call.path]}>
            <CallContextProvider>
              <Switch>
                <Route path={appointmentSubRoutes.waitingRoom.path} exact={appointmentSubRoutes.waitingRoom.exact}>
                  <WaitingRoom />
                </Route>
                <Route path={appointmentSubRoutes.call.path} exact={appointmentSubRoutes.call.exact}>
                  <AppointmentCall />
                </Route>
              </Switch>
            </CallContextProvider>
          </Route>
          <Route path={appointmentSubRoutes.end.path} exact={appointmentSubRoutes.end.exact}>
            <AppointmentEnd />
          </Route>
        </>
      )}
    </Switch>
  );
};

export default Appointment;
