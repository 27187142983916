import { getColumnsWidth, getGutter, getMargin } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IStickyBackButtonWrapperProps {}

export const StickyBackButtonWrapper = styled.div<IStickyBackButtonWrapperProps>`
  position: sticky;
  width: ${getColumnsWidth()};
  padding-right: ${getGutter()};

  > * {
    margin-top: ${getMargin()};
  }
`;
