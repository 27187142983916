import Button, { ButtonThemes, ButtonTypes } from 'components/Form/Button';
import { iconsTemp } from 'iconsTemp';
//import styles from './Buttons.module.scss';

export type ButtonsProps = {};

const Buttons: React.FC<ButtonsProps> = (props: React.PropsWithChildren<ButtonsProps>) => {
  const {} = props;

  return (
    <div>
      <div>
        <div>
          <h4>Main button</h4>
          <span>(high emphasis)</span>
        </div>
        <div>
          <h4>Secondary button</h4>
          <span>(medium emphasis)</span>
        </div>
        <div>
          <h4>Text button</h4>
          <span>(low emphasis)</span>
        </div>
      </div>
      <div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' onClick={() => {}} />
          <Button label='Just text' onClick={() => {}} />

          <Button icon={iconsTemp.add} label='Text and icon' theme={ButtonThemes.GREEN} onClick={() => {}} />
          <Button label='Just text' theme={ButtonThemes.GREEN} onClick={() => {}} />

          <Button icon={iconsTemp.add} label='Text and icon' theme={ButtonThemes.RED} onClick={() => {}} />
          <Button label='Just text' theme={ButtonThemes.RED} onClick={() => {}} />
        </div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' type={ButtonTypes.SECONDARY} onClick={() => {}} />
          <Button icon={iconsTemp.options} type={ButtonTypes.SECONDARY} onClick={() => {}} />
          <Button
            icon={iconsTemp.add}
            label='Text and icon'
            type={ButtonTypes.SECONDARY}
            theme={ButtonThemes.DARK}
            onClick={() => {}}
          />
          <Button label='Just text' type={ButtonTypes.SECONDARY} theme={ButtonThemes.DARK} onClick={() => {}} />
          <Button
            icon={iconsTemp.add}
            label='Text and icon'
            type={ButtonTypes.SECONDARY}
            theme={ButtonThemes.GREEN}
            onClick={() => {}}
          />
          <Button label='Just text' type={ButtonTypes.SECONDARY} theme={ButtonThemes.GREEN} onClick={() => {}} />
        </div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' type={ButtonTypes.TEXT} onClick={() => {}} />
          <Button label='Just text' type={ButtonTypes.TEXT} onClick={() => {}} />
          <Button icon={iconsTemp.image} type={ButtonTypes.TEXT} onClick={() => {}} />

          <Button
            icon={iconsTemp.add}
            label='Text and icon'
            type={ButtonTypes.TEXT}
            theme={ButtonThemes.DARK}
            onClick={() => {}}
          />
          <Button label='Just text' type={ButtonTypes.TEXT} theme={ButtonThemes.DARK} onClick={() => {}} />
          <Button icon={iconsTemp.image} type={ButtonTypes.TEXT} theme={ButtonThemes.DARK} onClick={() => {}} />

          <Button
            icon={iconsTemp.add}
            label='Text and icon'
            type={ButtonTypes.TEXT}
            theme={ButtonThemes.RED}
            onClick={() => {}}
          />
          <Button label='Just text' type={ButtonTypes.TEXT} theme={ButtonThemes.RED} onClick={() => {}} />
          <Button icon={iconsTemp.image} type={ButtonTypes.TEXT} theme={ButtonThemes.RED} onClick={() => {}} />
        </div>
      </div>
      <h3>Disabled</h3>
      <div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' disabled onClick={() => {}} />
          <Button label='Just text' disabled onClick={() => {}} />
        </div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' type={ButtonTypes.SECONDARY} disabled onClick={() => {}} />
          <Button icon={iconsTemp.options} type={ButtonTypes.SECONDARY} disabled onClick={() => {}} />
        </div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' type={ButtonTypes.TEXT} disabled onClick={() => {}} />
          <Button label='Just text' type={ButtonTypes.TEXT} disabled onClick={() => {}} />
          <Button icon={iconsTemp.add} type={ButtonTypes.TEXT} disabled onClick={() => {}} />

          <Button
            icon={iconsTemp.add}
            label='Text and icon'
            type={ButtonTypes.TEXT}
            theme={ButtonThemes.DARK}
            disabled
            onClick={() => {}}
          />
          <Button label='Just text' type={ButtonTypes.TEXT} theme={ButtonThemes.DARK} disabled onClick={() => {}} />
          <Button
            icon={iconsTemp.image}
            type={ButtonTypes.TEXT}
            theme={ButtonThemes.DARK}
            disabled
            onClick={() => {}}
          />

          <Button
            icon={iconsTemp.add}
            label='Text and icon'
            type={ButtonTypes.TEXT}
            theme={ButtonThemes.RED}
            disabled
            onClick={() => {}}
          />
          <Button label='Just text' type={ButtonTypes.TEXT} theme={ButtonThemes.RED} disabled onClick={() => {}} />
          <Button icon={iconsTemp.image} type={ButtonTypes.TEXT} theme={ButtonThemes.RED} disabled onClick={() => {}} />
        </div>
      </div>
      <h3>Active</h3>
      <div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' active onClick={() => {}} />
          <Button label='Just text' active onClick={() => {}} />
        </div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' type={ButtonTypes.SECONDARY} active onClick={() => {}} />
          <Button icon={iconsTemp.options} type={ButtonTypes.SECONDARY} active onClick={() => {}} />
        </div>
        <div>
          <Button icon={iconsTemp.add} label='Text and icon' type={ButtonTypes.TEXT} active onClick={() => {}} />
          <Button label='Just text' type={ButtonTypes.TEXT} active onClick={() => {}} />
          <Button icon={iconsTemp.add} type={ButtonTypes.TEXT} active onClick={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default Buttons;
