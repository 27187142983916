import {
  Button,
  Flex,
  getColumnsWidth,
  getGutter,
  ScrollView,
  Select,
  transition,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AppointmentTypesMultioption from 'components/Appointment/AppointmentTypesMultioption';
import styled from 'styled-components';

const width = getColumnsWidth({ nrColumns: 4 });

export interface IAvailableSchedulesWrapperProps {}

export const AvailableSchedulesWrapper = styled.section<IAvailableSchedulesWrapperProps>`
  position: sticky;
  top: 0;
  width: ${width};
  margin-right: ${getColumnsWidth()};

  > * {
    width: 100%;
  }
`;

export interface IAvailableSchedulesFormProps {}

export const AvailableSchedulesForm = styled(Flex)<IAvailableSchedulesFormProps>`
  flex: 1;
  width: ${width} !important;
  overflow-y: hidden;
  margin-top: 1.5rem;
`;

export interface IAvailableSchedulesScrollProps {}

export const AvailableSchedulesScroll = styled(ScrollView)<IAvailableSchedulesScrollProps>`
  width: ${width};

  > div {
    &:nth-child(2) {
      padding: 0 ${getGutter()};

      > * {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

export interface IAvailableSchedulesAppointmentsTagsProps {}

export const AvailableSchedulesAppointmentsTags = styled(
  AppointmentTypesMultioption
)<IAvailableSchedulesAppointmentsTagsProps>`
  > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 4%;
    }
  }
`;

export interface IAvailableSchedulesSelectProps {}

export const AvailableSchedulesSelect = styled(Select)<IAvailableSchedulesSelectProps>`
  > div {
    width: 100%;
  }
`;

export interface IScheduleButtonProps {}

export const ScheduleButton = styled(Button)<IScheduleButtonProps>`
  margin-top: 0.5rem;
  margin-right: auto;
  margin-left: auto;
  ${transition('opacity', 'transform')}

  &.schedule_button-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  &.schedule_button-enter,
  &.schedule_button-exit,
  &.schedule_button-exit-active {
    opacity: 0;
    transform: translateY(2rem);
  }
`;
