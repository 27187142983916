import AppointmentDTO, { AppointmentPlaces } from 'dtos/AppointmentDTO';
import EventDTO, { AppointmentEventTypes } from 'dtos/EventDTO';
import EventSchema from 'schemas/EventSchema';
import AppointmentMapper from './AppointmentMapper';
import ICalendarEvent from 'interfaces/ICalendarEvent';
import ILanguage from 'interfaces/ILanguage';

const appointmentMapper = AppointmentMapper();

export enum CalendarViews {
  MONTH = 'month',
  WEEK = 'week',
}

export const placesIcons = {
  [AppointmentPlaces.Remote]: 'videoCall',
  [AppointmentPlaces.Home]: 'address',
  [AppointmentPlaces.Office]: 'institution',
  [AppointmentEventTypes.Available]: 'calendar',
};

const EventMapper = () => {
  const toInterface = (event: EventSchema, userId?: string): EventDTO => {
    const start = new Date(event.startAt);
    const end = new Date(event.endAt);
    const appointment: AppointmentDTO | undefined = event.appointment
      ? appointmentMapper.toInterface(event.appointment, userId)
      : undefined;

    return {
      id: event.id,
      availabilityId: event.availability?.id,
      start,
      end,
      duration: (end.getTime() - start.getTime()) / 1000 / 60,
      appointment: appointment,
      place: appointment ? appointment.place : AppointmentEventTypes.Available,
      icon: appointment ? placesIcons[appointment.place] : 'calendar',
      price: event.availability?.price,
    };
  };

  const toCalendarEvent = (event: EventDTO, language: ILanguage, view: string): ICalendarEvent => {
    const place = event.appointment ? event.appointment.place : AppointmentEventTypes.Available;

    return {
      id: `${place}-${event.start.getTime()}`,
      title: language[place as keyof ILanguage],
      start: view === CalendarViews.WEEK ? event.start : undefined,
      end: view === CalendarViews.WEEK ? event.end : undefined,
      date: view === CalendarViews.MONTH ? event.start : undefined,
      defaultAllDay: view === CalendarViews.MONTH,
      extendedProps: {
        duration: event.duration,
        icon: placesIcons[place],
        place,
        events: [event],
      },
    };
  };

  return { toInterface, toCalendarEvent };
};

export default EventMapper;
