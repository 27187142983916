import { Button, Flex, getMargin, styles, transition } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const paddingTop = '0.75rem';

export interface IHeaderWrapperProps {}

export const HeaderWrapper = styled(Flex)<IHeaderWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  z-index: 15;

  > a > img,
  > div {
    position: absolute;
    z-index: 5;
  }
`;

export interface IHeaderLogoProps {
  disabled: boolean;
}

export const HeaderLogo = styled(Link)<IHeaderLogoProps>`
  top: ${paddingTop};

  ${({ disabled }: IHeaderLogoProps) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : css``}

  > img {
    left: ${getMargin()};
    height: ${styles.iconSize.big};
  }
`;

export interface IHeaderActionsContainerProps {}

export const HeaderActionsContainer = styled(Flex)<IHeaderActionsContainerProps>`
  top: calc(${getMargin()} / 2);
  right: ${getMargin()};

  > * {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export interface IHeaderAddAvailabilityButtonProps {
  animate: boolean;
}

export const HeaderAddAvailabilityButton = styled(Button)<IHeaderAddAvailabilityButtonProps>`
  max-width: ${styles.iconSize.normal};
  overflow: hidden;
  ${transition('all')}

  ${({ animate }: IHeaderAddAvailabilityButtonProps) =>
    animate
      ? css`
          &:hover {
            width: fit-content !important;
            max-width: 16rem;
          }
        `
      : css``}

  > span {
    white-space: nowrap;
  }
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .header {
    padding: (getMargin($tablet) / 2) getMargin($tablet);

    > .page-name {
      flex-direction: column;
      align-items: flex-start;

      > h1 {
        margin-bottom: 0.25rem;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .header {
    padding: (getMargin($smartphone) / 2) getMargin($smartphone);

    > .page-name {
      > h1 {
        font-weight: 600;
      }
    }
  }
}
*/
