import { getColumnsWidth, ScrollView } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AvailableSlotsSlider from 'components/AvailableSlotsSlider/AvailableSlotsSlider';
import styled, { css } from 'styled-components';

const contentWidth = css`
  width: ${getColumnsWidth({ nrColumns: 6 })} !important;
  margin-right: auto;
  margin-left: auto;
`;

export interface IAvailabilitiesStepWrapperProps {}

export const AvailabilitiesStepWrapper = styled(ScrollView)<IAvailabilitiesStepWrapperProps>`
  width: 100vw !important;
  margin-bottom: 1rem;

  & + * {
    ${contentWidth}
  }
`;

export interface IAvailabilitiesStepSlotsSliderProps {}

export const AvailabilitiesStepSlotsSlider = styled(AvailableSlotsSlider)<IAvailabilitiesStepSlotsSliderProps>`
  ${contentWidth}
`;
