import { TabsProps } from './Tabs';
import ITab from 'interfaces/ITab';
import { createRef, RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isInURL } from 'utils/general';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';

let barTimeout: NodeJS.Timeout;

export type UseTabs = {
  tabIsSelected: (tab: ITab) => boolean;
  width: number;
  left: number;
  tabsRefs: RefObject<HTMLLIElement>[];
  bar: RefObject<HTMLDivElement>;
};

const useTabs = (props: TabsProps): UseTabs => {
  const { tabs, borderWidth } = props;
  const location = useLocation();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [tabsRefs, setTabsRefs] = useState<RefObject<HTMLLIElement>[]>(new Array(tabs.length));
  const [barCss, setBarCss] = useState({ width: 0, left: 0 });
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const bar = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setTabsRefs((prevState) =>
      Array(tabs.length)
        .fill(null)
        .map((_, i) => prevState[i] || createRef())
    );
  }, [tabs.length]);

  useEffect(() => {
    const updateWindowSize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', updateWindowSize);

    return () => {
      clearTimeout(barTimeout);
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getBarCss(true);
  }, [windowSize.width, windowSize.height, tabsRefs, language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getBarCss(false);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabIsSelected = (tab: ITab): boolean => {
    return isInURL(location.pathname, tab.route.linkPath ? tab.route.linkPath : tab.route.path);
  };

  const getBarCss = (timeout: boolean): void => {
    const selectedTabIndex = tabs.findIndex((tab: ITab) => tabIsSelected(tab));
    const selectedTabRef = tabsRefs[selectedTabIndex];

    barTimeout = setTimeout(
      () => {
        if (selectedTabRef && selectedTabRef.current) {
          if (borderWidth !== undefined) {
            setBarCss({
              width: 0,
              left:
                selectedTabRef.current!.offsetLeft +
                (selectedTabRef.current!.clientWidth - bar.current!.clientWidth) / 2,
            });
          } else {
            setBarCss({
              width: selectedTabRef.current!.clientWidth,
              left: selectedTabRef.current!.offsetLeft,
            });
          }
        }
      },
      timeout ? 500 : 0
    );
  };

  return { tabsRefs, bar, ...barCss, tabIsSelected };
};

export default useTabs;
