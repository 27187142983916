import Button, { ButtonTypes } from 'components/Form/Button';
import CheckList from 'components/Form/CheckList/CheckList';
import Input, { INPUT_TYPES } from 'components/Form/Input/Input';
import Popup from 'components/View/Popup';
import { iconsTemp } from 'iconsTemp';
import { IDayGroup } from 'interfaces/IGroup';
import FileDTO from 'dtos/FileDTO';
import styles from './GalleryAndFiles.module.scss';
import GalleryGroup from './GalleryGroup';
import ImageFilePreview from './ImageFilePreview';
import useGalleryAndFiles from './GalleryAndFiles.logic';

export type GalleryAndFilesProps = {
  className?: string;
  calleeId: string;
  group?: boolean;
  showFilters?: boolean;
};

const GalleryAndFiles: React.FC<GalleryAndFilesProps> = (props: React.PropsWithChildren<GalleryAndFilesProps>) => {
  const { className, group, showFilters } = props;
  const {
    language,
    files,
    groups,
    showFiltersPopup,
    isMobileScreen,
    filters,
    setShowFiltersPopup,
    toggleShowFiltersPopup,
  } = useGalleryAndFiles(props);

  return (
    <div className={`${styles.gallery} ${className}`}>
      <div className={styles.filters}>
        <Input dataTestId='galleryAndFiles-searchInput' className={styles['search_input']} type={INPUT_TYPES.SEARCH} />
        {showFilters && (
          <div>
            <Button
              dataTestId='galleryAndFiles-filterButton'
              className={styles['filter_button']}
              activeClassName={styles['filter_button_active']}
              label={isMobileScreen ? undefined : language.allM}
              icon={iconsTemp.filter}
              type={ButtonTypes.SECONDARY}
              active={showFiltersPopup}
              onClick={toggleShowFiltersPopup}
            />
            <Popup
              dataTestId='galleryAndFiles-filterPopup'
              className={styles.popup}
              show={showFiltersPopup}
              close={setShowFiltersPopup}>
              <CheckList identifier='files-filter' type='checkbox' options={filters} />
            </Popup>
          </div>
        )}
      </div>
      {group && (
        <>
          {groups.map((group: IDayGroup<FileDTO>, i: number) => (
            <GalleryGroup key={i} day={group.date} files={group.list} />
          ))}
        </>
      )}
      {!group && (
        <div data-testid='galleryAndFiles-list' className={styles.list}>
          {files.map((file: FileDTO) => (
            <ImageFilePreview className={styles.preview} key={file.id} file={file} showDate />
          ))}
        </div>
      )}
      {groups.length === 0 && files.length === 0 && <p className={styles['empty-files']}>{language.noFiles}</p>}
    </div>
  );
};

GalleryAndFiles.defaultProps = {
  group: false,
  showFilters: true,
};

export default GalleryAndFiles;
