import { useContext, useEffect } from 'react';
import AgendaContext from 'store/agenda-context';
import { ActionTypes, FormContext, getInputValue, InputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useParams } from 'react-router-dom';
import { IEditAvailabilityRouteParams } from 'interfaces/IRouteParams';
import { IEditAvailabilityFormContentProps } from './EditAvailabilityFormContent';

export interface IUseEditAvailabilityFormContent {}

const useEditAvailabilityFormContent = (props: IEditAvailabilityFormContentProps): IUseEditAvailabilityFormContent => {
  const { event } = props;
  const { eventId } = useParams<IEditAvailabilityRouteParams>();
  const { selectedDate } = useContext(AgendaContext);
  const { inputs, dispatchInputs } = useContext(FormContext);

  useEffect(() => {
    if (!event) return;

    interface IValue {
      attribute: string;
      value: InputValue | undefined;
    }

    const values: IValue[] = [
      { attribute: 'availability', value: event.availabilityId },
      { attribute: 'event', value: eventId },
      { attribute: 'startDate', value: selectedDate },
      { attribute: 'startTime', value: new Date(event.start) },
    ];

    values.forEach(({ attribute, value }: IValue) => {
      if (value) dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute, value });
    });
  }, [event]);

  useEffect(() => {
    if (!event) return;

    const endTime = new Date(getInputValue<Date>(inputs.startTime)!);
    endTime.setTime(endTime.getTime() + event.duration * 60 * 1000);

    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'endTime', value: endTime });
  }, [event, getInputValue<Date>(inputs.startTime)!.getTime()]);

  return {};
};

export default useEditAvailabilityFormContent;
