import NotificationDTO, { NotificationType } from 'dtos/NotificationDTO';
import { iconsTemp } from 'iconsTemp';
import NotificationSchema, { NotificationMessageSchema } from 'schemas/NotificationSchema';

const NotificationMapper = () => {
  const getNotificationIcon = (type: NotificationType): string => {
    switch (type) {
      case NotificationType.APPOINTMENT_PATIENT_SCHEDULED:
      case NotificationType.APPOINTMENT_PATIENT_RESCHEDULED:
        return iconsTemp.calendar;
      case NotificationType.APPOINTMENT_PATIENT_CANCELED:
      case NotificationType.APPOINTMENT_MEDIC_CANCELED:
      case NotificationType.APPOINTMENT_NO_PAYMENT_CANCELED:
        return iconsTemp.close;
      case NotificationType.APPOINTMENT_PAYMENT_COMPLETE:
      case NotificationType.APPOINTMENT_MEDIC_CANCELED:
      case NotificationType.APPOINTMENT_REFUND_COMPLETE:
        return iconsTemp.check;
      case NotificationType.APPOINTMENT_REQUEST_PAYMENT:
        return iconsTemp.price;
        return '';
    }
  };

  const toInterface = (schema: NotificationSchema): NotificationDTO => {
    const type = schema.templateType as NotificationType;
    const message =
      typeof schema.message === 'string' ? JSON.parse(schema.message) : (schema.message as NotificationMessageSchema);

    return {
      id: schema.id,
      type,
      userId: message.id,
      userName: message.name,
      icon: getNotificationIcon(type),
      message: message.message,
      mainLine: message.mainLine,
      lineThrough: message.lineThrough,
      read: typeof schema.read === 'string' ? schema.read === 'true' : schema.read,
      date: new Date(schema.createdAt),
    };
  };

  return { toInterface };
};

export default NotificationMapper;
