import EventDTO from 'dtos/EventDTO';
import AppointmentCard from './AppointmentCard';
import EmptyEventCard from './EmptyEventCard';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import { Flex, IProps } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import { EventCardTime } from './EventCard.styles';
import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

export interface IEventCardProps extends PropsWithChildren<IProps> {
  tagsClassName?: string;
  event: EventDTO;
  isSelected?: boolean;
  showTime?: boolean;
  hideInfoOnWarning?: boolean;
  onSelect?: (appointmentId: string) => void;
}

const EventCard: FC<IEventCardProps> = (props: IEventCardProps): JSX.Element => {
  const { className, style, tagsClassName, event, isSelected, showTime, hideInfoOnWarning, onSelect } = props;
  const { formatDate, masks } = useDate();
  const eventHasPassed = event.end.getTime() < new Date().getTime();

  return (
    <Flex>
      {showTime && (
        <MediaScreenDesktop>
          <EventCardTime isSelected={isSelected} hasPassed={eventHasPassed}>
            <h4>{formatDate(event.start, masks.shortTime)}</h4>
            <h5>{formatDate(event.end, masks.shortTime)}</h5>
          </EventCardTime>
        </MediaScreenDesktop>
      )}
      {event.appointment && (
        <AppointmentCard
          className={className}
          tagsClassName={tagsClassName}
          style={style}
          appointmentEvent={event}
          placeIcon={event.icon}
          isSelected={isSelected}
          hasPassed={eventHasPassed}
          hideInfoOnWarning={hideInfoOnWarning}
          onSelect={onSelect ? onSelect : () => {}}
        />
      )}
      {!event.appointment && (
        <EmptyEventCard className={className} style={style} event={event} hasPassed={eventHasPassed} />
      )}
    </Flex>
  );
};

EventCard.defaultProps = {
  isSelected: false,
  showTime: true,
  hideInfoOnWarning: true,
};

export default EventCard;
