import { DateInput, Flex, TimeInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import useAddAvailabilityTime from './AddAvailabilityTime.logic';

export interface IAddAvailabilityTimeProps extends PropsWithChildren<{}> {
  separate?: boolean;
}

const AddAvailabilityTime: FC<IAddAvailabilityTimeProps> = (props: IAddAvailabilityTimeProps): JSX.Element => {
  const { separate } = props;
  const {} = useAddAvailabilityTime();

  return (
    <Flex direction={separate ? 'column' : undefined} justifyContent='space-between' alignItems='flex-start'>
      <DateInput dataTestId='addAvailabilityForm-startDateInput' name='startDate' icon='calendar' />
      <Flex justifyContent='flex-start'>
        <TimeInput dataTestId='addAvailabilityForm-startTimeInput' name='startTime' icon='time' />
        <span style={{ margin: '1.2rem 0.5rem 0 0.5rem' }}>-</span>
        <TimeInput dataTestId='addAvailabilityForm-endTimeInput' name='endTime' />
      </Flex>
    </Flex>
  );
};

export default AddAvailabilityTime;
