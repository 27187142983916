import styled from 'styled-components';
import AppointmentTypesMultioption from 'components/Appointment/AppointmentTypesMultioption';
import { getColumnsWidth, Select, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface IAppointmentDataStepWrapperProps {}

export const AppointmentDataStepWrapper = styled.div<IAppointmentDataStepWrapperProps>`
  width: ${getColumnsWidth({ nrColumns: 3 })};
`;

export interface IAppointmentDataStepSelectProps {}

export const AppointmentDataStepSelect = styled(Select)<IAppointmentDataStepSelectProps>`
  > div {
    flex: 1 !important;
  }
`;

export interface IAppointmentDataStepMultioptionProps {}

export const AppointmentDataStepMultioption = styled(AppointmentTypesMultioption)<IAppointmentDataStepMultioptionProps>`
  padding-left: 2.5rem;
  margin-top: ${styles.inputMargin};
`;
