import { iconsTemp } from 'iconsTemp';
import { ContentBlock, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import styles from 'pages/Appointment/AppointmentCall/AppointmentCallSideSection/AppointmentCallPrivateNotes/WriteNote/WriteNote.module.scss';
import useHttp from './http.service';
import NoteDTO from 'dtos/NoteDTO';
import NoteSchema from 'schemas/NoteSchema';
import NoteMapper from 'mappers/NoteMapper';
import { appointmentsAPI } from 'apis';

const noteMapper = NoteMapper();

export enum STYLE_TYPE {
  BLOCK,
  INLINE,
}

export enum STYLES {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  INDENT = 'increase',
  OUTDENT = 'decrease',
  UL = 'unordered-list-item',
  OL = 'ordered-list-item',
  EMPTY = '',
}

export type Format = {
  icon: string;
  style: STYLES;
  type: STYLE_TYPE;
};

export const FORMATS: Format[][] = [
  [
    { icon: iconsTemp.bold, style: STYLES.BOLD, type: STYLE_TYPE.INLINE },
    { icon: iconsTemp.italic, style: STYLES.ITALIC, type: STYLE_TYPE.INLINE },
    { icon: iconsTemp.underline, style: STYLES.UNDERLINE, type: STYLE_TYPE.INLINE },
    //{ icon: iconsTemp.changeColor, style: STYLES.EMPTY, type: STYLE_TYPE.INLINE },
    //{ icon: iconsTemp.indent, style: STYLES.INDENT, type: STYLE_TYPE.BLOCK },
  ],
  [
    //{ icon: iconsTemp.outdent, style: STYLES.OUTDENT, type: STYLE_TYPE.BLOCK },
    { icon: iconsTemp.unorderedList, style: STYLES.UL, type: STYLE_TYPE.BLOCK },
    { icon: iconsTemp.orderedList, style: STYLES.OL, type: STYLE_TYPE.BLOCK },
    { icon: iconsTemp.cut, style: STYLES.EMPTY, type: STYLE_TYPE.BLOCK },
  ],
];

export type UseNotesService = {
  isLoading: boolean;
  createOrUpdateAppointmentNote: (appointmentId: string, note?: string) => Promise<void>;
  getAppointmentNote: (appointmentId: string) => Promise<NoteDTO>;
  getNotesBetweenUsers: (userId: string) => Promise<NoteDTO[]>;
  getNrNotesBetweenUsers: (userId: string) => Promise<number>;
  deleteAppointmentNote: (appointmentId: string) => Promise<void>;
};

const useNotesService = (): UseNotesService => {
  const { get, put, del, isLoading } = useHttp();

  const createOrUpdateAppointmentNote = (appointmentId: string, note?: string): Promise<void> => {
    if (!note) return deleteAppointmentNote(appointmentId);

    return new Promise((resolve, reject) => {
      put(`${appointmentsAPI}/${appointmentId}/notes`, undefined, {
        text: note,
      })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getAppointmentNote = (appointmentId: string): Promise<NoteDTO> => {
    return new Promise((resolve, reject) => {
      get(`${appointmentsAPI}/${appointmentId}/notes`)
        .then((note: NoteSchema) => {
          resolve(noteMapper.toInterface(note));
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getNotesBetweenUsers = (userId: string): Promise<NoteDTO[]> => {
    const parameters = { patientId: userId, embedded_specialties: true };

    return new Promise((resolve, reject) => {
      get(`${appointmentsAPI}/notes`, parameters)
        .then((notes: NoteSchema[]) => {
          resolve(notes.map((note: NoteSchema) => noteMapper.toInterface(note)));
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getNrNotesBetweenUsers = (userId: string): Promise<number> => {
    return new Promise((resolve, reject) => {
      get(`${appointmentsAPI}/notes/total`, { patientId: userId })
        .then((nrNotes: { totalNotes: number }) => {
          resolve(nrNotes.totalNotes);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const deleteAppointmentNote = (appointmentId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      del(`${appointmentsAPI}/${appointmentId}/notes`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  return {
    createOrUpdateAppointmentNote,
    getAppointmentNote,
    getNotesBetweenUsers,
    getNrNotesBetweenUsers,
    deleteAppointmentNote,
    isLoading,
  };
};

export const getBlockClass = (block: ContentBlock): string => {
  const type = block.getType();

  switch (type) {
    case STYLES.UL:
      return styles['unordered-list'];
    case STYLES.OL:
      return styles['ordered-list'];
    default:
      return '';
  }
};

export const importNote = (noteText: string) => {
  return EditorState.createWithContent(convertFromRaw(JSON.parse(noteText)));
};

export const exportNote = (editorState: EditorState) => {
  return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
};

export default useNotesService;
