import {
  getCountriesOptions,
  PhoneNumberInput,
  Select,
  styles,
  TextInput,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { userIsMedic } from 'utils/user';
import { OnboardingParagraph } from '../Onboarding.styles';
import { InputWithNoIcon } from './AddressStep.styles';

export interface IAddressStepProps extends PropsWithChildren<{}> {}

const AddressStep: FC<IAddressStepProps> = (props: IAddressStepProps) => {
  const {} = props;
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <>
      <h1>{language.whatsYourAddress}</h1>
      <OnboardingParagraph>
        {userIsMedic(userType)
          ? language.thisInfoIsImportantToShareYourGeoArea
          : language.thisInfoIsImportantForHomeAppointments}
      </OnboardingParagraph>
      <TextInput name='address' icon='address' placeholder={language.address} />
      <InputWithNoIcon style={{ width: '50%' }} name='postalCode' placeholder={language.postalCode} />
      <InputWithNoIcon
        as={Select}
        style={{ marginTop: styles.inputMargin }}
        name='country'
        options={getCountriesOptions()}
      />
      <PhoneNumberInput name='phoneNumber' includeIcon />
    </>
  );
};

export default AddressStep;
