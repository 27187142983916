import Color from './Color';
import styles from './Colors.module.scss';
import sass from 'styles/exports.module.scss';

const brandColors = Object.entries(sass)
  .filter(([key]) => key.includes('colors-brand'))
  .map(([key, value]) => [key.split('-')[2], value]);
const feedbackColors = Object.entries(sass)
  .filter(([key]) => key.includes('colors-feedback'))
  .map(([key, value]) => [key.split('-')[2], value]);
const auxiliaryColors = Object.entries(sass)
  .filter(([key]) => key.includes('colors-auxiliary'))
  .map(([key, value]) => [key.split('-')[2], value]);
const auxiliaryColorsNormal = auxiliaryColors.filter(([key]) => !key.includes('_'));
const auxiliaryColorsDark = auxiliaryColors
  .filter(([key]) => key.includes('_'))
  .map(([key, value]) => [key.replace('_', ' '), value]);
const colors = [brandColors, feedbackColors, auxiliaryColorsNormal, auxiliaryColorsDark];

export type ColorsProps = {};

const Colors: React.FC<ColorsProps> = (props: React.PropsWithChildren<ColorsProps>) => {
  const {} = props;

  return (
    <div className={styles.container}>
      <div>
        <div>
          <h4>Brand colors</h4>
        </div>
        <div>
          <h4>Feedback colors</h4>
        </div>
        <div>
          <h4>Auxiliary colors</h4>
        </div>
        <div></div>
      </div>
      <div>
        {colors.map((colors: string[][]) => (
          <div>
            {colors.map(([name, hex]) => (
              <Color key={name} name={name} hex={hex} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Colors;
