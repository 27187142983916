import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

export enum DailyHabitsTypes {
  FOOD = 'FOOD',
  ACTIVITY = 'ACTIVITY',
  WATER = 'WATER',
  SLEEP = 'SLEEP',
  SMOKE = 'SMOKE',
}

export type DailyHabitName = {
  [code in LanguageCode]: string;
};

export interface DailyHabitOptionSchema {
  id: string;
  name: DailyHabitName;
}

export default interface DailyHabitSchema {
  id: string;
  type: DailyHabitsTypes;
  name: DailyHabitName;
  options: DailyHabitOptionSchema[];
}
