import { Button, Container, getColumnsWidth, getGutter } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import PatientInfo from 'components/PatientInfo/PatientInfo';
import PatientInfoHeader from 'components/PatientInfo/PatientInfoHeader';
import { StickyBackButtonWrapper } from 'components/Templates';
import { FC, PropsWithChildren } from 'react';
import PatientDetailsAppointments from './PatientDetailsAppointments';
import usePatientDetails from './PatientDetails.logic';

export interface IPatientDetailsProps extends PropsWithChildren<{}> {}

const PatientDetails: FC<IPatientDetailsProps> = (props: IPatientDetailsProps): JSX.Element => {
  const {} = props;
  const { patientId, patient, backHandler } = usePatientDetails();

  if (!patient) return <></>;

  return (
    <>
      <StickyBackButtonWrapper>
        <Button dataTestId='medicDetails-backButton' icon='arrowLeft' type='secondary' onClick={backHandler} />
      </StickyBackButtonWrapper>
      <Container style={{ position: 'sticky', width: getColumnsWidth({ nrColumns: 7 }), marginRight: getGutter() }}>
        <PatientInfoHeader patient={patient} />
        <PatientInfo patientId={patientId} />
      </Container>
      <PatientDetailsAppointments />
    </>
  );
};

export default PatientDetails;
