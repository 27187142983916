import { PropsWithChildren, FC } from 'react';
import { AddAvailabilitySpecialtySelect } from './AddAvailabilityFormContent.styles';
import useAddAvailabilitySpecialty from './AddAvailabilitySpecialty.logic';

export interface IAddAvailabilitySpecialtyProps extends PropsWithChildren<{}> {}

const AddAvailabilitySpecialty: FC<IAddAvailabilitySpecialtyProps> = (
  props: IAddAvailabilitySpecialtyProps
): JSX.Element => {
  const {} = props;
  const { language, specialties } = useAddAvailabilitySpecialty();

  return (
    <AddAvailabilitySpecialtySelect
      dataTestId='addAvailabilityForm-specialtyInput'
      name='specialty'
      icon='appointment'
      placeholder={language.insertAvailability}
      options={specialties}
    />
  );
};

export default AddAvailabilitySpecialty;
