export const iconsTemp = {
  // A
  add: 'add.svg',
  address: 'profile/address.svg',
  allergy: 'profile/allergy.svg',
  appointment: 'appointment.svg',
  appointmentPlaces: {
    remote: 'videoCall.svg',
    office: 'profile/office.svg',
    home: 'profile/address.svg',
  },
  appointmentMotive: 'appointmentMotive.svg',
  arrowLeft: 'arrows/left.svg',
  arrows: {
    down: 'arrows/down.svg',
    left: 'arrows/left.svg',
    right: 'arrows/right.svg',
    up: 'arrows/up.svg',
  },
  // B
  birthDate: 'profile/birthDate.svg',
  bold: 'textFormat/bold.svg',
  // C
  calendar: 'calendar.svg',
  call: 'call.svg',
  calories: 'profile/calories.svg',
  changeColor: 'textFormat/changeColor.svg',
  chat: 'chat.svg',
  check: 'check.svg',
  chronicDisease: 'profile/chronicDisease.svg',
  clinicalFile: 'clinicalFile.svg',
  clinicalFileFilled: 'clinicalFile_filled.svg',
  close: 'close.svg',
  contacts: 'contacts.svg',
  cut: 'textFormat/cut.svg',
  // D
  dailyHabits: 'dailyHabits.svg',
  delete: 'delete.svg',
  details: 'details.svg',
  doctor: 'doctor.svg',
  // E
  edit: 'edit.svg',
  education: 'education.svg',
  endCall: 'endCall.svg',
  exit: 'exit.svg',
  // F
  female: 'female.svg',
  file: 'file.svg',
  filter: 'filter.svg',
  flipCamera: 'flipCamera.svg',
  // G
  gender: 'profile/gender.svg',
  // H
  heart: 'heart.svg',
  heartFilled: 'heart_filled.svg',
  height: 'profile/height.svg',
  help: 'help.svg',
  home: 'home.svg',
  // I
  image: 'image.svg',
  indent: 'textFormat/indent.svg',
  integrations: 'integrations.svg',
  italic: 'textFormat/italic.svg',
  // J
  // K
  // L
  loading: 'loading.svg',
  loadingFailed: 'loading_failed.svg',
  loadingSuccessfull: 'loading_successfull.svg',
  location: 'location.svg',
  lungs: 'profile/lungs.svg',
  // M
  male: 'male.svg',
  medic: 'medic.svg',
  medicFilled: 'medic_filled.svg',
  menu: 'menu.svg',
  microphoneOff: 'mic_off.svg',
  microphoneOn: 'mic.svg',
  muscle: 'profile/muscle.svg',
  // N
  notifications: 'notifications.svg',
  // O
  office: 'profile/office.svg',
  options: 'options.svg',
  orderedList: 'textFormat/orderedList.svg',
  outdent: 'textFormat/outdent.svg',
  // P
  patient: 'patient.svg',
  pills: 'profile/pills.svg',
  price: 'price.svg',
  profile: 'profile.svg',
  // Q
  questionnaire: 'questionnaire.svg',
  // R
  radioButton: 'radioButton.svg',
  repeat: 'repeat.svg',
  // S
  search: 'search.svg',
  security: 'security.svg',
  send: 'send.svg',
  screenShare: 'screenShare.svg',
  sleep: 'profile/sleep.svg',
  speakerOff: 'speaker_off.svg',
  speakerOn: 'speaker.svg',
  spo2: 'spo2.svg',
  star: 'star_fill.svg',
  starOutline: 'star_outline.svg',
  steps: 'steps.svg',
  // T
  time: 'time.svg',
  timer: 'timer.svg',
  // U
  underline: 'textFormat/underline.svg',
  unorderedList: 'textFormat/unorderedList.svg',
  user: 'user.svg',
  userFilled: 'user_filled.svg',
  // V
  videoCallOff: 'videoCall_off.svg',
  videoCallOn: 'videoCall.svg',
  visibility: 'visibility.svg',
  visibilityOff: 'visibility_off.svg',
  // W
  water: 'profile/water.svg',
  weight: 'profile/weight.svg',
  // X
  // Y
  // Z
};
