import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useContext, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import ChatContext from 'store/chat-context';
import { LOG_COMPONENT, LOG_WARN } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export type UseSendMessage = {
  language: ILanguage;
  nrVisibleLines: number;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  fileUploadChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  writeHandler: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  sendHandler: () => void;
};

const useSendMessage = (): UseSendMessage => {
  const logger = useWinstonLogger();
  const { sendMessage, sendFile } = useContext(ChatContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [nrVisibleLines, setNrVisibleLines] = useState<number>(1);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // gets the chosen file from the file input
  const fileUploadChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files?.length > 0) {
      logger.log(LOG_COMPONENT, `a file has been chosen with the filename being ${event.target.files[0].name}`);
      sendFile(event.target.files![0]);
    }
  };

  const writeHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const element = event.target as HTMLTextAreaElement;

    // creates a new line if the user presses enter and shift or controll
    // or if it is only pressed the enter key, sends the message
    if (event.key === 'Enter' && (event.ctrlKey || event.shiftKey || isMobile)) {
      event.preventDefault();
      element.value += '\n';
      logger.log(LOG_COMPONENT, 'creating a new line on the message input');

      if (nrVisibleLines < 4) setNrVisibleLines((prevState) => ++prevState);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      sendHandler();
    } else if (nrVisibleLines < 4) {
      // gets if the user wrote a long word that uses 2 or more lines of the textarea
      const { clientHeight, scrollHeight } = element;
      const nrLinesDifference = scrollHeight / clientHeight;
      if (nrLinesDifference > 1) setNrVisibleLines((prevState: number) => ++prevState);
    }
  };

  const sendHandler = () => {
    const message = inputRef.current!.value.trim();

    // checks if the message text has content after trimming it
    if (message !== '') {
      logger.log(LOG_COMPONENT, `sending message '${message}'`);

      setNrVisibleLines(1);
      inputRef.current!.value = '';
      sendMessage(message);
    } else logger.log(LOG_WARN, 'message was not sent because it has no content after trimming');
  };

  return { language, nrVisibleLines, inputRef, fileUploadChangeHandler, writeHandler, sendHandler };
};

export default useSendMessage;
