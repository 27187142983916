import { FC, PropsWithChildren } from 'react';
import StartOptionalOnboarding from 'pages/Onboarding/StartOptionalOnboarding';
import {
  OnboardingForm,
  OnboardingPreviousButton,
  OnboardingSkipButton,
  OnboardingSubmitButton,
} from 'pages/Onboarding/Onboarding.styles';
import useOptionalOnboarding from './OptionalOnboarding.logic';
import { StepProgress } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface IOptionalOnboardingProps extends PropsWithChildren<{}> {}

const OptionalOnboarding: FC<IOptionalOnboardingProps> = (props: IOptionalOnboardingProps): JSX.Element => {
  const {} = props;
  const {
    language,
    initialValues,
    stepsElements,
    step,
    isFistStep,
    isFinalStep,
    isLoading,
    previousStepHandler,
    nextStepHandler,
    submitHandler,
    skipOptionalOnboardingHandler,
  } = useOptionalOnboarding();

  return (
    <OnboardingForm initialValues={initialValues} onSubmit={submitHandler}>
      {step < 0 && <StartOptionalOnboarding infoText={language.byCompletingYourInfo} />}
      {step >= 0 && (
        <>
          <OnboardingPreviousButton
            type='secondary'
            icon='arrowLeft'
            disabled={isFistStep}
            onClick={previousStepHandler}
          />
          <StepProgress step={step} nrSteps={stepsElements.length} />
          {stepsElements[step]}
        </>
      )}
      <OnboardingSubmitButton
        buttonType={isFinalStep ? 'submit' : 'button'}
        label={isFinalStep ? language.conclude : language.next}
        loading={isLoading}
        onClick={!isFinalStep ? nextStepHandler : undefined}
      />
      <OnboardingSkipButton
        type='text'
        state='dark'
        label={language.completeLater}
        onClick={skipOptionalOnboardingHandler}
      />
    </OnboardingForm>
  );
};

export default OptionalOnboarding;
