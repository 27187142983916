import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILanguageSlice } from 'interfaces/ISlices';

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    code: null,
    values: {},
  } as ILanguageSlice,
  reducers: {
    changeLanguage: (state: any, action: PayloadAction<LanguageCode>) => {
      state.code = action.payload;
    },
    setValues: (state: any, action: PayloadAction<{}>) => {
      state.values = action.payload;
    },
  },
});

export const languageActions = languageSlice.actions;

export default languageSlice;
