import { IOption } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { Gender, ProfileType } from 'dtos/EntityDTO';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import ILanguage from 'interfaces/ILanguage';
import jwt_decode from 'jwt-decode';

export const decodeToken = (token: string): { id: string; type: ProfileType } | undefined => {
  let decoded;

  try {
    decoded = jwt_decode(token) as any;

    return {
      id: decoded.sub,
      type: decoded.realm_access.roles
        .find((role: string) => {
          const type = role.toLowerCase();
          return type === ProfileType.MEDIC || type === ProfileType.PATIENT;
        })
        .toLowerCase(),
    };
  } catch (err) {
    return undefined;
  }
};

export const getGenderOptions = (language: ILanguage): IOption[] => {
  return Object.values(Gender).map(
    (gender: Gender) =>
      ({
        id: gender,
        icon: gender.toLowerCase(),
        name: language[gender.toLowerCase() as keyof ILanguage],
      } as IOption)
  );
};

export const userIsMedic = (userType?: ProfileType | null): boolean => {
  return userType === ProfileType.MEDIC;
};

export const userIsPatient = (userType?: ProfileType | null): boolean => {
  return userType === ProfileType.PATIENT;
};

export const getCounterUserType = (userType: ProfileType): ProfileType => {
  return userIsMedic(userType) ? ProfileType.PATIENT : ProfileType.MEDIC;
};

export const getUserInitials = (fullName: string): string => {
  const names = fullName.split(' ');
  if (names.length === 0) return '';
  return `${names.at(0)?.charAt(0)}${names.length > 1 ? names.at(-1)?.charAt(0) : ''}`;
};

export const getMedicSpecialtiesList = (specialties: SpecialtyDTO[]): string => {
  return specialties.map((speacialty: SpecialtyDTO) => speacialty.name).join(', ');
};
