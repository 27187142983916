import { Flex, IProps } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import ImproveProfileSuggestions from './ImproveProfileSuggestions';
import useNoTodayAppointments from './NoTodayAppointments.logic';

export interface NoTodayAppointmentsProps extends PropsWithChildren<IProps> {}

const NoTodayAppointments: FC<NoTodayAppointmentsProps> = (props: NoTodayAppointmentsProps) => {
  const { className, style } = props;
  const {} = useNoTodayAppointments();

  return (
    <Flex as='div' className={className} style={{ margin: 'auto 0', ...style }}>
      <ImproveProfileSuggestions />
    </Flex>
  );
};

export default NoTodayAppointments;
