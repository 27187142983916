import useEditAvailability from './EditAvailability.logic';
import { FC, PropsWithChildren } from 'react';
import { Button, Flex } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import {
  EditAvailabilityEventInfo,
  EditAvailabilityForm,
  EditAvailabilitySubmitButton,
} from './EditAvailability.styles';
import { editAvailabilityFormInputs } from 'services/schedules.service';
import EditAvailabilityFormContent from './EditAvailabilityFormContent';

export interface IEditAvailabilityProps extends PropsWithChildren<{}> {}

const EditAvailability: FC<IEditAvailabilityProps> = (props: IEditAvailabilityProps): JSX.Element => {
  const {} = props;
  const {
    language,
    selectedEvent,
    isLoading,
    closeAvailabilityPanelHandler,
    submitEditAvailabilityHandler,
    deleteAvailableEventHandler,
  } = useEditAvailability();

  if (!selectedEvent) return <></>;

  return (
    <>
      <Flex style={{ marginBottom: '1rem' }} justifyContent='space-between'>
        <h4>{language.editAvailability}</h4>
        <Button
          dataTestId='agenda-closeEditAvailabilityButton'
          type='secondary'
          icon='close'
          onClick={closeAvailabilityPanelHandler}
        />
      </Flex>
      <EditAvailabilityForm initialValues={editAvailabilityFormInputs} onSubmit={submitEditAvailabilityHandler}>
        <EditAvailabilityEventInfo event={selectedEvent} selected={false} showDetails showStartDate />
        <EditAvailabilityFormContent event={selectedEvent} />
        <Flex style={{ marginTop: '2rem' }} justifyContent='space-between'>
          <Button
            dataTestId='editAvailability-deleteButton'
            type='text'
            state='red'
            icon='delete'
            label={language.delete}
            onClick={deleteAvailableEventHandler}
          />
          <Flex>
            <Button
              dataTestId='editAvailability-cancelButton'
              type='text'
              state='dark'
              label={language.cancel}
              onClick={closeAvailabilityPanelHandler}
            />
            <EditAvailabilitySubmitButton
              dataTestId='editAvailability-submitButton'
              label={language.save}
              buttonType='submit'
              loading={isLoading}
            />
          </Flex>
        </Flex>
      </EditAvailabilityForm>
    </>
  );
};

export default EditAvailability;
