import { ProfileType } from 'dtos/EntityDTO';
import IRoute, {
  IAppointmentCallSubRoutes,
  IAppointmentChatSubRoutes,
  IAppointmentNotesSubRoutes,
  IAppointmentSubRoutes,
} from 'interfaces/IRoute';

const appointmentCallNotesSubRoutes: IAppointmentNotesSubRoutes = {
  writeNote: {
    path: 'write',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
  previousNotes: {
    path: 'previous',
    exact: true,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
  } as IRoute,
};

const appointmentCallChatSubRoutes: IAppointmentChatSubRoutes = {
  messages: {
    path: 'messages',
    exact: false,
    meta: { auth: true, roles: [] },
  } as IRoute,
  galleryAndFiles: {
    path: 'galleryAndFiles',
    exact: false,
    meta: { auth: true, roles: [] },
  } as IRoute,
};

const appointmentCallSubRoutes: IAppointmentCallSubRoutes = {
  notes: {
    path: '/appointment/:appointmentId/:page/notes/:tab?',
    exact: false,
    meta: { auth: true, roles: [ProfileType.MEDIC] },
    subRoutes: appointmentCallNotesSubRoutes,
  } as IRoute,
  chat: {
    path: '/appointment/:appointmentId/:page/chat/:tab?',
    exact: false,
    meta: { auth: true, roles: [] },
    subRoutes: appointmentCallChatSubRoutes,
  } as IRoute,
  settings: {
    path: '/appointment/:appointmentId/:page/settings',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
};

const appointmentSubRoutes: IAppointmentSubRoutes = {
  waitingRoom: {
    path: '/appointment/:appointmentId/waiting',
    exact: true,
    meta: { auth: true, roles: [] },
  } as IRoute,
  call: {
    path: '/appointment/:appointmentId/call/:side?/:tab?',
    exact: true,
    meta: { auth: true, roles: [] },
    subRoutes: appointmentCallSubRoutes,
  } as IRoute,
  end: {
    path: '/appointment/:appointmentId/end/:side?/:tab?',
    exact: true,
    meta: { auth: true, roles: [] },
    subRoutes: appointmentCallSubRoutes,
  } as IRoute,
};

export default appointmentSubRoutes;
