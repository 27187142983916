import styles from './Toast.module.scss';
import Button, { ButtonThemes, ButtonTypes } from 'components/Form/Button';
import { iconsTemp } from 'iconsTemp';
import Icon from 'components/View/Icon';
import React, { RefObject } from 'react';
import useCounter from 'hooks/use-counter';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import IToastContent from 'interfaces/IToastContent';

export enum ToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export type ToastProps = {
  dataTestId?: string;
  className?: string;
  content: IToastContent;
  onClose: () => void;
};

const Toast: React.FC<ToastProps> = (props: React.PropsWithChildren<ToastProps>) => {
  const { dataTestId, className, content, onClose } = props;
  const { type, timeout, icon, title, subtitle, onCancel, onTimerEnd } = content;
  const language = useSelector((state: IReduxState) => state.language.values);
  const { counter, containerRef, cancelHandler } = useCounter({ timer: timeout, onCancel, onTimerEnd, onClose });

  return (
    <div
      data-testid={dataTestId}
      className={`${styles.toast} ${styles[type!]} ${className}`}
      ref={containerRef as RefObject<HTMLDivElement>}>
      <div>
        <Icon className={styles.icon} src={icon} />
        <div>
          <h4>{title}</h4>
          <p>{subtitle}</p>
        </div>
      </div>
      {type === ToastTypes.INFO ? (
        <Button
          className={styles['action_button']}
          label={`${language.undo} ${counter}s`}
          type={ButtonTypes.SECONDARY}
          onClick={cancelHandler}
        />
      ) : (
        <Button icon={iconsTemp.close} type={ButtonTypes.TEXT} theme={ButtonThemes.DARK} onClick={onClose} />
      )}
    </div>
  );
};

export default Toast;
