import React, { FC, JSXElementConstructor, PropsWithChildren, ReactElement, useState } from 'react';
import styles from './Accordion.module.scss';

export interface AccordionProps {
  className?: string;
}

const Accordion: FC<AccordionProps> = (props: PropsWithChildren<AccordionProps>) => {
  const { className, children } = props;
  const [openedChild, setOpenedChild] = useState<number>(-1);

  const clickHandler = (index: number): void => {
    setOpenedChild((prevState: number) => (index === prevState ? -1 : index));
  };

  if (!children) return <></>;

  return (
    <div className={`${styles.accordion} ${className}`}>
      {React.Children.map(children, (child, index: number) => {
        const element = child as ReactElement<any, string | JSXElementConstructor<any>>;

        if (!element || (element.type as JSXElementConstructor<any>)?.name !== 'AccordionItem') return null;
        else
          return React.cloneElement(child as ReactElement<any, string | JSXElementConstructor<any>>, {
            opened: openedChild === index,
            onClick: () => clickHandler(index),
          });
      })}
    </div>
  );
};

export default Accordion;
