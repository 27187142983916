import { Flex, IProps, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import EntityDTO from 'dtos/EntityDTO';
import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import { getDoctorAbreviation } from 'utils/language';
import { userIsMedic } from 'utils/user';

export interface UserInfoProps extends PropsWithChildren<IProps> {
  user: EntityDTO;
}

const UserInfo: FC<UserInfoProps> = (props: UserInfoProps): JSX.Element => {
  const { className, style, user, children } = props;
  const { type, name, gender, image } = user;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <Flex className={className} style={style} justifyContent='flex-start'>
      <UserImage src={image} />
      <div style={{ marginLeft: '1rem' }}>
        <h5 style={{ marginBottom: '0.1rem' }}>
          {userIsMedic(type) ? getDoctorAbreviation(language, gender, name) : name}
        </h5>
        <p>{children}</p>
      </div>
    </Flex>
  );
};

export default UserInfo;
