import { ToastTypes } from 'components/View/Toast';
import MedicDTO from 'dtos/MedicDTO';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { IHomeSubRoutes } from 'interfaces/IRoute';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import routes from 'routes/routes';
import useMedicsService from 'services/medics.service';
import { feedbackActions } from 'store/redux/feedback-slice';

export interface IUseMedicDetails {
  medic?: MedicDTO;
  backHandler: () => void;
}

const useMedicDetails = (): IUseMedicDetails => {
  const history = useHistory();
  const { id: medicId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { getMedic } = useMedicsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [medic, setMedic] = useState<MedicDTO>();

  useEffect((): void => {
    getMedic(medicId)
      .then((medic: MedicDTO): void => setMedic(medic))
      .catch((): void => {
        dispatch(
          feedbackActions.addMessage({
            dataTestId: 'toastError-medic',
            type: ToastTypes.ERROR,
            icon: iconsTemp.close,
            title: language.errorGettingMedic,
          })
        );

        history.replace((routes.home.subRoutes as IHomeSubRoutes).medics.path);
      });
  }, [medicId]);

  const backHandler = (): void => {
    history.goBack();
  };

  return { medic, backHandler };
};

export default useMedicDetails;
