import { PropsWithChildren, FC } from 'react';
import { WalletIFrame } from './BuyAppointmentStep.styles';

export interface IBuyAppointmentStepProps extends PropsWithChildren<{}> {
  paymentLink: string;
}

const BuyAppointmentStep: FC<IBuyAppointmentStepProps> = (props: IBuyAppointmentStepProps): JSX.Element => {
  const { paymentLink } = props;

  return (
    <WalletIFrame
      src={`https://icheckout.sandbox.meowallet.pt//checkout/frame/${paymentLink?.split('/')[4]}`}
      title='Wallet'
    />
  );
};

export default BuyAppointmentStep;
