import { FC, PropsWithChildren } from 'react';
import { PatientDataItemContent, PatientDataItemTag, PatientDataItemWrapper } from './PatientDataItem.styles';

export interface IPatientDataItemProps extends PropsWithChildren<{}> {
  icon: string;
  iconColor: string;
  title: string;
  text?: string;
}

const PatientDataItem: FC<IPatientDataItemProps> = (props: IPatientDataItemProps): JSX.Element => {
  const { icon, iconColor, title, text, children } = props;

  return (
    <PatientDataItemWrapper>
      <PatientDataItemTag icon={icon} label={title} iconColor={iconColor} iconWithBackground />
      {text && <PatientDataItemContent as='p'>{text}</PatientDataItemContent>}
      <PatientDataItemContent>{children}</PatientDataItemContent>
    </PatientDataItemWrapper>
  );
};

export default PatientDataItem;
