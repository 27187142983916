import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SearchBarProps } from './SearchBar';

export type UseSearchBar = {
  language: ILanguage;
  specialty: string;
  location: string;
  specialtyInputRef: React.RefObject<HTMLInputElement>;
  specialtyChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearchInputHandler: () => void;
  locationChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearLocationInputHandler: () => void;
  submitHandler: () => void;
};

const useSearchBar = (props: SearchBarProps): UseSearchBar => {
  const { onSubmit } = props;
  const { search } = useLocation();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [specialty, setSpecialty] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const specialtyInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const specialty = params.get('specialty');
    const location = params.get('location');

    if (specialty) setSpecialty(specialty);
    if (location) setLocation(location);
  }, [search]);

  const specialtyChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSpecialty(event.target.value);
  };

  const clearSearchInputHandler = (): void => {
    setSpecialty('');
  };

  const locationChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLocation(event.target.value);
  };

  const clearLocationInputHandler = (): void => {
    setLocation('');
  };

  const submitHandler = () => {
    if (specialty === '') specialtyInputRef.current?.focus();
    else onSubmit(specialty, location);
  };

  return {
    language,
    specialty,
    location,
    specialtyInputRef,
    specialtyChangeHandler,
    clearSearchInputHandler,
    locationChangeHandler,
    clearLocationInputHandler,
    submitHandler,
  };
};

export default useSearchBar;
