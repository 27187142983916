import useAddAvailability from './AddAvailability.logic';
import { FC, PropsWithChildren } from 'react';
import AddAvailabilityFormContent from 'components/AddAvailabilityFormContent/AddAvailabilityFormContent';
import { Button, Flex, ScrollView } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { createAvailabilityFormInputs } from 'services/schedules.service';
import { AddAvailabilityForm } from './AddAvailability.styles';

export interface IAddAvailabilityProps extends PropsWithChildren<{}> {}

const AddAvailability: FC<IAddAvailabilityProps> = (props: IAddAvailabilityProps): JSX.Element => {
  const {} = props;
  const { language, isLoading, createAvailabilityHandler, closeAvailabilityPanelHandler } = useAddAvailability();

  return (
    <>
      <Flex style={{ marginBottom: '1rem' }} justifyContent='space-between'>
        <h4>{language.addAvailability}</h4>
        <Button
          dataTestId='agenda-closeAddAvailabilityButton'
          type='secondary'
          icon='close'
          onClick={closeAvailabilityPanelHandler}
        />
      </Flex>
      <AddAvailabilityForm initialValues={createAvailabilityFormInputs} onSubmit={createAvailabilityHandler}>
        <ScrollView>
          <AddAvailabilityFormContent separateTime />
        </ScrollView>
        <Flex style={{ marginTop: '2rem' }} justifyContent='flex-end'>
          <Button
            dataTestId='addAvailability-cancelButton'
            type='text'
            state='dark'
            label={language.cancel}
            onClick={closeAvailabilityPanelHandler}
          />
          <Button
            dataTestId='addAvailability-submitButton'
            style={{ minWidth: '6.25rem', marginTop: 0 }}
            buttonType='submit'
            loading={isLoading}
            label={language.save}
          />
        </Flex>
      </AddAvailabilityForm>
    </>
  );
};

export default AddAvailability;
