import { Button, FormContextProvider, TextInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import { initialResetPasswordFormInputs } from 'services/authentication.service';
import { LoginContainer } from '../Login.styles';
import useResetPassword from './PasswordReset.logic';

export interface IPasswordResetProps extends PropsWithChildren<{}> {}

const PasswordReset: FC<IPasswordResetProps> = (props: IPasswordResetProps): JSX.Element => {
  const {} = props;
  const { language, formLoadHandler, resetPasswordHandler, backHandler } = useResetPassword();

  return (
    <>
      <LoginContainer
        as={FormContextProvider}
        initialValues={initialResetPasswordFormInputs}
        onLoad={formLoadHandler}
        onSubmit={resetPasswordHandler}>
        <TextInput dataTestId='resetPassword-emailInput' name='email' placeholder={language.email} />
        <Button dataTestId='resetPassword-submitButton' buttonType='submit' label={language.resetPassword} />
      </LoginContainer>
      <Button
        dataTestId='resetPassword-goBackLink'
        style={{ margin: '0 auto' }}
        type='text'
        theme='dark'
        label={language.back}
        onClick={backHandler}
      />
    </>
  );
};

export default PasswordReset;
