import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'store/redux';
import { BrowserRouter } from 'react-router-dom';
import { WinstonProvider } from 'winston-react';
import logger from 'utils/logger';
import countries from 'i18n-iso-countries';
import { SocketContextProvider } from 'store/socket-context';
import '@AlticeLabsProjects/smartal-b2c-frontend-ui/dist/styles.css';

countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <WinstonProvider logger={logger}>
          <SocketContextProvider>
            <App />
          </SocketContextProvider>
        </WinstonProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
