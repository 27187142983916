import { FC, PropsWithChildren, RefObject } from 'react';
import { EventContentArg } from '@fullcalendar/react';
import Button, { ButtonThemes, ButtonTypes } from 'components/Form/Button';
import Popup from 'components/View/Popup';
import ScrollView from 'components/View/ScrollView';
import { iconsTemp } from 'iconsTemp';
import EventDTO, { AppointmentEventTypes } from 'dtos/EventDTO';
import useCalendarEventPopupPopup from './CalendarEventPopup.logic';
import styles from './CalendarEventPopup.module.scss';
import { hasAppointmentEnded, isAppointmentPayed } from 'utils/appointment';
import EventInfo from '../../EventInfo';

export interface CalendarEventPopupProps {
  show: boolean;
  parent: RefObject<HTMLDivElement>;
  event: EventContentArg | undefined;
  onClose: () => void;
}

const CalendarEventPopupPopup: FC<CalendarEventPopupProps> = (
  props: PropsWithChildren<CalendarEventPopupProps>
): JSX.Element => {
  const { show, event, onClose } = props;
  const { language, viewportOffset, seePatientDetailsHandler, editHandler, deleteEventHandler } =
    useCalendarEventPopupPopup(props);
  const hasMoreThan4Events = event?.event._def.extendedProps.events.length > 4;

  return (
    <Popup
      className={`${styles['place-event_tooltip']} ${hasMoreThan4Events && styles.scroll} ${
        viewportOffset.top && styles.top
      } ${viewportOffset.right && styles.right} ${viewportOffset.bottom && styles.bottom} ${
        viewportOffset.left && styles.left
      }`}
      show={show}
      onClose={onClose}>
      <Button
        className={styles['close_button']}
        type={ButtonTypes.TEXT}
        theme={ButtonThemes.DARK}
        icon={iconsTemp.close}
        onClick={onClose}
      />
      <ScrollView>
        {event?.event._def.extendedProps.events.map((event: EventDTO, index: number) => (
          <div key={event.id} data-testid={`calendarEventPopup-${event.place}-${index}`} className={styles.event}>
            <EventInfo event={event} showDetails={true} />
            <div className={styles.buttons}>
              {event.place !== AppointmentEventTypes.Available && (
                <div>
                  <Button
                    dataTestId='calendarEventPopup-detailsEventButton'
                    type={ButtonTypes.TEXT}
                    theme={ButtonThemes.DARK}
                    icon={iconsTemp.user}
                    onClick={() => seePatientDetailsHandler(event.appointment!.user!.id)}
                    onMouseOver={language.details}
                  />
                </div>
              )}
              {!hasAppointmentEnded(event) && (
                <>
                  {/*(!isAppointmentPayed(event.appointment?.status) ||
                    event.place === AppointmentEventTypes.Available) && (
                    <div>
                      <Button
                        dataTestId='calendarEventPopup-rescheduleEventButton'
                        type={ButtonTypes.TEXT}
                        theme={ButtonThemes.DARK}
                        icon={iconsTemp.edit}
                        onClick={() => editHandler(event.id, event.appointment?.id)}
                        onMouseOver={language.reschedule}
                      />
                    </div>
                  )*/}
                  <div>
                    <Button
                      dataTestId='calendarEventPopup-cancelEventButton'
                      type={ButtonTypes.TEXT}
                      theme={ButtonThemes.RED}
                      icon={iconsTemp.delete}
                      onClick={() => deleteEventHandler(event.id, event.appointment?.id)}
                      onMouseOver={event.appointment ? language.cancel : language.delete}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </ScrollView>
    </Popup>
  );
};

export default CalendarEventPopupPopup;
