import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import MediaScreenMobile from 'components/Media/MediaScreenMobile';
import { ChatContextProvider } from 'store/chat-context';
import AppointmentHeader from '../AppointmentHeader';
import styles from './AppointmentCall.module.scss';
import useAppointmentCall from './AppointmentCall.logic';
import AppointmentSideButtons from './AppointmentCallSideSection/AppointmentSideButtons';
import AppointmentCallSideSection from './AppointmentCallSideSection/AppointmentCallSideSection';
import Call from 'components/Call/Call';
import { FC, PropsWithChildren } from 'react';

export interface AppointmentCallProps {}

const AppointmentCall: FC<AppointmentCallProps> = (props: PropsWithChildren<AppointmentCallProps>): JSX.Element => {
  const {} = props;
  const { calleeId, appointmentId, endAppointmentCallHandler } = useAppointmentCall();

  return (
    <div className={styles['appointment-call']}>
      <AppointmentHeader />
      <div>
        <ChatContextProvider calleeId={calleeId} appointmentId={appointmentId}>
          <div className={styles.main}>
            <Call onEnd={endAppointmentCallHandler} />
            <MediaScreenDesktop>
              <AppointmentSideButtons />
            </MediaScreenDesktop>
          </div>
          <AppointmentCallSideSection />
          <MediaScreenMobile>
            <AppointmentSideButtons />
          </MediaScreenMobile>
        </ChatContextProvider>
      </div>
    </div>
  );
};

export default AppointmentCall;
