import React from 'react';
import MediaQuery from 'react-responsive';
import sass from 'styles/exports.module.scss';

const MediaScreenDesktop: React.FC = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;

  return <MediaQuery minWidth={parseInt(sass.tablet) + 1}>{children}</MediaQuery>;
};

export default MediaScreenDesktop;
