import { getInputValue, InputsReducerType } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { ProfileType } from 'dtos/EntityDTO';
import RegisterMedicBody from 'RequestsBody/RegisterMedicBody';
import RegisterPatientBody from 'RequestsBody/RegisterPatientBody';

const RegisterMapper = () => {
  const toMedicBody = (inputs: InputsReducerType): RegisterMedicBody => {
    return {
      username: getInputValue(inputs.email)!,
      password: getInputValue(inputs.password)!,
      profile: ProfileType.MEDIC.toUpperCase(),
    };
  };

  const toPatientBody = (inputs: InputsReducerType): RegisterPatientBody => {
    return {
      username: getInputValue(inputs.email)!,
      password: getInputValue(inputs.password)!,
      profile: ProfileType.PATIENT.toUpperCase(),
    };
  };

  return { toMedicBody, toPatientBody };
};

export default RegisterMapper;
