import Button, { ButtonTypes } from 'components/Form/Button';
import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import useSearchBar from './SearchBar.logic';
import styles from './SearchBar.module.scss';

export type SearchBarProps = {
  onSubmit: (specialty: string, location: string) => void;
};

const SearchBar: React.FC<SearchBarProps> = (props: React.PropsWithChildren<SearchBarProps>) => {
  const {} = props;
  const {
    language,
    specialty,
    location,
    specialtyInputRef,
    specialtyChangeHandler,
    clearSearchInputHandler,
    locationChangeHandler,
    clearLocationInputHandler,
    submitHandler,
  } = useSearchBar(props);

  return (
    <form className={styles['search-bar']}>
      <Icon className={styles.icon} src={iconsTemp.search} />
      <label>
        <input
          data-testid='searchMedics-searchInput'
          type='search'
          placeholder={language.whatSpecialtyLookingFor}
          value={specialty}
          onChange={specialtyChangeHandler}
          ref={specialtyInputRef}
        />
        {specialty !== '' && (
          <Button
            data-testid='searchMedics-clearSearchInputButton'
            className={styles['clear_button']}
            type={ButtonTypes.SECONDARY}
            icon={iconsTemp.close}
            onClick={clearSearchInputHandler}
          />
        )}
      </label>
      <Icon className={styles.icon} src={iconsTemp.location} />
      <label>
        <input
          data-testid='searchMedics-locationInput'
          type='text'
          placeholder={language.location}
          value={location}
          onChange={locationChangeHandler}
        />
        {location !== '' && (
          <Button
            data-testid='searchMedics-clearLocationInputButton'
            className={styles['clear_button']}
            type={ButtonTypes.SECONDARY}
            icon={iconsTemp.close}
            onClick={clearLocationInputHandler}
          />
        )}
      </label>
      <Button
        dataTestId='searchMedics-submitSearchButton'
        type={ButtonTypes.PRIMARY}
        valueType='submit'
        label={language.search}
        onClick={submitHandler}
      />
    </form>
  );
};

export default SearchBar;
