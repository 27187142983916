import AppointmentDTO, { AppointmentPlaces, AppointmentStatus } from 'dtos/AppointmentDTO';
import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import AppointmentSchema from 'schemas/AppointmentSchema';
import MedicMapper from './MedicMapper';
import NoteMapper from './NoteMapper';
import PatientMapper from './PatientMapper';
import SpecialtyMapper from './SpecialtyMapper';
import { getInputValue, InputsReducerType } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import {
  CreateAppointmentBody,
  CancelAppointmentBody,
  RescheduleAppointmentBody,
} from 'RequestsBody/AppointmentBodies';

const medicMapper = MedicMapper();
const patientMapper = PatientMapper();
const specialtyMapper = SpecialtyMapper();
const noteMapper = NoteMapper();

const AppointmentMapper = () => {
  const toInterface = (schema: AppointmentSchema, userId?: string, languageCode?: LanguageCode): AppointmentDTO => {
    const medicId = schema.medicId || schema.medic?.id;
    const patientId = schema.patientId || schema.patient?.id;
    const isMedic = userId === medicId;
    const isPatient = userId === patientId;

    return {
      id: schema.id,
      medicId: medicId!,
      patientId: patientId!,
      userId: isMedic ? schema.patientId : isPatient ? schema.medicId : '',
      user:
        isMedic && schema.patient
          ? patientMapper.toInterface(schema.patient)
          : isPatient && schema.medic && languageCode
          ? medicMapper.toInterface(schema.medic, languageCode)
          : undefined,
      specialty: schema.specialty ? specialtyMapper.toInterface(schema.specialty) : { id: schema.specialtyId! },
      status: schema.status as AppointmentStatus,
      place: schema.appointmentType.toLowerCase() as AppointmentPlaces,
      startedAt: schema.startedAt ? new Date(schema.startedAt) : undefined,
      endedAt: schema.endedAt ? new Date(schema.endedAt) : undefined,
      note: schema.notes ? noteMapper.toInterface(schema.notes) : undefined,
    };
  };

  const toBody = (patientId: string, inputs: InputsReducerType): CreateAppointmentBody => {
    return {
      specialtyId: getInputValue(inputs.specialty)!,
      medicId: getInputValue(inputs.medic)!,
      patientId,
      eventId: getInputValue(inputs.event)!,
      reason: getInputValue(inputs.reason)!,
    };
  };

  const toRescheduleBody = (inputs: InputsReducerType): RescheduleAppointmentBody => {
    return {
      id: getInputValue(inputs.event)!,
    };
  };

  const toCancelBody = (inputs: InputsReducerType): CancelAppointmentBody => {
    return {
      reason: getInputValue(inputs.reason)!,
    };
  };

  return { toInterface, toBody, toRescheduleBody, toCancelBody };
};

export default AppointmentMapper;
