import Icon from 'components/View/Icon';
import { EditorState } from 'draft-js';
import { FC, PropsWithChildren, MouseEvent } from 'react';
import { FORMATS, STYLE_TYPE } from 'services/notes.service';
import styles from './WriteNoteFormatting.module.scss';
import sass from 'styles/exports.module.scss';
import { iconsTemp } from 'iconsTemp';
import { Transition } from 'react-transition-group';
import useWriteNoteFormatting from './WriteNoteFormatting.logic';
import MediaScreenSmartphone from 'components/Media/MediaScreenSmartphone';

export interface WriteNoteFormattingProps {
  editorState: EditorState;
  onToggle: (style: string, type: STYLE_TYPE) => void;
  onClear: () => void;
}

const WriteNoteFormatting: FC<WriteNoteFormattingProps> = (
  props: PropsWithChildren<WriteNoteFormattingProps>
): JSX.Element => {
  const {} = props;
  const { isOpen, currentStyle, blockType, toggleShowStylesHandler, selectFormatHandler } =
    useWriteNoteFormatting(props);

  return (
    <div className={styles['notes-formatting']}>
      <MediaScreenSmartphone>
        <div className={`${styles['show-styles']} ${isOpen && styles.open}`} onClick={toggleShowStylesHandler}>
          <Icon className={styles['show-icon']} src={iconsTemp.arrows.up} />
        </div>
      </MediaScreenSmartphone>
      <Transition in={isOpen} timeout={parseFloat(sass.transition)} mountOnEnter unmountOnExit>
        {(state) => (
          <div className={`${styles.lists} ${styles[state]}`}>
            {FORMATS.map((formatsList, i) => (
              <ul key={i}>
                {formatsList.map((format, j) => (
                  <li
                    key={j}
                    data-testid='notesFormatting-button'
                    className={`${(currentStyle.has(format.style) || blockType === format.style) && styles.active}`}
                    tabIndex={j}
                    onMouseDown={(event: MouseEvent) => selectFormatHandler(event, format.style, format.type)}>
                    <Icon className={styles.icon} src={format.icon} />
                  </li>
                ))}
              </ul>
            ))}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default WriteNoteFormatting;
