import { FC, PropsWithChildren, useState } from 'react';
import Star from './Star';
import styles from './Evaluate.module.scss';

export interface EvaluateProps {
  dataTestId?: string;
  className?: string;
  score: number;
  onSelectScore: (score: number) => void;
}

const Evaluate: FC<EvaluateProps> = (props: PropsWithChildren<EvaluateProps>) => {
  const { dataTestId, className, score, onSelectScore } = props;
  const [hoverIndex, setHoverIndex] = useState<number>(-1);

  return (
    <div className={`${styles.evaluate} ${className}`} onMouseLeave={() => setHoverIndex(-1)}>
      {new Array(5).fill(0).map((_: number, index: number) => (
        <Star
          key={index}
          dataTestId={`${dataTestId}-score${index + 1}`}
          hover={index <= hoverIndex}
          active={index + 1 <= score && hoverIndex === -1}
          onHover={() => setHoverIndex(index)}
          onSelect={() => onSelectScore(index + 1)}
        />
      ))}
    </div>
  );
};

export default Evaluate;
