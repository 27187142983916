import { Action, ActionTypes, FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useStorage } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import PatientDTO from 'dtos/PatientDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import onboardingSubRoutes from 'routes/onboardingSubRoutes';
import usePatientsService from 'services/patients.service';
import { entityActions } from 'store/redux/entity-slice';

export interface IUsePatientMandatoryOnboarding {
  language: ILanguage;
  isLoading: boolean;
  loadHandler: (dispatchInputs: Dispatch<Action>) => void;
  submitHandler: (submit: FormSubmit) => void;
}

const usePatientMandatoryOnboarding = (): IUsePatientMandatoryOnboarding => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getFromStorage } = useStorage('localStorage');
  const { isLoading, createPatient } = usePatientsService();
  const language = useSelector((state: IReduxState) => state.language.values);

  const loadHandler = (dispatchInputs: Dispatch<Action>): void => {
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'email', value: getFromStorage('email') });
  };

  const submitHandler = ({ inputs }: FormSubmit): void => {
    createPatient(inputs).then((patient: PatientDTO) => {
      dispatch(entityActions.setEntity(patient));
      history.push(onboardingSubRoutes.optional.path);
    });
  };

  return {
    language,
    isLoading,
    loadHandler,
    submitHandler,
  };
};

export default usePatientMandatoryOnboarding;
