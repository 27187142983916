import CheckList from 'components/Form/CheckList/CheckList';
import MultiOption from 'components/Form/MultiOption/MultiOption';
import Toggle from 'components/Form/Toggle/Toggle';
import styles from './Form.module.scss';

export type FormProps = {};

const Form: React.FC<FormProps> = (props: React.PropsWithChildren<FormProps>) => {
  const {} = props;
  const options = [
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' },
  ];

  return (
    <div className={styles.form}>
      <div>
        <div>
          <h4>Toggle Options</h4>
        </div>
        <div>
          <h4>Multi Options</h4>
        </div>
        <div>
          <h4>Checkboxes</h4>
        </div>
        <div>
          <h4>Radio buttons</h4>
        </div>
      </div>
      <div>
        <div>
          <Toggle />
          <MultiOption identifier='multiOption1' options={options} />
          <MultiOption identifier='multiOption2' join={true} options={options} />
        </div>
        <div>
          <MultiOption identifier='multiOption3' multi={true} options={options} />
          <MultiOption identifier='multiOption4' multi={true} join={true} options={options} />
        </div>
        <div>
          <CheckList identifier='checklist-1' type='checkbox' options={options} />
        </div>
        <div>
          <CheckList identifier='checklist-2' type='radio' options={options} />
        </div>
      </div>
    </div>
  );
};

export default Form;
