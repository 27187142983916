import CallButtons from 'components/Call/CallButtons/CallButtons';
import CallTime from 'components/Call/CallTime';
import EventCard from 'components/EventCard/EventCard';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';
import MediaScreenMobile from 'components/Media/MediaScreenMobile';
import { DateMasks } from 'hooks/use-date';
import { FC, PropsWithChildren } from 'react';
import { ChatContextProvider } from 'store/chat-context';
import { placeValue } from 'utils/language';
import AppointmentCallSideSection from '../AppointmentCall/AppointmentCallSideSection/AppointmentCallSideSection';
import AppointmentSideButtons from '../AppointmentCall/AppointmentCallSideSection/AppointmentSideButtons';
import useMedicAppointmentEnd from './MedicAppointmentEnd.logic';
import styles from './MedicAppointmentEnd.module.scss';

export interface MedicAppointmentEndProps {}

const MedicAppointmentEnd: FC<MedicAppointmentEndProps> = (
  props: PropsWithChildren<MedicAppointmentEndProps>
): JSX.Element => {
  const {} = props;
  const { language, appointmentId, appointmentEnd, nextAppointmentEvent, calleeId, formatDate, endAppointmentHandler } =
    useMedicAppointmentEnd();

  return (
    <div className={styles['appointment-end']}>
      <ChatContextProvider calleeId={calleeId} appointmentId={appointmentId}>
        <div className={styles.main}>
          <div className={styles['next-appointment']}>
            <h4>{placeValue(language.appointmentWillBeClosedAtX, formatDate(appointmentEnd, DateMasks.shortTime))}</h4>
            {nextAppointmentEvent && (
              <>
                <h1>{language.nextAppointment}</h1>
                <EventCard
                  className={styles.event}
                  tagsClassName={styles['event-tags']}
                  event={nextAppointmentEvent}
                  isSelected={false}
                  showTime={false}
                  hideInfoOnWarning={false}
                />
              </>
            )}
            {nextAppointmentEvent === null && <p>{language.youHaveNoMoreAppointmentsInTheNextHour}</p>}
            <CallTime />
            <CallButtons include={['exit']} onExit={endAppointmentHandler} />
          </div>
          <MediaScreenDesktop>
            <AppointmentSideButtons />
          </MediaScreenDesktop>
        </div>
        <AppointmentCallSideSection />
        <MediaScreenMobile>
          <AppointmentSideButtons />
        </MediaScreenMobile>
      </ChatContextProvider>
    </div>
  );
};

export default MedicAppointmentEnd;
