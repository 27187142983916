import { Action, ActionTypes, FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useStorage } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { Gender } from 'dtos/EntityDTO';
import MedicDTO from 'dtos/MedicDTO';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import ILanguage from 'interfaces/ILanguage';
import IOption from 'interfaces/IOption';
import IReduxState from 'interfaces/IReduxState';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import onboardingSubRoutes from 'routes/onboardingSubRoutes';
import useMedicsService from 'services/medics.service';
import useSpecialtiesService from 'services/specialties.service';
import { entityActions } from 'store/redux/entity-slice';

export interface IUseMedicMandatoryOnboarding {
  language: ILanguage;
  genderOptions: IOption[];
  specialties: SpecialtyDTO[];
  isLoading: boolean;
  loadHandler: (dispatchInputs: Dispatch<Action>) => void;
  submitHandler: (submit: FormSubmit) => void;
}

const useMedicMandatoryOnboarding = (): IUseMedicMandatoryOnboarding => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getFromStorage } = useStorage('localStorage');
  const { isLoading, createMedic } = useMedicsService();
  const { getSpecialties } = useSpecialtiesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [specialties, setSpecialties] = useState<SpecialtyDTO[]>([]);

  useEffect(() => {
    getSpecialties().then((specialties: SpecialtyDTO[]) => setSpecialties(specialties));
  }, []);

  const loadHandler = (dispatchInputs: Dispatch<Action>): void => {
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'email', value: getFromStorage('email') });
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'name', value: getFromStorage('name') });
    dispatchInputs({
      type: ActionTypes.UPDATE_VALUE,
      attribute: 'licence',
      value: getFromStorage('medicalCertificate'),
    });
  };

  const submitHandler = ({ inputs }: FormSubmit): void => {
    createMedic(inputs).then((medic: MedicDTO) => {
      dispatch(entityActions.setEntity(medic));
      history.push(onboardingSubRoutes.optional.path);
    });
  };

  return {
    language,
    genderOptions: [
      { id: Gender.FEMALE, name: language.doctorF },
      { id: Gender.MALE, name: language.doctorM },
    ],
    specialties,
    isLoading,
    loadHandler,
    submitHandler,
  };
};

export default useMedicMandatoryOnboarding;
