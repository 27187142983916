import { IOption, IProps, MultiOption } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import { getAppointmentPlacesOptions } from 'utils/appointment';

export interface IAppointmentTypesMultioptionProps extends PropsWithChildren<IProps> {
  name: string;
  multi?: boolean;
  join?: boolean;
  big?: boolean;
  nrRemote?: number;
  nrHome?: number;
  nrOffice?: number;
}

const AppointmentTypesMultioption: FC<IAppointmentTypesMultioptionProps> = (
  props: IAppointmentTypesMultioptionProps
) => {
  const { dataTestId, className, style, name, multi, join, big, nrRemote, nrHome, nrOffice } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const placesNrs = {
    [AppointmentPlaces.Remote]: nrRemote,
    [AppointmentPlaces.Home]: nrHome,
    [AppointmentPlaces.Office]: nrOffice,
  };
  const options: IOption[] = getAppointmentPlacesOptions(language).map((place: IOption) => ({
    ...place,
    name: `${place.name}${
      placesNrs[place.id as AppointmentPlaces] !== undefined ? ` (${placesNrs[place.id as AppointmentPlaces]})` : ''
    }`,
  }));

  return (
    <MultiOption
      dataTestId={dataTestId}
      className={className}
      style={style}
      name={name}
      multi={multi}
      join={join}
      big={big}
      options={options}
    />
  );
};

export default AppointmentTypesMultioption;
