import ILanguage from 'interfaces/ILanguage';
import { ILoginSubRoutes } from 'interfaces/IRoute';
import IReduxState from 'interfaces/IReduxState';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'routes/routes';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export type UseLogin = {
  title: string;
  phrase: string;
};

type Texts = {
  title: string;
  phrase: string;
};

const useLogin = (): UseLogin => {
  const logger = useWinstonLogger();
  const history = useHistory();
  const location = useLocation();
  const language = useSelector((state: IReduxState) => state.language.values) as ILanguage;
  const loginRoutes = routes.login.subRoutes as ILoginSubRoutes;

  useEffect(() => {
    if (location.pathname === routes.login.path) history.push((routes.login.subRoutes! as ILoginSubRoutes).login.path);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTexts = useCallback((): Texts => {
    let title: string = '';
    let phrase: string = '';

    if (!language) return { title: '', phrase: '' };

    switch (location.pathname) {
      case loginRoutes.accounts.path:
        logger.log(LOG_COMPONENT, 'displaying accounts texts');
        title = language.helloAgain;
        phrase = language.loginPhrase;
        break;
      case loginRoutes.login.path:
        logger.log(LOG_COMPONENT, 'displaying login form texts');
        title = language.healthComesFirst;
        phrase = language.loginPhrase;
        break;
      case loginRoutes.resetPassword.path:
        logger.log(LOG_COMPONENT, 'displaying reset password texts');
        title = language.forgotPassword;
        phrase = language.writeRecoveryEmail;
        break;
      case loginRoutes.resetPasswordSent.path:
        logger.log(LOG_COMPONENT, 'displaying reset password sent texts');
        title = language.helpIsOnTheWay;
        phrase = language.checkEmailBox;
        break;
    }

    return { title, phrase };
  }, [language, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...getTexts() };
};

export default useLogin;
