import { Flex, NumberInput, TagsWithOptionsInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import DailyHabitDTO from 'dtos/DailyHabitDTO';
import IReduxState from 'interfaces/IReduxState';
import { OnboardingParagraph } from 'pages/Onboarding/Onboarding.styles';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCatalogService from 'services/catalog.service';

export interface IPatientLifestyleStepProps extends PropsWithChildren<{}> {}

const PatientLifestyleStep: FC<IPatientLifestyleStepProps> = (props: IPatientLifestyleStepProps) => {
  const {} = props;
  const { getDailyHabits } = useCatalogService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [dailyHabits, setDailyHabits] = useState<DailyHabitDTO[]>([]);

  useEffect(() => {
    getDailyHabits().then((dailyHabits: DailyHabitDTO[]) => setDailyHabits(dailyHabits));
  }, []);

  return (
    <>
      <h1>{language.lastQuestions}</h1>
      <OnboardingParagraph>{language.tellUsAboutYourHealth}</OnboardingParagraph>
      <Flex>
        <NumberInput
          name='height'
          icon='height'
          nrDigits={4}
          min={0.5}
          max={2.5}
          prefix={language.height}
          placeholder='0'
          suffix='m'
        />
        <NumberInput
          style={{ marginLeft: '1rem' }}
          name='weight'
          icon='weight'
          nrDigits={3}
          max={150}
          prefix={language.weight}
          placeholder='0'
          suffix='kg'
        />
      </Flex>
      <TagsWithOptionsInput name='dailyHabits' icon='steps' placeholder={language.dailyHabits} tags={dailyHabits} />
    </>
  );
};

export default PatientLifestyleStep;
