import { AppointmentPlaces, AppointmentStatus } from 'dtos/AppointmentDTO';
import EventDTO, { AppointmentEventTypes } from 'dtos/EventDTO';
import { timeIsCloseFromNow, timeIsInsidePeriod } from './date';
import { EventApi } from '@fullcalendar/react';
import EventAvailabilityDTO from 'dtos/EventAvailabilityDTO';
import PeriodsDTO from 'dtos/PeriodsDTO';
import ILanguage from 'interfaces/ILanguage';
import { IOption } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

// appointments --------------------------------------------------
export const getAppointmentPlacesOptions = (language: ILanguage): IOption[] => {
  return [
    { id: AppointmentPlaces.Remote, icon: 'videoCall', name: language.remote },
    { id: AppointmentPlaces.Home, icon: 'address', name: language.home },
    { id: AppointmentPlaces.Office, icon: 'institution', name: language.office },
  ];
};

export const isAppointmentPayed = (appointmentStatus?: AppointmentStatus): boolean => {
  return (
    !!appointmentStatus &&
    appointmentStatus !== AppointmentStatus.Created &&
    appointmentStatus !== AppointmentStatus.Pending
  );
};

export const isAppointmentAboutToStart = (appointmentEvent: EventDTO): boolean => {
  const appointmentStart = appointmentEvent.start;
  return timeIsCloseFromNow(appointmentStart) && appointmentStart.getTime() > new Date().getTime();
};

export const isAppointmentOngoing = (appointmentEvent: EventDTO): boolean => {
  const now = new Date().getTime();
  return appointmentEvent.start.getTime() < now && now < appointmentEvent.end.getTime();
};

export const hasAppointmentPassed = (appointmentEvent: EventDTO): boolean => {
  const now = new Date();
  return appointmentEvent.end.getTime() < now.getTime();
};

export const hasAppointmentEnded = (appointmentEvent: EventDTO): boolean => {
  return hasAppointmentPassed(appointmentEvent) || appointmentEvent.appointment?.status === AppointmentStatus.Ended;
};

export const canStartAppointment = (appointmentEvent: EventDTO): boolean => {
  return (
    (appointmentEvent.appointment?.status === AppointmentStatus.Confirmed ||
      appointmentEvent.appointment?.status === AppointmentStatus.Started) &&
    appointmentEvent.appointment.place === AppointmentPlaces.Remote &&
    (isAppointmentAboutToStart(appointmentEvent) || isAppointmentOngoing(appointmentEvent))
  );
};

export const canModifyAppointment = (appointmentEvent: EventDTO): boolean => {
  return hasAppointmentPassed(appointmentEvent) || appointmentEvent.appointment?.status !== AppointmentStatus.Ended;
};

// events --------------------------------------------------

export const getFrom = (): string => new Date(new Date().setSeconds(new Date().getSeconds() + 30)).toISOString();

export const getTo = (): string => new Date(new Date().setDate(new Date().getDate() + 30)).toISOString();

export const areEventsEqual = (event1: EventApi | undefined, event2: EventApi | undefined): boolean => {
  return event1?._def.defId === event2?._def.defId;
};

export const getEventTypeImportance = (eventType: AppointmentEventTypes | AppointmentPlaces): number => {
  switch (eventType) {
    case AppointmentEventTypes.Available:
      return 0;
    case AppointmentPlaces.Remote:
      return 1;
    case AppointmentPlaces.Home:
      return 2;
    case AppointmentPlaces.Office:
      return 3;
    default:
      return 4;
  }
};

export const separateEventsIntoPeriods = (events: EventAvailabilityDTO[]): PeriodsDTO<EventAvailabilityDTO[]> => {
  const maxDawn = 8;
  const maxMorning = 13;
  const maxAfternoon = 20;

  return {
    dawn: events.filter((event: EventAvailabilityDTO) => timeIsInsidePeriod(event.start, 0, maxDawn)),
    morning: events.filter((event: EventAvailabilityDTO) => timeIsInsidePeriod(event.start, maxDawn, maxMorning)),
    afternoon: events.filter((event: EventAvailabilityDTO) =>
      timeIsInsidePeriod(event.start, maxMorning, maxAfternoon)
    ),
    night: events.filter((event: EventAvailabilityDTO) => timeIsInsidePeriod(event.start, maxAfternoon, 24)),
  };
};
