import useLoginAccounts from './LoginAccounts.logic';
import NoAccount from '../NoAccount';
import LoginAccount from './LoginAccount';
import { LoginContainer } from '../Login.styles';
import { PropsWithChildren, FC } from 'react';

export interface LoginAccountsProps extends PropsWithChildren<{}> {}

const LoginAccounts: FC<LoginAccountsProps> = (props: LoginAccountsProps): JSX.Element => {
  const {} = props;
  const { accounts, selectAccountHandler } = useLoginAccounts();

  return (
    <>
      <LoginContainer style={{ padding: 0 }}>
        {accounts.map((account, index) => (
          <LoginAccount key={index} name={account.name} email={account.email} onSelect={selectAccountHandler} />
        ))}
      </LoginContainer>
      <NoAccount />
    </>
  );
};

export default LoginAccounts;
