import { Flex, getColumnsWidth, IFlexProps, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface ILoginWrapperProps {}

export const LoginWrapper = styled(Flex)<ILoginWrapperProps>`
  width: 100vw;
  height: 100vh;
  padding-top: 10vh;
  box-sizing: border-box;

  > img {
    height: 50px;
  }

  > h1 {
    margin-top: 30px;
    margin-bottom: 5px;
    font-weight: 600;
    text-align: center;
  }

  > p {
    text-align: center;
  }
`;

export interface ILoginContainerProps {}

export const LoginContainer = styled(Flex).attrs((_: IFlexProps) => ({
  direction: 'column',
}))<ILoginContainerProps>`
  position: relative;
  width: ${getColumnsWidth({ nrColumns: 3 })};
  padding: 10px 20px 30px 20px;
  border-radius: 30px;
  margin: 30px 0;
  background-color: ${styles.colors.brand.lightest};

  > * {
    width: 100%;
  }
`;

/*
@media screen and (max-width: ($smallDesktop + 0px)) {
  .login {
    > div {
      > form,
      > div {
        width: getColWidth($nrColumns: 4);
      }
    }
  }
}

@media screen and (max-width: ($tablet + 0px)) {
  .login {
    padding-top: 5vh;

    > div {
      > form,
      > div {
        width: getColWidth($device: $tablet, $nrColumns: 6);
      }
    }
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .login {
    padding-top: 4vh;

    > h1 {
      width: getColWidth($device: $smartphone, $nrColumns: 4);
    }

    > p {
      width: 75%;
    }

    > div {
      > form,
      > div {
        width: getColWidth($device: $smartphone, $nrColumns: 4);
        padding: 0 getGutter($smartphone) 20px getGutter($smartphone);
        box-sizing: border-box;
      }

      > p {
        position: absolute;
        left: 0;
        bottom: getMargin($smartphone);
        width: 100%;
      }
    }
  }
}
*/
