import { EventContentArg } from '@fullcalendar/react';
import Button, { ButtonTypes } from 'components/Form/Button';
import { iconsTemp } from 'iconsTemp';
import EventDTO, { AppointmentEventTypes } from 'dtos/EventDTO';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import styles from './DayEvent.module.scss';
import EventInfo from '../../EventInfo';
import DayEventPopup from './DayEventPopup';
import useDate, { DateMasks } from 'hooks/use-date';
import { FC, PropsWithChildren, useContext } from 'react';
import AgendaContext from 'store/agenda-context';

export interface DayEventProps {
  event: EventContentArg;
  selected?: boolean;
  onClose: () => void;
}

const DayEvent: FC<DayEventProps> = (props: PropsWithChildren<DayEventProps>): JSX.Element => {
  const { event, selected, onClose } = props;
  const { formatDate } = useDate();
  const { goToEditAvailabilityHandler } = useContext(AgendaContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const { durationTime, place, icon, appointment } = event.event._def.extendedProps;
  const { start, end } = event.event._instance!.range;
  const eventDTO: EventDTO = {
    id: event.event._def.publicId,
    start,
    end,
    duration: durationTime,
    appointment,
    place,
    icon,
  };
  const eventIsFuture = end.getTime() > new Date().getTime();

  return (
    <div data-testid={`dayEvents-${place}-${formatDate(start, DateMasks.shortTime)}`} className={styles['day-event']}>
      <EventInfo event={eventDTO} selected={selected} showDetails={durationTime >= 30} />
      {place === AppointmentEventTypes.Available && eventIsFuture && (
        <Button
          className={styles['edit-button']}
          icon={iconsTemp.edit}
          label={language.edit}
          type={ButtonTypes.SECONDARY}
          onClick={() => goToEditAvailabilityHandler(eventDTO.id)}
        />
      )}
      <DayEventPopup show={!!selected} event={eventDTO} onClose={onClose} />
    </div>
  );
};

export default DayEvent;
