import { FC, PropsWithChildren } from 'react';
import AppointmentTypesIcons from 'components/Appointment/AppointmentTypesIcons';
import UserItem from 'components/User/UserItem';
import { AssociatedMedicDTO, AssociatedPatientDTO } from 'dtos/AssociatedUserDTO';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { placeValue } from 'utils/language';
import { getMedicSpecialtiesList } from 'utils/user';
import { RelatedUserItemTag } from './RelatedUserItem.styles';
import { SmallText } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

export interface IRelatedUserItemProps extends PropsWithChildren<{}> {
  user: AssociatedMedicDTO | AssociatedPatientDTO;
}

const RelatedUserItem: FC<IRelatedUserItemProps> = (props: IRelatedUserItemProps) => {
  const { user } = props;
  const { name, age, image, lastAppointmentDate } = user;
  const { formatDate, masks } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);

  const userName = (
    <>
      {name || language.unknown}
      {!!age && (
        <>
          {', '}
          <SmallText as='span'>
            {age} {language.yearsOld}
          </SmallText>
        </>
      )}
    </>
  );

  return (
    <UserItem name={userName} userImage={image}>
      {/*userIsMedic(userType) && (
        <RelatedUserItemDiseasesTag
          icon='chronicDisease'
          label={'Cardiopatia congênita; Colestrol; Triglicérides; Diabetes'}
          iconColor={styles.colors.feedback.alert}
        />
      )*/}
      {'specialties' in user && (
        <RelatedUserItemTag icon='appointment' label={getMedicSpecialtiesList(user.specialties)} />
      )}
      {'nrNotes' in user && (
        <RelatedUserItemTag
          icon='details'
          label={user.nrNotes !== undefined ? `${user.nrNotes} ${language.notes}` : '-'}
        />
      )}
      {'nrFiles' in user && (
        <RelatedUserItemTag
          icon='image'
          label={user.nrFiles !== undefined ? `${user.nrFiles} ${language.files}` : '-'}
        />
      )}
      <RelatedUserItemTag
        icon='calendar'
        label={
          lastAppointmentDate
            ? placeValue(language.lastAppointmentAt, formatDate(lastAppointmentDate, masks.mediumDate))
            : language.noAppointmentFulfilled
        }
      />
      {'appointmentTypes' in user && (
        <AppointmentTypesIcons style={{ marginLeft: 'auto' }} include={user.appointmentTypes} />
      )}
    </UserItem>
  );
};

export default RelatedUserItem;
