import Popup from 'components/View/Popup';
import BaseInput from '../BaseInput';
import { InputProps, InputsInnerProps } from '../Input';
import InputStep from './InputStep';
import useNumberInput from './NumberInput.logic';
import styles from './NumberInput.module.scss';

export type NumberInputProps = InputProps & InputsInnerProps & {};

const NumberInput: React.FC<NumberInputProps> = (props: React.PropsWithChildren<NumberInputProps>) => {
  const { dataTestId, inputValue: value, min, max, step, changeNumberHandler } = props;
  const { showStep, containerRef, setShowStep, openDialogHandler } = useNumberInput(props);

  return (
    <label className={styles['number_input_container']} ref={containerRef}>
      <BaseInput
        {...{
          ...props,
          value,
          canEdit: !step,
          focusHandler: openDialogHandler,
        }}
      />
      <Popup show={showStep && step !== undefined} close={setShowStep}>
        <InputStep
          valueDataTestId={`${dataTestId}-stepUpValue`}
          upDataTestId={`${dataTestId}-stepUpButton`}
          downDataTestId={`${dataTestId}-stepDownButton`}
          defaultValue={value as number}
          min={min as number}
          max={max as number}
          step={step!}
          onChange={changeNumberHandler!}
        />
      </Popup>
    </label>
  );
};

export default NumberInput;
