import { AppointmentPlaces } from 'dtos/AppointmentDTO';
import EventDTO from 'dtos/EventDTO';
import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import AppointmentSchema from 'schemas/AppointmentSchema';
import AppointmentMapper from './AppointmentMapper';
import { placesIcons } from './EventMapper';

const appointmentMapper = AppointmentMapper();

const AppointmentEventMapper = () => {
  const toInterface = (schema: AppointmentSchema, userId?: string, languageCode?: LanguageCode): EventDTO => {
    const start = new Date(schema.event.startAt);
    const end = new Date(schema.event.endAt);
    const place = schema.appointmentType.toLowerCase() as AppointmentPlaces;

    return {
      id: schema.event.id,
      start,
      end,
      duration: (end.getTime() - start.getTime()) / 1000 / 60,
      appointment: appointmentMapper.toInterface(schema, userId, languageCode),
      place,
      icon: placesIcons[place],
    };
  };

  return { toInterface };
};

export default AppointmentEventMapper;
