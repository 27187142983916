import EventDTO from './EventDTO';

export enum AlertTypes {
  USER_ENTERED_APPOINTMENT = 'user-entered-appointment',
  USER_LEFT_APPOINTMENT = 'user-left-appointment',
}

export default interface AlertDTO {
  id: string;
  type: AlertTypes;
  appointmentEvent: EventDTO;
}
