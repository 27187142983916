import AppointmentsTags from 'components/Appointment/AppointmentsTags';
import Button, { ButtonTypes } from 'components/Form/Button';
import Select from 'components/Form/Select';
import { iconsTemp } from 'iconsTemp';
import IOption from 'interfaces/IOption';
import useSearchFilters from './SearchFilters.logic';
import styles from './SearchFilters.module.scss';

export type SearchFiltersProps = {};

const SearchFilters: React.FC<SearchFiltersProps> = (props: React.PropsWithChildren<SearchFiltersProps>) => {
  const {} = props;
  const { language } = useSearchFilters();

  return (
    <div className={styles['search-filters']}>
      <AppointmentsTags
        className={styles['place_toggle']}
        multi={true}
        join={true}
        // nrRemote={124}
        // nrHome={12}
        // nrOffice={22}
        onChange={(/*option: IOption*/) => {}}
      />
      <div>
        <label>
          <span>{language.orderBy}</span>
          <Select
            className={styles.select}
            options={[{ id: 1, name: language.favorites }]}
            defaultOption={{ id: 1, name: language.favorites }}
          />
        </label>
        <Select
          className={styles.select}
          options={[{ id: 1, name: language.allAvailabilities }]}
          defaultOption={{ id: 1, name: language.allAvailabilities }}
          small={true}
        />
        <Button
          className={styles['filter_button']}
          type={ButtonTypes.SECONDARY}
          icon={iconsTemp.filter}
          label={language.filters}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export default SearchFilters;
