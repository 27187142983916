import Button, { ButtonTypes } from 'components/Form/Button';
import { iconsTemp } from 'iconsTemp';
import Select from 'components/Form/Select';
import useCalendarHeader from './CalendarHeader.logic';
import styles from './CalendarHeader.module.scss';
import IOption from 'interfaces/IOption';
import SmallCalendar from 'components/SmallCalendar/SmallCalendar';
import Popup from 'components/View/Popup';
import { CalendarViews } from 'mappers/EventMapper';
import { FC, PropsWithChildren } from 'react';

export interface CalendarHeaderProps {}

const CalendarHeader: FC<CalendarHeaderProps> = (props: PropsWithChildren<CalendarHeaderProps>): JSX.Element => {
  const {} = props;
  const {
    language,
    showDatePicker,
    title,
    options,
    selectedView,
    selectedDate,
    setShowDatePicker,
    selectDatePickerDateHandler,
    toggleDatePickerHandler,
    goToTodayHandler,
    previousHandler,
    nextHandler,
    changeViewHandler,
  } = useCalendarHeader();

  return (
    <div className={styles['calendar-header']}>
      <div>
        <Button
          dataTestId='calendar-calendarButton'
          className={styles['icon_button']}
          type={ButtonTypes.SECONDARY}
          active={showDatePicker}
          icon={iconsTemp.calendar}
          onClick={toggleDatePickerHandler}
        />
        <Popup className={styles['date-picker']} show={showDatePicker} close={setShowDatePicker}>
          <SmallCalendar value={selectedDate} onChange={selectDatePickerDateHandler} />
        </Popup>
      </div>
      <Button
        dataTestId='calendar-todayButton'
        className={styles['today_button']}
        type={ButtonTypes.SECONDARY}
        label={language.today}
        onClick={goToTodayHandler}
      />
      <Button
        dataTestId='calendar-previousButton'
        className={styles['icon_button']}
        type={ButtonTypes.SECONDARY}
        icon={iconsTemp.arrows.left}
        onClick={previousHandler}
      />
      <Button
        dataTestId='calendar-nextButton'
        className={styles['icon_button']}
        type={ButtonTypes.SECONDARY}
        icon={iconsTemp.arrows.right}
        onClick={nextHandler}
      />
      <h4>{title}</h4>
      <label>
        <span>{language.view}</span>
        <Select
          dataTestId='calendarHeader-viewSelect'
          className={styles.select}
          optionsClassName={styles['select-options']}
          options={options}
          option={options.find((option: IOption) => option.id === selectedView)}
          onChange={(option: IOption) => changeViewHandler(option.id as CalendarViews)}
          //small={true}
        />
      </label>
    </div>
  );
};

export default CalendarHeader;
