import ReadMore from 'components/View/ReadMore';
import { healthInsurances } from 'utils/healthInsurance';
import styles from './MedicProfile.module.scss';
import MedicProfileInfo from './MedicProfileInfo';
import MedicDTO from 'dtos/MedicDTO';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { CountryFlag } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export type MedicProfileProps = {
  medic: MedicDTO;
};

const MedicProfile: React.FC<MedicProfileProps> = (props: React.PropsWithChildren<MedicProfileProps>) => {
  const { medic } = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <div className={styles.profile}>
      <MedicProfileInfo medic={medic} />
      <section>
        <h5>{language.acceptedHealthInsurances}</h5>
        <div className={styles['health-insurances']}>
          {healthInsurances.map((healthInsurance) => (
            <img key={healthInsurance.id} src={healthInsurance.logo} alt='' />
          ))}
        </div>
      </section>
      <section>
        <h5>{language.about}</h5>
        <ReadMore>
          Médica gastroenterologista, com interesse no sistema Hepato-Bilio-Pancreático.
          <br />
          Formação em investigação clínica.
          <br />
          Membro da Sociedade Portuguesa de Gastroenterologia.
        </ReadMore>
      </section>
      <section>
        <h5>{language.educationAndExperience}</h5>
        <ReadMore>
          - Mestrado Integrado em Medicina pela Faculdade de Medicina da Universidade de Lisboa
          <br />
          - CSRT na Harvard Medical School
          <br />
          - Internato do ano comum no Centro Hospitalar Universitário de Coimbra, EPE
          <br />
          - Pós-graduação em procesos inflamatórios pancreáticos pela Faculdade de Medicina da Universidade de Coimbra
          <br />
          - Médica interna de Gastroenterologia no Hospital do Espírito Santo de Évora, EPE
          <br />
          - Gastroenterologista no Hospital do Espírito Santo de Évora, EPE e no Hospital CUF Descobertas
          <br />- Conferencista convidada na XII Conferência Europeia de Cirurgia Hepática
        </ReadMore>
      </section>
      <section>
        <h5>{language.languages}</h5>
        <ul className={styles.languages}>
          {[
            { code: 'PT', name: 'Português' },
            { code: 'US', name: 'English (US)' },
            { code: 'ES', name: 'Español' },
          ].map(({ code, name }: { code: string; name: string }) => (
            <li key={code}>
              <span>
                <CountryFlag countryCode={code} />
              </span>
              {name}
            </li>
          ))}
        </ul>
      </section>
      {/* <section>
        <h5>{language.officeLocations}</h5>
      </section>
      <section>
        <h5>{language.patientsEvaluations}</h5>
      </section> */}
    </div>
  );
};

export default MedicProfile;
