import { FC, PropsWithChildren } from 'react';
import IReduxState from 'interfaces/IReduxState';
import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import AppointmentContext from 'store/appointment-context';
import { placeValue } from 'utils/language';
import styles from './AppointmentHeader.module.scss';

export interface AppointmentHeaderProps {}

const AppointmentHeader: FC<AppointmentHeaderProps> = (
  props: PropsWithChildren<AppointmentHeaderProps>
): JSX.Element => {
  const {} = props;
  const { appointmentEvent } = useContext(AppointmentContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const { specialty } = appointmentEvent?.appointment!;

  if (isMobile) return <></>;

  return (
    <div className={styles['appointment-header']}>
      <div>
        <img src={`${process.env.PUBLIC_URL}/logos/symbol.svg`} alt='Simple Logo' />
        <span>{process.env.REACT_APP_NAME}</span>
      </div>
      {specialty.name && <p>{placeValue(language.appointmentSpecialty, specialty.name)}</p>}
    </div>
  );
};

export default AppointmentHeader;
