import useAuthGuard from './AuthGuard';
import useMedicGuard from './MedicGuard';
import usePatientGuard from './PatientGuard';

export type UseUserGuard = {
  userCanSee: (meta: any) => boolean;
  userCanActivate: (meta: any) => boolean;
};

const useUserGuard = (): UseUserGuard => {
  const { canActivate } = useAuthGuard();
  const { medicCanSee, medicCanActivate } = useMedicGuard();
  const { patientCanSee, patientCanActivate } = usePatientGuard();

  const userCanSee = (meta: any): boolean => {
    return medicCanSee(meta) || patientCanSee(meta);
  };

  const userCanActivate = (meta: any): boolean => {
    return canActivate(meta) && medicCanActivate(meta) && patientCanActivate(meta);
  };

  return { userCanSee, userCanActivate };
};

export default useUserGuard;
