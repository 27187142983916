import { iconsTemp } from 'iconsTemp';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import Button, { ButtonTypes } from 'components/Form/Button';
import styles from './DatePickerHeader.module.scss';
import useDate, { DateMasks } from 'hooks/use-date';

const DatePickerHeader: React.FC<ReactDatePickerCustomHeaderProps> = (props: ReactDatePickerCustomHeaderProps) => {
  const { date, prevMonthButtonDisabled, nextMonthButtonDisabled, decreaseMonth, increaseMonth } = props;
  const { formatDate } = useDate();

  return (
    <div className={styles['date-picker-header']}>
      <Button
        dataTestId='datePicker-previousButton'
        className={styles['arrow_icon']}
        type={ButtonTypes.TEXT}
        icon={iconsTemp.arrows.left}
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      />
      <h6>{formatDate(date, DateMasks.monthYear)}</h6>
      <Button
        dataTestId='datePicker-nextButton'
        className={styles['arrow_icon']}
        type={ButtonTypes.TEXT}
        icon={iconsTemp.arrows.right}
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
      />
    </div>
  );
};

export default DatePickerHeader;
