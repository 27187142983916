import { Button } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import MedicProfile from 'components/Profile/MedicProfile/MedicProfile';
import { StickyBackButtonWrapper } from 'components/Templates';
import { FC, PropsWithChildren } from 'react';
import AvailableSchedules from './AvailableSchedules';
import useMedicDetails from './MedicDetails.logic';

export interface IMedicDetailsProps extends PropsWithChildren<{}> {}

const MedicDetails: FC<IMedicDetailsProps> = (props: IMedicDetailsProps) => {
  const {} = props;
  const { medic, backHandler } = useMedicDetails();

  if (!medic) return <></>;

  return (
    <>
      <StickyBackButtonWrapper>
        <Button dataTestId='medicDetails-backButton' icon='arrowLeft' type='secondary' onClick={backHandler} />
      </StickyBackButtonWrapper>
      <MedicProfile medic={medic} />
      <AvailableSchedules medicSpecialties={medic.specialties} />
    </>
  );
};

export default MedicDetails;
