import styles from './AppointmentChat.module.scss';
import Messages from 'components/Chat/Messages/Messages';
import SendMessage from 'components/Chat/Messages/SendMessage';
import { IAppointmentChatSubRoutes, IAppointmentCallSubRoutes } from 'interfaces/IRoute';
import ITab from 'interfaces/ITab';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import GalleryAndFiles from 'components/Chat/GalleryAndFiles/GalleryAndFiles';
import Tabs from 'components/View/Tabs';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { FC, PropsWithChildren, useContext } from 'react';
import AppointmentContext from 'store/appointment-context';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';

export interface AppointmentChatProps {}

const AppointmentChat: FC<AppointmentChatProps> = (props: PropsWithChildren<AppointmentChatProps>): JSX.Element => {
  const {} = props;
  const match = useRouteMatch();
  const { appointmentEvent } = useContext(AppointmentContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const chatRoutes = (appointmentSubRoutes.call.subRoutes! as IAppointmentCallSubRoutes).chat
    .subRoutes! as IAppointmentChatSubRoutes;
  const tabs: ITab[] = [
    { id: 'call-chatTab', route: chatRoutes.messages, name: language.messages },
    { id: 'call-galleryAndFilesTab', route: chatRoutes.galleryAndFiles, name: language.galleryAndFiles },
  ];

  return (
    <>
      {!(match.params as any).tab && <Redirect to={`${match.url}/${chatRoutes.messages.path}`} />}
      <Tabs tabs={tabs} className={styles.tabs}>
        <Route path={`${match.path}(${chatRoutes.messages.path})`} exact={chatRoutes.messages.exact}>
          <Messages className={styles.messages} userClassName={styles['messages-user']} />
          <SendMessage />
        </Route>
        <Route path={`${match.path}(${chatRoutes.galleryAndFiles.path})`} exact={chatRoutes.galleryAndFiles.exact}>
          <GalleryAndFiles
            className={styles['gallery-files']}
            calleeId={appointmentEvent!.appointment!.user!.id}
            group={true}
            showFilters={false}
          />
        </Route>
      </Tabs>
    </>
  );
};

export default AppointmentChat;
