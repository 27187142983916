import { CallButtonsRef } from 'components/Call/CallButtons/CallButtons';
import routes from 'routes/routes';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useWinstonLogger } from 'winston-react';
import { LOG_COMPONENT, LOG_SOCKET } from 'utils/logger';
import { userIsMedic, userIsPatient } from 'utils/user';
import useSocket from 'hooks/use-socket';
import {
  UserEnteredWaitingRoomSocketMessage,
  UserLeftWaitingRoomSocketMessage,
  AppointmentStartSocketMessage,
} from 'interfaces/ISocketMessages';
import AppointmentContext from 'store/appointment-context';
import CallContext from 'store/call-context';
import { IAppointmentRouteState } from 'interfaces/IRouteStates';
import { getDoctorAbreviation, placeValue } from 'utils/language';
import { AppointmentAlertMessage } from '../AppointmentAlert';

export interface IUseWaitingRoom {
  language: ILanguage;
  localStreamRef: RefObject<HTMLVideoElement>;
  alert?: AppointmentAlertMessage;
  cameraIsOn: boolean;
  toggleButtonsHandler: () => void;
  startAppointmentHandler: () => void;
  exitAppointmentHandler: () => void;
}

export const useWaitingRoom = (): IUseWaitingRoom => {
  const logger = useWinstonLogger();
  const location = useLocation<IAppointmentRouteState>();
  const history = useHistory();
  const { listenSocketMessage, emitSocketMessage } = useSocket();
  const { appointmentEvent, startAppointmentFromWaitingRoomHandler, emitLeaveWaitingRoomHandler } =
    useContext(AppointmentContext);
  const { localStream, cameraIsOn, startCallHandler } = useContext(CallContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const [alert, setAlert] = useState<AppointmentAlertMessage>();
  const localStreamRef = useRef<HTMLVideoElement>(null);
  const callButtonsRef = useRef<CallButtonsRef>(null);
  const { user } = appointmentEvent!.appointment!;

  useEffect(() => {
    if (location.state?.appointmentHasStarted) {
      setAlert({
        message: placeValue(
          language.xIsAlreadyInAppointment,
          userIsPatient(userType)
            ? getDoctorAbreviation(language, user!.gender, user!.name)
            : userIsMedic(userType)
            ? user!.name
            : ''
        ),
        buttonLabel: language.restartAppointment,
        buttonDataTest: 'waitingRoom-startAppointmentButton',
        onClick: startAppointmentHandler,
      });
    }

    if (userIsMedic(userType)) {
      listenSocketMessage('user-waiting', (_: UserEnteredWaitingRoomSocketMessage) => {
        logger.log(LOG_SOCKET, 'received info that the another user (patient) is waiting');
        logger.log(LOG_COMPONENT, 'showing call message to start appointment');
        setAlert({
          message: placeValue(language.xAlreadyEntered, user!.name),
          buttonLabel: userIsMedic(userType) ? language.startAppointment : undefined,
          buttonDataTest: 'waitingRoom-startAppointmentButton',
          onClick: startAppointmentHandler,
        });
      });

      listenSocketMessage('user-gone', (_: UserLeftWaitingRoomSocketMessage) => {
        logger.log(LOG_SOCKET, 'received info that the is no longer waiting');
        logger.log(LOG_COMPONENT, 'hiding call message from waiting room');
        setAlert(undefined);
      });
    }

    listenSocketMessage('starting-appointment', startAppointmentCallback);

    // listens for the user click on the browser back button
    const unlisten = history.listen(() => {
      if (history.action === 'POP') {
        logger.log(LOG_COMPONENT, 'user clicked on the back button while in waiting room');
        emitLeaveWaitingRoomHandler();
      }
    });

    return () => {
      history.listen(unlisten);
    };
  }, []);

  useEffect(() => {
    if (location.state?.verified) emitSocketMessage('entered-waiting-room');
  }, [location.state?.verified]);

  useEffect(() => {
    if (cameraIsOn && localStreamRef.current) localStreamRef.current.srcObject = localStream;
  }, [cameraIsOn, localStreamRef.current]);

  const startAppointmentCallback = (data: AppointmentStartSocketMessage) => {
    const { eventDate, to } = data;
    startAppointmentFromWaitingRoomHandler(new Date(eventDate));
    startCallHandler([to]);
  };

  const toggleButtonsHandler = (): void => {
    callButtonsRef?.current?.toggle();
  };

  const startAppointmentHandler = (): void => {
    logger.log(LOG_SOCKET, 'emiting start appointment');
    emitSocketMessage('start-appointment', undefined, startAppointmentCallback);
  };

  const exitAppointmentHandler = (): void => {
    emitLeaveWaitingRoomHandler();
    history.replace(routes.home.path);
  };

  return {
    language,
    localStreamRef,
    alert,
    cameraIsOn,
    toggleButtonsHandler,
    startAppointmentHandler,
    exitAppointmentHandler,
  };
};
