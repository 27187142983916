import { IMessageGroup } from 'interfaces/IGroup';
import { useContext, useEffect, useRef, useState } from 'react';
import { groupMessages } from 'utils/group';
import ChatContext from 'store/chat-context';
import { useWinstonLogger } from 'winston-react';
import { LOG_COMPONENT } from 'utils/logger';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import ILanguage from 'interfaces/ILanguage';

export type UseMessages = {
  language: ILanguage;
  canLoadMore: boolean;
  groupedMessages: IMessageGroup[];
  messagesContainerRef: React.RefObject<HTMLDivElement>;
  lastReadMessagePosition?: number;
  calleeImage?: string;
  loadMoreMessagesHandler: () => void;
};

const useMessages = (): UseMessages => {
  const logger = useWinstonLogger();
  const { messages, canLoadMore, calleeImage, loadMoreMessages } = useContext(ChatContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [reachedTop, setReachedTop] = useState<boolean>(false);
  const [reachedBottom, setReachedBottom] = useState<boolean>(true);
  const [previousHeight, setPreviousHeight] = useState<number>(0);
  const [lastReadMessagePosition, setLastReadMessagePosition] = useState<number>();
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  // scrolls to the bottom when opening the chat messages
  useEffect(() => {
    logger.log(LOG_COMPONENT, 'scrolling to the end of the messages container');
    const container = messagesContainerRef.current;

    if (!container) return;

    container.scrollTo(0, container.scrollHeight);
    container.onscroll = () => {
      const { scrollTop, offsetHeight, scrollHeight } = container;
      setReachedBottom(scrollTop + offsetHeight === scrollHeight);
    };
  }, [messagesContainerRef.current]);

  useEffect(() => {
    const container = messagesContainerRef.current;

    if (!container) return;

    // scrolls to the bottom if the user sent a new message
    if (messages.length && messages.at(-1)?.id === `${messages.length}`) {
      logger.log(LOG_COMPONENT, 'scrolling to the end of the messages container because a new message was sent');
      container.scrollTo(0, container.scrollHeight);
    }
    // scrolls into the previous scroll position before new previous messages were added if it reached the top
    else if (reachedTop) {
      logger.log(LOG_COMPONENT, 'scrolling to the same position before load more messages request was made');
      container.scrollTo(0, container.scrollHeight - previousHeight);
      setReachedTop(false);
    }
    // scrolls to the bottom when receiving a new message
    // if the container was scrolled all the way down before receiving the new message
    else if (reachedBottom) {
      container.scrollTo(0, container.scrollHeight);
    }

    setLastReadMessagePosition(getLastReadMessagePosition());
  }, [messages]);

  const loadMoreMessagesHandler = () => {
    // sets that the container scroll has reached the top and saves the scroll height to keep the scroll at the same position
    setReachedTop(true);
    if (messagesContainerRef.current) setPreviousHeight(messagesContainerRef.current.scrollHeight);

    loadMoreMessages();
  };

  // gets the last read message element through the data-red attribute
  const getLastReadMessagePosition = (): number | undefined => {
    const messagesContainers = messagesContainerRef.current?.querySelectorAll("[data-read='true']");

    if (!messagesContainers || messagesContainers.length === 0) {
      logger.log(LOG_COMPONENT, 'no read message was found');
      return;
    }

    const { offsetTop, offsetHeight } = messagesContainers[messagesContainers.length - 1] as HTMLDivElement;
    logger.log(LOG_COMPONENT, `last read message has top ${offsetTop} and height ${offsetHeight}`);
    return offsetTop + offsetHeight;
  };

  return {
    language,
    canLoadMore,
    groupedMessages: groupMessages(messages),
    messagesContainerRef,
    lastReadMessagePosition,
    calleeImage,
    loadMoreMessagesHandler,
  };
};

export default useMessages;
