import useScrollView from 'components/View/ScrollView.logic';
import Button, { ButtonTypes } from 'components/Form/Button';
import { iconsTemp } from 'iconsTemp';
import React from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './ScrollView.module.scss';
import sass from 'styles/exports.module.scss';

export type ScrollViewProps = {
  className?: string;
  hideGradients?: boolean;
  showButtonScrollTop?: boolean;
};

const ScrollView = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ScrollViewProps>>(
  (props: React.PropsWithChildren<ScrollViewProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, hideGradients, showButtonScrollTop, children } = props;
    const { scroll, showButton, containerRef, scrollToTopHandler } = useScrollView(ref);

    return (
      <div className={`${styles.scroll} ${styles[scroll]} ${hideGradients && styles['no-gradients']} ${className}`}>
        <div className={styles.gradient}>
          <div /> <div />
        </div>
        <div ref={containerRef}>{children}</div>
        <div className={styles.gradient}>
          <div /> <div />
        </div>
        {showButtonScrollTop && (
          <Transition in={showButton} timeout={+sass.transition}>
            {(state: TransitionStatus) => (
              <span className={`${styles['back-to-top_button']} ${styles[state]}`}>
                <Button icon={iconsTemp.arrows.up} type={ButtonTypes.SECONDARY} onClick={scrollToTopHandler} />
              </span>
            )}
          </Transition>
        )}
      </div>
    );
  }
);

ScrollView.defaultProps = {
  hideGradients: false,
  showButtonScrollTop: false,
};

export default ScrollView;
