import useClickOutside from 'hooks/use-clickOutside';
import IOption from 'interfaces/IOption';
import { useRef, useState } from 'react';
import { OptionsInputProps } from './OptionsInput';

export type UseOptioncInput = {
  selected: string | number | undefined;
  showPopup: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  changeTextHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openPopupHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectOptionHandler: (option: IOption) => void;
};

const useOptioncInput = (props: OptionsInputProps): UseOptioncInput => {
  const { setValue, onChange, focusHandler, changeOptionHandler } = props;
  const [selected, setSelected] = useState<string | number>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, setShowPopup);

  const changeTextHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowPopup(true);
    setSelected(undefined);
    setValue(event.target.value);

    if (onChange) onChange(undefined);
  };

  const openPopupHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPopup(true);
    focusHandler(event);
  };

  const selectOptionHandler = (option: IOption) => {
    setSelected(option.id);
    setShowPopup(false);
    changeOptionHandler!(option);
  };

  return { selected, showPopup, containerRef, setShowPopup, changeTextHandler, openPopupHandler, selectOptionHandler };
};

export default useOptioncInput;
