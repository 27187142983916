import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';

export interface IUseRelatedUsersFilters {
  language: ILanguage;
}

const useRelatedUsersFilters = (): IUseRelatedUsersFilters => {
  const language = useSelector((state: IReduxState) => state.language.values);

  return { language };
};

export default useRelatedUsersFilters;
