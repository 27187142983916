import EventDTO from 'dtos/EventDTO';
import useMediaScreen from 'hooks/use-mediaScreen';
import { IHomeSubRoutes, ITodaySubRoutes } from 'interfaces/IRoute';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import routes from 'routes/routes';
import useHttp from 'services/http.service';
import useSchedulesService from 'services/schedules.service';

export interface IUseTodaysAgenda {
  routes: ITodaySubRoutes;
  isLoading: boolean;
  events?: EventDTO[];
  selectedEvent?: EventDTO;
  isMobileScreen: boolean;
}

const useTodaysAgenda = (): IUseTodaysAgenda => {
  const { id: appointmentId } = useParams<{ id: string }>();
  const { isLoading } = useHttp();
  const { isMobileScreen } = useMediaScreen();
  const { getTodaysEvents } = useSchedulesService();
  const [events, setEvents] = useState<EventDTO[]>();

  useEffect(() => {
    getTodaysEvents().then((events: EventDTO[]) => setEvents(events));
  }, []);

  return {
    routes: (routes.home.subRoutes! as IHomeSubRoutes).today.subRoutes as ITodaySubRoutes,
    isLoading,
    events,
    selectedEvent: events?.find((event: EventDTO) => event.appointment?.id === appointmentId),
    isMobileScreen,
  };
};

export default useTodaysAgenda;
