import useCancelAppointment from './CancelAppointment.logic';
import {
  LeftContent,
  TextArea,
  RightContent,
  Button,
  FormContextProvider,
  WhitePage,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import UserInfo from 'components/User/UserInfo';
import EntityDTO from 'dtos/EntityDTO';
import { CancelAppointmentSuggestions } from './CancelAppointment.styles';
import { placeValue } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { PropsWithChildren, FC } from 'react';
import { cancelAppointmentFormInputs } from 'services/appointments.service';

export interface ICancelAppointmentProps extends PropsWithChildren<{}> {}

const CancelAppointment: FC<ICancelAppointmentProps> = (props: ICancelAppointmentProps): JSX.Element => {
  const {} = props;
  const { language, appointment, backHandler, submitCancellationHandler } = useCancelAppointment();

  if (!appointment) return <></>;

  const { place, specialty, user } = appointment;

  return (
    <FormContextProvider initialValues={cancelAppointmentFormInputs} onSubmit={submitCancellationHandler}>
      <WhitePage title={language.cancelAppointment} onBack={backHandler}>
        <LeftContent>
          {user && (
            <UserInfo user={user as EntityDTO}>
              {specialty.name ? `${placeValue(language.appointmentSpecialty, specialty.name)} . ` : ''}
              {language[place]}
            </UserInfo>
          )}
          <TextArea
            dataTestId='cancelAppointment-reasonTextarea'
            name='reason'
            placeholder={language.cancellationReason}
          />
          <CancelAppointmentSuggestions>
            <h6>{language.messageSuggestions}</h6>
            <p>{placeValue(language.messageSuggestion1, user?.name || '')}</p>
            <p>{placeValue(language.messageSuggestion2, user?.name || '')}</p>
          </CancelAppointmentSuggestions>
        </LeftContent>
        <RightContent>
          <Button dataTestId='cancelAppointment-submitButton' label={language.sendCancellation} buttonType='submit' />
        </RightContent>
      </WhitePage>
    </FormContextProvider>
  );
};

export default CancelAppointment;
