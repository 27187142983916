import { FC, PropsWithChildren } from 'react';
import Tag from 'components/View/Tag';
import { Editor } from 'draft-js';
import NoteDTO from 'dtos/NoteDTO';
import { DateMasks } from 'hooks/use-date';
import { importNote } from 'services/notes.service';
import usePreviousNotes from './PreviousNotes.logic';
import styles from './PreviousNotes.module.scss';

export type PreviousNotesProps = {
  className?: string;
  calleeId: string;
};

const PreviousNotes: FC<PreviousNotesProps> = (props: PropsWithChildren<PreviousNotesProps>) => {
  const { className } = props;
  const { language, notes, formatDate } = usePreviousNotes(props);

  return (
    <>
      {notes.length > 0 && (
        <ul className={`${styles['previous-notes']} ${className}`}>
          {notes.map((note: NoteDTO) => (
            <li key={note.id}>
              <div>
                <h6>{formatDate(note.updatedAt, DateMasks.dateTime)}</h6>
                {note.specialty && (
                  <Tag className={styles.tag} toggle={false}>
                    {note.specialty.name}
                  </Tag>
                )}
              </div>
              <Editor key={note.id} editorState={importNote(note.text)} onChange={() => {}} readOnly />
            </li>
          ))}
        </ul>
      )}
      {notes.length === 0 && <p className={styles['empty-notes']}>{language.noNotes}</p>}
    </>
  );
};

export default PreviousNotes;
