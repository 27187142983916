import LoadMore from 'components/View/LoadMore';
import ScrollView from 'components/View/ScrollView';
import EventDTO from 'dtos/EventDTO';
import { IDayGroup } from 'interfaces/IGroup';
import styles from './AppointmentsList.module.scss';
import YearGroup from './YearGroup';

export type AppointmentsListProps = {
  title: string;
  appointments: IDayGroup<IDayGroup<EventDTO>>[];
  hasMoreAppointments: boolean;
  allLoadedText: string;
  onDeleteAppointment?: (eventId: string) => void;
  onLoadMore: () => void;
};

const AppointmentsList: React.FC<AppointmentsListProps> = (props: React.PropsWithChildren<AppointmentsListProps>) => {
  const { title, appointments, hasMoreAppointments, allLoadedText, onDeleteAppointment, onLoadMore } = props;

  return (
    <>
      <h4>{title}</h4>
      <ScrollView className={styles.scroll}>
        {appointments.map((yearEvents: IDayGroup<IDayGroup<EventDTO>>, i: number) => (
          <YearGroup key={i} yearEvents={yearEvents} onDelete={onDeleteAppointment} />
        ))}
        <LoadMore canLoadMore={hasMoreAppointments} allLoadedText={allLoadedText} onLoadMore={onLoadMore} />
      </ScrollView>
    </>
  );
};

export default AppointmentsList;
