import EntityImageDTO from 'dtos/EntityImageDTO';
import EntityImageBody from 'RequestsBody/EntityImageBody';
import EntityImageSchema from 'schemas/EntityImageSchema';

const EntityImageMapper = () => {
  const toInterface = (image: EntityImageSchema): EntityImageDTO => {
    return {
      contentType: image.contentType,
      bytes: image.bytes,
    };
  };

  const toBody = async (photo: File): Promise<EntityImageBody> => {
    return new Promise((resolve, _) => {
      var reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onload = () => {
        const dataUrl = (reader.result as string).replace('data:', '');
        const data = dataUrl.split(';');

        resolve({
          contentType: data[0],
          bytes: data[1].replace('base64,', ''),
        });
      };
    });
  };

  return { toInterface, toBody };
};

export default EntityImageMapper;
