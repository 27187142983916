import { FC, Fragment, PropsWithChildren } from 'react';
import Button, { ButtonTypes } from 'components/Form/Button';
import { useAppointmentSideButtons } from './AppointmentSideButtons.logic';
import styles from './AppointmentSideButtons.module.scss';

export interface AppointmentSideButtonsProps {}

const AppointmentSideButtons: FC<AppointmentSideButtonsProps> = (
  props: PropsWithChildren<AppointmentSideButtonsProps>
): JSX.Element => {
  const {} = props;
  const { actionButtons, hasUnreadMessages, userCanSee, clickHandler } = useAppointmentSideButtons();

  return (
    <div className={styles.container}>
      {actionButtons.map((button, index) => (
        <Fragment key={index}>
          {userCanSee(button.route.meta) && (
            <div>
              <Button
                dataTestId={button.dataTestId}
                className={styles['action_button']}
                type={ButtonTypes.TEXT}
                icon={button.icon}
                active={button.active}
                onClick={() => clickHandler(index)}
              />
              {button.showMessagesNotification && hasUnreadMessages && (
                <div className={styles['new-messages']}>
                  <div />
                </div>
              )}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default AppointmentSideButtons;
