import { Flex, styles, transition } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';

export interface IAppointmentDetailHeaderWrapperProps {}

export const AppointmentDetailHeaderWrapper = styled.div<IAppointmentDetailHeaderWrapperProps>`
  display: flex;
  margin-bottom: 20px;
`;

export interface IAppointmentDetailHeaderInfoProps {
  isShrinked: boolean;
}

export const AppointmentDetailHeaderInfo = styled(Flex)<IAppointmentDetailHeaderInfoProps>`
  flex: 70%;
  position: relative;
  width: 100%;
  margin-left: ${({ isShrinked }: IAppointmentDetailHeaderInfoProps) => (isShrinked ? '1rem' : '2rem')};

  > * {
    width: 100%;
  }
`;

export interface IAppointmentDetailHeaderPatientInfoProps {
  isShrinked: boolean;
}

export const AppointmentDetailHeaderPatientInfo = styled(Flex)<IAppointmentDetailHeaderPatientInfoProps>`
  width: 100%;
  margin-bottom: ${({ isShrinked }: IAppointmentDetailHeaderInfoProps) => (isShrinked ? '0' : '0.75rem')};
  ${transition('margin-bottom')}

  > h1,
  > h3 {
    ${transition('font-size')}
  }

  ${({ isShrinked }: IAppointmentDetailHeaderInfoProps) =>
    isShrinked
      ? css`
          > h1 {
            font-size: ${styles.textSize.normal};
          }

          > h3 {
            margin-top: 0;
            font-size: ${styles.textSize.small};
          }
        `
      : css`
          > h3 {
            margin-top: 0.3em;
          }
        `};
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .detail-header {
    display: block;

    > .back_button {
      position: absolute;
      top: getGutter($tablet);
      left: getMargin($tablet);
    }

    > div {
      &:first-of-type {
        margin: 3rem auto 0 auto;
      }

      &:last-child {
        > div {
          &.patient {
            display: block;
            margin-top: 10px;

            > * {
              margin-top: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .detail-header {
    > .back_button {
      top: getGutter($smartphone);
      left: getMargin($smartphone);
    }
  }
}
*/
