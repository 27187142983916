import LoadMore from 'components/View/LoadMore';
import { AssociatedMedicDTO, AssociatedPatientDTO } from 'dtos/AssociatedUserDTO';
import { FC, PropsWithChildren } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { getXMedics, getXPatients } from 'utils/language';
import EmptyList from './EmptyList';
import useRelatedUsers from './RelatedUsers.logic';
import { RelatedUsersList, RelatedUsersScroll } from './RelatedUsers.styles';
import RelatedUserItem from './RelatedUserItem';
import RelatedUsersFilters from './RelatedUsersFilters';

export interface IRelatedUsersProps extends PropsWithChildren<{}> {}

const RelatedUsers: FC<IRelatedUsersProps> = (props: IRelatedUsersProps) => {
  const {} = props;
  const { language, isMedic, userDetailsRoute, users, canLoadMore, loadMoreHandler } = useRelatedUsers();

  if (users === undefined) return <></>;

  return (
    <>
      {users.length > 0 ? (
        <RelatedUsersList>
          <RelatedUsersFilters />
          <h3>{(isMedic ? getXPatients : getXMedics)(language, users.length)}</h3>
          <RelatedUsersScroll>
            {users.map((user: AssociatedMedicDTO | AssociatedPatientDTO) => (
              <Link key={user.id} to={generatePath(userDetailsRoute.path, { id: user.id })}>
                <RelatedUserItem user={user} />
              </Link>
            ))}
            <LoadMore canLoadMore={canLoadMore} onLoadMore={loadMoreHandler} />
          </RelatedUsersScroll>
        </RelatedUsersList>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default RelatedUsers;
