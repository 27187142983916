import { FC, PropsWithChildren } from 'react';
import StartOnboarding from './StartOnboarding';
import { Redirect, Route, Switch } from 'react-router-dom';
import onboardingSubRoutes from 'routes/onboardingSubRoutes';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { userIsMedic, userIsPatient } from 'utils/user';
import PatientMandatoryOnboarding from './Patient/PatientMandatoryOnboarding';
import OptionalOnboarding from './OptionalOnboarding';
import MedicMandatoryOnboarding from './Medic/MedicMandatoryOnboarding';
import routes from 'routes/routes';

export interface IOnboardingProps extends PropsWithChildren<{}> {}

const Onboarding: FC<IOnboardingProps> = (props: IOnboardingProps): JSX.Element => {
  const {} = props;
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const entity = useSelector((state: IReduxState) => state.entity);

  return (
    <Switch>
      <Route path={routes.onboarding.path} exact={true}>
        {entity && <Redirect to={routes.home.path} />}
        <StartOnboarding />
      </Route>
      <Route path={onboardingSubRoutes.mandatory.path} exact={onboardingSubRoutes.mandatory.exact}>
        {entity && <Redirect to={routes.home.path} />}
        {userIsMedic(userType) && <MedicMandatoryOnboarding />}
        {userIsPatient(userType) && <PatientMandatoryOnboarding />}
      </Route>
      <Route path={onboardingSubRoutes.optional.path} exact={onboardingSubRoutes.optional.exact}>
        {!entity && <Redirect to={routes.onboarding.path} />}
        <OptionalOnboarding />
      </Route>
    </Switch>
  );
};

export default Onboarding;
