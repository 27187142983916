import { Button, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AppointmentInfo from 'components/Appointment/AppointmentInfo';
import EventDTO from 'dtos/EventDTO';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import { hasAppointmentPassed, isAppointmentPayed } from 'utils/appointment';
import { placeValue } from 'utils/language';
import {
  AppointmentButtonsPopup,
  AppointmentMotiveTag,
  AppointmentTitle,
  AppointmentWrapper,
} from './Appointment.styles';

export interface IAppointmentProps extends PropsWithChildren<{}> {
  appointmentEvent: EventDTO;
}

const Appointment: FC<IAppointmentProps> = (props: IAppointmentProps): JSX.Element => {
  const { appointmentEvent } = props;
  const { appointment } = appointmentEvent;
  const history = useHistory();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const appointmentHasPassed = hasAppointmentPassed(appointmentEvent);

  return (
    <AppointmentWrapper alignItems='flex-start'>
      <div style={{ flex: 1, marginRight: 'auto' }}>
        <div style={{ marginBottom: '1rem' }}>
          <AppointmentTitle as='b'>
            {appointment?.specialty.name ? placeValue(language.appointmentSpecialty, appointment.specialty.name) : ''}
          </AppointmentTitle>
          <AppointmentInfo appointmentEvent={appointmentEvent} hideSpecialty />
        </div>
        <div>
          <AppointmentMotiveTag
            icon='questionnaire'
            label={language.appointmentReason}
            iconColor={appointmentHasPassed ? styles.colors.brand.dark : styles.colors.feedback.success}
            textColor={appointmentHasPassed ? rgba(styles.colors.brand.dark, 0.7) : styles.colors.brand.dark}
            iconWithBackground
          />
          <p>Dores no peito, fraqueza, dores de cabeça</p>
        </div>
      </div>
      {!appointmentHasPassed && (
        <div style={{ position: 'relative', marginLeft: '1.125rem' }}>
          <Button
            style={{ transform: 'rotate(90deg)' }}
            type='secondary'
            active={showPopup}
            icon='options'
            onClick={() => {
              setShowPopup((prevState: boolean) => !prevState);
            }}
          />
          <AppointmentButtonsPopup top='110%' show={showPopup} close={setShowPopup}>
            {/*!isAppointmentPayed(appointment?.status) && (
              <Button
                type='text'
                state='dark'
                icon='calendar'
                label={language.reschedule}
                disabled
                onClick={() => history.push(generatePath(routes.reschedule.path, { appointmentId: appointment?.id }))}
              />
            )*/}
            <Button
              type='text'
              state='red'
              icon='close'
              label={language.cancelAppointment}
              onClick={() =>
                history.push(generatePath(routes.cancelAppointment.path, { appointmentId: appointment!.id }))
              }
            />
          </AppointmentButtonsPopup>
        </div>
      )}
    </AppointmentWrapper>
  );
};

export default Appointment;
