import { useStorage } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import MedicDTO from 'dtos/MedicDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import useAvailabilitiesService from 'services/availabilities.service';

export type UseSearchMedics = {
  language: ILanguage;
  medics: MedicDTO[] | undefined;
  showList: boolean;
  reportSymptomsHandler: () => void;
  submitHandler: (specialty: string, location: string) => void;
};

const useSearchMedics = (): UseSearchMedics => {
  const match = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const { getFromStorage, saveToStorage } = useStorage();
  const { getMedicsWithAvailabilities } = useAvailabilitiesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [medics, setMedics] = useState<MedicDTO[]>();
  const [showList, setShowList] = useState<boolean>(false);

  const reportSymptomsHandler = () => {};

  useEffect(() => {
    const params = new URLSearchParams(search);
    const specialty = params.get('specialty');
    //const location = params.get('location');

    if (!specialty) {
      const previousSearch = getFromStorage('searchMedics');
      if (previousSearch) submitHandler(previousSearch, '');
      return;
    }

    getMedics(specialty);
  }, []);

  const getMedics = (specialty: string) => {
    setShowList(true);

    getMedicsWithAvailabilities(specialty)
      .then((medicsPagination: { medics: MedicDTO[]; last: boolean }) => {
        setMedics(medicsPagination.medics);
      })
      .catch(() => {});
  };

  const submitHandler = (specialty: string, location: string) => {
    history.replace({
      pathname: match.path,
      search: `?specialty=${specialty}&location=${location}`,
    });

    saveToStorage('searchMedics', specialty);
    getMedics(specialty);
  };

  return { language, medics, showList, reportSymptomsHandler, submitHandler };
};

export default useSearchMedics;
