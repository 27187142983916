import ILanguage from 'interfaces/ILanguage';
import { ILoginSubRoutes } from 'interfaces/IRoute';
import IReduxState from 'interfaces/IReduxState';
import { IResetPasswordRouteState } from 'interfaces/IRouteStates';
import { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'routes/routes';
import { goBack } from 'utils/general';
import useAuthenticationService from 'services/authentication.service';
import { Action, ActionTypes, FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';

export interface IUseResetPassword {
  language: ILanguage;
  formLoadHandler: (dispatchInputs: Dispatch<Action>) => void;
  resetPasswordHandler: (submit: FormSubmit) => void;
  backHandler: () => void;
}

const useResetPassword = (): IUseResetPassword => {
  const history = useHistory();
  const location = useLocation();
  const {} = useAuthenticationService();
  const language = useSelector((state: IReduxState) => state.language.values);

  const formLoadHandler = (dispatchInputs: Dispatch<Action>): void => {
    if (!location.state) return;

    const email = (location.state as IResetPasswordRouteState).email;

    if (email !== '') {
      dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'email', value: email });
      dispatchInputs({ type: ActionTypes.VERIFY, attribute: 'email' });
    }
  };

  const resetPasswordHandler = ({}: FormSubmit): void => {
    history.push((routes.login.subRoutes! as ILoginSubRoutes).resetPasswordSent.path);
  };

  const backHandler = (): void => {
    goBack(history);
  };

  return { language, formLoadHandler, resetPasswordHandler, backHandler };
};

export default useResetPassword;
