import { FC, PropsWithChildren } from 'react';
import Icon from 'components/View/Icon';
import Volume from 'components/View/Volume';
import { iconsTemp } from 'iconsTemp';
import { useContext } from 'react';
import CallContext from 'store/call-context';
import styles from './MicrophoneVolume.module.scss';

export type MicrophoneVolumeProps = {
  volume: number;
};

const MicrophoneVolume: FC<MicrophoneVolumeProps> = (props: PropsWithChildren<MicrophoneVolumeProps>) => {
  const { volume } = props;
  const { microphoneIsOn } = useContext(CallContext);

  return (
    <div className={styles.microphone}>
      {microphoneIsOn && <Icon className={styles.icon} src={iconsTemp.microphoneOn} />}
      {!microphoneIsOn && <Icon className={styles.icon} src={iconsTemp.microphoneOff} />}
      <Volume value={volume} min={0} max={100} />
    </div>
  );
};

export default MicrophoneVolume;
