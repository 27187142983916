import FileDTO from 'dtos/FileDTO';
import MessageDTO, { MessageState } from 'dtos/MessageDTO';
import FileSchema from 'schemas/FileSchema';

const FileMapper = () => {
  const toInterface = (schema: FileSchema): FileDTO => {
    return {
      id: schema.id,
      date: new Date(schema.createdAt),
      filename: schema.fileName,
      mimeType: schema.mimeType,
    };
  };

  const createMessage = (id: number): MessageDTO => {
    return {
      id: `${id}`,
      mine: true,
      text: null,
      date: new Date(),
      state: MessageState.SENDING,
    };
  };
  return { toInterface, createMessage };
};

export default FileMapper;
