import { Tag } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IPatientDataTagProps {}

export const PatientDataTag = styled(Tag)<IPatientDataTagProps>`
  > span {
    font-size: 1rem;
  }
`;
