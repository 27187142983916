import { useMediaQuery } from 'react-responsive';
import sass from 'styles/exports.module.scss';

export type UseMediaScreen = {
  isDesktopScreen: boolean;
  isSmallDesktopScreen: boolean;
  isMobileScreen: boolean;
  isTabletScreen: boolean;
  isSmartphoneScreen: boolean;
};

const useMediaScreen = (): UseMediaScreen => {
  const isDesktopScreen = useMediaQuery({ minWidth: `${sass.desktop}px` });
  const isSmallDesktopScreen = useMediaQuery({ minWidth: `${sass.tablet}px`, maxWidth: `${sass.smallDesktop}px` });
  const isMobileScreen = useMediaQuery({ maxWidth: `${sass.tablet}px` });
  const isTabletScreen = useMediaQuery({ minWidth: `${sass.smartphone}px`, maxWidth: `${sass.tablet}px` });
  const isSmartphoneScreen = useMediaQuery({ maxWidth: `${sass.smartphone}px` });

  return { isDesktopScreen, isSmallDesktopScreen, isMobileScreen, isTabletScreen, isSmartphoneScreen };
};

export default useMediaScreen;
