import { useHeaderProfile } from './HeaderProfile.logic';
import { Button, IProps, styles, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import { HeaderProfilePopup } from './HeaderProfile.styles';

export interface IHeaderProfileProps extends PropsWithChildren<IProps> {}

const HeaderProfile: FC<IHeaderProfileProps> = (props: IHeaderProfileProps): JSX.Element => {
  const { className, style } = props;
  const {
    language,
    imageSrc,
    showProfilePopup,
    setShowProfilePopup,
    toggleProfilePopupHandler,
    accountHandler,
    logoutHandler,
  } = useHeaderProfile();

  return (
    <div className={className} style={{ position: 'relative', ...style }}>
      <UserImage
        dataTestId='headerProfile-picture'
        src={imageSrc}
        size={styles.iconSize.normal}
        onClick={toggleProfilePopupHandler}
      />
      <HeaderProfilePopup
        dataTestId='headerProfile-popup'
        style={{ right: 0 }}
        top='110%'
        show={showProfilePopup}
        close={setShowProfilePopup}>
        <Button
          dataTestId='headerProfile-accountButton'
          type='text'
          state='dark'
          icon='userData'
          label={language.manageAccount}
          onClick={accountHandler}
        />
        <Button
          dataTestId='headerProfile-helpButton'
          type='text'
          state='dark'
          icon='settings'
          label={language.settings}
          disabled
        />
        <Button
          dataTestId='headerProfile-helpButton'
          type='text'
          state='dark'
          icon='help'
          label={language.help}
          disabled
        />
        <Button
          dataTestId='headerProfile-logoutButton'
          style={{ opacity: 0.75 }}
          type='text'
          state='dark'
          icon='logout'
          label={language.logout}
          onClick={logoutHandler}
        />
      </HeaderProfilePopup>
    </div>
  );
};

export default HeaderProfile;
