import EventDTO, { AppointmentEventTypes } from 'dtos/EventDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import styles from './EventInfo.module.scss';
import useDate, { DateMasks } from 'hooks/use-date';
import { isAppointmentPayed } from 'utils/appointment';
import { FC, PropsWithChildren } from 'react';
import { Icon, UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { timeIsPast } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';

export interface EventInfoProps {
  dataTestId?: string;
  className?: string;
  event: EventDTO;
  selected?: boolean;
  showDetails?: boolean;
  showStartDate?: boolean;
}

const EventInfo: FC<EventInfoProps> = (props: PropsWithChildren<EventInfoProps>): JSX.Element => {
  const { dataTestId, className, event, selected, showDetails, showStartDate } = props;
  const { formatDate } = useDate();
  const language = useSelector((state: IReduxState) => state.language.values);
  const { place, icon, start, end, appointment } = event;
  const eventIsAvailableAndPast = place === AppointmentEventTypes.Available && timeIsPast(start);
  console.log(place, start, timeIsPast(start));

  return (
    <div
      data-testid={dataTestId}
      className={`${styles.event} ${styles[place]} ${selected && styles.selected} ${
        eventIsAvailableAndPast && styles.disabled
      } ${className}`}>
      <div>
        <Icon className={styles['circle-icon']} src={icon as any} />
        {place !== AppointmentEventTypes.Available && (
          <>
            {appointment?.user && (
              <>
                <h5>{appointment?.user.name}</h5>
                <UserImage style={{ marginLeft: '0.5rem' }} src={appointment?.user.image} size='1rem' />
              </>
            )}
            {!appointment?.user && language.unknown}
          </>
        )}
        {place === AppointmentEventTypes.Available && <h5>{language.available}</h5>}
      </div>
      {showDetails && (
        <div>
          {showStartDate && <span>{formatDate(start, DateMasks.mediumDate)}&ensp;.&ensp;</span>}
          <span>{formatDate(start, DateMasks.shortTime)}</span>
          &ensp;-&ensp;
          <span>{formatDate(end, DateMasks.shortTime)}</span>
          {place !== AppointmentEventTypes.Available && (
            <>
              &ensp;.&ensp;
              <span>{language[place as keyof ILanguage]}</span>
            </>
          )}
          {appointment && (
            <>&nbsp;.&nbsp;{isAppointmentPayed(appointment?.status) ? language.payed : language.notPayed}</>
          )}
        </div>
      )}
    </div>
  );
};

export default EventInfo;
