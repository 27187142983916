import MedicDTO from 'dtos/MedicDTO';
import PatientDTO from 'dtos/PatientDTO';

export enum ProfileType {
  MEDIC = 'medic',
  PATIENT = 'patient',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export default interface EntityDTO
  extends Omit<MedicDTO & PatientDTO, 'licence' | 'birthDate' | 'age' | 'specialties'> {
  type: ProfileType;
  licence?: number;
  birthDate?: Date;
  age?: number;
}
