import {
  IFlexProps,
  Flex,
  StepProgressDetailed,
  getMargin,
  Button,
  WhitePage,
  getGutter,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';

export interface IScheduleAppointmentWrapperProps {
  isFullPageStep: boolean;
}

export const ScheduleAppointmentWrapper = styled(WhitePage)<IScheduleAppointmentWrapperProps>`
  ${({ isFullPageStep }: IScheduleAppointmentWrapperProps) =>
    isFullPageStep
      ? css`
          padding-right: 0;
          padding-left: 0;

          > div {
            &:nth-child(1) {
              margin-left: ${getMargin()};
            }

            &:nth-child(2) {
              overflow-y: hidden;
            }
          }
        `
      : css``}
`;

export interface IScheduleAppointmentStepProgressProps {}

export const ScheduleAppointmentStepProgress = styled(StepProgressDetailed)<IScheduleAppointmentStepProgressProps>`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export interface IScheduleAppointmentFormProps {}

export const ScheduleAppointmentForm = styled(Flex).attrs((_: IFlexProps) => ({
  direction: 'column',
}))<IScheduleAppointmentFormProps>`
  flex: 1;
  overflow-y: hidden;
  padding-bottom: ${getMargin()};

  > * {
    width: 100%;
  }
`;

export interface IScheduleAppointmentButtonsProps {}

export const ScheduleAppointmentButtons = styled(Flex)<IScheduleAppointmentButtonsProps>`
  margin-top: auto;

  > button {
    margin-top: 0 !important;
  }
`;

export interface IScheduleAppointmentNextButtonProps {}

export const ScheduleAppointmentNextButton = styled(Button)<IScheduleAppointmentNextButtonProps>`
  margin-top: 0 !important;
  margin-left: auto;
`;
