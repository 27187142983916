import { useEffect, useState } from 'react';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import Buttons from './Buttons';
import Colors from './Colors';
import Form from './Form';
import Inputs from './Inputs';
import styles from './StyleGuide.module.scss';
import Toasts from './Toasts';

export type StyleGuideProps = {};

const StyleGuide: React.FC<StyleGuideProps> = (props: React.PropsWithChildren<StyleGuideProps>) => {
  const {} = props;
  const logger = useWinstonLogger();
  const [showStyles, setShowStyles] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('keypress', (event) => {
      if (event.ctrlKey && event.shiftKey && event.code === 'KeyS') {
        setShowStyles((prevState) => {
          if (prevState) logger.log(LOG_COMPONENT, 'hiding styles');
          else logger.log(LOG_COMPONENT, 'showing styles');
          return !prevState;
        });
      }
    });
  }, [logger]);

  if (!showStyles) return null;

  return (
    <div className={styles.container}>
      <div>
        <h1>Colors</h1>
        <Colors />
      </div>
      <div>
        <h1>Buttons</h1>
        <Buttons />
      </div>
      <div>
        <h1>Inputs</h1>
        <Inputs />
      </div>
      <div>
        <h1>Form</h1>
        <Form />
      </div>
      <div>
        <h1>Toasts</h1>
        <Toasts />
      </div>
    </div>
  );
};

export default StyleGuide;
