import { FormContextProvider, getColumnsWidth } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IAddAvailabilityFormProps {}

export const AddAvailabilityForm = styled(FormContextProvider)<IAddAvailabilityFormProps>`
  width: ${getColumnsWidth({ nrColumns: 3 })};
  height: 100%;
`;

export interface IAddAvailabilityTitleProps {}

export const AddAvailabilityTitle = styled.h1<IAddAvailabilityTitleProps>`
  margin-bottom: 2.5rem;
  text-align: center;
`;
