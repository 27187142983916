import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import MessageDTO, { MessageState } from 'dtos/MessageDTO';
import FileDTO from 'dtos/FileDTO';
import React from 'react';
import styles from './Message.module.scss';
import ImageFilePreview from '../GalleryAndFiles/ImageFilePreview';
import useMessage from './Message.logic';

export type MessageProps = {
  dataTestId?: string;
  message: MessageDTO;
};

const Message: React.FC<MessageProps> = (props: React.PropsWithChildren<MessageProps>) => {
  const { dataTestId, message } = props;
  const { text, mine, state } = message;
  const { messageContainer, stateIcon } = useMessage(props);

  return (
    <div
      data-testid={dataTestId}
      data-read={mine ? state === MessageState.READ : undefined}
      className={`${styles.message} ${mine ? styles.mine : styles.other}`}
      ref={messageContainer}>
      <div>
        {typeof text === 'string' && (
          <p data-testid='message-text'>
            {text.split('\n').map((textLine, i) => (
              <React.Fragment key={i}>
                {textLine}
                <br />
              </React.Fragment>
            ))}
          </p>
        )}
        {text && typeof text !== 'string' && (
          <ImageFilePreview className={styles['file-preview']} file={text as FileDTO} />
        )}
        {!text && (
          <div className={styles.loading}>
            <Icon dataTestId='message-loading' className={styles['loading-icon']} src={iconsTemp.loading} />
          </div>
        )}
      </div>
      {stateIcon !== undefined && <Icon className={styles['state_icon']} src={stateIcon} />}
    </div>
  );
};

export default Message;
