import { ActionTypes, FormContext, getInputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { timeToMinutes } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { useContext, useEffect } from 'react';

export interface IUseAddAvailabilityTime {}

const useAddAvailabilityTime = (): IUseAddAvailabilityTime => {
  const { inputs, dispatchInputs } = useContext(FormContext);

  useEffect(() => {
    if (inputs.duration.value === '') return;

    const duration = getInputValue<number>(inputs.duration)!;
    const startTime = timeToMinutes(getInputValue<Date>(inputs.startTime)!);
    const endTime = timeToMinutes(getInputValue<Date>(inputs.endTime)!);
    const timeDifference = endTime - startTime;
    const missingMinutes = timeDifference % duration;

    if (missingMinutes === 0) return;

    const minutesToAddOrRemove = missingMinutes >= duration / 2 ? duration - missingMinutes : -missingMinutes;
    const updatedEndTime = new Date(getInputValue<Date>(inputs.endTime)!.getTime() + minutesToAddOrRemove * 60 * 1000);
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'endTime', value: updatedEndTime });
  }, [getInputValue<number>(inputs.duration)]);

  return {};
};

export default useAddAvailabilityTime;
