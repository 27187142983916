import { Button, WhitePage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AddAvailabilityFormContent from 'components/AddAvailabilityFormContent/AddAvailabilityFormContent';
import { PropsWithChildren, FC } from 'react';
import { createAvailabilityFormInputs } from 'services/schedules.service';

import useAddAvailability from './AddAvailability.logic';
import { AddAvailabilityForm, AddAvailabilityTitle } from './AddAvailability.styles';

export interface IAddAvailabilityProps extends PropsWithChildren<{}> {}

const AddAvailability: FC<IAddAvailabilityProps> = (props: IAddAvailabilityProps): JSX.Element => {
  const {} = props;
  const { language, isLoading, backHandler, createAvailabilityHandler } = useAddAvailability();

  return (
    <WhitePage title='' onBack={backHandler}>
      <AddAvailabilityForm initialValues={createAvailabilityFormInputs} onSubmit={createAvailabilityHandler}>
        <AddAvailabilityTitle>{language.addAvailability}</AddAvailabilityTitle>
        <AddAvailabilityFormContent />
        <Button
          dataTestId='addAvailability-submitButton'
          style={{ marginRight: 'auto', marginLeft: 'auto' }}
          buttonType='submit'
          label={language.save}
          loading={isLoading}
        />
      </AddAvailabilityForm>
    </WhitePage>
  );
};

export default AddAvailability;
