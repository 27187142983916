import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { useHistory } from 'react-router-dom';
import { useWinstonLogger } from 'winston-react';
import { LOG_HTTP } from 'utils/logger';
import ILanguage from 'interfaces/ILanguage';
import { entityActions } from 'store/redux/entity-slice';
import useAuthenticationService from './services/authentication.service';
import routes from 'routes/routes';
import IRoute, { ILoginSubRoutes } from 'interfaces/IRoute';
import IToastContent from 'interfaces/IToastContent';
import { feedbackActions } from 'store/redux/feedback-slice';
import useNotificationsService from 'services/notifications.service';
import { requestFCMToken } from 'utils/firebase';
import SocketContext from 'store/socket-context';
import { IEntitySlice } from 'interfaces/ISlices';
import { useLanguage } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { userIsMedic } from 'utils/user';
import useMedicsService from 'services/medics.service';
import usePatientsService from 'services/patients.service';
import PatientDTO from 'dtos/PatientDTO';
import MedicDTO from 'dtos/MedicDTO';

let timeout: NodeJS.Timeout;

export interface IUseApp {
  language: ILanguage;
  entity: IEntitySlice | null;
  firstTime: boolean;
  loadedEntity: boolean;
  toastFeedbacks: IToastContent[];
  closeToastHandler: (toastId: string) => void;
}

const useApp = (): IUseApp => {
  const logger = useWinstonLogger();
  const dispatch = useDispatch();
  const history = useHistory();
  const {} = useLanguage(true, `${process.env.PUBLIC_URL}/languages`);
  const { connectToSocket } = useContext(SocketContext);
  const { refreshToken } = useAuthenticationService();
  const { getCurrentMedic } = useMedicsService();
  const { getCurrentPatient } = usePatientsService();
  const { createOrUpdateFirebaseToken } = useNotificationsService();
  const initialLocation = useMemo(() => history.location.pathname, []);
  const initialSearch = useMemo(() => history.location.search, []);
  const language = useSelector((state: IReduxState) => state.language.values);
  const { id: userId, type: userType, token, expiresIn } = useSelector((state: IReduxState) => state.auth);
  const entity = useSelector((state: IReduxState) => state.entity);
  const toastFeedbacks = useSelector((state: IReduxState) => state.feedback.toastFeedbacks);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [loadedEntity, setLoadedEntity] = useState<boolean>(false);

  useEffect(() => {
    logger.log(LOG_HTTP, 'getting if user token is valid');

    refreshToken()
      .then(() => {
        let userWasInAuthRoute = false;
        const authRoutesPaths = [
          ...Object.values(routes.login.subRoutes! as ILoginSubRoutes).map((route: IRoute) => route.path),
        ];

        authRoutesPaths.forEach((authRoutePath: string) => {
          if (initialLocation.includes(authRoutePath)) userWasInAuthRoute = true;
        });

        if (userWasInAuthRoute) history.replace(routes.home.path);
        else history.replace({ pathname: initialLocation, search: initialSearch });
      })
      .catch(() => {
        setLoadedEntity(true);
      })
      .finally(() => {
        setFirstTime(false);
      });
  }, []);

  useEffect(() => {
    if (!expiresIn) {
      clearTimeout(timeout);
      return;
    }

    timeout = setTimeout(() => refreshToken(), expiresIn - 10000);
  }, [token, expiresIn]);

  useEffect(() => {
    if (!userId) return;

    connectToSocket(token!);
    setLoadedEntity(false);

    (userIsMedic(userType!) ? getCurrentMedic : getCurrentPatient)()
      .then((entity: MedicDTO | PatientDTO) => {
        dispatch(entityActions.setEntity(entity));
      })
      .catch(() => {
        logger.log(LOG_HTTP, 'no entity found. redirecting user to onboarding');
        history.push(routes.onboarding.path);
      })
      .finally(() => {
        setLoadedEntity(true);
      });

    requestFCMToken().then((token: string) => createOrUpdateFirebaseToken(token));
  }, [userId]);

  const closeToastHandler = (toastId: string): void => {
    dispatch(feedbackActions.removeMessage(toastId));
  };

  return { language, entity, firstTime, loadedEntity, toastFeedbacks, closeToastHandler };
};

export default useApp;
