import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import { useEffect, useState } from 'react';
import useFilesService from 'services/files.service';
import { ImageFilePreviewProps } from './ImageFilePreview';

export type UseImageFilePreview = {
  extension: string;
  imageSrc: string | undefined;
  formatDate: (date: Date, mask: DateMasks | GlobalDateMasks) => string;
  clickHandler: (event: React.MouseEvent) => void;
};

const useImageFilePreview = (props: ImageFilePreviewProps): UseImageFilePreview => {
  const { file } = props;
  const { id, filename } = file;
  const { getFilePreview, getFile } = useFilesService();
  const { formatDate } = useDate();
  const extension = filename.slice(filename.lastIndexOf('.'));
  const [imageSrc, setImageSrc] = useState<string>();

  useEffect(() => {
    getFilePreview(id).then((blobUrl: string) => setImageSrc(blobUrl));
  }, []);

  const clickHandler = (event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    getFile(id);
  };

  return { extension, imageSrc, formatDate, clickHandler };
};

export default useImageFilePreview;
