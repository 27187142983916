import { Flex, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface INoTodayEventsWrapperProps {}

export const NoTodayEventsWrapper = styled(Flex)<INoTodayEventsWrapperProps>`
  align-self: flex-start;
  overflow: hidden;
  flex: 1;
  margin-top: 10%;

  > h1,
  > h4 {
    color: ${styles.colors.brand.main};
    text-align: center;
  }

  > h1 {
    margin-top: 2rem;
    margin-bottom: 0.75rem;

    > span {
      color: inherit;
      font-size: inherit;
      font-weight: 400;
    }
  }

  > img {
    display: block;
    width: 12.5rem;
    height: 12.5rem;
    margin: 2rem auto;
    border-radius: 999px;
    background-color: ${rgba(styles.colors.brand.main, 0.3)};
  }
`;
