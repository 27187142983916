import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import './DatePicker.scss';
import DatePickerHeader from './DatePickerHeader';
//import styles from './SmallCalendar.module.scss';

export type SmallCalendarProps = {
  value: Date;
  min?: Date;
  max?: Date;
  onChange: (date: Date) => void;
};

const SmallCalendar: React.FC<SmallCalendarProps> = (props: React.PropsWithChildren<SmallCalendarProps>) => {
  const { value, min, max, onChange } = props;

  return (
    <DatePicker
      selected={value}
      minDate={min}
      maxDate={max}
      renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => <DatePickerHeader {...props} />}
      renderDayContents={(dayOfMonth: number) => (
        <span data-testid={`datePicker-day${dayOfMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`}>
          {dayOfMonth}
        </span>
      )}
      formatWeekDay={(formattedDate: string) => formattedDate.charAt(0)}
      onChange={onChange}
      inline={true}
    />
  );
};

export default SmallCalendar;
