import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import AppointmentContext from 'store/appointment-context';
import { millisecondsToTime } from 'utils/date';
import styles from './CallTime.module.scss';

export interface CallTimeProps {}

const CallTime: FC<CallTimeProps> = (props: PropsWithChildren<CallTimeProps>) => {
  const {} = props;
  const { appointmentEvent, start } = useContext(AppointmentContext);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    if (!start) return;

    const now = new Date().getTime();
    setTime(now - start.getTime());

    const timeout = setInterval(() => setTime((prevState: number) => prevState + 1000), 1000);

    return () => clearInterval(timeout);
  }, [start]);

  return (
    <div className={styles.time}>
      <span>{appointmentEvent?.appointment?.user && <b>{appointmentEvent?.appointment?.user?.name}</b>}</span>
      <span>{millisecondsToTime(time)}</span>
    </div>
  );
};

export default CallTime;
