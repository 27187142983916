import { ToastTypes } from 'components/View/Toast';
import { ProfileType } from 'dtos/EntityDTO';
import { iconsTemp } from 'iconsTemp';
import IErrorResponse from 'interfaces/IErrorResponse';
import IReduxState from 'interfaces/IReduxState';
import LoginMapper from 'mappers/LoginMapper';
import RegisterMapper from 'mappers/RegisterMapper';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'routes/routes';
import LoginSchema from 'schemas/LoginSchema';
import { authActions } from 'store/redux/auth-slice';
import { entityActions } from 'store/redux/entity-slice';
import { feedbackActions } from 'store/redux/feedback-slice';
import SocketContext from 'store/socket-context';
import { isInURL } from 'utils/general';
import { LOG_COMPONENT, LOG_ERROR, LOG_HTTP } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';
import useHttp from './http.service';
import { userIsMedic, userIsPatient } from 'utils/user';
import loginSubRoutes from 'routes/loginSubRoutes';
import {
  ActionTypes,
  FormSubmit,
  getInputValue,
  IInitFormInput,
  InputTypes,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useStorage } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { communicationAPI } from 'apis';

const root = '/auth';

const loginMapper = LoginMapper();
const registerMapper = RegisterMapper();

export type AuthenticationFormValues = 'change-password';

export interface IUseAuthenticationService {
  isLoading: boolean;
  login: (submit: FormSubmit) => Promise<void>;
  logout: () => void;
  refreshToken: () => Promise<void>;
  register: (type: ProfileType, submit: FormSubmit) => Promise<void>;
}

const useAuthenticationService = (): IUseAuthenticationService => {
  const logger = useWinstonLogger();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { saveToStorage } = useStorage('localStorage');
  const { isLoading, post, put } = useHttp();
  const language = useSelector((state: IReduxState) => state.language.values);
  const { disconnectSocket } = useContext(SocketContext);

  const authenticationSuccess = (loginResponse: LoginSchema, isRefresh: boolean = false): void => {
    const { token, expiresIn } = loginMapper.toInterface(loginResponse);

    dispatch(authActions.login({ token, expiresIn }));

    if (isRefresh) return;

    const changeRoute =
      isInURL(location.pathname, loginSubRoutes.accounts.path) ||
      isInURL(location.pathname, loginSubRoutes.login.path) ||
      isInURL(location.pathname, loginSubRoutes.resetPassword.path) ||
      isInURL(location.pathname, loginSubRoutes.resetPasswordSent.path) ||
      isInURL(location.pathname, routes.register.path);

    if (changeRoute) history.push(routes.home.path);
  };

  const destroySession = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      put(`${communicationAPI}/bye`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  const login = ({ inputs }: FormSubmit): Promise<void> => {
    return new Promise((resolve, reject) => {
      post(`${root}/login`, undefined, loginMapper.toBody(inputs))
        .then((loginResponse: LoginSchema): void => {
          logger.log(LOG_HTTP, 'successfully logged user in');

          authenticationSuccess(loginResponse);
          resolve();
        })
        .catch((): void => {
          logger.log(LOG_ERROR, 'error on logging user in');

          reject();
        });
    });
  };

  const logout = () => {
    destroySession();

    post(`${root}/logout`)
      .then((): void => {
        logger.log(LOG_HTTP, 'user loged out successfully');
        history.push(routes.login.path);

        dispatch(authActions.clear());
        dispatch(entityActions.clear());
        disconnectSocket();
      })
      .catch((): void => {
        logger.log(LOG_ERROR, 'error on logging user out');
      });
  };

  const refreshToken = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      post(`${root}/refresh`)
        .then((loginResponse: LoginSchema): void => {
          logger.log(LOG_HTTP, 'refresh done successfully');

          authenticationSuccess(loginResponse, true);
          resolve();
        })
        .catch((): void => {
          logger.log(LOG_ERROR, "token has expired or it doens't exist");
          logger.log(LOG_COMPONENT, 'redirecting user to login page');

          if (!isInURL(location.pathname, routes.register.linkPath!)) history.push(routes.login.path);

          reject();
        });
    });
  };

  const register = (type: ProfileType, { inputs, dispatchInputs }: FormSubmit): Promise<void> => {
    const isMedic = userIsMedic(type);
    const isPatient = userIsPatient(type);
    const body = isMedic ? registerMapper.toMedicBody(inputs) : isPatient ? registerMapper.toPatientBody(inputs) : {};

    return new Promise((resolve, reject) => {
      post(`${root}/account`, undefined, body)
        .then((loginResponse: LoginSchema): void => {
          logger.log(LOG_HTTP, 'successfully registered user');

          if (isPatient) authenticationSuccess(loginResponse);

          // temp
          saveToStorage('email', getInputValue(inputs.email)!);
          if (isMedic) {
            saveToStorage('name', getInputValue(inputs.name)!);
            saveToStorage('medicalCertificate', getInputValue(inputs.medicalCertificate)!);
          }

          resolve();
        })
        .catch((error: IErrorResponse): void => {
          logger.log(LOG_ERROR, 'error on registering user');

          // shows a toast error if the given error wasn't a conflict
          if (error.status === 409) {
            dispatchInputs({
              type: ActionTypes.SET_ERROR,
              attribute: 'email',
              value: language.emailAlreadyTaken,
            });
          } else {
            feedbackActions.addMessage({
              dataTestId: 'toastError-registerFailed',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorRegistering,
            });
          }

          reject(error);
        });
    });
  };

  return {
    isLoading,
    login,
    logout,
    refreshToken,
    register,
  };
};

export const initialLoginFormInputs: IInitFormInput[] = [
  { name: 'email', type: InputTypes.TEXT },
  { name: 'password', type: InputTypes.PASSWORD },
];

export const initialResetPasswordFormInputs: IInitFormInput[] = [{ name: 'email', type: InputTypes.TEXT }];

const initialCommonRegisterFormInputs: IInitFormInput[] = [
  { name: 'email', type: InputTypes.EMAIL, autoVerification: true },
  { name: 'password', type: InputTypes.PASSWORD, autoVerification: true },
  { name: 'confirmPassword', type: InputTypes.PASSWORD },
];

export const initialRegisterMedicFormInputs: IInitFormInput[] = initialCommonRegisterFormInputs.concat([
  { name: 'name', type: InputTypes.TEXT },
  { name: 'medicalCertificate', type: InputTypes.NUMBER },
]);

export const initialRegisterPatientFormInputs: IInitFormInput[] = [...initialCommonRegisterFormInputs];

export const initialChangePasswordFormInputs: IInitFormInput[] = [
  { name: 'currentPassword', type: InputTypes.PASSWORD },
  { name: 'newPassword', type: InputTypes.PASSWORD, autoVerification: true },
  { name: 'confirmNewPassword', type: InputTypes.PASSWORD },
];

export default useAuthenticationService;
