import EventDTO from 'dtos/EventDTO';
import EventCard from 'components/EventCard/EventCard';
import useTodaysEvents from './TodaysEvents.logic';
import { ScrollView } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { TodaysEventsList, TodaysEventsWrapper } from './TodaysEvents.styles';
import { FC, PropsWithChildren } from 'react';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';

export interface ITodaysEventsProps extends PropsWithChildren<{}> {
  events: EventDTO[];
}

const TodaysEvents: FC<ITodaysEventsProps> = (props: ITodaysEventsProps): JSX.Element => {
  const { events } = props;
  const {
    language,
    selectedAppointmentId,
    now,
    isMobileScreen,
    scrollViewRef,
    nextEventContainerRef,
    nextEventId,
    selectHandler,
  } = useTodaysEvents(props);

  return (
    <TodaysEventsWrapper as='section'>
      <MediaScreenDesktop>
        <h1>{language.todaysPatientsList}</h1>
      </MediaScreenDesktop>
      <ScrollView hideGradients={isMobileScreen} ref={scrollViewRef}>
        <TodaysEventsList as='ul' direction='column'>
          {events
            .filter((event: EventDTO) => !isMobileScreen || (isMobileScreen && event.end.getTime() > now))
            .map((event: EventDTO, index: number) => (
              <li key={event.id} ref={event.id === nextEventId ? nextEventContainerRef : undefined}>
                <EventCard
                  event={event}
                  isSelected={
                    isMobileScreen
                      ? index === 0
                      : event.appointment
                      ? event.appointment?.id === selectedAppointmentId
                      : false
                  }
                  onSelect={() => selectHandler(event.appointment?.id)}
                />
              </li>
            ))}
        </TodaysEventsList>
      </ScrollView>
    </TodaysEventsWrapper>
  );
};

export default TodaysEvents;
