import { AssociatedMedicDTO, AssociatedPatientDTO } from 'dtos/AssociatedUserDTO';
import { PaginationDTO } from 'dtos/PaginationDTO';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import IRoute, { IMedicsSubRoutes, IPatientsSubRoutes } from 'interfaces/IRoute';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import homeSubRoutes from 'routes/homeSubRoutes';
import useEntitiesService from 'services/entities.service';
import useMedicsService from 'services/medics.service';
import usePatientsService from 'services/patients.service';
import { updateUndefinedArray } from 'utils/general';
import { LOG_HTTP } from 'utils/logger';
import { userIsMedic, userIsPatient } from 'utils/user';
import { useWinstonLogger } from 'winston-react';

export interface IUseRelatedUsers {
  language: ILanguage;
  isMedic: boolean;
  userDetailsRoute: IRoute;
  users?: (AssociatedMedicDTO | AssociatedPatientDTO)[];
  canLoadMore: boolean;
  loadMoreHandler: () => void;
}

const useRelatedUsers = (): IUseRelatedUsers => {
  const logger = useWinstonLogger();
  const { getMedicRelatedPatients } = useMedicsService();
  const { getPatientRelatedMedics } = usePatientsService();
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [users, setUsers] = useState<(AssociatedMedicDTO | AssociatedPatientDTO)[]>();
  const [page, setPage] = useState<number>(0);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const isMedic = userIsMedic(userType);
  const isPatient = userIsPatient(userType);

  useEffect(() => {
    loadRelatedUsers();
  }, []);

  const loadRelatedUsers = useCallback((): void => {
    const updateUsersState = (usersPagination: PaginationDTO<AssociatedPatientDTO | AssociatedMedicDTO>): void => {
      const { list, last } = usersPagination;
      setCanLoadMore(!last);
      setUsers((prevState?: (AssociatedMedicDTO | AssociatedPatientDTO)[]) => updateUndefinedArray(list, prevState));
      if (!last) setPage((prevState: number) => ++prevState);
      else logger.log(LOG_HTTP, `users pagination reached the end at page ${page}`);
    };

    if (isMedic) {
      getMedicRelatedPatients().then((usersPagination: PaginationDTO<AssociatedPatientDTO>) => {
        updateUsersState(usersPagination);
      });
    } else if (isPatient) {
      getPatientRelatedMedics().then((usersPagination: PaginationDTO<AssociatedMedicDTO>) => {
        updateUsersState(usersPagination);
      });
    }
  }, [page]);

  const loadMoreHandler = (): void => loadRelatedUsers();

  return {
    language,
    isMedic,
    userDetailsRoute: isMedic
      ? (homeSubRoutes.patients.subRoutes! as IPatientsSubRoutes).patientDetails
      : (homeSubRoutes.medics.subRoutes! as IMedicsSubRoutes).medicDetails,
    users,
    canLoadMore,
    loadMoreHandler,
  };
};

export default useRelatedUsers;
