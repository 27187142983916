import { getColumnsWidth, getGutter, ScrollView } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IRelatedUsersListProps {}

export const RelatedUsersList = styled.section<IRelatedUsersListProps>`
  width: ${getColumnsWidth({ nrColumns: 6 })};
  padding-right: 0 !important;

  > h3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export interface IRelatedUsersScrollProps {}

export const RelatedUsersScroll = styled(ScrollView)<IRelatedUsersScrollProps>`
  > div {
    &:nth-child(2) {
      padding-right: ${getGutter()};
    }
  }
`;
