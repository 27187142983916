import { FC, PropsWithChildren, useContext, useRef } from 'react';
import { SwitchTransition, Transition, TransitionStatus } from 'react-transition-group';
import Loader from './CallLoader';
import CallTime from './CallTime';
import CallStreams from './CallStreams';
import CallButtons, { CallButtonsRef } from 'components/Call/CallButtons/CallButtons';
import styles from './Call.module.scss';
import sass from 'styles/exports.module.scss';
import CallContext from 'store/call-context';

export type CallProps = {
  onEnd: () => void;
};

const Call: FC<CallProps> = (props: PropsWithChildren<CallProps>) => {
  const { onEnd } = props;
  const { connection } = useContext(CallContext);
  const callButtonsRef = useRef<CallButtonsRef>(null);
  const showLoader = connection?.state === 'new' || connection?.state === 'connecting';

  const toggleButtonsHandler = (): void => {
    callButtonsRef?.current?.toggle();
  };

  return (
    <div className={styles.call}>
      <SwitchTransition mode='out-in'>
        <Transition key={connection?.state} timeout={+sass.transition} unmountOnExit>
          {(state: TransitionStatus) => (
            <>
              {!showLoader && <CallStreams onMainVideoClick={toggleButtonsHandler} />}
              {showLoader && <Loader state={state} />}
            </>
          )}
        </Transition>
      </SwitchTransition>
      <CallTime />
      <CallButtons include={['microphone', 'camera', 'screen-share', 'end']} onEndCall={onEnd} ref={callButtonsRef} />
    </div>
  );
};

export default Call;
