import React from 'react';
import Grid from 'components/Grid/Grid';
import useApp from './App.logic';
import Header from 'components/Header/Header';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes/routes';
import Login from 'pages/Authentication/Login/Login';
import Index from 'pages/Index/Index';
// import Register from 'pages/Authentication/Register/Register';
import StyleGuide from 'pages/StyleGuide/StyleGuide';
import AddAvailability from 'pages/AddAvailability/AddAvailability';
import ScheduleAppointment from 'pages/ScheduleAppointment/ScheduleAppointment';
import IToastContent from 'interfaces/IToastContent';
import Toast from 'components/View/Toast';
import CancelAppointment from 'pages/CancelAppointment/CancelAppointment';
import Account from 'pages/Account/Account';
import { Transition, TransitionGroup, TransitionStatus } from 'react-transition-group';
import styles from './App.module.scss';
import sass from 'styles/exports.module.scss';
import Onboarding from 'pages/Onboarding/Onboarding';
import Appointment from 'pages/Appointment/Appointment';
import { AppointmentContextProvider } from 'store/appointment-context';
import RescheduleAppointment from 'pages/RescheduleAppointment/RescheduleAppointment';
import BuyAppointment from 'pages/BuyAppointment/BuyAppointment';

const App: React.FC = () => {
  const { language, entity, firstTime, loadedEntity, toastFeedbacks, closeToastHandler } = useApp();

  if (firstTime || Object.keys(language).length === 0 || (!loadedEntity && !entity)) return <></>;

  return (
    <>
      <Switch>
        <Route path={routes.home.path} exact={routes.home.exact}>
          <Header />
          <Index />
        </Route>
        <Route path={routes.addAvailability.path} exact={routes.addAvailability.exact}>
          <AddAvailability />
        </Route>
        <Route path={routes.schedule.path} exact={routes.schedule.exact}>
          <ScheduleAppointment />
        </Route>
        <Route path={routes.buyAppointment.path} exact={routes.buyAppointment.exact}>
          <BuyAppointment />
        </Route>
        <Route path={routes.reschedule.path} exact={routes.reschedule.exact}>
          <RescheduleAppointment />
        </Route>
        <Route path={routes.cancelAppointment.path} exact={routes.cancelAppointment.exact}>
          <CancelAppointment />
        </Route>
        <Route path={routes.appointment.path} exact={routes.appointment.exact}>
          <AppointmentContextProvider>
            <Appointment />
          </AppointmentContextProvider>
        </Route>
        <Route path={routes.account.path} exact={routes.account.exact}>
          <Account />
        </Route>
        <Route path={routes.onboarding.path} exact={routes.onboarding.exact}>
          <Onboarding />
        </Route>
        {/* <Route path={routes.register.path} exact={routes.register.exact}>
          <Register />
        </Route> */}
        <Route path={routes.login.path} exact={routes.login.exact}>
          <Login />
        </Route>
      </Switch>
      <StyleGuide />
      <Grid />
      <div className={styles.toast}>
        <TransitionGroup component={null}>
          {toastFeedbacks.map((toastFeedback: IToastContent) => (
            <Transition
              key={toastFeedback.id}
              timeout={+sass.transition}
              appear={true}
              mountOnEnter={true}
              unmountOnExit={true}>
              {(state: TransitionStatus) => (
                <Toast
                  dataTestId={toastFeedback.dataTestId}
                  className={styles[state]}
                  content={toastFeedback}
                  onClose={() => closeToastHandler(toastFeedback.id!)}
                />
              )}
            </Transition>
          ))}
        </TransitionGroup>
      </div>
    </>
  );
};

export default App;
