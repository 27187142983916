import { useEffect, useRef, useState } from 'react';

export type UseOnScreen = {
  elementRef: React.RefObject<HTMLElement>;
  isIntersecting: boolean;
};

const useOnScreen = (disconnectAfterIntersecting: boolean = true): UseOnScreen => {
  const [isIntersecting, setIntersecting] = useState(false);
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // disconnects the observer if it was only meant to check for one intersection
      if (entry.isIntersecting && disconnectAfterIntersecting) observer.disconnect();

      setIntersecting(entry.isIntersecting);
    });

    // adds the dom element to be observed
    if (elementRef.current) observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [elementRef.current]);

  return { elementRef, isIntersecting };
};

export default useOnScreen;
