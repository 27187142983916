import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { isMobileOnly } from 'react-device-detect';
import Modal from 'components/View/Modal';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren, useState } from 'react';
import { getLanguageName, getLanguages } from 'utils/language';
import CheckList from 'components/Form/CheckList/CheckList';
import { Button } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { LanguagePopupWrapper } from './LanguagePopup.styles';
import IOption from 'interfaces/IOption';

export interface ILanguagePopupProps extends PropsWithChildren<{}> {
  show: boolean;
  code: LanguageCode;
  onSelect: (code: LanguageCode) => void;
  onClosePopup: () => void;
}

const LanguagePopup: FC<ILanguagePopupProps> = (props: ILanguagePopupProps): JSX.Element => {
  const { show, code, onSelect, onClosePopup } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const [languageCode, setLanguageCode] = useState<LanguageCode>(code);

  return (
    <>
      {!isMobileOnly && (
        <LanguagePopupWrapper dataTestId='language-popup' show={show} onClose={onClosePopup}>
          {getLanguages().map((language: LanguageCode) => (
            <Button
              key={language}
              type='text'
              state='dark'
              label={getLanguageName(language)}
              disabled={code === language}
              onClick={() => onSelect(language)}
            />
          ))}
        </LanguagePopupWrapper>
      )}
      {isMobileOnly && show && (
        <Modal
          title={language.language}
          submitLabel={language.select}
          onCancel={() => onSelect(code)}
          onSubmit={() => onSelect(languageCode)}>
          <ul>
            <CheckList
              identifier='language-code'
              type='radio'
              selected={languageCode}
              options={getLanguages().map((language) => ({ id: language, name: getLanguageName(language) }))}
              onChange={(selectedLanguage: IOption) => setLanguageCode(selectedLanguage.id as LanguageCode)}
            />
          </ul>
        </Modal>
      )}
    </>
  );
};

export default LanguagePopup;
