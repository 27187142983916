import { ContentBlock, ContentState, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { exportNote, importNote, STYLES, STYLE_TYPE } from 'services/notes.service';
import AppointmentContext from 'store/appointment-context';

let noteSaveTimeout: NodeJS.Timeout;

export interface IUseNote {
  language: ILanguage;
  editorState: EditorState;
  saveNote: boolean;
  formatterToggleHandler: (style: string, type: STYLE_TYPE) => void;
  clearFormattingHandler: () => void;
  handleKeyCommand: (command: any) => 'handled' | 'not-handled';
  changeHandler: (editorState: EditorState) => void;
}

const useNote = (): IUseNote => {
  const { note, updateNoteHandler } = useContext(AppointmentContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [saveNote, setSaveNote] = useState<boolean>(false);

  useEffect(() => {
    if (note) setEditorState(importNote(note));
  }, []);

  const formatterToggleHandler = (style: string, type: STYLE_TYPE): void => {
    if (type === STYLE_TYPE.BLOCK) setEditorState(RichUtils.toggleBlockType(editorState, style));
    else if (type === STYLE_TYPE.INLINE) setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const clearFormattingHandler = () => {
    setEditorState((prevState: EditorState) => clearFormatting(prevState));
  };

  //setEditorState(RichUtils.onTab(event, editorState, 4));
  const handleKeyCommand = (command: any): 'handled' | 'not-handled' => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const changeHandler = (editorState: EditorState): void => {
    setEditorState(editorState);
    updateNoteHandler(exportNote(editorState));
    clearTimeout(noteSaveTimeout);
    setSaveNote(false);

    noteSaveTimeout = setTimeout(() => {
      setSaveNote(true);
    }, 5000);
  };

  return {
    language,
    editorState,
    saveNote,
    formatterToggleHandler,
    clearFormattingHandler,
    handleKeyCommand,
    changeHandler,
  };
};

const clearFormatting = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent();
  const styles = [STYLES.BOLD, STYLES.ITALIC, STYLES.UNDERLINE];
  const contentWithoutStyles = styles.reduce(
    (acc: ContentState, style: STYLES) => Modifier.removeInlineStyle(acc, editorState.getSelection(), style),
    contentState
  );
  const editorStateWithoutStyles = EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');

  const contentWithoutEntities = Modifier.applyEntity(
    editorStateWithoutStyles.getCurrentContent(),
    editorState.getSelection(),
    null
  );
  const editorStateWithoutEntities = EditorState.push(editorState, contentWithoutEntities, 'apply-entity');

  var contentWithoutLists = editorStateWithoutEntities.getCurrentContent();
  var blocksMap = contentState.getBlockMap();
  blocksMap.forEach((block: ContentBlock | undefined) => {
    if (block) {
      const blockType = block.getType();
      if (blockType === STYLES.OL || blockType === STYLES.UL) {
        const selectionState = SelectionState.createEmpty(block.getKey());
        const updatedSelection = selectionState.merge({
          focusOffset: 0,
          anchorOffset: block.getText().length,
        });

        contentWithoutLists = Modifier.setBlockType(contentWithoutLists, updatedSelection, 'unstyled');
      }
    }
  });

  return EditorState.push(editorStateWithoutEntities, contentWithoutLists, 'change-block-type');
};

export default useNote;
