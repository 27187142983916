import styles from './GalleryGroup.module.scss';
import FileDTO from 'dtos/FileDTO';
import ImageFilePreview from './ImageFilePreview';
import useDate, { DateMasks } from 'hooks/use-date';

export type GalleryGroupProps = {
  day: Date;
  files: FileDTO[];
};

const GalleryGroup: React.FC<GalleryGroupProps> = (props: React.PropsWithChildren<GalleryGroupProps>) => {
  const { day, files } = props;
  const { formatDate } = useDate();

  if (files.length === 0) return null;

  return (
    <div data-testid='galleryGroup' className={styles.group}>
      <h6 data-testid='galleryGroup-day'>{formatDate(day, DateMasks.fullDate)}</h6>
      <div data-testid='galleryGroup-files'>
        {files.map((file: FileDTO) => (
          <ImageFilePreview key={file.id} file={file} />
        ))}
      </div>
    </div>
  );
};

export default GalleryGroup;
