import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import ITab from 'interfaces/ITab';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import accountSubRoutes from 'routes/accountSubRoutes';
import routes from 'routes/routes';
import { userIsMedic } from 'utils/user';

export type UseAccount = {
  tabs: ITab[];
  backHandler: () => void;
};

const useAccount = (): UseAccount => {
  const history = useHistory();
  const location = useLocation<{ from: string }>();
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [previousLocation, setPreviousLocation] = useState<string>(routes.home.path);

  useEffect(() => {
    if (location.state) setPreviousLocation(location.state.from);
  }, []);

  const tabs = useCallback((): ITab[] => {
    const tabs: ITab[] = [];

    tabs.push(
      {
        id: 'account-infoTab',
        route: accountSubRoutes.info,
        name: language.accountInfo,
        icon: iconsTemp.profile,
      },
      {
        id: 'account-securityTab',
        route: accountSubRoutes.security,
        name: language.security,
        icon: iconsTemp.security,
      }
      // {
      //   id: 'account-integrationsTab',
      //   route: accountSubRoutes.preferences,
      //   name: language.integrations,
      //   icon: iconsTemp.integrations,
      // }
    );

    // if (userIsMedic(userType)) {
    //   tabs.push({
    //     id: 'account-paymentsTab',
    //     route: accountSubRoutes.payments,
    //     name: language.payments,
    //     icon: iconsTemp.price,
    //   });
    // }

    return tabs;
  }, [language]);

  const backHandler = (): void => {
    history.push(previousLocation);
  };

  return { tabs: tabs(), backHandler };
};

export default useAccount;
