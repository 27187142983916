import Icon from 'components/View/Icon';
import { iconsTemp } from 'iconsTemp';
import styles from './ImageFilePreview.module.scss';
import React from 'react';
import FileDTO from 'dtos/FileDTO';
import useImageFilePreview from './ImageFilePreview.logic';
import { DateMasks } from 'hooks/use-date';
import MediaScreenMobile from 'components/Media/MediaScreenMobile';
import MediaScreenDesktop from 'components/Media/MediaScreenDesktop';

export type ImageFilePreviewProps = {
  className?: string;
  file: FileDTO;
  showDate?: boolean;
};

const ImageFilePreview: React.FC<ImageFilePreviewProps> = (props: React.PropsWithChildren<ImageFilePreviewProps>) => {
  const { className, file, showDate } = props;
  const { filename, date } = file;
  const { extension, imageSrc, formatDate, clickHandler } = useImageFilePreview(props);

  return (
    <div data-testid='imageFilePreview' className={`${styles.preview} ${className}`} onClick={clickHandler}>
      <div className={styles.image}>
        {imageSrc && <img data-testid='imageFilePreview-preview' src={imageSrc} alt='preview' />}
        <MediaScreenMobile>
          <Icon className={styles.icon} src={iconsTemp.image} />
        </MediaScreenMobile>
      </div>
      <div className={styles.info}>
        <MediaScreenDesktop>
          <div>
            <Icon className={styles.icon} src={iconsTemp.image} />
          </div>
        </MediaScreenDesktop>
        <div>
          <div>
            <h6 data-testid='imageFilePreview-filename'>{filename.replace(extension, '')}</h6>
            <h6 data-testid='imageFilePreview-extension'>{extension}</h6>
          </div>
          {showDate && <span data-testid='imageFilePreview-date'>{formatDate(date, DateMasks.dateTime)}</span>}
        </div>
      </div>
      <div className={styles.hover} />
    </div>
  );
};

ImageFilePreview.defaultProps = {
  showDate: false,
};

export default ImageFilePreview;
