import EventSchema from 'schemas/EventSchema';
import { AppointmentPlaces, AppointmentStatus } from 'dtos/AppointmentDTO';
import EventAvailabilityDTO from 'dtos/EventAvailabilityDTO';
import EventDTO from 'dtos/EventDTO';
import MedicDTO from 'dtos/MedicDTO';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import IReduxState from 'interfaces/IReduxState';
import EventAvailabilityMapper from 'mappers/EventAvailabilityMapper';
import EventMapper from 'mappers/EventMapper';
import { useSelector } from 'react-redux';
import EventAvailabilitySchema from 'schemas/EventAvailabilitySchema';
import { isPast } from 'utils/date';
import useHttp from './http.service';
import PaginationSchema from 'schemas/PaginationSchema';
import { userIsMedic } from 'utils/user';
import useFilesService from './files.service';
import { getUniqueValues } from 'utils/filter';
import PatientDTO from 'dtos/PatientDTO';
import useNotesService from './notes.service';
import { getFrom, hasAppointmentPassed, isAppointmentOngoing } from 'utils/appointment';
import { IInitFormInput, InputTypes } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import useMedicsService from './medics.service';
import useSpecialtiesService from './specialties.service';
import { schedulerAPI } from 'apis';

const eventMapper = EventMapper();
const eventAvailabilityMapper = EventAvailabilityMapper();

export type UseSchedulesService = {
  isLoading: boolean;
  getTodaysEvents: () => Promise<EventDTO[]>;
  getEvents: (startDate: Date, endDate: Date) => Promise<EventDTO[]>;
  getMedicAvailableEvents: (
    medicId: string,
    specialtyId: string,
    appointmentType: AppointmentPlaces | undefined,
    from: Date,
    to: Date
  ) => Promise<EventAvailabilityDTO[]>;
  getPatientNextAppointments: (
    page: number
  ) => Promise<{ nextEvents: EventDTO[]; finishedEvents: EventDTO[]; last: boolean }>;
  getPatientPastAppointments: (page: number) => Promise<{ events: EventDTO[]; last: boolean }>;
  getNextAppointment: (currentAppointmentEnd: Date) => Promise<EventDTO | null>;
};

const useSchedulesService = (): UseSchedulesService => {
  const { isLoading, get, post, del } = useHttp();
  const { getMedic, getMedicPatient } = useMedicsService();
  const { getSpecialty } = useSpecialtiesService();
  const { getNrNotesBetweenUsers } = useNotesService();
  const { getNrFilesBetweenUsers } = useFilesService();
  const userId = useSelector((state: IReduxState) => state.auth.id);
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const isMedic = userIsMedic(userType);

  const getAppointmentsInfo = async (
    events: EventDTO[],
    includeFilesNr: boolean = false,
    includeNotesNr: boolean = false
  ): Promise<EventDTO[]> => {
    const getEntity = isMedic ? getMedicPatient : getMedic;
    const usersIds = getUniqueValues(events, 'appointment.userId');
    const specialtiesIds = getUniqueValues(events, 'appointment.specialty.id');

    const users = (await Promise.allSettled([...usersIds.map((userId: string) => getEntity(userId))]))
      .filter((result: PromiseSettledResult<MedicDTO | PatientDTO>) => result.status === 'fulfilled')
      .map((result) => (result as PromiseFulfilledResult<MedicDTO | PatientDTO>).value);

    const nrNotesBetweenUsers = includeNotesNr
      ? (await Promise.allSettled([...usersIds.map((userId: string) => getNrNotesBetweenUsers(userId))])).map(
          (result) => (result.status === 'fulfilled' ? (result as PromiseFulfilledResult<number>).value : undefined)
        )
      : [];

    const nrFilesBetweenUsers = includeFilesNr
      ? (await Promise.allSettled([...usersIds.map((userId: string) => getNrFilesBetweenUsers(userId))])).map(
          (result) => (result.status === 'fulfilled' ? (result as PromiseFulfilledResult<number>).value : undefined)
        )
      : [];

    const specialties = (
      await Promise.allSettled([...specialtiesIds.map(async (specialtyId: string) => getSpecialty(specialtyId))])
    )
      .filter((result: PromiseSettledResult<SpecialtyDTO>) => result.status === 'fulfilled')
      .map((result) => (result as PromiseFulfilledResult<SpecialtyDTO>).value);

    events.forEach((event: EventDTO) => {
      const userIdIndex = usersIds.findIndex((userId: string) => userId === event.appointment?.userId);
      event.nrNotes = nrNotesBetweenUsers[userIdIndex];
      event.nrFiles = nrFilesBetweenUsers[userIdIndex];

      if (event.appointment) {
        event.appointment.user = users.find((user: MedicDTO | PatientDTO) => user.id === event.appointment?.userId);

        const specialty = specialties.find(
          (specialty: SpecialtyDTO) => specialty.id === event.appointment?.specialty.id
        );
        if (specialty) event.appointment.specialty = specialty;
      }
    });

    return events;
  };

  const getTodaysEvents = (): Promise<EventDTO[]> => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const parameters = {
      endAtGte: today.toISOString(),
      endAtLte: tomorrow.toISOString(),
      size: 96, // maximum number of events per day
    };

    return new Promise((resolve, reject) => {
      get(schedulerAPI, parameters).then(async (scheduler: PaginationSchema<EventSchema>) => {
        const todaysEvents = scheduler.content.map((event: EventSchema) => {
          if (
            event.appointment?.status === AppointmentStatus.Created ||
            event.appointment?.status === AppointmentStatus.Pending ||
            event.appointment?.status === AppointmentStatus.Canceled
          )
            event.appointment = undefined;
          return eventMapper.toInterface(event, userId!);
        });

        resolve(await getAppointmentsInfo(todaysEvents, true, true));
      });
    });
  };

  const getEvents = (startDate: Date, endDate: Date): Promise<EventDTO[]> => {
    const parameters = {
      startAtGte: startDate.toISOString(),
      startAtLte: endDate.toISOString(),
      size: 96 * 31, // maximum number of events per month
    };

    return new Promise((resolve, reject) => {
      get(schedulerAPI, parameters).then(async (scheduler: PaginationSchema<EventSchema>) => {
        resolve(
          await getAppointmentsInfo(
            scheduler.content.map((event: EventSchema) => eventMapper.toInterface(event, userId!))
          )
        );
      });
    });
  };

  const getMedicAvailableEvents = async (
    medicId: string,
    specialtyId: string,
    appointmentType: AppointmentPlaces | undefined,
    from: Date,
    to: Date
  ): Promise<EventAvailabilityDTO[]> => {
    const parameters = {
      specialtyId: [specialtyId],
      medicId,
      'appointment-types': [appointmentType?.toUpperCase()],
      from: isPast(from, true) ? getFrom() : from.toISOString(),
      to: to.toISOString(),
    };

    return new Promise((resolve, reject) => {
      get(`${schedulerAPI}/public`, parameters)
        .then((medicAvailableEvents: EventAvailabilitySchema[]) => {
          resolve(
            medicAvailableEvents.map((availableEvent: EventAvailabilitySchema) =>
              eventAvailabilityMapper.toInterface(availableEvent)
            )
          );
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getPatientAppointments = (parameters: any): Promise<{ events: EventDTO[]; last: boolean }> => {
    return new Promise((resolve, reject) => {
      get(schedulerAPI, parameters)
        .then(async (scheduler: PaginationSchema<EventSchema>) => {
          resolve({
            events: await getAppointmentsInfo(
              scheduler.content.map((event: EventSchema) => eventMapper.toInterface(event, userId!))
            ),
            last: scheduler.last,
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getPatientNextAppointments = (
    page: number
  ): Promise<{ nextEvents: EventDTO[]; finishedEvents: EventDTO[]; last: boolean }> => {
    const parameters = { endAtGte: getFrom(), size: 15, page };

    return new Promise((resolve, reject) => {
      getPatientAppointments(parameters)
        .then((appointmentsPagination: { events: EventDTO[]; last: boolean }) => {
          const { events, last } = appointmentsPagination;

          // retrieves the appointments that have ended
          const finishedEvents = events.filter(
            (event: EventDTO) => event.appointment?.status === AppointmentStatus.Ended
          );

          // removes events with appointments that are not created, or waiting for payment or confirmed
          const nextEvents = events.filter(
            (event: EventDTO) =>
              ((event.appointment?.status === AppointmentStatus.Created ||
                event.appointment?.status === AppointmentStatus.Pending) &&
                !isAppointmentOngoing(event)) ||
              (event.appointment?.status === AppointmentStatus.Started && !hasAppointmentPassed(event)) ||
              event.appointment?.status === AppointmentStatus.Confirmed
          );

          resolve({ nextEvents, finishedEvents, last });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getPatientPastAppointments = (page: number): Promise<{ events: EventDTO[]; last: boolean }> => {
    const parameters = { endAtLte: new Date().toISOString(), size: 15, page, sort: ['startAt,DESC'] };

    return new Promise((resolve, reject) => {
      getPatientAppointments(parameters)
        .then((appointmentsPagination: { events: EventDTO[]; last: boolean }) => {
          const { events, last } = appointmentsPagination;

          resolve({
            events: events.filter(
              (event: EventDTO) =>
                event.appointment?.status === AppointmentStatus.Confirmed ||
                event.appointment?.status === AppointmentStatus.Ended
            ),
            last,
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getNextAppointment = (currentAppointmentEnd: Date): Promise<EventDTO | null> => {
    const end = new Date(currentAppointmentEnd);
    end.setTime(end.getTime() + 60 * 60 * 1000);

    const parameters = {
      startAtGte: currentAppointmentEnd.toISOString(),
      endAtLte: end.toISOString(),
      size: 1,
    };

    return new Promise((resolve, reject) => {
      get(schedulerAPI, parameters)
        .then(async (scheduler: PaginationSchema<EventSchema>) => {
          const appointments = await getAppointmentsInfo(
            scheduler.content.map((event: EventSchema) => eventMapper.toInterface(event, userId!)),
            true,
            true
          );

          resolve(appointments[0] ? appointments[0] : null);
        })
        .catch(() => {
          reject();
        });
    });
  };

  return {
    isLoading,
    getTodaysEvents,
    getEvents,
    getMedicAvailableEvents,
    getPatientNextAppointments,
    getPatientPastAppointments,
    getNextAppointment,
  };
};

const getDefaultStartTime = (): Date => {
  const time = new Date();
  const minutes = time.getMinutes();
  time.setMinutes(minutes + (5 - (minutes % 5)));
  return time;
};

const getDefaultEndTime = (): Date => {
  const time = getDefaultStartTime();
  time.setTime(time.getTime() + 60 * 60 * 1000);
  return time;
};

export const createAvailabilityFormInputs: IInitFormInput[] = [
  { name: 'specialty', type: InputTypes.TEXT },
  { name: 'place', type: InputTypes.TEXT },
  { name: 'cost', type: InputTypes.NUMBER },
  { name: 'startDate', type: InputTypes.DATE },
  { name: 'startTime', type: InputTypes.TIME, value: getDefaultStartTime() },
  { name: 'endTime', type: InputTypes.TIME, value: getDefaultEndTime() },
  { name: 'endDate', type: InputTypes.DATE },
  { name: 'duration', type: InputTypes.NUMBER, value: 15 },
  { name: 'daysRepeat', type: InputTypes.TEXT, isRequired: false },
  { name: 'description', type: InputTypes.TEXT, isRequired: false },
];

export const editAvailabilityFormInputs: IInitFormInput[] = [
  { name: 'availability', type: InputTypes.TEXT },
  { name: 'event', type: InputTypes.TEXT },
  { name: 'startDate', type: InputTypes.DATE },
  { name: 'startTime', type: InputTypes.TIME },
  { name: 'endTime', type: InputTypes.TIME, isRequired: false },
];

export default useSchedulesService;
