import useAvailableSchedules from './AvailableSchedules.logic';
import AvailableSlotsSlider from 'components/AvailableSlotsSlider/AvailableSlotsSlider';
import { CSSTransition } from 'react-transition-group';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import { FC, PropsWithChildren } from 'react';
import {
  AvailableSchedulesAppointmentsTags,
  AvailableSchedulesForm,
  AvailableSchedulesScroll,
  AvailableSchedulesSelect,
  AvailableSchedulesWrapper,
  ScheduleButton,
} from './AvailableSchedules.styles';
import {
  FormContextProvider,
  getInputValue,
  InputsReducerType,
  styles,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { startScheduleAppointmentFormInputs } from 'services/appointments.service';

export interface AvailableSchedulesProps {
  medicSpecialties: SpecialtyDTO[];
}

const AvailableSchedules: FC<AvailableSchedulesProps> = (props: PropsWithChildren<AvailableSchedulesProps>) => {
  const { medicSpecialties } = props;
  const { language, medicId, scheduleAppointmentHandler } = useAvailableSchedules();

  return (
    <AvailableSchedulesWrapper>
      <h4>{language.availableSchedules}</h4>
      <AvailableSchedulesForm
        as={FormContextProvider}
        direction='column'
        initialValues={startScheduleAppointmentFormInputs}
        onSubmit={scheduleAppointmentHandler}>
        {(inputs: InputsReducerType, isValid: boolean) => (
          <>
            <AvailableSchedulesScroll>
              <AvailableSchedulesSelect
                dataTestId='availableSchedules-specialtyInput'
                name='specialty'
                options={medicSpecialties}
                placeholder={language.insertAvailability}
              />
              <AvailableSchedulesAppointmentsTags name='type' multi={false} join={false} />
              <AvailableSlotsSlider
                name='event'
                medicId={medicId}
                specialtyId={getInputValue(inputs.specialty)!}
                type={getInputValue(inputs.type)!}
                start={new Date()}
                nrDays={3}
              />
            </AvailableSchedulesScroll>
            <CSSTransition
              classNames='schedule_button'
              in={isValid}
              timeout={styles.transition}
              mountOnEnter
              unmountOnExit>
              <ScheduleButton label={language.scheduleAppointment} buttonType='submit' />
            </CSSTransition>
          </>
        )}
      </AvailableSchedulesForm>
    </AvailableSchedulesWrapper>
  );
};

export default AvailableSchedules;
