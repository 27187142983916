export enum NotificationType {
  APPOINTMENT_PATIENT_SCHEDULED = 'appointment-patient-scheduled',
  APPOINTMENT_PATIENT_RESCHEDULED = 'appointment-patient-rescheduled',
  APPOINTMENT_PATIENT_CANCELED = 'appointment-patient-canceled',
  APPOINTMENT_MEDIC_CANCELED = 'appointment-medic-canceled',
  APPOINTMENT_PAYMENT_COMPLETE = 'appointment-payment-complete',
  APPOINTMENT_REFUND_COMPLETE = 'appointment-refund-complete',
  APPOINTMENT_REQUEST_PAYMENT = 'appointment-request-payment',
  APPOINTMENT_NO_PAYMENT_CANCELED = 'appointment-no-payment-canceled',
}

export default interface NotificationDTO {
  id: string;
  type: NotificationType;
  userId: string;
  userName?: string;
  image?: string;
  icon: string;
  message: string;
  mainLine?: string;
  lineThrough?: string;
  read: boolean;
  date: Date;
}
