import { Button, MultiOption, Select, styles, transition } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import AppointmentTypesMultioption from 'components/Appointment/AppointmentTypesMultioption';
import styled from 'styled-components';

export interface IAddAvailabilitySpecialtySelectProps {}

export const AddAvailabilitySpecialtySelect = styled(Select)<IAddAvailabilitySpecialtySelectProps>`
  > div {
    flex: 1 !important;
  }
`;

export interface IAddAvailabilityAppointmentTypesMultiOptionProps {}

export const AddAvailabilityAppointmentTypesMultiOption = styled(
  AppointmentTypesMultioption
)<IAddAvailabilityAppointmentTypesMultiOptionProps>`
  margin-top: ${styles.inputMargin};
  margin-left: ${styles.iconSize.normal};

  > div {
    > * {
      flex: 1;

      > div {
        min-width: auto;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
`;

export interface IDaysToRepeatMultiOptionProps {}

export const DaysToRepeatMultiOption = styled(MultiOption)<IDaysToRepeatMultiOptionProps>`
  margin-top: ${styles.inputMargin};

  > div {
    justify-content: space-between;

    > * {
      width: auto;

      > div {
        min-width: auto;
        width: 3rem;
        height: 3rem;
        padding: 0;
      }
    }
  }
`;

export interface IAddAvailabilityEndRepeatContainerProps {}

export const AddAvailabilityEndRepeatContainer = styled.div<IAddAvailabilityEndRepeatContainerProps>`
  ${transition('opacity')};

  &.repeat-enter-active {
    opacity: 0;
  }

  &.repeat-enter,
  &.repeat-exit,
  &.repeat-exit-active {
    opacity: 0;
  }
`;

export interface IAddAvailabilityEndRepeatButtonProps {}

export const AddAvailabilityEndRepeatButton = styled(Button)<IAddAvailabilityEndRepeatButtonProps>`
  margin-top: ${styles.inputMargin};
  margin-left: ${styles.iconSize.normal};
`;

export interface IAddAvailabilityEndDateCloseRepeatButtonProps {}

export const AddAvailabilityEndDateCloseRepeatButton = styled(Button)<IAddAvailabilityEndDateCloseRepeatButtonProps>`
  margin-top: ${styles.iconSize.text};
  margin-left: 0.5rem;
`;
