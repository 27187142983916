import { IInitFormInput, InputTypes } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { getTimezone, useStorage } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { getMinAgeDate } from 'utils/date';

export interface IUseEntitiesService {
  removeRegisterValuesFromStorage: () => void;
}

const useEntitiesService = (): IUseEntitiesService => {
  const { removeFromStorage } = useStorage('localStorage');

  const removeRegisterValuesFromStorage = (): void => {
    removeFromStorage('email');
    removeFromStorage('name');
    removeFromStorage('medicalCertificate');
  };

  return {
    removeRegisterValuesFromStorage,
  };
};

const commonMandatoryOnbordingFormInputs: IInitFormInput[] = [
  { name: 'email', type: InputTypes.TEXT },
  { name: 'name', type: InputTypes.TEXT },
  { name: 'language', type: InputTypes.TEXT, value: navigator.language },
  { name: 'timezone', type: InputTypes.TEXT, value: getTimezone() },
];

export const initialMedicMandatoryOnboardingFormInputs: IInitFormInput[] = commonMandatoryOnbordingFormInputs.concat([
  { name: 'gender', type: InputTypes.TEXT },
  { name: 'tin', type: InputTypes.NUMBER, autoVerification: true },
  { name: 'specialties', type: InputTypes.TAGS },
  { name: 'licence', type: InputTypes.NUMBER },
]);

export const initialPatientMandatoryOnboardingFormInputs: IInitFormInput[] = commonMandatoryOnbordingFormInputs.concat([
  { name: 'birthDate', type: InputTypes.DATE, value: getMinAgeDate() },
  { name: 'gender', type: InputTypes.TEXT },
  { name: 'tin', type: InputTypes.NUMBER, autoVerification: true },
]);

const commonOptionalOnboardingFormInputs: IInitFormInput[] = [
  { name: 'photo', type: InputTypes.PHOTO, isRequired: false },
  { name: 'address', type: InputTypes.TEXT, isRequired: false },
  { name: 'postalCode', type: InputTypes.TEXT, isRequired: false },
  { name: 'country', type: InputTypes.TEXT, /* value: getUserCountry() as unknown as string, */ isRequired: false },
  { name: 'phoneNumber', type: InputTypes.PHONE, isRequired: false },
];

export const initialMedicOptionalOnboardingFormInputs: IInitFormInput[] = commonOptionalOnboardingFormInputs.concat([
  { name: 'appointments', type: InputTypes.TAGS, isRequired: false },
  { name: 'languages', type: InputTypes.TAGS, isRequired: false },
  { name: 'about', type: InputTypes.TEXT, isRequired: false },
]);

export const initialPatientOptionalOnboardingFormInputs: IInitFormInput[] = commonOptionalOnboardingFormInputs.concat([
  { name: 'diseases', type: InputTypes.TAGS, isRequired: false },
  { name: 'medication', type: InputTypes.TAGS, isRequired: false },
  { name: 'allergies', type: InputTypes.TAGS, isRequired: false },
  { name: 'height', type: InputTypes.NUMBER, isRequired: false },
  { name: 'weight', type: InputTypes.NUMBER, isRequired: false },
  { name: 'dailyHabits', type: InputTypes.TAGS, isRequired: false },
]);

export const commonInitialAccountFormInputs: IInitFormInput[] = [
  { name: 'name', type: InputTypes.TEXT, isRequired: false },
  { name: 'email', type: InputTypes.TEXT, isRequired: false },
  { name: 'phoneNumber', type: InputTypes.PHONE, isRequired: false },
];

export const initialMedicAccountFormInputs: IInitFormInput[] = [...commonInitialAccountFormInputs];

export const initialPatientAccountFormInputs: IInitFormInput[] = commonInitialAccountFormInputs.concat([
  { name: 'gender', type: InputTypes.TEXT, isRequired: false },
]);

export default useEntitiesService;
