import { Flex } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IAppointmentTypesIconsWrapperProps {}

export const AppointmentTypesIconsWrapper = styled(Flex)<IAppointmentTypesIconsWrapperProps>`
  > * {
    background-color: transparent !important;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;
