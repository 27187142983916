import {
  Container,
  Flex,
  getColumnsWidth,
  IContainerProps,
  IFlexProps,
  rgba,
  styles,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IImproveProfileSuggestionsWrapperProps {}

export const ImproveProfileSuggestionsWrapper = styled.div<IImproveProfileSuggestionsWrapperProps>`
  width: ${getColumnsWidth({ nrColumns: 6 })};

  > p {
    text-align: center;

    &:nth-child(2) {
      margin-top: 0.25rem;
      font-size: 1.25rem;
    }
  }
`;

export interface ISuggestionsContainerProps {}

export const SuggestionsContainer = styled(Container).attrs((_: IContainerProps) => ({
  direction: 'row',
  alignItems: 'flex-start',
}))<ISuggestionsContainerProps>`
  margin-top: 2rem;

  > img {
    width: 5.75rem;
    height: 5.75rem;
    border-radius: 999px;
    margin-right: 2rem;
    background-color: ${rgba(styles.colors.brand.main, 0.05)};
  }

  > div {
    width: auto;

    > h5 {
      margin-bottom: 1rem;
    }
  }
`;

export interface ISuggestionItemProps {}

export const SuggestionItem = styled(Flex).attrs((_: IFlexProps) => ({
  as: 'li',
  justifyContent: 'flex-start',
}))<ISuggestionItemProps>`
  > span {
    height: 2rem;
    margin-right: 0.5em;
    color: ${rgba(styles.colors.brand.main, 0.25)};
    font-size: 2em;
    line-height: 2rem;
  }
`;
