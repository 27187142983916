import MessageDTO from 'dtos/MessageDTO';
import { IDayGroup, IMessageGroup } from 'interfaces/IGroup';
import { datesAreEqual, yearsAreEqual } from './date';
import { sortByDate } from './sort';

const GROUP_TIME_MINUTES = 10;

const groupByDate = <T>(
  comparisonFunction: (group: IDayGroup<T>, item: T) => boolean,
  list: T[],
  sort: boolean = true,
  reverse: boolean = true,
  defaultGroups: IDayGroup<T>[] = []
): IDayGroup<T>[] => {
  let groups = [...defaultGroups];

  list.forEach((item: any) => {
    const date = new Date(item.date ? item.date : item.start);
    date.setHours(0, 0, 0, 0);
    const index = groups.findIndex((group) => comparisonFunction(group, item));

    // verifies if there is already a group with the same date as the item
    if (index >= 0) {
      groups[index].list.push({ ...item });
    } else {
      groups.push({
        date,
        list: [{ ...item }],
      } as IDayGroup<T>);
    }
  });

  if (sort) {
    groups = [...sortByDate(groups, reverse)] as IDayGroup<T>[];
    groups.forEach((group) => {
      group.list = [...sortByDate(group.list, reverse)] as T[];
    });
  }

  return [...groups];
};

export const groupByDay = <T>(
  list: T[],
  sort: boolean = true,
  reverse: boolean = true,
  defaultGroups: IDayGroup<T>[] = []
): IDayGroup<T>[] => {
  return groupByDate(
    (group: IDayGroup<T>, item: any) => datesAreEqual(group.date, new Date(item.date ? item.date : item.start)),
    list,
    sort,
    reverse,
    defaultGroups
  );
};

export const groupByYear = <T>(
  list: T[],
  sort: boolean = true,
  reverse: boolean = true,
  defaultGroups: IDayGroup<T>[] = []
): IDayGroup<T>[] => {
  return groupByDate(
    (group: IDayGroup<T>, item: any) => yearsAreEqual(group.date, new Date(item.date ? item.date : item.start)),
    list,
    sort,
    reverse,
    defaultGroups
  );
};

export const groupMessages = (messages: MessageDTO[]): IMessageGroup[] => {
  let groups: IMessageGroup[] = [];

  messages.forEach((message: MessageDTO) => {
    const messageDate: Date = new Date(message.date);
    const messageReceived = !message.mine;
    const includeInLastGroup =
      groups.at(-1) &&
      groups.at(-1)!.received === messageReceived &&
      Math.abs(groups.at(-1)!.date.getTime() - messageDate.getTime()) < GROUP_TIME_MINUTES * 60 * 1000;

    // verifies if there is already a group with the same date as the item
    if (includeInLastGroup) {
      groups.at(-1)!.messages.push({ ...message });
    } else {
      groups.push({
        date: messageDate,
        received: messageReceived,
        messages: [{ ...message }],
      } as IMessageGroup);
    }
  });

  return [...groups];
};
