import useAccountInfo from './AccountInfo.logic';
import AccountInfoUser from './AccountInfoUser';
import { Button, InputsReducerType, PhoneNumberInput, TextInput } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import { AccountGenderMultiOption, AccountInfoForm, AccountInfoSeparator } from './AccountInfo.styles';
import { getGenderOptions } from 'utils/user';

export interface IAccountInfoProps extends PropsWithChildren<{}> {}

const AccountInfo: FC<IAccountInfoProps> = (props: IAccountInfoProps): JSX.Element => {
  const {} = props;
  const {
    language,
    isPatient,
    initialValues,
    checkValuesAreDifferent,
    loadHandler,
    submitHandler,
    deleteAccountHandler,
  } = useAccountInfo();

  return (
    <AccountInfoForm initialValues={initialValues} onLoad={loadHandler} onSubmit={submitHandler}>
      {(inputs: InputsReducerType) => (
        <>
          <AccountInfoUser />
          <TextInput dataTestId='accountInfo-nameInput' name='name' />
          <TextInput dataTestId='accountInfo-emailInput' name='email' disabled />
          <PhoneNumberInput dataTestId='accountInfo-emailInput' style={{ width: '50%' }} name='phoneNumber' />
          {isPatient && (
            <AccountGenderMultiOption
              dataTestId='accountInfo-genderInput'
              name='gender'
              options={getGenderOptions(language)}
            />
          )}
          <Button
            dataTestId='accountInfo-submitButton'
            buttonType='submit'
            label={language.saveChanges}
            isValid={checkValuesAreDifferent(inputs)}
          />
          <AccountInfoSeparator />
          <Button
            dataTestId='accountInfo-deleteButton'
            type='text'
            state='red'
            icon='delete'
            label={language.deleteAccount}
            disabled
            onClick={deleteAccountHandler}
          />
        </>
      )}
    </AccountInfoForm>
  );
};

export default AccountInfo;
