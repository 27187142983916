import { UserImage } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import Button, { ButtonThemes } from 'components/Form/Button';
import AlertDTO, { AlertTypes } from 'dtos/AlertDTO';
import useCounter from 'hooks/use-counter';
import useDate from 'hooks/use-date';
import { iconsTemp } from 'iconsTemp';
import IReduxState from 'interfaces/IReduxState';
import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import useAppointmentsService from 'services/appointments.service';
import { getAppointmentAlertMessage } from 'utils/language';
import styles from './Alert.module.scss';

export type AlertProps = {
  dataTestId?: string;
  className?: string;
  alert: AlertDTO;
  onClose: () => void;
};

const Alert: React.FC<AlertProps> = (props: React.PropsWithChildren<AlertProps>) => {
  const { dataTestId, className, alert, onClose } = props;
  const { appointmentEvent } = alert;
  const { startAppointment } = useAppointmentsService();
  const { formatDate } = useDate();
  const { containerRef, cancelHandler } = useCounter({ timer: 5000, onClose });
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <div
      data-testid={dataTestId}
      className={`${styles.alert} ${className}`}
      ref={containerRef as RefObject<HTMLDivElement>}>
      <UserImage className={styles['user-image']} src={appointmentEvent.appointment?.user?.image} />
      <div className={styles['alert-info']}>
        <p>{getAppointmentAlertMessage(language, formatDate, alert)}</p>
        {alert.type === AlertTypes.USER_ENTERED_APPOINTMENT && (
          <Button
            className={styles['main-action_button']}
            theme={ButtonThemes.LIGHT}
            label={language.goToAppointment}
            onClick={() => startAppointment(appointmentEvent)}
          />
        )}
      </div>
      <Button className={styles['close_button']} icon={iconsTemp.close} onClick={cancelHandler} />
    </div>
  );
};

export default Alert;
