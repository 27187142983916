import { Flex, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IHeaderNotificationsNumberProps {}

export const HeaderNotificationsNumber = styled(Flex)<IHeaderNotificationsNumberProps>`
  position: absolute;
  top: -5%;
  right: -5%;
  width: 1rem;
  height: 1rem;
  border-radius: 999px;
  color: ${styles.colors.brand.lightest};
  background-color: ${styles.colors.feedback.alert};
  font-size: ${styles.textSize.tiny};
`;
