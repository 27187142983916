import { Icon } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { EventContentArg } from '@fullcalendar/common';
import { AppointmentEventTypes, EventPlace } from 'dtos/EventDTO';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren, useRef } from 'react';
import { useSelector } from 'react-redux';
import MonthEventPopup from './CalendarEventPopup';
import styles from './MonthEvent.module.scss';

export interface MonthEventProps {
  event?: EventContentArg;
  selected?: boolean;
  onClose?: () => void;
}

const MonthEvent: FC<MonthEventProps> = (props: PropsWithChildren<MonthEventProps>): JSX.Element => {
  const { event, selected, onClose } = props;
  const language = useSelector((state: IReduxState) => state.language.values);
  const eventContainerRef = useRef<HTMLDivElement>(null);

  let title: string;
  let nrEvents: number;
  let place: EventPlace;
  let icon: string | null;

  if (event) {
    title = event.event._def.title;
    nrEvents = event.event._def.extendedProps.events.length;
    place = event.event._def.extendedProps.place;
    icon = event.event._def.extendedProps.icon;
  } else {
    title = `${language.seeMore}...`;
    nrEvents = 2;
    place = AppointmentEventTypes.SeeMore;
    icon = null;
  }

  return (
    <div className={styles.container} ref={eventContainerRef}>
      <div
        data-testid={`monthEvent-${place}`}
        className={`${styles['month-event']} ${styles[place]} ${selected && styles.selected}`}>
        <Icon className={styles.icon} src={icon as any} />
        <span>{title}</span>
        <b>{nrEvents}</b>
      </div>
      <MonthEventPopup
        show={!!selected}
        parent={eventContainerRef}
        event={event}
        onClose={onClose ? onClose : () => {}}
      />
    </div>
  );
};

export default MonthEvent;
