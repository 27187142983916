import { Button, Flex, Popup } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

const buttonMargin = '8px';

export interface IAppointmentDetailsHeaderButtonsWrapperProps {}

export const AppointmentDetailsHeaderButtonsWrapper = styled(Flex)<IAppointmentDetailsHeaderButtonsWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;

  > button {
    &:not(:last-of-type) {
      margin-right: ${buttonMargin};
    }
  }
`;

export interface IAppointmentDetailsHeaderPopupButtonProps {}

export const AppointmentDetailsHeaderPopupButton = styled(Button)<IAppointmentDetailsHeaderPopupButtonProps>`
  transform: rotate(90deg);
`;

export interface IAppointmentDetailsHeaderPopupProps {}

export const AppointmentDetailsHeaderPopup = styled(Popup)<IAppointmentDetailsHeaderPopupProps>`
  width: max-content;
  padding: 0.75rem 0;

  &.popup-enter-done {
    padding: 0.75rem;
  }
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .appointment-action_buttons {
    position: static;

    > .show-actions-buttons_button {
      position: absolute;
      top: getGutter($tablet);
      right: getMargin($tablet);
      z-index: 50;
    }

    > .appointment-actions_buttons_popup {
      top: 4rem;
      right: getMargin($tablet);
    }

    > .start-appointment {
      @include flex($flex: inline-flex, $vertical: normal);
      position: fixed;
      bottom: getGutter($tablet);
      left: 0;
      right: 0;
      width: fit-content;
      border-radius: 999px;
      padding: 0.5rem;
      margin: 0 auto;
      background-color: $lightest;
      box-shadow: 0px 2px 8px rgba(0, 68, 94, 0.3);
      z-index: 30;

      > div {
        @include flex;
        margin-right: 1rem;

        > .time-icon {
          @include iconColor($fill: rgba($dark, 0.6));
          margin-right: 0.5rem;
        }

        h5 {
          color: rgba($dark, 0.75);
        }
      }
    }
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .appointment-action_buttons {
    > .show-actions-buttons_button {
      top: getGutter($smartphone);
      right: getMargin($smartphone);
    }
  }

  > .start-appointment {
    box-sizing: border-box;

    > div {
      > .time-icon {
        @include iconSize($iconSize_small);
      }
    }

    > .call-button {
      > span {
        &:last-of-type {
          margin-left: 1rem;
        }
      }

      > button {
        padding-right: 1rem;
      }
    }
  }
}
*/
