import Tabs from 'components/View/Tabs';
import { IAppointmentNotesSubRoutes, IAppointmentCallSubRoutes } from 'interfaces/IRoute';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import ITab from 'interfaces/ITab';
import PreviousNotes from 'components/PreviousNotes/PreviousNotes';
import styles from './AppointmentCallPrivateNotes.module.scss';
import { useSelector } from 'react-redux';
import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren, useContext } from 'react';
import AppointmentContext from 'store/appointment-context';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import WriteNote from './WriteNote/WriteNote';

export interface AppointmentCallPrivateNotesProps {}

const AppointmentCallPrivateNotes: FC<AppointmentCallPrivateNotesProps> = (
  props: PropsWithChildren<AppointmentCallPrivateNotesProps>
): JSX.Element => {
  const {} = props;
  const match = useRouteMatch();
  const { appointmentEvent } = useContext(AppointmentContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const notesRoutes = (appointmentSubRoutes.call.subRoutes! as IAppointmentCallSubRoutes).notes
    .subRoutes as IAppointmentNotesSubRoutes;
  const tabs: ITab[] = [
    { id: 'call-notesTab', route: notesRoutes.writeNote, name: language.notes },
    { id: 'call-previousNotesTab', route: notesRoutes.previousNotes, name: language.seePreviousNotes },
  ];

  return (
    <>
      {!(match.params as any).tab && <Redirect to={`${match.url}/${notesRoutes.writeNote.path}`} />}
      <Tabs tabs={tabs} className={styles.tabs}>
        <Route path={`${match.path}(${notesRoutes.writeNote.path})`} exact={notesRoutes.writeNote.exact}>
          <WriteNote />
        </Route>
        <Route path={`${match.path}(${notesRoutes.previousNotes.path})`} exact={notesRoutes.previousNotes.exact}>
          <PreviousNotes calleeId={appointmentEvent!.appointment!.userId} className={styles['previous-notes']} />
        </Route>
      </Tabs>
    </>
  );
};

export default AppointmentCallPrivateNotes;
