import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AgendaContext from 'store/agenda-context';
import { CalendarEventPopupProps } from './CalendarEventPopup';

export interface PopupPosition {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
}

export interface IUseCalendarEventPopupPopup {
  language: ILanguage;
  viewportOffset: PopupPosition;
  seePatientDetailsHandler: (patientId: string) => void;
  editHandler: (eventId: string, appointmentId?: string) => void;
  deleteEventHandler: (eventId: string, appointmentId?: string | undefined) => void;
}

const useCalendarEventPopupPopup = (props: CalendarEventPopupProps): IUseCalendarEventPopupPopup => {
  const { show, parent, event } = props;
  const {
    seePatientDetailsHandler,
    goToEditAvailabilityHandler,
    goToRescheduleAppointmentHandler,
    deleteEventHandler,
  } = useContext(AgendaContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [viewportOffset, setViewportOffset] = useState<PopupPosition>({});

  useEffect(() => {
    if (!event) return;

    setViewportOffset(() => {
      const parentViewport = parent.current?.getBoundingClientRect();
      const calendarViewport = parent.current?.closest('.fc-media-screen')?.getBoundingClientRect();

      if (!parentViewport || !calendarViewport) return {};

      const eventIsOnTopHalf = parentViewport?.top <= (2 * calendarViewport?.height) / 3;
      const eventIsOnLeftHalf = parentViewport!.left <= calendarViewport!.width / 2;

      return { top: eventIsOnTopHalf, right: eventIsOnLeftHalf, bottom: !eventIsOnTopHalf, left: !eventIsOnLeftHalf };
    });
  }, [event]);

  useEffect(() => {
    if (!parent.current) return;

    // puts the event container on top of all other events
    const selectedEventContainer = parent.current.closest('.fc-timegrid-event-harness') as HTMLDivElement | null;
    if (selectedEventContainer) selectedEventContainer.style.zIndex = show ? '4' : '2';

    // puts the event column above others (in week view)
    const selectedEventColumn = parent.current.closest('.fc-timegrid-col') as HTMLTableCellElement | null;
    if (selectedEventColumn) {
      selectedEventColumn.style.position = 'relative';
      selectedEventColumn.style.zIndex = show ? '4' : '';
    }
  }, [parent.current, show]);

  const editHandler = (eventId: string, appointmentId?: string): void => {
    if (appointmentId) goToRescheduleAppointmentHandler(appointmentId);
    else goToEditAvailabilityHandler(eventId);
  };

  return { language, viewportOffset, seePatientDetailsHandler, editHandler, deleteEventHandler };
};

export default useCalendarEventPopupPopup;
