import { ToastTypes } from 'components/View/Toast';
import AppointmentDTO from 'dtos/AppointmentDTO';
import { iconsTemp } from 'iconsTemp';
import IErrorResponse from 'interfaces/IErrorResponse';
import IReduxState from 'interfaces/IReduxState';
import AppointmentMapper from 'mappers/AppointmentMapper';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import routes from 'routes/routes';
import AppointmentPaymentSchema from 'schemas/AppointmentPaymentSchema';
import AppointmentSchema from 'schemas/AppointmentSchema';
import { feedbackActions } from 'store/redux/feedback-slice';
import { getDoctorAbreviation, placeValue } from 'utils/language';
import useHttp from './http.service';
import AppointmentEventMapper from 'mappers/AppointmentEventMapper';
import useSocket from 'hooks/use-socket';
import { useWinstonLogger } from 'winston-react';
import { LOG_SOCKET } from 'utils/logger';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import EventDTO from 'dtos/EventDTO';
import { IAppointmentRouteState } from 'interfaces/IRouteStates';
import { EnterWaitingRoomSocketMessage } from 'interfaces/ISocketMessages';
import PaginationSchema from 'schemas/PaginationSchema';
import { getUniqueValues } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import { FormSubmit, IInitFormInput, InputTypes } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { userIsMedic } from 'utils/user';
import useSpecialtiesService from './specialties.service';
import { appointmentsAPI } from 'apis';

const appointmentMapper = AppointmentMapper();
const appointmentEventMapper = AppointmentEventMapper();

export type UseAppointmentsService = {
  isLoading: boolean;
  createAppointment: (submit: FormSubmit) => Promise<AppointmentDTO>;
  rescheduleAppointment: (appointment: AppointmentDTO, { inputs }: FormSubmit) => Promise<void>;
  startPayingAppointment: (appointmentId: string) => Promise<string>;
  startAppointment: (appointmentEvent: EventDTO) => Promise<void>;
  getAppointment: (appointmentId: string) => Promise<EventDTO>;
  cancelAppointment: (appointment: AppointmentDTO, submit?: FormSubmit) => Promise<void>;
  getNextAppointmentsWithPatient: (patientId: string) => Promise<EventDTO[]>;
  getPastAppointmentsWithPatient: (
    patientId: string,
    page: number
  ) => Promise<{ appointments: EventDTO[]; last: boolean }>;
  evaluateMedic: (appointmentId: string, score: number) => Promise<void>;
};

const useAppointmentsService = (): UseAppointmentsService => {
  const logger = useWinstonLogger();
  const location = useLocation();
  const history = useHistory();
  const { isLoading, get, post, put, del } = useHttp();
  const { emitSocketMessage } = useSocket();
  const { getSpecialty } = useSpecialtiesService();
  const dispatch = useDispatch();
  const userId = useSelector((state: IReduxState) => state.auth.id);
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const languageCode = useSelector((state: IReduxState) => state.language.code);
  const language = useSelector((state: IReduxState) => state.language.values);

  const getAppointmentsSpecialty = async (appointmentsEvents: EventDTO[]): Promise<EventDTO[]> => {
    const specialtiesIds = getUniqueValues(appointmentsEvents, 'appointment.specialty.id');

    const specialties = (
      await Promise.allSettled([...specialtiesIds.map(async (specialtyId: string) => getSpecialty(specialtyId))])
    )
      .filter((result: PromiseSettledResult<SpecialtyDTO>) => result.status === 'fulfilled')
      .map((result) => (result as PromiseFulfilledResult<SpecialtyDTO>).value);

    appointmentsEvents.forEach((appointmentEvent: EventDTO) => {
      if (appointmentEvent.appointment) {
        const specialty = specialties.find(
          (specialty: SpecialtyDTO) => specialty.id === appointmentEvent.appointment?.specialty.id
        );
        if (specialty) appointmentEvent.appointment.specialty = specialty;
      }
    });

    return appointmentsEvents;
  };

  // POST /appointments
  const createAppointment = ({ inputs }: FormSubmit): Promise<AppointmentDTO> => {
    const body = appointmentMapper.toBody(userId!, inputs);

    return new Promise((resolve, reject) => {
      post(appointmentsAPI, undefined, body)
        .then((appointment: AppointmentSchema) => {
          const mappedAppointment = appointmentMapper.toInterface(appointment, userId!, languageCode!);

          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastSuccess-scheduleDone',
              type: ToastTypes.SUCCESS,
              icon: iconsTemp.calendar,
              title: language.scheduleDone,
            })
          );

          resolve(mappedAppointment);
        })
        .catch((error: IErrorResponse) => {
          const { status, message } = error;

          if (status === 403 && message.search('already exists')) {
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-slotAlreadyTaken',
                type: ToastTypes.ERROR,
                icon: iconsTemp.close,
                title: language.slotAlreadyTaken,
              })
            );
          } else {
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-schedulingAppointment',
                type: ToastTypes.ERROR,
                icon: iconsTemp.close,
                title: language.errorOnSchedulingAppointment,
                subtitle: language.weWillCheckTheProblem,
              })
            );
          }

          reject(error);
        });
    });
  };

  // PUT /appointments/{appointmentId}/event
  const rescheduleAppointment = (appointment: AppointmentDTO, { inputs }: FormSubmit): Promise<void> => {
    const body = appointmentMapper.toRescheduleBody(inputs);

    return new Promise((resolve, reject) => {
      put(`${appointmentsAPI}/${appointment.id}/event`, undefined, body)
        .then(() => {
          const title = userIsMedic(userType)
            ? placeValue(language.appointmentRescheduleRequestDone, appointment.user?.name || language.unknown)
            : placeValue(
                language.appointmentCancellationDone,
                appointment.user
                  ? getDoctorAbreviation(language, appointment.user.gender, appointment.user?.name)
                  : language.unknown
              );

          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastInfo-appointmentCancellation',
              icon: iconsTemp.close,
              title,
            })
          );

          resolve();
        })
        .catch(() => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastError-reschedulingAppointment',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorOnReschedulingAppointment,
              subtitle: language.weWillCheckTheProblem,
            })
          );

          reject();
        });
    });
  };

  // POST /appointments/payment/{appointmentId}
  const startPayingAppointment = (appointmentId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      post(`${appointmentsAPI}/payment/${appointmentId}`)
        .then((paymentLink: AppointmentPaymentSchema) => {
          resolve(paymentLink.checkoutUrl);
        })
        .catch(() => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastError-startPayingAppointment',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorOnStartPayingAppointment,
              subtitle: language.weWillCheckTheProblem,
            })
          );
          reject();
        });
    });
  };

  const startAppointment = (appointmentEvent: EventDTO): Promise<void> => {
    const appointmentId = appointmentEvent.appointment!.id;

    return new Promise((resolve, reject) => {
      emitSocketMessage('enter-waiting-room', appointmentId, (data: EnterWaitingRoomSocketMessage) => {
        const { code, appointmentHasStarted } = data;

        logger.log(
          LOG_SOCKET,
          `received code ${code} as response to entering waiting room with appointment id ${appointmentId}`
        );

        switch (code) {
          case 200:
            history.push(generatePath(appointmentSubRoutes.waitingRoom.path, { appointmentId }), {
              verified: true,
              appointmentEvent,
              appointmentHasStarted: !!appointmentHasStarted,
            } as IAppointmentRouteState);

            resolve();
            return;
          case 412:
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-startAppointmentOutsideTime',
                type: ToastTypes.ERROR,
                icon: iconsTemp.videoCallOn,
                title: language.itWasNotPossibleToStartAppointment,
                subtitle: language.theTimeToStartAppointmentHasPassed,
              })
            );
            break;
          case 423:
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-startAppointmentConflict',
                type: ToastTypes.ERROR,
                icon: iconsTemp.videoCallOn,
                title: language.couldNotStartAppointment,
                subtitle: language.youAlreadyAreInAnAppointment,
              })
            );
            break;
          case 500:
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-startAppointment',
                type: ToastTypes.ERROR,
                icon: iconsTemp.close,
                title: language.errorOnStartAppointment,
                subtitle: language.weWillCheckTheProblem,
              })
            );
            break;
        }

        if (matchPath(location.pathname, { path: routes.appointment.path, exact: false }))
          history.push(routes.home.path);
        reject();
      });
    });
  };

  // GET /appointments/{appointmentId}
  const getAppointment = (appointmentId: string): Promise<EventDTO> => {
    return new Promise((resolve, reject) => {
      get(`${appointmentsAPI}/${appointmentId}`, { embedded_entities: true })
        .then(async (appointment: AppointmentSchema) => {
          resolve(
            (
              await getAppointmentsSpecialty([appointmentEventMapper.toInterface(appointment, userId!, languageCode!)])
            )[0]
          );
        })
        .catch(() => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastError-gettingAppointment',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorOnGettingAppointment,
              subtitle: language.weWillCheckTheProblem,
            })
          );
          reject();
        });
    });
  };

  // DELETE /appointments/{appointmentId}
  const cancelAppointment = (appointment: AppointmentDTO, submit?: FormSubmit): Promise<void> => {
    const body = submit ? appointmentMapper.toCancelBody(submit.inputs) : undefined;

    return new Promise((resolve, reject) => {
      del(`${appointmentsAPI}/${appointment.id}`, undefined, body)
        .then(() => {
          const title = submit
            ? placeValue(language.appointmentCancellationRequestDone, appointment.user?.name || language.unknown)
            : placeValue(
                language.appointmentCancellationDone,
                appointment.user
                  ? getDoctorAbreviation(language, appointment.user.gender, appointment.user?.name)
                  : language.unknown
              );

          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastInfo-appointmentCancellation',
              icon: iconsTemp.close,
              title,
            })
          );

          resolve();
        })
        .catch(() => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastError-cancellingAppointment',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorOnCancellingAppointment,
              subtitle: language.weWillCheckTheProblem,
            })
          );

          reject();
        });
    });
  };

  // GET /appointments
  const getNextAppointmentsWithPatient = (patientId: string): Promise<EventDTO[]> => {
    return new Promise((resolve, reject) => {
      get(appointmentsAPI, { patientId, startAtGte: new Date().toISOString(), size: 2 })
        .then(async (appointmentsPagination: PaginationSchema<AppointmentSchema>) => {
          resolve(
            await getAppointmentsSpecialty(
              appointmentsPagination.content.map((appointment: AppointmentSchema) => ({
                ...appointmentEventMapper.toInterface(appointment, userId!, languageCode!),
              }))
            )
          );
        })
        .catch(() => {
          reject();
        });
    });
  };

  // GET /appointments
  const getPastAppointmentsWithPatient = (
    patientId: string,
    page: number
  ): Promise<{ appointments: EventDTO[]; last: boolean }> => {
    return new Promise((resolve, reject) => {
      get(appointmentsAPI, { patientId, page, endAtLte: new Date().toISOString(), size: 2 })
        .then(async (appointmentsPagination: PaginationSchema<AppointmentSchema>) => {
          resolve({
            appointments: await getAppointmentsSpecialty(
              appointmentsPagination.content.map((appointment: AppointmentSchema) => ({
                ...appointmentEventMapper.toInterface(appointment, userId!, languageCode!),
              }))
            ),
            last: appointmentsPagination.last,
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

  // TODO: send evaluation request
  const evaluateMedic = (appointmentId: string, score: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  return {
    isLoading,
    createAppointment,
    rescheduleAppointment,
    startPayingAppointment,
    startAppointment,
    getAppointment,
    cancelAppointment,
    getNextAppointmentsWithPatient,
    getPastAppointmentsWithPatient,
    evaluateMedic,
  };
};

export const scheduleAppointmentCommonFormInputs: IInitFormInput[] = [
  { name: 'specialty', type: InputTypes.TEXT },
  { name: 'type', type: InputTypes.TEXT },
  { name: 'event', type: InputTypes.TEXT },
];

export const scheduleAppointmentFormInputs: IInitFormInput[] = scheduleAppointmentCommonFormInputs.concat([
  { name: 'medic', type: InputTypes.TEXT },
  // { name: 'ensurance', type: InputTypes.TEXT },
  { name: 'reason', type: InputTypes.TEXT },
]);

export const startScheduleAppointmentFormInputs: IInitFormInput[] = [...scheduleAppointmentCommonFormInputs];

export const rescheduleAppointmentFormInputs: IInitFormInput[] = [
  { name: 'reason', type: InputTypes.TEXT },
  { name: 'event', type: InputTypes.TEXT },
];

export const cancelAppointmentFormInputs: IInitFormInput[] = [{ name: 'reason', type: InputTypes.TEXT }];

export default useAppointmentsService;
