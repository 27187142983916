import React from 'react';
import useInput from './Input.logic';
import PasswordInput from './PasswordInput';
import DateInput from './DateInput/DateInput';
import SearchInput from './SearchInput';
import BaseInput from './BaseInput';
import TimeInput from './TimeInput/TimeInput';
import NumberInput from './NumberInput/NumberInput';
import OptionsInput from './OptionsInput';
import IOption from 'interfaces/IOption';

export type InputValue = string | number | Date | undefined;

export enum INPUT_TYPES {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  SEARCH = 'search',
  DATE = 'date',
  TIME = 'time',
  NUMBER = 'number',
  OPTIONS = 'options',
}

export type InputProps = {
  dataTestId?: string;
  className?: string;
  type?: INPUT_TYPES;
  label?: string;
  placeholder?: string;
  defaultValue?: InputValue;
  value?: InputValue;
  min?: number | Date;
  max?: number | Date;
  step?: number;
  disabled?: boolean;
  success?: string | null;
  error?: string | null;
  help?: string;
  options?: IOption[];
  onChange?: (value: InputValue | undefined) => void;
  onFocus?: () => void;
  onBlur?: (value: InputValue | undefined) => void;
};

export type InputsInnerProps = {
  inputValue: InputValue;
  canEdit?: boolean;
  setValue: React.Dispatch<React.SetStateAction<InputValue>>;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  focusHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blurHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeNumberHandler?: (value: number) => void;
  changeDateOrTimeHandler?: (date: Date) => void;
  changeOptionHandler?: (option: IOption) => void;
};

export type InputRef = {
  value: InputValue;
};

const Input: React.ForwardRefExoticComponent<InputProps & React.RefAttributes<InputRef>> = React.forwardRef(
  (props: React.PropsWithChildren<InputProps>, ref: React.ForwardedRef<InputRef>) => {
    const { type } = props;
    const {
      value,
      setValue,
      changeHandler,
      focusHandler,
      blurHandler,
      changeNumberHandler,
      changeDateOrTimeHandler,
      changeOptionHandler,
    } = useInput(props, ref);
    const inputsProps = {
      ...props,
      inputValue: value,
      setValue,
      changeHandler,
      focusHandler,
      blurHandler,
    };

    switch (type) {
      case INPUT_TYPES.SEARCH:
        return <SearchInput {...{ ...inputsProps, canEdit: true }} />;
      case INPUT_TYPES.PASSWORD:
        return <PasswordInput {...{ ...inputsProps, canEdit: true }} />;
      case INPUT_TYPES.NUMBER:
        return <NumberInput {...{ ...inputsProps, changeNumberHandler }} />;
      case INPUT_TYPES.DATE:
        return <DateInput {...{ ...inputsProps, changeDateOrTimeHandler }} />;
      case INPUT_TYPES.TIME:
        return <TimeInput {...{ ...inputsProps, changeDateOrTimeHandler }} />;
      case INPUT_TYPES.OPTIONS:
        return <OptionsInput {...{ ...inputsProps, changeOptionHandler }} />;
      case INPUT_TYPES.TEXT:
      case INPUT_TYPES.EMAIL:
      default:
        return <BaseInput {...{ ...inputsProps, canEdit: true }} />;
    }
  }
);

Input.defaultProps = {
  className: '',
  type: INPUT_TYPES.TEXT,
  disabled: false,
  error: null,
  success: null,
  onChange: undefined,
  onFocus: undefined,
  onBlur: undefined,
};

export default Input;
