import { ActionTypes, FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { ToastTypes } from 'components/View/Toast';
import { iconsTemp } from 'iconsTemp';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useDispatch, useSelector } from 'react-redux';
import useAuthenticationService from 'services/authentication.service';
import { feedbackActions } from 'store/redux/feedback-slice';

export interface IUseSecurity {
  language: ILanguage;
  changePasswordHandler: (submit: FormSubmit) => void;
}

const useSecurity = (): IUseSecurity => {
  const dispatch = useDispatch();
  const {} = useAuthenticationService();
  const language = useSelector((state: IReduxState) => state.language.values);

  const changePasswordHandler = ({ inputs, dispatchInputs }: FormSubmit): void => {
    if (inputs.newPassword.value !== inputs.confirmNewPassword.value) {
      dispatchInputs({
        type: ActionTypes.SET_ERROR,
        attribute: 'confirmNewPassword',
        value: language.passwordsDontMatch,
      });
    } else {
      dispatch(
        feedbackActions.addMessage({
          dataTestId: 'toastError-changedPassword',
          type: ToastTypes.SUCCESS,
          icon: iconsTemp.check,
          title: language.changedPasswordSuccessfully,
        })
      );

      dispatchInputs({ type: ActionTypes.CLEAR });
    }
  };

  return { language, changePasswordHandler };
};

export default useSecurity;
