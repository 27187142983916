import { getMargin, styles, Button, getGutter, getColumnsWidth } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import HeaderProfile from 'components/Header/HeaderProfile';
import Tabs from 'components/View/Tabs';
import styled from 'styled-components';

const top = `top: calc(${getMargin()} / 2);`;

export interface IAccountWrapperProps {}

export const AccountWrapper = styled.div<IAccountWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${styles.colors.brand.lightest};
`;

export interface IAccountBackButtonProps {}

export const AccountBackButton = styled(Button)<IAccountBackButtonProps>`
  position: absolute;
  ${top}
  left: ${getMargin()};
  z-index: 5;
`;

export interface IAccountTabsProps {}

export const AccountTabs = styled(Tabs)<IAccountTabsProps>`
  padding-top: calc(${getGutter()} / 2);

  a {
    font-weight: 600;
  }

  > div {
    width: ${getColumnsWidth({ nrColumns: 4 })} !important;
    margin: 3.5rem auto 0 auto;
  }
`;

export interface IAccountHeaderProfileProps {}

export const AccountHeaderProfile = styled(HeaderProfile)<IAccountHeaderProfileProps>`
  position: absolute !important;
  ${top}
  right: ${getMargin()};
`;
