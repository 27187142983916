import { getCountriesOptions, TagsWithOptionsInput, TextArea } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import IReduxState from 'interfaces/IReduxState';
import { OnboardingParagraph } from 'pages/Onboarding/Onboarding.styles';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { getAppointmentPlacesOptions } from 'utils/appointment';
import { MedicAppointmentOptions } from './MedicAboutStep.styles';

export interface IMedicAboutStepProps extends PropsWithChildren<{}> {}

const MedicAboutStep: FC<IMedicAboutStepProps> = (props: IMedicAboutStepProps) => {
  const {} = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <>
      <h1>{language.myProfile}</h1>
      <OnboardingParagraph>{language.tellUsAvailableParametersToYourAppointments}</OnboardingParagraph>
      <h5 style={{ marginTop: '2rem' }}>{language.whatAppointmentWillYouGive}</h5>
      <MedicAppointmentOptions name='appointments' options={getAppointmentPlacesOptions(language)} multi big />
      <TagsWithOptionsInput
        name='languages'
        icon='education'
        placeholder={language.spokenLanguages}
        tags={getCountriesOptions(true)}
      />
      <TextArea name='about' icon='details' placeholder={`${language.aboutMe} (${language.optional})`} />
    </>
  );
};

export default MedicAboutStep;
