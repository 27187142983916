import MessageDTO, { MessageState } from 'dtos/MessageDTO';
import useSocket from 'hooks/use-socket';
import IReduxState from 'interfaces/IReduxState';
import {
  ISendAppointmentMsgMessage,
  MessageReadSocketMessage,
  ReceiveFileShareSocketMessage,
  ReceiveMessageSocketMessage,
} from 'interfaces/ISocketMessages';
import FileMapper from 'mappers/FileMapper';
import MessageMapper from 'mappers/MessageMapper';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MessageSchema from 'schemas/MessageSchema';
import useFilesService from 'services/files.service';
import useMedicsService from 'services/medics.service';
import useMessagesService from 'services/messages.service';
import { LOG_COMPONENT, LOG_ERROR, LOG_HTTP, LOG_SOCKET, LOG_WARN } from 'utils/logger';
import { userIsMedic } from 'utils/user';
import { useWinstonLogger } from 'winston-react';

const messageMapper = MessageMapper();
const fileMapper = FileMapper();

export interface IChatContext {
  messages: MessageDTO[];
  canLoadMore: boolean;
  hasUnreadMessages: boolean;
  calleeImage?: string;
  loadMoreMessages: () => void;
  sendMessage: (message: string) => void;
  sendFile: (file: File) => void;
  readMessage: (messageId: string) => void;
}

const ChatContext = createContext<IChatContext>({
  messages: [],
  canLoadMore: false,
  hasUnreadMessages: false,
  calleeImage: undefined,
  loadMoreMessages: () => {},
  sendMessage: (_: string) => {},
  sendFile: (_: File) => {},
  readMessage: (_: string) => {},
} as IChatContext);

export type ChatContextProviderProps = {
  calleeId: string;
  appointmentId?: string;
  disableSend?: boolean;
};

export const ChatContextProvider = (props: PropsWithChildren<ChatContextProviderProps>) => {
  const { calleeId, appointmentId, disableSend, children } = props;
  const logger = useWinstonLogger();
  const { getMedicImage, getMedicPatientImage } = useMedicsService();
  const { getMessagesBetweenUsers } = useMessagesService();
  const { uploadFile } = useFilesService();
  const { listenSocketMessage, emitSocketMessage } = useSocket();
  const userId = useSelector((state: IReduxState) => state.auth.id)!;
  const userType = useSelector((state: IReduxState) => state.auth.type)!;
  const [messages, setMessages] = useState<MessageDTO[]>([]);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false);
  const [calleeImage, setCalleeImage] = useState<string>();

  useEffect(() => {
    listenSocketMessage('received-message', (data: ReceiveMessageSocketMessage) => {
      logger.log(LOG_SOCKET, 'received new message');
      onReceiveMessage(data.message);
    });

    listenSocketMessage('file-shared', (data: ReceiveFileShareSocketMessage) => {
      logger.log(LOG_SOCKET, 'received new file shared');
      onReceiveMessage(data as MessageSchema);
    });

    listenSocketMessage('message-read', (data: MessageReadSocketMessage) => {
      logger.log(LOG_SOCKET, 'received message read: ' + data.id);
      logger.log(LOG_COMPONENT, `received message with id ${data.id} was read and updating its state`);
      updateMessageReadState(data.id);
    });
  }, []);

  useEffect(() => {
    setMessages([]);
    loadMessages();
    (userIsMedic(userType) ? getMedicPatientImage : getMedicImage)(calleeId).then((blob: string) =>
      setCalleeImage(blob)
    );
  }, [calleeId]);

  useEffect(() => {
    setHasUnreadMessages(() => {
      // checks if there are any unread received messages
      const hasUnreadMessages = !!messages.find((message) => message.state === MessageState.SENT && !message.mine);
      logger.log(LOG_COMPONENT, `updating unread messages to ${hasUnreadMessages}`);
      return hasUnreadMessages;
    });
  }, [messages]);

  const loadMessages = useCallback(() => {
    const lastDate = messages.length > 0 ? messages[0].date : undefined;

    getMessagesBetweenUsers(calleeId, lastDate).then((messages: MessageDTO[]) => {
      if (messages.length > 0) {
        setMessages((prevState) => messages.concat(prevState));
        setCanLoadMore(true);
      } else setCanLoadMore(false);
    });
  }, [messages]);

  const onReceiveMessage = (message: MessageSchema) => {
    logger.log(LOG_COMPONENT, `received a new message ${JSON.stringify(message)}`);
    setMessages((prevState: MessageDTO[]) => [...prevState, messageMapper.toInterface(userId, message)]);
  };

  const callback = useCallback((message: MessageDTO | null, tempId: number): void => {
    setMessages((prevState: MessageDTO[]) => {
      // Updates the message sent after the server confirms that has created/saved it successfully/unsuccessfully
      if (message) {
        logger.log(LOG_HTTP, `updated message sent with id ${message.id} after being successfully uploaded`);

        return prevState.map((previousMessage: MessageDTO) =>
          previousMessage.id === `${tempId}` ? message : previousMessage
        );
      } else {
        logger.log(LOG_ERROR, `there was an error sending the message ${JSON.stringify(message)}`);

        const newMessage = prevState.find((message: MessageDTO) => message.id === `${tempId}`);
        newMessage!.state = MessageState.NOT_SENT;
        return [...prevState];
      }
    });
  }, []);

  const loadMoreMessages = () => loadMessages();

  const sendMessage = (message: string): void => {
    if (disableSend) {
      logger.log(LOG_WARN, 'sending a message is disabled');
      return;
    }

    logger.log(LOG_COMPONENT, `creating temporary message ${JSON.stringify(message)}`);

    setMessages((prevState: MessageDTO[]) => {
      const tempId = prevState.length + 1; // creates a temporary id for the message

      // maps and updates the message state on the socket callback
      const socketCallback = (message: MessageSchema) => {
        const mappedMessage = message ? messageMapper.toInterface(userId, message) : null;
        if (mappedMessage) mappedMessage.state = MessageState.SENT;
        callback(mappedMessage, tempId);
      };

      // sends the new message through the socket
      logger.log(LOG_SOCKET, 'emiting new message from appointment');
      const data = { message } as ISendAppointmentMsgMessage;
      emitSocketMessage('send-message-appointment', data, socketCallback);

      return [...prevState, messageMapper.createMessage(tempId, message)];
    });
  };

  const sendFile = (file: File): void => {
    if (disableSend) {
      logger.log(LOG_WARN, 'sending a file is disabled');
      return;
    }

    setMessages((prevState: MessageDTO[]) => {
      const tempId = prevState.length + 1; // creates a temporary id for the message

      uploadFile(calleeId, appointmentId!, file)
        .then((message: MessageDTO) => callback(message, tempId))
        .catch(() => callback(null, tempId));

      return [...prevState, fileMapper.createMessage(tempId)];
    });
  };

  const updateMessageReadState = (messageId: string) => {
    logger.log(LOG_COMPONENT, `updating message state with id ${messageId} to read`);

    setMessages((prevState: MessageDTO[]) =>
      prevState.map((message: MessageDTO) => ({
        ...message,
        state: message.id === messageId ? MessageState.READ : message.state,
      }))
    );
  };

  const readMessage = (messageId: string): void => {
    logger.log(LOG_COMPONENT, `setting message with id ${messageId} to read`);

    logger.log(LOG_SOCKET, 'emiting message read');
    emitSocketMessage('set-message-read', { id: messageId });

    updateMessageReadState(messageId);
  };

  const value: IChatContext = {
    messages,
    canLoadMore,
    hasUnreadMessages,
    calleeImage,
    loadMoreMessages,
    sendMessage,
    sendFile,
    readMessage,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ChatContext;
