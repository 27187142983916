import { FormSubmit, getInputValue, ActionTypes } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { useHttp, useDate, isPast } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { schedulerAPI } from 'apis';
import { ToastTypes } from 'components/View/Toast';
import AvailabilityDTO from 'dtos/AvailabilityDTO';
import EventDTO from 'dtos/EventDTO';
import MedicDTO from 'dtos/MedicDTO';
import SpecialtyDTO from 'dtos/SpecialtyDTO';
import { iconsTemp } from 'iconsTemp';
import IErrorResponse from 'interfaces/IErrorResponse';
import IReduxState from 'interfaces/IReduxState';
import AvailabilityMapper from 'mappers/AvailabilityMapper';
import EventMapper from 'mappers/EventMapper';
import MedicMapper from 'mappers/MedicMapper';
import { useDispatch, useSelector } from 'react-redux';
import AvailabilitySchema from 'schemas/AvailabilitySchema';
import EventSchema from 'schemas/EventSchema';
import MedicSchema from 'schemas/MedicSchema';
import PaginationSchema from 'schemas/PaginationSchema';
import { feedbackActions } from 'store/redux/feedback-slice';
import { getFrom, getTo } from 'utils/appointment';
import useSpecialtiesService from './specialties.service';

const availabilityMapper = AvailabilityMapper();
const eventMapper = EventMapper();
const medicMapper = MedicMapper();

export interface IUseAvailabilitiesService {
  isLoading: boolean;
  createAvailability: (submit: FormSubmit) => Promise<AvailabilityDTO>;
  editAvailableEvent: (submit: FormSubmit) => Promise<EventDTO>;
  deleteAvailableEvent: (eventId: string) => Promise<void>;
  getMedicsWithAvailabilities: (specialtyName: string, page?: number) => Promise<{ medics: MedicDTO[]; last: boolean }>;
}

const useAvailabilitiesService = (): IUseAvailabilitiesService => {
  const dispatch = useDispatch();
  const { isLoading, get, post, del } = useHttp();
  const { formatDate } = useDate();
  const { getSpecialties, getSpecialty } = useSpecialtiesService();
  const languageCode = useSelector((state: IReduxState) => state.language.code);
  const language = useSelector((state: IReduxState) => state.language.values);

  // POST /scheduler/availabilities
  const createAvailability = ({ inputs, dispatchInputs }: FormSubmit): Promise<AvailabilityDTO> => {
    return new Promise((resolve, reject) => {
      post(`${schedulerAPI}/availabilities`, undefined, availabilityMapper.toCreateBody(inputs, formatDate))
        .then((availability: AvailabilitySchema) => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastSuccess-availabilityAdded',
              type: ToastTypes.SUCCESS,
              icon: iconsTemp.check,
              title: language.availabilityAdded,
            })
          );
          resolve(availabilityMapper.toInterface(availability));
        })
        .catch((error: IErrorResponse) => {
          const { status, errors, message } = error;

          if (status === 400) {
            const errorFields = errors!.map((error: any) => error.field);

            if (errorFields.includes('startDate') && isPast(getInputValue<Date>(inputs.startDate)!)) {
              dispatchInputs({
                type: ActionTypes.SET_ERROR,
                attribute: 'startDate',
                value: language.startDateError,
              });
            } else if (errorFields.includes('startTime')) {
              dispatchInputs({
                type: ActionTypes.SET_ERROR,
                attribute: 'startTime',
                value: language.startTimeError,
              });
            } else if (errorFields.includes('endTime')) {
              dispatchInputs({
                type: ActionTypes.SET_ERROR,
                attribute: 'endTime',
                value: language.endTimeError,
              });
            } else if (errorFields.includes('duration')) {
              dispatchInputs({
                type: ActionTypes.SET_ERROR,
                attribute: 'duration',
                value: language.durationError,
              });
            } else if (errorFields.includes('price')) {
              dispatchInputs({
                type: ActionTypes.SET_ERROR,
                attribute: 'cost',
                value: getInputValue<number>(inputs.cost) === 0 ? language.costZeroError : language.costNegativeError,
              });
            }
          } else if (status === 403 && message.search(/(colliding)/i) >= 0) {
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-availabilitiesCollision',
                type: ToastTypes.ERROR,
                icon: iconsTemp.close,
                title: language.collisionAvailabilitiesError,
              })
            );
          } else {
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-addingAvailability',
                type: ToastTypes.ERROR,
                icon: iconsTemp.close,
                title: language.errorOnAddingAvailability,
                subtitle: language.weWillCheckTheProblem,
              })
            );
          }

          reject(error);
        });
    });
  };

  // POST /scheduler
  const createEvent = ({ inputs }: FormSubmit): Promise<EventDTO> => {
    const body = availabilityMapper.toEditBody(inputs);

    return new Promise((resolve, reject) => {
      post(`${schedulerAPI}/`, undefined, body)
        .then((event: EventSchema) => {
          resolve(eventMapper.toInterface(event));
        })
        .catch((error: IErrorResponse) => {
          reject(error);
        });
    });
  };

  // POST /scheduler
  // DELETE /scheduler/{eventId}
  const editAvailableEvent = ({ inputs, dispatchInputs }: FormSubmit): Promise<EventDTO> => {
    return new Promise((resolve, reject) => {
      createEvent({ inputs, dispatchInputs })
        .then(async (event: EventDTO) => {
          await del(`${schedulerAPI}/${getInputValue(inputs.event)!}`);

          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastSuccess-eventEdited',
              type: ToastTypes.SUCCESS,
              icon: iconsTemp.edit,
              title: language.availableEventEditedSuccessful,
            })
          );

          resolve(event);
        })
        .catch(({ status, message }: IErrorResponse) => {
          if (status === 403 && message.includes('Colliding events found')) {
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-eventEditedWithCollision',
                type: ToastTypes.ERROR,
                icon: iconsTemp.edit,
                title: language.collisionAvailabilitiesError,
              })
            );
          } else {
            dispatch(
              feedbackActions.addMessage({
                dataTestId: 'toastError-eventEdited',
                type: ToastTypes.ERROR,
                icon: iconsTemp.close,
                title: language.errorOnEditingAvailableEvent,
              })
            );
          }

          reject();
        });
    });
  };

  // DELETE /scheduler/{eventId}
  const deleteAvailableEvent = (eventId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      del(`${schedulerAPI}/${eventId}`)
        .then(() => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastSuccess-availableEventDeleted',
              type: ToastTypes.SUCCESS,
              icon: iconsTemp.delete,
              title: language.availableEventDeleted,
            })
          );
          resolve();
        })
        .catch((error: IErrorResponse) => {
          dispatch(
            feedbackActions.addMessage({
              dataTestId: 'toastError-deletingAvailableEvent',
              type: ToastTypes.ERROR,
              icon: iconsTemp.close,
              title: language.errorOnDeletingAvailableEvent,
            })
          );

          reject(error);
        });
    });
  };

  const getMedicsWithAvailabilities = async (
    specialtyName: string,
    page?: number
  ): Promise<{ medics: MedicDTO[]; last: boolean }> => {
    const specialties = await getSpecialties();
    const specialty = specialties.find((specialty: SpecialtyDTO) =>
      specialty.name.toLowerCase().includes(specialtyName.toLowerCase().trim())
    );

    if (!specialty) return new Promise((resolve, _) => resolve({ medics: [], last: true }));

    const parameters = {
      specialtyId: specialty.id,
      from: getFrom(),
      to: getTo(),
      embedded_entities: true,
      page: page,
    };

    return new Promise((resolve, reject) => {
      get(`${schedulerAPI}/public/medics`, parameters).then(async (pagination: PaginationSchema<MedicSchema>) => {
        resolve({
          medics: pagination.content.map((medic: MedicSchema) => medicMapper.toInterface(medic, languageCode!)),
          last: pagination.last,
        });
      });
    });
  };

  return {
    isLoading,
    createAvailability,
    editAvailableEvent,
    deleteAvailableEvent,
    getMedicsWithAvailabilities,
  };
};

export default useAvailabilitiesService;
