import { ActionTypes, FormContext, getInputValue } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { datesAreEqual, useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export interface IUseAddAvailabilityFormContent {
  language: ILanguage;
  days: string[];
  showEndDate: boolean;
  toggleShowEndDateHandler: () => void;
}

const useAddAvailabilityFormContent = (): IUseAddAvailabilityFormContent => {
  const { date } = useParams<{ date: string }>();
  const { days } = useDate();
  const { inputs, dispatchInputs } = useContext(FormContext);
  const language = useSelector((state: IReduxState) => state.language.values);
  const [showEndDate, setShowEndDate] = useState<boolean>(false);
  const startDateTime = getInputValue<Date>(inputs.startDate)?.getTime();

  useEffect(() => {
    if (date) dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'startDate', value: new Date(date) });
  }, [date]);

  useEffect(() => {
    const startDate = new Date(getInputValue<Date>(inputs.startDate)!);
    const endDate = getInputValue<Date>(inputs.endDate)!;
    let newEndDate: Date | undefined = undefined;

    if ((datesAreEqual(startDate, endDate) || endDate.getTime() < startDate.getTime()) && showEndDate)
      newEndDate = new Date(startDate.setDate(startDate.getDate() + 1));
    else if (endDate.getTime() < startDate.getTime()) newEndDate = new Date(startDate);

    if (!newEndDate) return;

    dispatchInputs({
      type: ActionTypes.UPDATE_VALUE,
      attribute: 'endDate',
      value: newEndDate,
    });
  }, [startDateTime]);

  const toggleShowEndDateHandler = (): void => {
    setShowEndDate((prevState: boolean) => {
      const newState = !prevState;
      const startDate = new Date(getInputValue<Date>(inputs.startDate)!);
      let newEndDate: Date;

      if (newState) newEndDate = new Date(startDate.setDate(startDate.getDate() + 1));
      else newEndDate = new Date(startDate);

      dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'endDate', value: newEndDate });
      return newState;
    });
  };

  return {
    language,
    days,
    showEndDate,
    toggleShowEndDateHandler,
  };
};

export default useAddAvailabilityFormContent;
