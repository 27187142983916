import { Flex, ITagProps, rgba, styles, Tag, transition } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';
import { EventCardContainer } from './EventCard.styles';

export interface IAppointmentCardWrapperProps {
  isSelected?: boolean;
}

export const AppointmentCardWrapper = styled(EventCardContainer)<IAppointmentCardWrapperProps>`
  cursor: pointer;
  ${transition('all')}

  ${({ isSelected }: IAppointmentCardWrapperProps) =>
    isSelected
      ? css`
          background-color: ${rgba(styles.colors.brand.main, 0.1)};

          &:hover {
            background-color: ${rgba(styles.colors.brand.main, 0.25)};
          }
        `
      : css`
          &:hover {
            background-color: ${rgba(styles.colors.brand.main, 0.1)};
          }
        `}
`;

export interface IAppointmentCardInfoProps {
  isSelected?: boolean;
}

export const AppointmentCardInfo = styled(Flex)<IAppointmentCardInfoProps>`
  margin-right: auto;
  margin-left: 1.5rem;
  color: ${rgba(styles.colors.brand.main, 0.75)};

  > h5 {
    margin-bottom: 0.25rem;
  }
`;

export interface IAppointmentCardTagProps {
  isSelected?: boolean;
}

export const AppointmentCardTag = styled(Tag).attrs((_: ITagProps) => ({
  iconWithBackground: true,
}))<IAppointmentCardTagProps>`
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

/*
.appointment {
@media screen and (max-width: ($tablet + 0px)) {
  .appointment {
    height: 6.4375rem;
    padding: 1rem;
    margin-right: 0 !important;
    margin-bottom: 0;
    background-color: $lightest;

    &.selected {
      padding: 1.5rem 1rem;
      margin-bottom: 0.5rem;
      background-color: $main;
      transform: scale(1.03);
      transform-origin: 50%;

      &:hover {
        background-color: rgba($main, 0.75);
      }

      * {
        color: $lightest;
      }

      > .info {
        > div {
          > .info_icon {
            @include iconColor(rgba($lightest, 0.1), $lightest);
            background-color: transparent;
          }
        }
      }
    }

    &:not(.selected) {
      &:hover {
        background-color: rgba($lightest, 0.75);
      }
    }

    > .camera {
      @include circleIcon($iconSize, rgba($lightest, 0.15), $lightest);
      background-color: transparent;
    }
  }
}

@media screen and (max-width: ($smartphone + 0px)) {
  .appointment {
    height: 4.75rem;

    > .info {
      margin-left: 0.6rem;

      > div {
        > span {
          font-size: $textSize_small;
        }
      }
    }
  }
}
*/
