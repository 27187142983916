import EventDTO from 'dtos/EventDTO';
import useDate, { DateMasks } from 'hooks/use-date';
import useSocket from 'hooks/use-socket';
import { IAppointmentRouteState } from 'interfaces/IRouteStates';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import appointmentSubRoutes from 'routes/appointmentSubRoutes';
import routes from 'routes/routes';
import useAppointmentsService from 'services/appointments.service';
import useNotesService from 'services/notes.service';
import logger, { LOG_COMPONENT, LOG_SOCKET, LOG_WARN } from 'utils/logger';

export interface IAppointmentContext {
  appointmentIsVerified: boolean;
  appointmentEvent?: EventDTO;
  start?: Date;
  note?: string;
  updateNoteHandler: (note: string) => void;
  saveNoteHandler: () => void;
  startAppointmentFromWaitingRoomHandler: (start: Date) => void;
  emitLeaveWaitingRoomHandler: () => void;
  endAppointmentCallHandler: () => void;
  endAppointmentHandler: () => void;
}

const AppointmentContext = createContext<IAppointmentContext>({
  appointmentIsVerified: false,
  appointmentEvent: undefined,
  start: undefined,
  note: undefined,
  updateNoteHandler: (_: string) => {},
  saveNoteHandler: () => {},
  startAppointmentFromWaitingRoomHandler: (_: Date) => {},
  emitLeaveWaitingRoomHandler: () => {},
  endAppointmentCallHandler: () => {},
  endAppointmentHandler: () => {},
});

export interface AppointmentContextProviderProps {}

export const AppointmentContextProvider = (props: PropsWithChildren<AppointmentContextProviderProps>) => {
  const { children } = props;
  const history = useHistory();
  const location = useLocation<IAppointmentRouteState>();
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const { formatDate } = useDate();
  const { emitSocketMessage } = useSocket();
  const { getAppointment, startAppointment } = useAppointmentsService();
  const { createOrUpdateAppointmentNote } = useNotesService();
  const [appointmentEvent, setAppointmentEvent] = useState<EventDTO>();
  const [start, setStart] = useState<Date>();
  const [note, setNote] = useState<string>();
  const [appointmentIsVerified, setAppointmentIsVerified] = useState<boolean>(false);

  useEffect(() => {
    if (location.state?.appointmentEvent) {
      logger.log(LOG_COMPONENT, 'appointment was already defined');
      setAppointmentEvent(location.state.appointmentEvent);
      verifyAppointment(location.state.appointmentEvent);
    } else {
      logger.log(LOG_COMPONENT, 'appointment was not defined. getting information');

      getAppointment(appointmentId)
        .then((appointmentEvent: EventDTO) => {
          const note = appointmentEvent.appointment?.note?.text;
          if (note) {
            logger.log(LOG_COMPONENT, 'setting appointment note that was already defined');
            updateNoteHandler(note);
          }

          setAppointmentEvent(appointmentEvent);
          verifyAppointment(appointmentEvent);
        })
        .catch(() => {
          history.push(routes.home.path);
        });
    }
  }, [appointmentId]);

  useEffect(() => {
    if (location.state?.verified) setAppointmentIsVerified(true);
  }, [location.state?.verified]);

  const verifyAppointment = useCallback((appointment: EventDTO): void => {
    if (!location.state?.verified) {
      logger.log(LOG_WARN, 'appointment was not yet verified. verifying now (on appointment page)');
      startAppointment(appointment);
    } else logger.log(LOG_COMPONENT, 'appointment was already verified');
  }, []);

  const updateNoteHandler = (note: string): void => {
    setNote(note);
  };

  const saveNoteHandler = (): void => {
    createOrUpdateAppointmentNote(appointmentEvent!.appointment!.id, note);
  };

  const startAppointmentFromWaitingRoomHandler = (start: Date): void => {
    logger.log(LOG_SOCKET, `starting appointment at ${start && formatDate(start, DateMasks.dateTime)}`);
    setStart(start);
    history.replace(generatePath(appointmentSubRoutes.call.path, { appointmentId }));
  };

  const emitLeaveWaitingRoomHandler = (): void => {
    logger.log(LOG_SOCKET, 'emiting left waiting room');
    emitSocketMessage('left-waiting-room');
  };

  const endAppointmentCallHandler = (): void => {
    logger.log(LOG_SOCKET, "emiting 'left-call'");
    emitSocketMessage('left-call');
  };

  const endAppointmentHandler = (): void => {
    saveNoteHandler();

    logger.log(LOG_SOCKET, "emiting 'left-appointment'");
    emitSocketMessage('left-appointment');
    history.push(routes.home.path);
  };

  const value: IAppointmentContext = {
    appointmentIsVerified,
    appointmentEvent,
    start,
    note,
    updateNoteHandler,
    saveNoteHandler,
    startAppointmentFromWaitingRoomHandler,
    emitLeaveWaitingRoomHandler,
    endAppointmentCallHandler,
    endAppointmentHandler,
  };

  return <AppointmentContext.Provider value={value}>{appointmentEvent && children}</AppointmentContext.Provider>;
};

export default AppointmentContext;
