import { ITagProps, Tag, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IPatientDataItemWrapperProps {}

export const PatientDataItemWrapper = styled.div<IPatientDataItemWrapperProps>`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export interface IPatientDataItemTagProps {}

export const PatientDataItemTag = styled(Tag).attrs((_: ITagProps) => ({
  textColor: styles.colors.brand.dark,
}))<IPatientDataItemTagProps>`
  > span {
    font-weight: 600;
  }
`;

export interface IPatientDataItemContentProps {}

export const PatientDataItemContent = styled.div<IPatientDataItemContentProps>`
  margin-left: 2rem;

  > div {
    margin-right: 2rem;
  }
`;
