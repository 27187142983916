import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import IRoute, { ITodaySubRoutes } from 'interfaces/IRoute';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import homeSubRoutes from 'routes/homeSubRoutes';
import useRouterShow from 'hooks/use-routerShow';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'routes/routes';
import EventDTO from 'dtos/EventDTO';
import useMediaScreen from 'hooks/use-mediaScreen';
import useSchedulesService from 'services/schedules.service';
import { userIsMedic } from 'utils/user';

export interface IUseHeader {
  showHeader: boolean;
  language: ILanguage;
  isDesktopScreen: boolean;
  userIsMedic: boolean;
  nrEvents: number;
  routeIsHome: boolean;
  addAvailabilityHandler: () => void;
}

export const useHeader = (): IUseHeader => {
  const location = useLocation();
  const history = useHistory();
  const { getTodaysEvents } = useSchedulesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const { isDesktopScreen, isMobileScreen } = useMediaScreen();
  const [nrEvents, setNrEvents] = useState<number>(0);

  useEffect(() => {
    if (!isMobileScreen) return;

    getTodaysEvents().then((events: EventDTO[]) => {
      const todayEvents = events.filter((event: EventDTO) => event.appointment);
      setNrEvents(todayEvents.length);
    });
  }, []);

  const routesToNotShow = useCallback((): IRoute[] => {
    const routes: IRoute[] = [];
    if (isMobileScreen) routes.push((homeSubRoutes.today.subRoutes as ITodaySubRoutes).appointmentDetail);
    return routes;
  }, [isMobileScreen]);

  const addAvailabilityHandler = (): void => {
    history.push(routes.addAvailability.path);
  };

  return {
    showHeader: useRouterShow(routesToNotShow()),
    language,
    isDesktopScreen,
    userIsMedic: userIsMedic(userType),
    nrEvents,
    routeIsHome: location.pathname.includes(homeSubRoutes.today.linkPath!),
    addAvailabilityHandler,
  };
};
