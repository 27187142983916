import {
  Action,
  ActionTypes,
  FormSubmit,
  IInitFormInput,
  InputsReducerType,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { iconsTemp } from 'iconsTemp';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialMedicAccountFormInputs, initialPatientAccountFormInputs } from 'services/entities.service';
import useMedicsService from 'services/medics.service';
import usePatientsService from 'services/patients.service';
import { feedbackActions } from 'store/redux/feedback-slice';
import { userIsMedic, userIsPatient } from 'utils/user';

export interface IUseAccountInfo {
  language: ILanguage;
  isPatient: boolean;
  initialValues: IInitFormInput[];
  checkValuesAreDifferent: (inputs: InputsReducerType) => boolean;
  loadHandler: (dispatchInputs: Dispatch<Action>) => void;
  submitHandler: (submit: FormSubmit) => void;
  deleteAccountHandler: () => void;
}

const useAccountInfo = (): IUseAccountInfo => {
  const dispatch = useDispatch();
  const { updateCurrentMedic } = useMedicsService();
  const { updateCurrentPatient } = usePatientsService();
  const userType = useSelector((state: IReduxState) => state.auth.type)!;
  const user = useSelector((state: IReduxState) => state.entity)!;
  const language = useSelector((state: IReduxState) => state.language.values);
  const isMedic = userIsMedic(userType);
  const isPatient = userIsPatient(userType);

  const checkValuesAreDifferent = (inputs: InputsReducerType): boolean => {
    return (
      inputs.name.value.trim() !== user.name ||
      inputs.phoneNumber.value.trim() !== user.phone ||
      (inputs.gender ? inputs.gender.value !== user.gender : false)
    );
  };

  const loadHandler = (dispatchInputs: Dispatch<Action>): void => {
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'name', value: user.name });
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'email', value: user.email });
    dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'phoneNumber', value: user.phone });

    if (isPatient) {
      dispatchInputs({ type: ActionTypes.UPDATE_VALUE, attribute: 'gender', value: user.gender });
    }
  };

  const submitHandler = ({ inputs }: FormSubmit): void => {
    const { email, ...inputsToSubmit } = inputs;

    const successFeedback = () => {
      dispatch(
        feedbackActions.addMessage({
          dataTestId: 'toastError-updateAccount',
          icon: iconsTemp.check,
          title: language.changesSavedSuccessfully,
        })
      );
    };

    if (isMedic) updateCurrentMedic(inputsToSubmit).then(successFeedback);
    else if (isPatient) updateCurrentPatient(inputsToSubmit).then(successFeedback);
  };

  const deleteAccountHandler = (): void => {};

  return {
    language,
    isPatient,
    initialValues: isMedic ? initialMedicAccountFormInputs : isPatient ? initialPatientAccountFormInputs : [],
    checkValuesAreDifferent,
    loadHandler,
    submitHandler,
    deleteAccountHandler,
  };
};

export default useAccountInfo;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
