import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes/routes';
import useAuthenticationService from 'services/authentication.service';
import useMedicsService from 'services/medics.service';
import usePatientsService from 'services/patients.service';
import { userIsMedic } from 'utils/user';

export interface IUseHeaderProfile {
  language: ILanguage;
  imageSrc: string;
  showProfilePopup: boolean;
  setShowProfilePopup: Dispatch<SetStateAction<boolean>>;
  toggleProfilePopupHandler: () => void;
  accountHandler: () => void;
  logoutHandler: () => void;
}

export const useHeaderProfile = (): IUseHeaderProfile => {
  const history = useHistory();
  const { logout } = useAuthenticationService();
  const { getCurrentMedicImage } = useMedicsService();
  const { getCurrentPatientImage } = usePatientsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [showProfilePopup, setShowProfilePopup] = useState<boolean>(false);

  useEffect(() => {
    (userIsMedic(userType) ? getCurrentMedicImage() : getCurrentPatientImage()).then((blobUrl: string) =>
      setImageSrc(blobUrl)
    );
  }, []);

  const toggleProfilePopupHandler = (): void => {
    setShowProfilePopup((prevState: boolean) => !prevState);
  };

  const accountHandler = (): void => {
    history.push(routes.account.path, { from: history.location.pathname });
  };

  const logoutHandler = (): void => logout();

  return {
    language,
    imageSrc,
    showProfilePopup,
    setShowProfilePopup,
    toggleProfilePopupHandler,
    accountHandler,
    logoutHandler,
  };
};
