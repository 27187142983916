import { ILoginSubRoutes } from 'interfaces/IRoute';
import routes from 'routes/routes';
import useLoginForm from './LoginForm.logic';
import NoAccount from '../NoAccount';
import { Link } from 'react-router-dom';
import { IResetPasswordRouteState } from 'interfaces/IRouteStates';
import {
  Button,
  FormContextProvider,
  InputsReducerType,
  PasswordInput,
  SmallText,
  styles,
  TextInput,
} from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { LoginFormErrorMessage, LoginFormForgotPasswordLink, LoginFormWrapper } from './LoginForm.styles';
import { PropsWithChildren, FC } from 'react';
import { initialLoginFormInputs } from 'services/authentication.service';
import { CSSTransition } from 'react-transition-group';

export interface ILoginFormProps extends PropsWithChildren<{}> {}

const LoginForm: FC<ILoginFormProps> = (props: ILoginFormProps): JSX.Element => {
  const {} = props;
  const { language, wrongCredentials, isLoading, formLoadHandler, formFocusHandler, loginHandler } = useLoginForm();

  return (
    <>
      <LoginFormWrapper
        as={FormContextProvider}
        initialValues={initialLoginFormInputs}
        showError={wrongCredentials}
        onLoad={formLoadHandler}
        onFocus={formFocusHandler}
        onSubmit={loginHandler}>
        {(inputs: InputsReducerType) => (
          <>
            <TextInput dataTestId='login-emailInput' name='email' placeholder={language.email} />
            <PasswordInput dataTestId='login-passwordInput' name='password' />
            <LoginFormForgotPasswordLink
              as={Link}
              to={{
                pathname: (routes.login.subRoutes as ILoginSubRoutes)!.resetPassword.path,
                state: { email: inputs.email.value } as IResetPasswordRouteState,
              }}>
              <b>{language.forgotPassword}</b>
            </LoginFormForgotPasswordLink>
            <Button
              dataTestId='login-button'
              style={{ marginBottom: '0.5rem' }}
              buttonType='submit'
              label={language.login}
              loading={isLoading}
            />
            <CSSTransition
              classNames='login-error-message'
              in={wrongCredentials}
              timeout={styles.transition}
              mountOnEnter
              unmountOnExit>
              <LoginFormErrorMessage data-testid='login-errorMessage'>
                {language.wrongCredentials}
              </LoginFormErrorMessage>
            </CSSTransition>
          </>
        )}
      </LoginFormWrapper>
      <NoAccount />
    </>
  );
};

export default LoginForm;
