import AppointmentDTO, { AppointmentPlaces } from './AppointmentDTO';

export enum AppointmentEventTypes {
  Available = 'available',
  SeeMore = 'see-more',
}

export type EventPlace = AppointmentPlaces | AppointmentEventTypes;

export default interface EventDTO {
  id: string;
  availabilityId?: string;
  start: Date;
  end: Date;
  duration: number;
  appointment?: AppointmentDTO;
  place: EventPlace;
  icon: string;
  price?: number;
  nrFiles?: number;
  nrNotes?: number;
}
