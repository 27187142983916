import { useLanguage } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import { LanguageCode } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export interface ILink {
  name: keyof ILanguage;
  link: string;
}

export interface IUseAuthenticationLinks {
  languageCode: LanguageCode;
  language: ILanguage;
  links: ILink[];
  popupIsOpen: boolean;
  languagePopupIsOpen: boolean;
  togglePopup: () => void;
  toggleLanguagePopupHandler: () => void;
  selectLanguageHandler: (code: LanguageCode) => void;
}

const useAuthenticationLinks = (): IUseAuthenticationLinks => {
  const { changeLanguage } = useLanguage(false);
  const languageCode = useSelector((state: IReduxState) => state.language.code)!;
  const language = useSelector((state: IReduxState) => state.language.values);
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
  const [languagePopupIsOpen, setLanguagePopupIsOpen] = useState<boolean>(false);

  const getLinks = useCallback(
    (): ILink[] => [
      { name: 'userAgreement', link: '' },
      { name: 'privacyPolicy', link: '' },
      { name: 'cookiesPolicy', link: '' },
      { name: 'sendFeedback', link: '' },
    ],
    []
  );

  const togglePopup = (): void => {
    setPopupIsOpen((prevState: boolean) => !prevState);
  };

  const toggleLanguagePopupHandler = (): void => {
    setLanguagePopupIsOpen((prevState: boolean) => !prevState);
  };

  const selectLanguageHandler = (code: LanguageCode): void => {
    changeLanguage(code);
    setPopupIsOpen(false);
    setLanguagePopupIsOpen(false);
  };

  return {
    languageCode,
    language,
    links: getLinks(),
    popupIsOpen,
    languagePopupIsOpen,
    togglePopup,
    toggleLanguagePopupHandler,
    selectLanguageHandler,
  };
};

export default useAuthenticationLinks;
