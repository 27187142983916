import { Flex, getColumnsWidth, rgba, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled from 'styled-components';

export interface IEmptyListWrapperProps {}

export const EmptyListWrapper = styled(Flex)<IEmptyListWrapperProps>`
  width: ${getColumnsWidth({ nrColumns: 6 })};

  > p,
  > h3 {
    width: ${getColumnsWidth({ nrColumns: 4 })} !important;
    color: ${rgba(styles.colors.brand.dark, 0.75)};
    text-align: center;
  }

  > p {
    margin-top: 4.5rem;
    margin-bottom: 0.5em;
    font-size: 1.125rem;
  }

  > img {
    width: 12.5rem !important;
    height: 12.5rem;
    border-radius: 999px;
    margin-top: 2rem;
    background-color: ${rgba(styles.colors.brand.main, 0.1)};
  }
`;
