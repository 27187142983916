import IOption from 'interfaces/IOption';
import { useEffect, useState } from 'react';
import { LOG_COMPONENT } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export type OptionListType = string | number | (string | number)[] | undefined;

export interface UseOptionListProps {
  multi?: boolean;
  join?: boolean;
  defaultSelected?: OptionListType;
  selected?: OptionListType;
  onChange?: (option: IOption, checked?: boolean) => void;
  onValueChange?: (option: IOption, checked: boolean) => void;
}

export interface UseMultiOption {
  selectedOptions: (string | number)[];
  refValue: OptionListType;
  changeHandler: (checked: boolean, option: IOption) => void;
}

const useOptionList = (props: UseOptionListProps): UseMultiOption => {
  const { multi, defaultSelected, selected, onChange, onValueChange } = props;
  const logger = useWinstonLogger();
  const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>(
    multiOptionValueToSelectedOptions(defaultSelected)
  );

  useEffect(() => {
    if (selected !== undefined) {
      logger.log(LOG_COMPONENT, `updating selected options from the outside: ${selected}`);
      setSelectedOptions(multiOptionValueToSelectedOptions(selected));
    }
  }, [selected]);

  const changeHandler = (checked: boolean, option: IOption): void => {
    logger.log(LOG_COMPONENT, `value '${option}' has changed its state to ${checked}`);

    if (onChange) onChange(option, checked);

    if (onValueChange) onValueChange(option, checked);
    else {
      setSelectedOptions((prevState: (string | number)[]) => {
        if (checked) return multi ? [...prevState, option.id] : [option.id];
        else return prevState.filter((value: string | number) => value !== option.id);
      });
    }
  };

  return { selectedOptions, refValue: multi ? selectedOptions : selectedOptions.at(0), changeHandler };
};

// converts the selected option(s) to an array
const multiOptionValueToSelectedOptions = (selected: OptionListType): (string | number)[] => {
  return selected !== undefined ? (Array.isArray(selected) ? selected : [selected]) : [];
};

export default useOptionList;
