import { IProps, rgba, styles, Tag } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import EventDTO from 'dtos/EventDTO';
import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, FC } from 'react';
import { useSelector } from 'react-redux';
import { isAppointmentOngoing } from 'utils/appointment';
import { differenceInMinutesFromNow } from 'utils/date';
import { getAppointmentIsAboutToStartInMinutes } from 'utils/language';

export interface AppointmentWarningProps extends PropsWithChildren<IProps> {
  appointmentEvent: EventDTO;
}

const AppointmentWarning: FC<AppointmentWarningProps> = (props: AppointmentWarningProps) => {
  const { className, style, appointmentEvent } = props;
  const { start } = appointmentEvent;
  const language = useSelector((state: IReduxState) => state.language.values);
  const minutesToStart = differenceInMinutesFromNow(start);
  const label = isAppointmentOngoing(appointmentEvent)
    ? language.onGoing
    : getAppointmentIsAboutToStartInMinutes(language, minutesToStart);

  return (
    <Tag
      className={className}
      style={style}
      icon='clock'
      label={label}
      color={styles.colors.feedback.alert}
      background={rgba(styles.colors.feedback.alert, 0.1)}
    />
  );
};

export default AppointmentWarning;
