import useAddAvailabilityForm from './AddAvailabilityFormContent.logic';
import AddAvailabilityEndDate from './AddAvailabilityEndDate';
import AddAvailabilityTime from './AddAvailabilityTime';
import {
  AddAvailabilityAppointmentTypesMultiOption,
  DaysToRepeatMultiOption,
} from './AddAvailabilityFormContent.styles';
import { NumberInput, TextArea } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { PropsWithChildren, FC } from 'react';
import AddAvailabilitySpecialty from './AddAvailabilitySpecialty';

export interface IAddAvailabilityFormContentProps extends PropsWithChildren<{}> {
  separateTime?: boolean;
}

const AddAvailabilityFormContent: FC<IAddAvailabilityFormContentProps> = (
  props: IAddAvailabilityFormContentProps
): JSX.Element => {
  const { separateTime } = props;
  const { language, days, showEndDate, toggleShowEndDateHandler } = useAddAvailabilityForm();

  return (
    <>
      <AddAvailabilitySpecialty />
      <AddAvailabilityAppointmentTypesMultiOption dataTestId='addAvailabilityForm-placeMultiOption' name='place' />
      <NumberInput
        dataTestId='addAvailabilityForm-costInput'
        name='cost'
        icon='price'
        prefix={language.cost}
        placeholder='0'
        suffix='€'
        nrDigits={3}
      />
      <AddAvailabilityTime separate={separateTime} />
      <NumberInput
        dataTestId='addAvailabilityForm-durationInput'
        name='duration'
        icon='time'
        prefix={language.duration}
        suffix={language.minutes}
        step={5}
        min={15}
        max={60}
      />
      <DaysToRepeatMultiOption
        name='daysRepeat'
        icon='repeat'
        options={days.map((day: string) => ({ id: day, name: day.substring(0, 3) }))}
      />
      <div>
        <AddAvailabilityEndDate showEndDate={showEndDate} onToggle={toggleShowEndDateHandler} />
      </div>
      <TextArea
        dataTestId='addAvailabilityForm-descriptionInput'
        name='description'
        icon='details'
        placeholder={`${language.addDescription} (${language.optional})`}
      />
    </>
  );
};

export default AddAvailabilityFormContent;
