import { FC, PropsWithChildren } from 'react';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { userIsMedic, userIsPatient } from 'utils/user';
import { EmptyListWrapper } from './EmptyList.styles';

export interface IEmptyListProps extends PropsWithChildren<{}> {}

const EmptyList: FC<IEmptyListProps> = (props: PropsWithChildren<IEmptyListProps>) => {
  const {} = props;
  const userType = useSelector((state: IReduxState) => state.auth.type);
  const language = useSelector((state: IReduxState) => state.language.values);
  const isMedic = userIsMedic(userType);
  const isPatient = userIsPatient(userType);

  return (
    <EmptyListWrapper as='section' direction='column'>
      <p>{isMedic ? language.patientsListIsEmpty : isPatient ? language.medicsListIsEmpty : ''}</p>
      <h3>
        {isMedic
          ? language.youWillHaveAccessToPatientsAfterFirstAppointment
          : isPatient
          ? language.youWillHaveAccessToMedicsAfterFirstAppointment
          : ''}
      </h3>
      <img />
    </EmptyListWrapper>
  );
};

export default EmptyList;
