import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import FullCalendar, {
  DayCellContentArg,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  MoreLinkArg,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './MonthView.scss';
import useDate, { DateMasks, GlobalDateMasks } from 'hooks/use-date';
import { areEventsEqual } from 'utils/appointment';
import AgendaContext from 'store/agenda-context';
import { useLocation } from 'react-router-dom';
import { dateIsValid } from 'utils/date';
import MonthEvent from '../Events/MonthEvent';

export interface MonthViewProps {
  selectedEvent?: EventClickArg;
  onSelectEvent: (arg: EventClickArg) => void;
  onSeeMore: (moreLinkArg: MoreLinkArg) => void;
  onCloseEventPopup: () => void;
}

const MonthView: FC<MonthViewProps> = (props: PropsWithChildren<MonthViewProps>): JSX.Element => {
  const { selectedEvent, onSelectEvent, onSeeMore, onCloseEventPopup } = props;
  const location = useLocation();
  const { monthEvents, calendarRef, selectDayNumberHandler, selectDayCellHandler } = useContext(AgendaContext);
  const { days, formatDate } = useDate();
  const searchParameters = new URLSearchParams(location.search);
  const urlStartDate = searchParameters.get('start');

  useEffect(() => {
    if (dateIsValid(urlStartDate)) calendarRef.current?.getApi().gotoDate(new Date(urlStartDate!));
  }, []);

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView='dayGridMonth'
      height='100%'
      headerToolbar={false}
      firstDay={1}
      unselectAuto={false}
      dayHeaderContent={(dayHeader: DayHeaderContentArg) => days[dayHeader.date.getDay()]}
      dayCellContent={(dayCell: DayCellContentArg) => (
        <span data-testid={`agenda-calendarDayNumber${formatDate(dayCell.date, GlobalDateMasks.urlDate)}`}>
          {dayCell.isOther ? formatDate(dayCell.date, DateMasks.shortMonthDayNumber) : dayCell.date.getDate()}
        </span>
      )}
      // events
      eventOrder=''
      events={monthEvents}
      eventContent={(eventContentArg: EventContentArg) => (
        <MonthEvent
          event={eventContentArg}
          selected={areEventsEqual(selectedEvent?.event, eventContentArg.event)}
          onClose={onCloseEventPopup}
        />
      )}
      moreLinkContent={() => <MonthEvent />}
      moreLinkClick={onSeeMore}
      dayPopoverFormat={(a) => formatDate(a.date.marker, DateMasks.mediumDate)}
      eventClick={onSelectEvent}
      // days head
      navLinks={true}
      navLinkDayClick={selectDayNumberHandler}
      // day cells
      dateClick={selectDayCellHandler}
      ref={calendarRef}
    />
  );
};

export default MonthView;
