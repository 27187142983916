import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import LanguagePopup from './LanguagePopup';
import useAuthenticationLinks, { ILink } from './AuthenticationLinks.logic';
import { Button, SmallText, styles } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import { AuthenticationLinksWrapper, AuthenticationLinksLanguageIcon } from './AuthenticationLinks.styles';
import { FC, PropsWithChildren } from 'react';

export interface IAuthenticationLinksProps extends PropsWithChildren<{}> {}

const AuthenticationLinks: FC<IAuthenticationLinksProps> = (props: IAuthenticationLinksProps): JSX.Element => {
  const {} = props;
  const {
    languageCode,
    language,
    links,
    popupIsOpen,
    languagePopupIsOpen,
    togglePopup,
    toggleLanguagePopupHandler,
    selectLanguageHandler,
  } = useAuthenticationLinks();

  return (
    <>
      {isMobileOnly && <Button className={styles['popup_button']} icon='options' type='text' onClick={togglePopup} />}
      {(!isMobileOnly || popupIsOpen) && (
        <AuthenticationLinksWrapper>
          {links.map(({ name, link }: ILink, index) => (
            <SmallText as={Link} key={index} to={link}>
              <b>{language[name]}</b>
            </SmallText>
          ))}
          <span>
            <SmallText as='span' data-testid='authenticationLinks-language' onClick={toggleLanguagePopupHandler}>
              <b>{language.language}</b>
              <AuthenticationLinksLanguageIcon
                src='arrowDown'
                size={styles.iconSize.textSmall}
                fill={styles.colors.brand.dark}
                isOpen={languagePopupIsOpen}
              />
            </SmallText>
            <LanguagePopup
              show={languagePopupIsOpen}
              code={languageCode}
              onSelect={selectLanguageHandler}
              onClosePopup={toggleLanguagePopupHandler}
            />
          </span>
        </AuthenticationLinksWrapper>
      )}
    </>
  );
};

export default AuthenticationLinks;
