import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useNotesService from 'services/notes.service';
import AppointmentContext from 'store/appointment-context';
import { WriteNoteAutoSaveProps } from './WriteNoteAutoSave';

export interface IUseWriteNoteAutoSave {
  language: ILanguage;
  isLoading: boolean;
  success: boolean;
  failed: boolean;
}

const useWriteNoteAutoSave = (props: PropsWithChildren<WriteNoteAutoSaveProps>): IUseWriteNoteAutoSave => {
  const { save } = props;
  const { appointmentEvent, note } = useContext(AppointmentContext);
  const { createOrUpdateAppointmentNote, isLoading } = useNotesService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);

  useEffect(() => {
    let isSubscribed = true;
    let sucessTimeout: NodeJS.Timeout;
    let failedTimeout: NodeJS.Timeout;

    if (save) {
      createOrUpdateAppointmentNote(appointmentEvent!.appointment!.id, note)
        .then(() => {
          if (!isSubscribed) return;

          setSuccess(true);

          sucessTimeout = setTimeout(() => {
            if (!isSubscribed) return;
            setSuccess(false);
          }, 2000);
        })
        .catch(() => {
          if (!isSubscribed) return;

          setFailed(true);

          failedTimeout = setTimeout(() => {
            if (!isSubscribed) return;
            setFailed(false);
          }, 2000);
        });
    }

    return () => {
      isSubscribed = false;
      clearTimeout(sucessTimeout);
      clearTimeout(failedTimeout);
    };
  }, [save]);

  return { language, isLoading, success, failed };
};

export default useWriteNoteAutoSave;
