import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { goBack } from 'utils/general';
import { FormSubmit } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import useAvailabilitiesService from 'services/availabilities.service';

export interface IUseAddAvailability {
  language: ILanguage;
  isLoading: boolean;
  backHandler: () => void;
  createAvailabilityHandler: (submit: FormSubmit) => void;
}

const useAddAvailability = (): IUseAddAvailability => {
  const history = useHistory();
  const { isLoading, createAvailability } = useAvailabilitiesService();
  const language = useSelector((state: IReduxState) => state.language.values);

  const backHandler = (): void => {
    goBack(history);
  };

  const createAvailabilityHandler = (submit: FormSubmit) => {
    createAvailability(submit).then(() => {
      goBack(history);
    });
  };

  return {
    language,
    isLoading,
    backHandler,
    createAvailabilityHandler,
  };
};

export default useAddAvailability;
