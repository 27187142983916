import { useDate } from '@AlticeLabsProjects/smartal-b2c-frontend-utils';
import EventDTO from 'dtos/EventDTO';
import { IDayGroup } from 'interfaces/IGroup';
import ILanguage from 'interfaces/ILanguage';
import IReduxState from 'interfaces/IReduxState';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAppointmentsService from 'services/appointments.service';
import { updateUndefinedArray } from 'utils/general';
import { groupByDay, groupByYear } from 'utils/group';
import { LOG_HTTP } from 'utils/logger';
import { useWinstonLogger } from 'winston-react';

export interface IUseAppointments {
  language: ILanguage;
  nextAppointments?: IDayGroup<IDayGroup<EventDTO>>[];
  pastAppointments?: IDayGroup<IDayGroup<EventDTO>>[];
  hasMorePastAppointments: boolean;
  formatDate: (date: Date, mask: string) => string;
  loadMorePastAppointmentsHandler: () => void;
}

const useAppointments = (): IUseAppointments => {
  const { id: patientId } = useParams<{ id: string }>();
  const logger = useWinstonLogger();
  const { formatDate } = useDate();
  const { getNextAppointmentsWithPatient, getPastAppointmentsWithPatient } = useAppointmentsService();
  const language = useSelector((state: IReduxState) => state.language.values);
  const [nextAppointments, setNextAppointments] = useState<EventDTO[]>();
  const [pastAppointments, setPastAppointments] = useState<EventDTO[]>();
  const [pastAppointmentsPage, setPastAppointmentsPage] = useState<number>(0);
  const [hasMorePastAppointments, setHasMorePastAppointments] = useState<boolean>(false);

  useEffect(() => {
    getNextAppointmentsWithPatient(patientId).then((appointments: EventDTO[]) => setNextAppointments(appointments));
    getPastAppointments();
  }, []);

  const getPastAppointments = useCallback(() => {
    getPastAppointmentsWithPatient(patientId, pastAppointmentsPage).then(
      ({ appointments, last }: { appointments: EventDTO[]; last: boolean }) => {
        setPastAppointments((prevState?: EventDTO[]) => updateUndefinedArray(appointments, prevState));

        if (!last) setPastAppointmentsPage((prevState: number) => ++prevState);
        else logger.log(LOG_HTTP, `past appointments' pagination reached the end at page ${pastAppointmentsPage}`);

        setHasMorePastAppointments(!last);
      }
    );
  }, [pastAppointmentsPage]);

  const loadMorePastAppointmentsHandler = (): void => getPastAppointments();

  return {
    language,
    nextAppointments: nextAppointments ? groupByYear(groupByDay(nextAppointments)) : undefined,
    pastAppointments: pastAppointments ? groupByYear(groupByDay(pastAppointments)) : undefined,
    hasMorePastAppointments,
    formatDate,
    loadMorePastAppointmentsHandler,
  };
};

export default useAppointments;
