import IReduxState from 'interfaces/IReduxState';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { StartOptionalOnboardingIllustration, StartOptionalOnboardingSubtitle } from './StartOptionalOnboarding.styles';

export interface StartOptionalOnboardingProps extends PropsWithChildren<{}> {
  infoText: string;
}

const StartOptionalOnboarding: FC<StartOptionalOnboardingProps> = (props: StartOptionalOnboardingProps) => {
  const { infoText } = props;
  const language = useSelector((state: IReduxState) => state.language.values);

  return (
    <>
      <h1>{language.goodJob}</h1>
      <StartOptionalOnboardingSubtitle>{language.letsUpdateYourProfile}</StartOptionalOnboardingSubtitle>
      <p>{infoText}</p>
      <StartOptionalOnboardingIllustration />
    </>
  );
};

export default StartOptionalOnboarding;
