import { Flex, IFlexProps } from '@AlticeLabsProjects/smartal-b2c-frontend-ui';
import styled, { css } from 'styled-components';

export interface IEventCardTimeProps {
  isSelected?: boolean;
  hasPassed: boolean;
}

export const EventCardTime = styled.div<IEventCardTimeProps>`
  margin-right: 1rem;

  ${({ hasPassed }: IEventCardTimeProps) =>
    hasPassed
      ? css`
          opacity: 0.75;
        `
      : css``}

  > * {
    font-weight: ${({ isSelected }: IEventCardTimeProps) => (isSelected ? 600 : 400)};
    white-space: nowrap;
  }
`;

export interface IEventCardContainerProps {
  hasPassed: boolean;
}

export const EventCardContainer = styled(Flex).attrs((_: IFlexProps) => ({
  justifyContent: 'flex-start',
}))<IEventCardContainerProps>`
  width: 100%;
  height: 5rem;
  border-radius: 1.5rem;
  padding: 1rem;
  box-sizing: border-box;

  ${({ hasPassed }: IEventCardTimeProps) =>
    hasPassed
      ? css`
          opacity: 0.75;
        `
      : css``}
`;

/*
@media screen and (max-width: ($tablet + 0px)) {
  .event-time {
    margin-right: 2%;
  }
}
*/
