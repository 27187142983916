import { DateMasks } from 'hooks/use-date';
import BaseInput from '../BaseInput';
import { InputProps, InputsInnerProps, INPUT_TYPES } from '../Input';
import useTimeInput from './TimeInput.logic';
import styles from './TimeInput.module.scss';
import TimePicker from './TimePicker';

export type TimeInputProps = InputProps & InputsInnerProps & {};

const TimeInput: React.FC<TimeInputProps> = (props: React.PropsWithChildren<TimeInputProps>) => {
  const { dataTestId, className, inputValue: value } = props;
  const { showTimePicker, containerRef, formatDate, setShowTimePicker, openDialogHandler, changeHandler } =
    useTimeInput(props);

  return (
    <div className={styles['time_input_container']} ref={containerRef}>
      <BaseInput
        {...{
          ...props,
          className: `${styles['time_input']} ${className}`,
          value: formatDate(value as Date, DateMasks.shortTime),
          canEdit: false,
          type: INPUT_TYPES.TEXT,
          focusHandler: openDialogHandler,
        }}
      />
      <TimePicker
        dataTestId={dataTestId}
        show={showTimePicker}
        hour={(value as Date).getHours()}
        minutes={(value as Date).getMinutes()}
        close={setShowTimePicker}
        onChange={changeHandler}
      />
    </div>
  );
};

export default TimeInput;
