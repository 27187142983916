import IReduxState from 'interfaces/IReduxState';
import { ILoginRouteState } from 'interfaces/IRouteStates';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from 'routes/routes';

const enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type UseHttp = {
  isLoading: boolean;
  get: <Schema, Interface>(url: string, parameters?: any, mapper?: any | undefined) => Promise<any>;
  post: <Schema, Interface>(url: string, parameters?: any, body?: any, mapper?: any | undefined) => Promise<any>;
  put: <Schema, Interface>(url: string, parameters?: any, body?: any, mapper?: any | undefined) => Promise<any>;
  patch: <Schema, Interface>(url: string, parameters?: any, body?: any, mapper?: any | undefined) => Promise<any>;
  del: <Schema, Interface>(url: string, parameters?: any, body?: any) => Promise<any>;
};

const useHttp = (): UseHttp => {
  const history = useHistory();
  const token = useSelector((state: IReduxState) => state.auth.token);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [controller] = useState<AbortController>(new AbortController());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let isSubscribed = true;

  useEffect(() => {
    return () => {
      controller.abort();
      isSubscribed = false;
      setIsMounted(false);
    };
  }, []);

  const getHeaders = (method: METHOD, bodyIsFormData: boolean = false): RequestInit => {
    let headers = new Headers();
    if (token) headers.append('Authorization', `Bearer ${token}`);
    if (!bodyIsFormData) headers.append('Content-Type', 'application/json');

    return {
      method,
      mode: 'cors',
      credentials: 'same-origin',
      headers: headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    };
  };

  const getParameters = (params: any): string => {
    if (params === undefined) return '';

    const parameters = new URLSearchParams(params);
    parameters.forEach((value: string, key: string) => {
      if (value === 'undefined') parameters.delete(key);
    });

    const parametersString = parameters.toString();
    return parametersString === '' ? '' : `?${parametersString}`;
  };

  const request = <Schema, Interface>(
    method: METHOD,
    url: string,
    parameters?: any,
    body?: any,
    mapper?: any
  ): Promise<Schema | Interface | Interface[] | string | void> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);

      const bodyIsFormData = body instanceof FormData;

      fetch(`${url}${getParameters(parameters)}`, {
        signal: controller.signal,
        ...getHeaders(method, bodyIsFormData),
        body: body ? (bodyIsFormData ? body : JSON.stringify(body)) : undefined,
      })
        .then((response) => {
          if (isSubscribed) setIsLoading(false);

          const contentType = response.headers.get('Content-Type');

          if (response.ok) {
            if (contentType?.includes('application/json')) {
              return response.json().then((json: any) => {
                if (!isMounted) return;

                if (!mapper) resolve(json);
                else if (Array.isArray(json)) resolve(json.map((data) => mapper!.toInterface(data) as Interface));
                else resolve(mapper!.toInterface(json) as Interface);
              });
            } else if (contentType?.includes('text/plain')) {
              return response.text().then(() => {
                if (isMounted) resolve();
              });
            } else if (
              contentType?.includes('image/jpeg') ||
              contentType?.includes('image/png') ||
              contentType?.includes('application/pdf')
            ) {
              return response.blob().then((blob: Blob) => {
                if (isMounted) resolve(URL.createObjectURL(blob));
              });
            }

            if (isMounted) resolve();
          } else if (response.status === 401) {
            history.push(routes.login.path, { unauthorized: true } as ILoginRouteState);
          } else {
            if (contentType?.includes('application/json')) {
              return response.json().then((json: any) => {
                reject({ status: response.status, message: json.message, errors: json.errors });
              });
            }

            reject({ status: response.status, message: response.statusText });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.name !== 'AbortError') reject(error);
        });
    });
  };

  const get = <Schema, Interface>(
    url: string,
    parameters?: any,
    mapper?: any
  ): Promise<Interface | Interface[] | any> => {
    return request<Schema, Interface>(METHOD.GET, url, parameters, undefined, mapper);
  };

  const post = <Schema, Interface>(
    url: string,
    parameters?: any,
    body?: any,
    mapper?: any
  ): Promise<Interface | Interface[] | any> => {
    return request<Schema, Interface>(METHOD.POST, url, parameters, body, mapper);
  };

  const put = <Schema, Interface>(
    url: string,
    parameters?: any,
    body?: any,
    mapper?: any
  ): Promise<Interface | Interface[] | any> => {
    return request<Schema, Interface>(METHOD.PUT, url, parameters, body, mapper);
  };

  const patch = <Schema, Interface>(
    url: string,
    parameters?: any,
    body?: any,
    mapper?: any
  ): Promise<Interface | Interface[] | any> => {
    return request<Schema, Interface>(METHOD.PATCH, url, parameters, body, mapper);
  };

  const del = <Schema, Interface>(
    url: string,
    parameters?: any,
    body?: any
  ): Promise<Interface | Interface[] | any> => {
    return request<Schema, Interface>(METHOD.DELETE, url, parameters, body, undefined);
  };

  return { isLoading, get, post, put, patch, del };
};

export default useHttp;
