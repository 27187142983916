import AlertDTO from 'dtos/AlertDTO';
import { Transition, TransitionGroup, TransitionStatus } from 'react-transition-group';
import Alert from './Alert';
import useAlerts from './Alerts.logic';
import styles from './Alerts.module.scss';
import sass from 'styles/exports.module.scss';

export type AlertsProps = {};

const Alerts: React.FC<AlertsProps> = (props: React.PropsWithChildren<AlertsProps>) => {
  const {} = props;
  const { alerts, closeAlertHandler } = useAlerts();

  return (
    <div data-testid='alerts' className={styles.alerts}>
      <TransitionGroup component={null}>
        {alerts.map((alert: AlertDTO, i: number) => (
          <Transition key={alert.id} timeout={+sass.transition} appear={true} mountOnEnter={true} unmountOnExit={true}>
            {(state: TransitionStatus) => (
              <Alert
                key={alert.id}
                dataTestId={`alerts-alert${i}`}
                className={styles[state]}
                alert={alert}
                onClose={() => closeAlertHandler(alert.id)}
              />
            )}
          </Transition>
        ))}
      </TransitionGroup>
    </div>
  );
};

export default Alerts;
